import React, { useEffect } from 'react'
import { Card, Grid, Icon, Typography, Table, Box, IconButton, Stack, TextField } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteAnnouncements, deleteFailureReason, getAnnouncements } from '../../../../redux/slices/hyperdrive/setting';
import { useSelector } from 'react-redux';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../../redux/slices/modalSlice';
import { openSnackbar } from '../../../../redux/slices/common';

export default function AnnouncementsList() {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams();
    const { mainAnnouncementsData } = useSelector(state => state?.commonHyperdrive)

    useEffect(() => {
        dispatch(getAnnouncements())
    }, [])

    const deleteHandler = (announcementId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteAnnouncements(announcementId)).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    return (
        <Card sx={{ py: 3, px: 4, mb: 4, borderRadius: 0 }}>
            <MDBox display="flex" alignItems="center" justifyContent="space-between" mb={3} mt={1}>
                <MDBox>
                    <Typography variant='h5' fontWeight="semibold" color="#F5F5FF">Announcements</Typography>
                </MDBox>
                <MDButton color="primary" size="medium" sx={{ minWidth: "120px" }} onClick={() => setSearchParams({ roleTab: 'Announcements/add' })}>
                    <Icon>add</Icon>&nbsp;Add
                </MDButton>
            </MDBox>

            <Grid container alignItems="center" spacing={{ xs: 3, md: 3 }} mb={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Table className='hw-table' border="0" cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                <th>
                                    <Typography className='text-black-400 text-12 text-left'>Announcements</Typography>
                                </th>
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                mainAnnouncementsData?.data?.map((announceData) => {
                                    return (
                                        <tr key={announceData?._id}>
                                            <td>
                                                <Typography className='text-white-700 text-14'>{announceData?.announcement}</Typography>
                                            </td>
                                            <td>
                                                <Box display="flex" alignItems="center" justifyContent="end" className="table-action-btn">
                                                    <IconButton color='primary' size='small' variant="primary">
                                                        <EditIcon width='16px' height='16px' onClick={() => { setSearchParams({ roleTab: `Announcements/edit/id=${announceData?._id}` }) }} />
                                                    </IconButton>
                                                    <IconButton color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: announceData?._id, deleteType: 'announcement' }, modal: 'DELETE_MODAL' }))
                                                        }}  >
                                                        <DeleteIcon width='16px' height='16px' />
                                                    </IconButton>
                                                </Box>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </Table>
                </Grid>
            </Grid>
        </Card>
    )
}