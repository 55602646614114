import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import successMarker from "../../../assets/images/icons/marker/redmark.svg";
import darkMarker from "../../../assets/images/icons/marker/greenmark.svg";
import driverIdle from "../../../assets/images/icons/driversMarker/driver-idle.svg"
import onTransit from "../../../assets/images/icons/driversMarker/on-transit.svg"
import driverOffDuty from "../../../assets/images/icons/driversMarker/off-duty.svg"



import { useDispatch } from 'react-redux';
import { openCustomDrawer } from '../../../redux/slices/modalSlice';
import { fetchAllDriversLocations } from '../../../redux/slices/hyperdrive/drivers';
import { useSelector } from 'react-redux';
import { formatTimestampToDateView } from 'utilities/common';
import { InfoOutlined } from '@mui/icons-material';
import H from '@here/maps-api-for-javascript';
import { isEmptyObject } from 'utilities/common';

function getRandomCoordinate(base, offset) {
  return base + (Math.random() * 2 - 1) * offset;
}
function getRandomStatus() {
  // Randomly return "1" or "2"
  return Math.random() > 0.5 ? "1" : "2";
}



// function generateRandomLocations(numLocations) {
//   const baseLat = 33.877491;
//   const baseLng = -117.566096;
//   const offset = 0.02; // Offset for random lat/long generation

//   const locations = [];

//   for (let i = 0; i < numLocations; i++) {
//     const randomLat = getRandomCoordinate(baseLat, offset);
//     const randomLng = getRandomCoordinate(baseLng, offset);

//     const location = {
//       "lat": randomLat,
//       "lng": randomLng,
//       "status": getRandomStatus(),
//       "tasks": [
//         {
//           "failureDetails": {},
//           "attachments": {
//             "photoUrl": []
//           },
//           "companyAnnoucementIds": [],
//           "_id": `66dacfc0c5ef3d2b666bd3b1`, // Unique ID for each task
//           // "_id": `66d064eb5c78548965d0ba78${i}`,
//           "taskType": "deliveryTask",
//           "taskTag": "high",
//           "taskAssignmentMode": "manual",
//           "fleetId": "66c789d20d318e94f8b62a5c",
//           "taskDescription": "descriptions",
//           "recipientDetails": {
//             "name": "Mathew",
//             "phone": "+19876545790",
//             "notes": "please reach before time if possible"
//           },
//           "orderDetails": "Please don't ring the bell",
//           "address": {
//             "address1": "945 White Ranch Circle, Corona, CA, USA",
//             "address2": "",
//             "city": "Corona",
//             "state": "CA",
//             "zipcode": "92881",
//             "country": "US",
//             "latitude": randomLat.toString(),
//             "longitude": randomLng.toString(),
//             "notes": "urgent"
//           },
//           "taskStartTime": "2024-08-30T11:30:00.000Z",
//           "taskEndTime": "2024-08-30T12:20:00.000Z",
//           "driverAppRequirements": {
//             "customerSignature": true,
//             "deliveryPhoto": true,
//             "addNotes": true
//           },
//           "multipleFleetIds": [],
//           "items": [
//             {
//               "productId": "668ef41501bdfc49d503e31b",
//               "quantity": 1,
//               "productName": "Biscotti",
//               "productSku": "H31BPRO2",
//               "categoryName": "Premium",
//               "brandName": "Flora & Flame"
//             }
//           ],
//           "isActive": true,
//           "taskStatus": "not_started",
//           "createdBy": "60e6e5697e92567d11ada1b2",
//           "createdDate": "2024-08-29T12:09:15.799Z",
//           "updatedDate": "2024-08-29T12:09:15.799Z",
//           "taskDisplayId": 1031,
//           "taskArchived": true,
//           "expectedArrivalTime": "2024-08-30T11:30:00.000Z",
//           "fleet": {
//             "locationData": {
//               "latitude": randomLat,
//               "longitude": randomLng
//             },
//             "_id": "66c789d20d318e94f8b62a5c",
//             "fleetOnDutyStatus": false,
//             "fleetName": "ttt",
//             "fleetEmail": "shgfdfgadeleted@GFhfhh.com"
//           }
//         }
//       ],
//       "fleetId": "66c789d20d318e94f8b62a5c"
//     };

//     locations.push(location);
//   }

//   return locations;
// }


function getDomIcon(count, marker) {
  var outerElement = document.createElement('div'),
    imageElement = document.createElement('img'),
    countElement = document.createElement('div');

  outerElement.style.position = 'relative';  // Relative position for correct count placement
  outerElement.style.userSelect = 'none';
  outerElement.style.webkitUserSelect = 'none';
  outerElement.style.msUserSelect = 'none';
  outerElement.style.mozUserSelect = 'none';
  outerElement.style.cursor = 'pointer';

  // Configure the image element (marker image)
  imageElement.src = marker;
  // imageElement.style.width = '40px'; // Set marker image size
  // imageElement.style.height = '40px';
  // imageElement.style.borderRadius = '50%'; // Circle image, adjust as needed

  // Configure the count element (text over marker)
  countElement.style.color = 'white';
  countElement.style.backgroundColor = 'red';
  countElement.style.borderRadius = '50%';
  countElement.style.font = 'bold 12px arial';
  countElement.style.textAlign = 'center';
  countElement.style.width = '18px';
  countElement.style.height = '18px';
  countElement.style.lineHeight = '18px';
  countElement.style.position = 'absolute';
  countElement.style.right = '-8px';
  countElement.style.top = '-8px';

  // Set the count text
  countElement.innerHTML = count;

  // Append the image and count to the outer container
  outerElement.appendChild(imageElement);
  if (count > 1) {
    outerElement.appendChild(countElement);
  }
  return outerElement
}


const MapDrive = ({ dataPassComponent }) => {
  const dispatch = useDispatch()
  const { driversLocation = {}, loading = false } = useSelector(state => state.drivers)

  const mapRef = useRef(null);
  const mapKey = process.env.REACT_APP_MAP_KEY;
  const mapInstance = useRef(null);
  const markersRef = useRef([]);
  const mapUIRef = useRef(null)
  const mapMethodsRef = useRef(null)
  const defaultLayersRef = useRef(null)
  const [taskDetailFetch, setTaskDetailFetch] = useState("")
  const selectedPopup = useRef()
  const clustringRef = useRef()
  const dataLayerRef = useRef()
  const clusterLayerRef = useRef([])

  const memoizeDriversLocation = useMemo(() => {
    //  return [
    //     { lat: 33.8255728, lng: -117.5634377, label: 'Los Angeles', status: '1' },
    //     { lat: 34.0246, lng: -118.2428, label: 'Santa Monica', status: '3' },
    //   ]
    const locations = driversLocation?.data?.length > 0 ? driversLocation?.data?.map((location => {
      return {
        lat: location?.locationData?.latitude || 33.8254462,
        lng: location?.locationData?.longitude || -117.5636722,
        status: location?.fleetStatus ? '1' : '2',
        fleetId: location?.fleetId
      }
    })) || [] : []
    return locations
  }, [driversLocation])

  const groupByLocation = (data) => {
    const locationMap = new Map();
    console.log("mapasp", data)
    data.forEach((item) => {
      item.tasks.forEach((task) => {
        const { latitude = '', longitude = '' } = task?.address || {};
        const locationKey = `${latitude},${longitude}`; // create a unique key for the location

        if (!locationMap.has(locationKey)) {
          locationMap.set(locationKey, []);
        }
        locationMap.get(locationKey).push(task);
      });
    });

    // Return the grouped locations as an array
    return Array.from(locationMap.values());
  };

  const filterTaskbyLocation = (location = {}) => {
    let fitlerTasks = []
    driversLocation?.data?.filter((driver => driver?.tasks))?.forEach((_tasks => {
      _tasks?.tasks?.forEach((taskLocation => {
        if (Number(taskLocation?.address?.latitude)?.toFixed(6) === Number(location?.lat)?.toFixed(6)) {
          fitlerTasks?.push(taskLocation)
        }
      }))
    }))
    return fitlerTasks
  }
  const memoizeDriversTasksLocation = useMemo(() => {
    //  return [
    //     { lat: 33.8255728, lng: -117.5634377, label: 'Los Angeles', status: '1' },
    //     { lat: 34.0246, lng: -118.2428, label: 'Santa Monica', status: '3' },
    //   ]
    const taskLists = []
    driversLocation?.data?.length > 0 ? driversLocation?.data?.forEach((driver => {
      driver?.tasks?.forEach((_tasks => {
        taskLists.push(_tasks)
      }))
    })) : [];

    let taskPoints = taskLists?.map((_taskItem => {
      return {
        lat: _taskItem?.address?.latitude || 33.8254462,
        lng: _taskItem?.address?.longitude || -117.5636722,
        status: _taskItem?.fleet?.fleetOnDutyStatus ? '1' : '2',
        // tasks: task?.tasks,
        fleetId: _taskItem?.fleetId
      }
    }))
    return taskPoints

  }, [driversLocation])





  // useEffect(() => {
  //   if (!H) return;
  //   const platform = new H.service.Platform({
  //     apikey: mapKey,
  //   });
  //   const rasterTileService = platform.getRasterTileService({
  //     queryParams: {
  //       'style': 'logistics.night',
  //       'features': 'vehicle_restrictions:disabled,pois:disabled',
  //       tileSize: 256, // Reduce tile size (default is 512)
  //       ppi: 72        // Lower pixels-per-inch (for lower resolution)
  //     }
  //   });
  //   const rasterTileProvider = new H.service.rasterTile.Provider(rasterTileService);
  //   const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);



  //   const defaultLayers = platform.createDefaultLayers({
  //     tileSize: 256, ppi: 72,
  //   });
  //   defaultLayersRef.current = defaultLayers

  //   mapInstance.current = new H.Map(
  //     mapRef.current,
  //     rasterTileLayer,
  //     {
  //       zoom: 5,
  //       // center: { lat: 20.5937, lng: 78.9629 },
  //       center: { lat: 33.8255728, lng: -117.5634377 },
  //       pixelRatio: window.devicePixelRatio || 1
  //     }
  //   );
  //   // let provider = mapInstance.current.getBaseLayer().getProvider()
  //   // let style = new H.map.Style('heremapStyle/style.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/')
  //   // provider.setStyle(style)

  //   // // Load custom style into the vector layer:
  //   // let customLayer = platform.getMapTileService({
  //   //   type:'base'
  //   // }).createTileLayer('maptile', 'normal.day', 256, 'png8', {
  //   //   style: customStyle
  //   // });

  //   // // Replace the base layer with the custom styled layer:
  //   // mapInstance.current.setBaseLayer(customLayer);
  //   // mapMethodsRef.current = new H.map
  //   new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance.current));
  //   // var bounds = new H.geo.Rect(24.396308, -125.00165, 49.384358, -66.93457); // Min/max latitude/longitude
  //   // mapInstance.current.getViewModel().setLookAtData({
  //   //   bounds: bounds
  //   // });

  //   // mapInstance.current.addEventListener('mapviewchangeend', function() {
  //   //   var currentZoom = mapInstance.current.getZoom();
  //   //   console.log("currentZoom",currentZoom)
  //   //   if (currentZoom < 5) {
  //   //     mapInstance.current.setZoom(5);  // Maintain a zoom level similar to the screenshot
  //   //   }
  //   // });
  //   mapUIRef.current = H.ui
  //   const ui = H.ui.UI.createDefault(mapInstance.current, defaultLayers);
  //   const overviewMap = new H.ui.Overview(defaultLayers.raster.satellite.map, {
  //     alignment: H.ui.LayoutAlignment.LEFT_BOTTOM,
  //     zoomDelta: 6,
  //     scaleX: 5,
  //     scaleY: 6,
  //   });
  //   ui.addControl('overview', overviewMap);

  // }, [mapKey]);


  useEffect(() => {
    if (!H) return;
    const platform = new H.service.Platform({
      apikey: mapKey,
      // useCIT: true, // enable lite mode
      // useHTTPS: true
    });
    const rasterTileService = platform.getRasterTileService({
      queryParams: {
        'style': 'explore.night',
        'features': 'vehicle_restrictions:disabled,pois:disabled',
        size: 256, // Reduce tile size (default is 512)
        // ppi: 72        // Lower pixels-per-inch (for lower resolution)
      }
    });
    const rasterTileProvider = new H.service.rasterTile.Provider(rasterTileService);
    const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);

    const defaultLayers = platform.createDefaultLayers({ lg: 'zh' });
    defaultLayersRef.current = defaultLayers
    mapInstance.current = new H.Map(
      mapRef.current,
      rasterTileLayer,
      {
        zoom: 10,
        // center: { lat: 20.5937, lng: 78.9629 },
        center: { lat: 30.7333, lng: 76.7794 },
        pixelRatio: window.devicePixelRatio || 1
      }

    );
    // var bounds = new H.geo.Rect(24.396308, -125.00165, 49.384358, -66.93457); // Min/max latitude/longitude
    // mapInstance.current.getViewModel().setLookAtData({
    //   bounds: bounds
    // });

    new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance.current));

    rasterTileLayer.setMin(3)

    const ui = H.ui.UI.createDefault(mapInstance.current, defaultLayers, 'zh-CN');

    // const overviewMap = new H.ui.Overview(defaultLayers.raster.satellite.map, {
    //   alignment: H.ui.LayoutAlignment.LEFT_BOTTOM,
    //   zoomDelta: 6,
    //   scaleX: 5,
    //   scaleY: 6,
    // });
    ui.setUnitSystem(H.ui.UnitSystem.IMPERIAL)
    ui.removeControl('mapsettings')
    // ui.addControl('overview', overviewMap);


    return () => {
      mapInstance.current.dispose();
    };

  }, [mapKey]);
  const removeMarkers = () => {
    markersRef?.current?.length > 0 && markersRef?.current?.forEach((marker) => {
      mapInstance.current.removeObject(marker); // Remove from map
    });
    markersRef.current = []; // Clear markers array
  };

  function getRandomDataPoint(cluster) {
    var dataPoints = [];

    // Iterate through all points which fall into the cluster and store references to them
    cluster.forEachDataPoint(dataPoints.push.bind(dataPoints));

    // Randomly pick an index from [0, dataPoints.length) range
    // Note how we use bitwise OR ("|") operator for that instead of Math.floor
    return dataPoints[Math.random() * dataPoints.length | 0];
  }

  const getCustomClustorTheme = (markerIcon = darkMarker) => {
    return {
      getClusterPresentation: function (cluster) {
        // Get random DataPoint from our cluster
        var randomDataPoint = getRandomDataPoint(cluster)
        // Get a reference to data object that DataPoint holds

        //   // Create a marker for the cluster
        //  let noiseMarker = new H.map.Marker(cluster.getPosition(), {
        //     min: cluster.getMinZoom(),
        //     icon: new H.map.Icon(markerIcon, {
        //       // size: { w: 20, h: 20 },
        //       // anchor: { x: 10, y: 10 }
        //     })
        //     // icon:getCustomClusterIcon(noisePoint,markerIcon)
        //   });

        // noiseMarker.setData(data);

        // return noiseMarker;

        let domIcon = new H.map.DomIcon(getDomIcon(cluster?.getWeight(), markerIcon));

        // Create a marker from a random point in the cluster
        let clusterMarker = new H.map.DomMarker(cluster.getPosition(), {
          icon: domIcon,
          // Set min/max zoom with values from the cluster,
          // otherwise clusters will be shown at all zoom levels:
          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom()
        });

        // Link data from the random point from the cluster to the marker,
        // to make it accessible inside onMarkerClick
        clusterMarker.setData(cluster);

        return clusterMarker;


      },
      getNoisePresentation: function (noisePoint) {
        // Get a reference to data object our noise points
        var data = noisePoint.getData()
        // Create a marker for the noisePoint
        // noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
        //   // Use min zoom from a noise point
        //   // to show it correctly at certain zoom levels:
        //   min: noisePoint.getMinZoom(),
        //   icon: new H.map.Icon(markerIcon, {
        //     // size: { w: 20, h: 20 },
        //     // anchor: { x: 10, y: 10 }
        //   })
        //   // icon:getCustomClusterIcon(noisePoint,markerIcon)
        // });


        let domIcon = new H.map.DomIcon(getDomIcon(noisePoint?.getWeight(), markerIcon));

        // Create a marker from a random point in the cluster
        let clusterMarker = new H.map.DomMarker(noisePoint.getPosition(), {
          icon: domIcon,
          // Set min/max zoom with values from the cluster,
          // otherwise clusters will be shown at all zoom levels:
          min: noisePoint.getMinZoom(),
          // max: noisePoint.getMaxZoom()
        });


        // Link a data from the point to the marker
        // to make it accessible inside onMarkerClick
        clusterMarker.setData(noisePoint);

        return clusterMarker;
      }
      // getNoisePresentation: function (noisePoint) {
      //   // Get a reference to data object our noise points
      //   var data = noisePoint.getData(),
      //     // Create a marker for the noisePoint
      //     noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
      //       // Use min zoom from a noise point
      //       // to show it correctly at certain zoom levels:
      //       min: noisePoint.getMinZoom(),
      //       icon: new H.map.Icon(markerIcon, {
      //         // size: { w: 20, h: 20 },
      //         // anchor: { x: 10, y: 10 }
      //       })
      //       // icon:getCustomClusterIcon(noisePoint,markerIcon)
      //     });

      //   // Link a data from the point to the marker
      //   // to make it accessible inside onMarkerClick
      //   noiseMarker.setData(data);

      //   return noiseMarker;
      // }
    };
  }

  // Reusable function to create and add a clustered layer
  const addClusteredLayer = (locations, theme, mapInstance) => {
    let dataPoints = locations?.map(location =>
      new H.clustering.DataPoint(location?.lat, location?.lng)
    );
    // Create a data provider:
    let clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        eps: 64,  // Radius for clustering
        minWeight: 2,  // Minimum cluster weight
      },
      theme: theme  // Use the custom theme passed as an argument
    });

    // Add a tap event listener for clusters

    // Create a layer that includes the clustered data provider:
    let layer = new H.map.layer.ObjectLayer(clusteredDataProvider);
    clusterLayerRef.current.push(layer)
    // Remove previous cluster layer if necessary
    // mapInstance.current.removeLayer(layer);  // Optionally remove the previous layer

    // Add the new layer to the map:
    mapInstance.current.addLayer(layer);
    clusteredDataProvider.addEventListener('tap', (e) => {

      // Get the clicked marker - either a cluster or a noise point:
      var marker = e.target;

      // Get the marker implementing IResult interface:
      var point = marker.getData();

      // Is the marker a cluster?
      var isCluster = point.isCluster();

      if (isCluster) {
        // Log all the noise points inside the cluster along with their geographical position:
        let taskLocations = []
        point.forEachDataPoint((dataPoint) => {
          taskLocations?.push(filterTaskbyLocation(dataPoint.getPosition()))
        });
        showInfoMessage(e.target.getGeometry(), [].concat(...taskLocations))
        // console.groupEnd();
      } else {
        showInfoMessage(e.target.getGeometry(), filterTaskbyLocation(point.getPosition()))
        // We tapped a noise point
        console.log("Noise point at " + point.getPosition().lat + ", " + point.getPosition().lng + " was tapped.");
      }

    });

    // clusteredDataProvider.addEventListener('clustering:cluster', function (event) {
    //   const cluster = event.cluster;
    //   const clusterData = cluster.getData();
    //   const noiseValue = clusterData.length;
    //   console.log('Cluster noise value:', noiseValue);

    //   // Customize cluster marker if needed
    // });

  };

  // Function to show green clusters
  // const showGreenClusters = useCallback((locations) => {
  //   addClusteredLayer(locations, getCustomClustorTheme(darkMarker), mapInstance);
  // }, [mapInstance]);

  // // Function to show red clusters
  // const showRedClusters = useCallback((locations = []) => {
  //   addClusteredLayer(locations, getCustomClustorTheme(successMarker), mapInstance);
  // }, [mapInstance]);

  const showDriverLocations = useCallback(() => {
    if (!H) return;
    const driverIdleIcon = new H.map.Icon(driverIdle);
    const driverOffDutyIcon = new H.map.Icon(driverOffDuty);
    const onTransitDriver = new H.map.Icon(onTransit);

    // const customIconDark = new H.map.Icon(darkMarker);
    // const customIconYellow = new H.map.Icon(yellowMarker);
    // const greenMarkerLocations = memoizeDriversLocation?.filter((location => location.status === '1'))
    // const redMarkerLocations = memoizeDriversLocation?.filter((location => location.status === '2'))
    // const redMarkerLocations=  memoizeDriversLocation?.filter((location=>location.status === '3'))


    memoizeDriversLocation?.forEach(markerData => {
      const marker = new H.map.Marker(
        { lat: markerData?.lat, lng: markerData?.lng },
        // { icon: markerData?.status === '1' ? customIconSuccess : markerData?.status === '3' ? customIconYellow : customIconDark }
        { icon: markerData?.status === '1' ? onTransitDriver : markerData?.status === '2' ? driverOffDutyIcon : driverIdleIcon }
      );
      markersRef.current.push(marker)
      // marker.addEventListener('tap', () => {
      //   showInfoMessage(markerData, markerData?.tasks);
      // });
      // marker.addEventListener('pointerenter', () => {
      //   console.log("ender")
      // });
      // marker.addEventListener('pointerenter', () => {
      //   console.log("ender")
      // });
      // marker.setData(markerData.label);
      mapInstance.current.addObject(marker);
    });

    // // Prepare data for the heat map
    // const heatMapData = memoizeDriversLocation.map(marker => ({
    //   lat: marker.lat,
    //   lng: marker.lng,
    //   value: marker.status === '1' ? 1 : 0.5,
    // }));

    // // Create a provider for the heat map
    // const heatmapProvider = new H.data.heatmap.Provider({
    //   colors: new H.data.heatmap.Colors({
    //     '0': 'blue',
    //     '0.5': 'yellow',
    //     '1': 'red'
    //   }, true),
    //   opacity: 0.1,
    //   assumeValues: true
    // });

    // // Add the data to the heatmap provider
    // heatmapProvider.addData(heatMapData);

    // // Add a layer for the heat map provider to the map
    // mapInstance.current.addLayer(new H.map.layer.TileLayer(heatmapProvider));
  }, [memoizeDriversLocation])


  const showMarkerLocations = () => {
    if (!H) return;
    const greenMarkerLocations = memoizeDriversTasksLocation?.filter((location => location.status === '1'))
    const redMarkerLocations = memoizeDriversTasksLocation?.filter((location => location.status === '2'))
    addClusteredLayer(redMarkerLocations, getCustomClustorTheme(successMarker), mapInstance);
    addClusteredLayer(greenMarkerLocations, getCustomClustorTheme(darkMarker), mapInstance);
  }

  useEffect(() => {
    const resize = () => {
      if (mapInstance.current) {
        mapInstance.current.getViewPort().resize()
      }
    }
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, [loading])


  useEffect(() => {
    if (loading) {
      clusterLayerRef.current.forEach((cluster) => {
        mapInstance.current.removeLayer(cluster)
      })

      return;
    };
    if (mapInstance?.current && memoizeDriversLocation?.length === 0) {
      clusterLayerRef.current.forEach((cluster) => {
        mapInstance.current.removeLayer(cluster)
      })
      // if(markersRef.current){
      //   mapInstance.current.removeObject(markersRef.current)

      // }
      return;
    }

    showDriverLocations()
  }, [memoizeDriversLocation, loading])

  useEffect(() => {
    if (loading) return;
    // if (mapInstance?.current && memoizeDriversLocation?.length === 0) {
    //   clusterLayerRef.current.forEach((cluster) => {
    //     mapInstance.current.removeLayer(cluster)
    //   })
    //   return;
    // }
    showMarkerLocations()
  }, [JSON.stringify(memoizeDriversTasksLocation),loading])

  // useEffect(() => {
  //   // if (loading && mapInstance?.current && markersRef?.current?.length > 0) {
  //   //   removeMarkers()
  //   // }
  //   return () => {
  //     mapInstance.current.removeObjects(mapInstance.current.getObjects())
  //   }
  // }, [loading])

  const showInfoMessage = (markerData, tasks = []) => {
    if (selectedPopup.current) {
      selectedPopup.current?.removeBubbleHanlder()
    }
    const ui = H.ui.UI.createDefault(mapInstance.current, defaultLayersRef.current);
    selectedPopup.current = ui;
    const generateTableContent = (tasks) => {
      return tasks?.map(task => `
        <tr data-destination="${task?._id}" class="clickable-row">
          <td><Typography class='w-100 ellipsis-one text-white-700 text-12'>${task.address?.address1}</Typography></td>
          <td><Typography class='w-100 ellipsis-one text-white-700 text-12'>${task.recipientDetails?.name}</Typography></td>
          <td><Typography class='w-100 ellipsis-one text-white-700 text-12'>${task.fleet?.fleetName}</Typography></td>
          <td><Typography class='w-100 d-inline-block text-white-700 text-12'>${formatTimestampToDateView(task?.createdDate)}</Typography></td>
        </tr>
      `).join('');
    };

    // Custom content for the info bubble
    const content = `
      <div class='tasks-modal'>
        <Typography variant='h6' class='w-100 d-flex font-w-600 text-black-400 text-14 text-left px-3 py-2'>${tasks?.length || 0}  Tasks</Typography>
        <Table class='hw-table' border="0" cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th> 
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left box-sizing-border'>Destination</Typography>
              </th>
              <th>
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Recipient</Typography>
              </th>
              <th>
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Driver</Typography>
              </th>
              <th>
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Date/Time</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            ${generateTableContent(tasks)}
          </tbody>
        </Table>
      </div>`;

    const infoBubble = new H.ui.InfoBubble(markerData, {
      content
    });
    ui.addBubble(infoBubble);
    selectedPopup.current.removeBubbleHanlder = () => {
      ui.removeBubble(infoBubble)
    }
    // setTimeout(() => {
    const clickableRows = document.querySelectorAll('.clickable-row');
    clickableRows.forEach(row => {
      row.addEventListener('click', (event) => {
        const destination = event.currentTarget.getAttribute('data-destination');
        setTaskDetailFetch(destination)
      });
    });
    // }, 100);
  };

  useEffect(() => {
    if (taskDetailFetch) {
      dispatch(openCustomDrawer({
        drawer: 'TASK_DETAIL',
        drawerData: taskDetailFetch
      }))
    }
  }, [taskDetailFetch])

  const fetchDriverLists = useCallback(() => {
    if (!dataPassComponent?.endDate && !dataPassComponent?.startDate) return;
    dispatch(fetchAllDriversLocations({
      // startDate: "",
      // endDate: "",
      startDate: dataPassComponent?.startDate ? dataPassComponent?.startDate : "",
      endDate: dataPassComponent?.endDate ? dataPassComponent?.endDate : "",
      driverStatus: dataPassComponent?.driverStatus === "offDuty" ? false : dataPassComponent?.driverStatus === "inTransit" ? true : "",
      taskStatus: dataPassComponent?.taskStatus ? dataPassComponent?.taskStatus : ""
    })).unwrap().then((res) => {
    }).catch(err => {
      console.log("err", err)
    })
  }, [dataPassComponent])


  useEffect(() => {
    fetchDriverLists()
    // setInterval(() => {
    //   fetchDriverLists()
    // }, 5000)
  }, [dataPassComponent])


  return (
    <>
      <div className='hd-left-wrapper'>

        <div ref={mapRef} style={{ width: '100%', height: '100%', backgroundColor: 'black' }}></div>
        {/* {
          loading && <div id="shadow-overlay"></div>
        } */}
      </div>

    </>
  );
};
export default React.memo(MapDrive)

