import React from 'react'

export default function EditIcon({width = '24px', height = '24px', color = 'inherit'}) {
  return (
    <svg style={{width, height, color}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4686 5.0271L2.91414 16.5824C2.85601 16.6407 2.81403 16.7143 2.79404 16.7935L1.51334 21.9339C1.47503 22.0887 1.5205 22.2532 1.63343 22.3662C1.71888 22.4516 1.83531 22.4989 1.9544 22.4989C1.99088 22.4989 2.02819 22.4944 2.0645 22.4853L7.20497 21.2044C7.28509 21.1844 7.35788 21.1426 7.41601 21.0845L18.9715 9.52997L14.4686 5.0271Z" fill="currentColor"/>
      <path d="M21.8341 3.45161L20.5479 2.16541C19.6883 1.30578 18.19 1.30661 17.3314 2.16541L15.7559 3.74093L20.2586 8.24364L21.8341 6.66812C22.2635 6.23888 22.5 5.66757 22.5 5.05995C22.5 4.45232 22.2635 3.88101 21.8341 3.45161Z" fill="currentColor"/>
    </svg>    
  )
}
