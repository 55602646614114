import axiosClientHyperWolf from "axiosClient/indexHyperWolf";

// Main Setting API
export async function getTransportAPI() {
    return await axiosClientHyperWolf.get('api/v1/transportationTypes/get');
}

export async function updateTransportStatusAPI(data, transportId) {
    return await axiosClientHyperWolf.put(`api/v1/transportationTypes/${transportId}`, data);
}

export async function getChecklistApi() {
    return await axiosClientHyperWolf.get('api/v1/checklist/list');
}

export async function updateCheckListStatusAPI(data, checklistId) {
    return await axiosClientHyperWolf.put(`api/v1/checklist/update/${checklistId}`, data);
}

export async function getFailureReasonApi() {
    return await axiosClientHyperWolf.get('api/v1/failureReason/list');
}

export async function addFailureReasonApi(data) {
    return await axiosClientHyperWolf.post('api/v1/failureReason/add', data);
}

export async function getFailureReasonDetailsApi(reasonId) {
    return await axiosClientHyperWolf.get(`api/v1/failureReason/show/${reasonId}`);
}

export async function editFailureReasonApi(data,reasonId) {
    return await axiosClientHyperWolf.put(`api/v1/failureReason/edit/${reasonId}`, data);
}


export async function deleteFailureReasonApi(deleteFailureReason) {
    return await axiosClientHyperWolf.delete(`api/v1/failureReason/delete/${deleteFailureReason}`);
}

export async function getAnnouncementsApi() {
    return await axiosClientHyperWolf.get('api/v1/announcement/list');
}

export async function addAnnouncementApi(data) {
    return await axiosClientHyperWolf.post('api/v1/announcement/add', data);
}

export async function getAnnouncementDetailsApi(reasonId) {
    return await axiosClientHyperWolf.get(`api/v1/announcement/show/${reasonId}`);
}

export async function editAnnouncementApi(data,announcementId) {
    return await axiosClientHyperWolf.put(`api/v1/announcement/edit/${announcementId}`, data);
}

export async function deleteAnnouncementsApi(deleteAnnouncements) {
    return await axiosClientHyperWolf.delete(`api/v1/announcement/delete/${deleteAnnouncements}`);
}