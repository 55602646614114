
// @mui material components
import Grid from "@mui/material/Grid";
import { Box, Card, CardContent, FormControl, IconButton, InputLabel, MenuItem, Pagination, Paper, Select, Stack, Typography } from "@mui/material";
import DotIndicatorIcon from "common/customIcons/dot_indicator";
import LineChart from "common/charts/LinChart";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { cancelledOrderStats, completedOrdersStats } from "../../redux/slices/dashboard";
import FilterMenu from "common/FilterMenu";
import { FilterAltOutlined } from "@mui/icons-material";

const options = {
  responsive: true,
  plugins: {
    legend: {
      // position: 'top',
      display: false
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    tooltip: {
      backgroundColor: "#fdfdfd",
      bodyColor: "#16161E",
      titleColor: "#16161E",
      borderWidth: 1,
      borderColor: "#F1F1F1",
      padding: 8
    },
  },
  scales: {
    y: {
      display: true,
      ticks: {
        beginAtZero: true,
        display: true,
        stepSize: 10,
        max: 100
      },
      grid: {
        display: false
      }
    },
    x: {
      display: true,
    },
  },
};

export default function CompleteAndCancelledOrdersStats() {
  const dispatch = useDispatch()
  const { cancelledOrderStatsData = {}, completedOrdersStatsData = {} } = useSelector(state => state.dashboard)
  const [taxesAndRevenueFilters, setTaxesAndRevenueFilters] = useState({
    completeOrderWeekMonthToday: 'month',
    cancelOrdersWeekMonthToday: 'month'
  })
  const [completedOrderFilterOpen, setCompletedOrderFilterOpen] = useState(false)
  const [cancelledOrderFilterOpen, setCancelledOrderFilterOpen] = useState(false)

  



  const cancelledOrderChartData = useMemo(() => {
    const { results = [] } = cancelledOrderStatsData || {}
    const formatedChartData = results?.map((orders => orders?.map((item => item?.totalOrders || 0))))
    let chartData = {
      labels: results[0]?.map((item => item?.label)),
      datasets: [
        {
          data: formatedChartData[0],
          borderColor: '#6475FF',
          backgroundColor: '#6475FF',
          pointRadius: 3.5,
          lineTension: 0.5
        },
        {
          data: formatedChartData[1],
          borderColor: '#F3D349',
          backgroundColor: '#F3D349',
          pointRadius: 3.5,
          lineTension: 0.5,
        },
      ],
    };
    return chartData;
  }, [cancelledOrderStatsData?.results])


  const completedOrderChartData = useMemo(() => {
    const { results = [] } = completedOrdersStatsData || {}
    const formatedChartData = results?.map((orders => orders?.map((item => item?.totalOrders || 0))))
    let chartData = {
      labels: results[0]?.map((item => item?.label)),
      datasets: [
        {
          data: formatedChartData[0],
          borderColor: '#8676FF',
          backgroundColor: '#8676FF',
          pointRadius: 3.5,
          lineTension: 0.5,
        },
        {
          data: formatedChartData[1],
          borderColor: '#0BDB2C',
          backgroundColor: '#0BDB2C',
          pointRadius: 3.5,
          lineTension: 0.5,
        },
      ],
    };
    return chartData;
  }, [completedOrdersStatsData?.results])

  useEffect(() => {
    dispatch(cancelledOrderStats({ dashboardQuery: taxesAndRevenueFilters?.cancelOrdersWeekMonthToday }))
  }, [taxesAndRevenueFilters?.cancelOrdersWeekMonthToday])

  useEffect(() => {
    dispatch(completedOrdersStats({ dashboardQuery: taxesAndRevenueFilters?.completeOrderWeekMonthToday }))
  }, [taxesAndRevenueFilters?.completeOrderWeekMonthToday])

  return (
    <>
      <Grid item xs={12} md={6} xxl={5.5}>
        <Card variant='dashboardCard' sx={{ height: '100%', overflow: 'hidden' }}>
          <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", p: 2.5 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={5} mb={4}>
              <Typography variant="h5" lineHeight={1.25}>Completed Orders</Typography>

              {/* filter ui  */}
              <IconButton variant='btn-filter' color='secondary' onClick={(e) => setCompletedOrderFilterOpen(e.currentTarget)}>
                <FilterAltOutlined fontSize='medium' color='secondary' />
              </IconButton>
              <FilterMenu selectedMenu={taxesAndRevenueFilters?.completeOrderWeekMonthToday} filter={completedOrderFilterOpen} handleFilter={setCompletedOrderFilterOpen} applyFilterHandler={(value) => setTaxesAndRevenueFilters({ ...taxesAndRevenueFilters, completeOrderWeekMonthToday: value })} />
              {/* filter ui end  */}

              {/* <FormControl fullWidth sx={{ maxWidth: "108px" }}>
                <Select
                  value={taxesAndRevenueFilters?.completeOrderWeekMonthToday}
                  className="form-select form-dropdown"
                  name="completeOrderWeekMonthToday"
                  onChange={(e) => setTaxesAndRevenueFilters({ ...taxesAndRevenueFilters, completeOrderWeekMonthToday: e.target.value })}

                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="week">Weeks</MenuItem>
                  <MenuItem value="month">Months</MenuItem>
                </Select>
              </FormControl> */}
            </Stack>
            <Box width='100%' height='100%'>
              <LineChart chartData={completedOrderChartData} options={options} />
            </Box>
            <Stack direction="row" flexWrap="wrap" gap={2} mt={3}>
              <Box display="inline-flex" alignItems="center" gap={1} minWidth={{xs: 80, xxl: 100, xxxl: 140}}>
                <DotIndicatorIcon style={{ width: "6px", height: "6px", color: "#8274F6", marginTop: "-1px" }} />
                <Typography variant="body2" fontWeight="semibold" sx={{ textTransform: 'capitalize' }}> {taxesAndRevenueFilters?.completeOrderWeekMonthToday === 'today' ? 'Today' : <>This  {taxesAndRevenueFilters?.completeOrderWeekMonthToday} </>} </Typography>
              </Box>

              <Box display="inline-flex" alignItems="center" gap={1} minWidth={{xs: 80, xxl: 100, xxxl: 140}}>
                <DotIndicatorIcon style={{ width: "6px", height: "6px", color: "#0BDB2C", marginTop: "-1px" }} />
                <Typography variant="body2" fontWeight="semibold" sx={{ textTransform: 'capitalize' }}> {taxesAndRevenueFilters?.completeOrderWeekMonthToday === 'today' ? 'Yesterday' : <>Previous  {taxesAndRevenueFilters?.completeOrderWeekMonthToday} </>} </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6} xxl={6.5}>
        <Card variant='dashboardCard' sx={{ height: '100%', overflow: 'hidden' }}>
          <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", p: 2.5 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={5} mb={4}>
              <Typography variant="h5" lineHeight={1.25}>Cancelled Orders</Typography>

              {/* filter ui  */}
              <IconButton variant='btn-filter' color='secondary' onClick={(e) => setCancelledOrderFilterOpen(e.currentTarget)}>
                <FilterAltOutlined fontSize='medium' color='secondary' />
              </IconButton>
              <FilterMenu selectedMenu={taxesAndRevenueFilters?.cancelOrdersWeekMonthToday} filter={cancelledOrderFilterOpen} handleFilter={setCancelledOrderFilterOpen} applyFilterHandler={(value) => setTaxesAndRevenueFilters({ ...taxesAndRevenueFilters, cancelOrdersWeekMonthToday: value })} />
              {/* filter ui end  */}

              {/* <FormControl fullWidth sx={{ maxWidth: "108px" }}>
                <Select
                  value={taxesAndRevenueFilters?.cancelOrdersWeekMonthToday}
                  className="form-select form-dropdown"
                  name="cancelOrdersWeekMonthToday"
                  onChange={(e) => setTaxesAndRevenueFilters({ ...taxesAndRevenueFilters, cancelOrdersWeekMonthToday: e.target.value })}

                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="week">Weeks</MenuItem>
                  <MenuItem value="month">Months</MenuItem>
                </Select>
              </FormControl> */}
            </Stack>
            <Box width='100%' height='100%'>
              <LineChart chartData={cancelledOrderChartData} options={options} />
            </Box>
            <Stack direction="row" flexWrap="wrap" gap={2} mt={3}>
              <Box display="inline-flex" alignItems="center" gap={1} minWidth={{xs: 80, xxl: 100, xxxl: 140}}>
                <DotIndicatorIcon style={{ width: "6px", height: "6px", color: "#F3D349", marginTop: "-1px" }} />
                <Typography variant="body2" fontWeight="semibold" sx={{ textTransform: 'capitalize' }}> {taxesAndRevenueFilters?.cancelOrdersWeekMonthToday === 'today' ? 'Today' : <>This  {taxesAndRevenueFilters?.cancelOrdersWeekMonthToday} </>} </Typography>
              </Box>

              <Box display="inline-flex" alignItems="center" gap={1} minWidth={{xs: 80, xxl: 100, xxxl: 140}}>
                <DotIndicatorIcon style={{ width: "6px", height: "6px", color: "#6475FF", marginTop: "-1px" }} />
                <Typography variant="body2" fontWeight="semibold" sx={{ textTransform: 'capitalize' }}> {taxesAndRevenueFilters?.cancelOrdersWeekMonthToday === 'today' ? 'Yesterday' : <>Previous  {taxesAndRevenueFilters?.cancelOrdersWeekMonthToday} </>} </Typography>
              </Box>
            </Stack>

          </CardContent>
        </Card>
      </Grid>
    </>
  )
}
