import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react'

export default function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress style={{ color: '#8E0CF5', width: "52px", height: "52px" }} variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" fontSize={11} fontWeight={600} color="#2E2E3A" sx={{letterSpacing: "-.5px"}}>
                    {`${props.value}%`}
                </Typography>
            </Box>
        </Box>
    );
}

