import React, { useState, useEffect, useCallback } from 'react'
import { Grid, Button, TextField, Box, Card } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux';
import { shopUpdateTime, shopOpenTiming } from '../../../redux/slices/hyperwolf/auth';
import CommonLayout from 'layouts/DashboardLayout';
import { openSnackbar } from '../../../redux/slices/common';

const HyperwolfShopTiming = () => {
    const dispatch = useDispatch()

    const [updateData, setUpdateData] = useState({
        shopCloseTime: '',
        shopOpenTime: '',
        specailAddressOpenTime: ''
    })

    const { shopeTimeData = {} } = useSelector(state => state.hyperwolfAuth)
    const { shopCloseTime = "00:00", shopOpenTime = "00:00", specailAddressOpenTime = "00:00" } = shopeTimeData

    useEffect(() => { dispatch(shopOpenTiming()) }, [])

    const updateShoptime = () => {
        dispatch(shopUpdateTime({
            shopCloseTime: updateData.shopCloseTime ? reverseTimeFormat(updateData.shopCloseTime) : shopCloseTime,
            shopOpenTime: updateData.shopOpenTime ? reverseTimeFormat(updateData.shopOpenTime) : shopOpenTime,
            specailAddressOpenTime: updateData.specailAddressOpenTime ? reverseTimeFormat(updateData.specailAddressOpenTime) : specailAddressOpenTime,
        })).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success')
            dispatch(shopOpenTiming())
        }).catch(err => {
            showResponseMessage(err?.message, 'error')
        })
    }

    const timeFormat = useCallback((a) => {
        if (Number(a) !== 0) {
            let hour = parseInt(a.substr(0, 2));
            let minute = parseInt(a.substr(2, 2));
            return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
        }
    }, [])

    const reverseTimeFormat = (timeStr) => {
        let result = timeStr?.replace(/:/g, '');
        return result
    }

    const changeTime = (e, type) => {
        setUpdateData({ ...updateData, [type]: e.target.value })
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000); 
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }


    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: "none" }}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6} lg={4}>
                            <form noValidate>
                                <TextField
                                    id="time"
                                    label="Set Open Time"
                                    type="time"
                                    value={updateData.shopOpenTime ? updateData.shopOpenTime : timeFormat(shopOpenTime?.toString())}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    onChange={(e) => changeTime(e, 'shopOpenTime')}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <form noValidate>
                                <TextField
                                    id="time"
                                    label=" Special Address Open Time"
                                    type="time"
                                    value={updateData.specailAddressOpenTime ? updateData.specailAddressOpenTime : timeFormat(specailAddressOpenTime?.toString())}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    onChange={(e) => changeTime(e, 'specailAddressOpenTime')}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <form noValidate>
                                <TextField
                                    id="time"
                                    label="Set Close Time"
                                    type="time"
                                    value={updateData.shopCloseTime ? updateData.shopCloseTime : timeFormat(shopCloseTime?.toString())}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    onChange={(e) => changeTime(e, 'shopCloseTime')}
                                />
                            </form>
                        </Grid>
                    </Grid>
                    <Box textAlign='end' mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={updateShoptime}
                        >
                            Update Time
                        </Button>
                    </Box>
                </Card>

            </Box>

        </CommonLayout>
    )
}

export default HyperwolfShopTiming