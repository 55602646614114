import AddInventoryForm from 'components/inventory/addInventoryForm'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { fetchInventoryDetails } from '../../../redux/slices/inventory'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

export default function AddInventory() {
    const { inventoryId = "" } = useParams()
    const dispatch = useDispatch()
    useEffect(() => {
      if(inventoryId){
        dispatch(fetchInventoryDetails(inventoryId))
      }
    }, [inventoryId])
  return (
    <CommonLayout>
        <AddInventoryForm/>
    </CommonLayout>
  )
}
