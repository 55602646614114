import { Box, Typography } from '@mui/material'
import ActiveIcon from 'common/customIcons/active'
import InActiveIcon from 'common/customIcons/inactive'
import InProgressIcon from 'common/customIcons/inprogress'
import React from 'react'

const statusObj = {
    active: <Box color="#24CA49" display="flex" alignItems="center">
        <ActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Active</Typography>
    </Box>,
    inactive: <Box color="#FD4438" display="flex" alignItems="center">
        <InActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Inactive</Typography>
    </Box>,  
     pending: <Box color="#8E0CF5" display="flex" alignItems="center">
        <InProgressIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Pending</Typography>
    </Box>
    // inprogress: <Box color="#8E0CF5" display="flex" alignItems="center">
    //     <InProgressIcon width="18px" height="18px" flex="none" />
    //     <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">In Progress</Typography>
    // </Box>
}

export default function StatusText({ status="" }) {
    return statusObj[status?.toLowerCase()]
}
