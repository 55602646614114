import { DeleteOutline, Edit, SearchSharp } from '@mui/icons-material'
import { Avatar, Box, Button, ButtonGroup, Card, CardActions, CardContent, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import { AgGridTable } from 'components/tables/AgGridTable'
import Search from 'examples/Search'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchCategories, openSnackbar } from '../../redux/slices/common'
import { sortAndFilter } from 'components/tables/AgGridTable'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice'
import { deleteCategory, fetchCategoriesList } from '../../redux/slices/category'
import { useDebounce } from 'hooks/useDebounce'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import StatusText from 'common/component/StatusText'
import productImage from "assets/images/placeholder-image.png";
import MDBox from 'components/MDBox'
import InProgressIcon from 'common/customIcons/inprogress'
import ActiveIcon from 'common/customIcons/active'
import NoDataFound from 'common/component/NoDataFound'

export default function Categories() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { isFetchingCategories = false } = useSelector(state => state.common)
    const { categoriesList } = useSelector(state => state.category)

    const pageRef = useRef(0)
    const searchTermRef = useRef("")
    const mainGridApiRef = useRef('')
    const gridRefApi = useRef(null)
    const [searchTerm, setSearchTerm] = useState("")
    const handleSearch = useDebounce((term) => {
        // Perform search operation with the debounced term
        console.log('Searching for:', term);
        pageRef.current = 0
        searchFn()
        // mainGridApiRef.current.destroy()
        // getRows(gridRefApi.current)
    }, 500);
    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'sno',
            suppressMenu: false
        },
        {
            field: 'categoryName',
            key: "fullName",
            suppressMenu: false,
        },
        {
            field: 'categoryImage',
            key: "fullName",
            suppressMenu: false,
            cellRenderer: (props) => {
                if (!props?.data?.categoryImage) return "-"
                return <img height={100} style={{ padding: '10px 0px', objectFit: 'cover', borderRadius: '10px' }} width={100} src={props?.value} />
            },
            height: 200
        },
        {
            field: 'status',
            suppressMenu: false,
            cellRenderer: (props) => {
                return <StatusText status={props?.data?.status} />
            },
        },
        {
            headerName: 'Actions',
            cellRenderer: (props) => {
                return <Box>
                    <IconButton onClick={() => onRowClicked(props)} color='primary' size='small' variant="primary">
                        <EditIcon width='16px' height='16px' />
                    </IconButton>
                    <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?.categoryId }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
                        <DeleteIcon width='16px' height='16px' />
                    </IconButton>
                </Box>
            },
        },
    ]);
    const deleteHandler = (categoryId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteCategory(categoryId)).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            pageRef.current = 0
            getCategories()
            // getRows(gridRefApi.current)
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const getCategories = () => {
        dispatch(fetchCategoriesList({ skip: pageRef.current, limit: 100, userType: 'admin', searchTerm: searchTermRef.current })).unwrap()
    }
    const getRows = async (params) => {
        gridRefApi.current = params;
        dispatch(fetchCategoriesList({ skip: pageRef.current, limit: 100, userType: 'admin', searchTerm: searchTermRef.current })).unwrap().then(res => {
            const { categories, total } = res
            const categoriesArray = categories?.map(((item, idx) => {
                return { ...item, sno: idx + 1, id: item._id }
            }))
            setTimeout(function () {
                const dataAfterSortingAndFiltering = sortAndFilter(
                    categoriesArray,
                    params.sortModel,
                    params.filterModel
                );
                const rowsThisPage = dataAfterSortingAndFiltering.slice(
                    params.startRow,
                    params.endRow
                );
                let lastRow = -1;
                if (total <= params.endRow) {
                    lastRow = dataAfterSortingAndFiltering.length;
                }
                params.successCallback(dataAfterSortingAndFiltering, lastRow);
            }, 500);
            pageRef.current =pageRef.current + 20
        }).catch((err => {
            console.log("err", err)
        }))
    }
    const onRowClicked = (row) => {
        navigate(`/categories/${row?.data.categoryId}`)
    }
    const searchHandler = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        searchTermRef.current = value
        handleSearch(value);
        // Debounce the search callback
    };
    const searchFn = () => {
        // const dataSource = {
        //     getRows
        // }
        // mainGridApiRef.current.setGridOption('datasource', dataSource);
        getCategories()
    }

    useEffect(() => {
        getCategories()
    }, [])
    return (
        <CommonLayout>
            <Box p={{xs: 2, sm: 3, lg: 4}}>
                <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant='h4'>Sub Categories</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Stack direction="row" justifyContent={{xs: "start", sm: "end"}} flexWrap="wrap" alignItems="stretch" gap={2}>
                            <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
                            <MDButton
                                startIcon={<AddRoundedIcon fontSize='large' />}
                                variant="contained" color="primary"
                                onClick={() => navigate('/categories/add')} >
                                Add Category
                            </MDButton>
                        </Stack>
                    </Grid>
                </Grid>
                {
                    categoriesList?.categories?.length > 0 ?  <Grid container spacing={2} mb={4}>
                        {categoriesList?.categories?.map((item, index) => (
                            <Grid item xs={12} sm={6} xl={4} key={index}>
                                <Card sx={{ boxShadow: 0, height: '100%', justifyContent: 'space-between' }}>
                                    <CardContent sx={{ p: 2.5 }}>
                                        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                                            <Box display="flex">
                                                <Avatar
                                                    alt="Product Name"
                                                    src={item?.categoryImage}
                                                    sx={{ width: 68, height: 68, borderRadius: 2 }}
                                                />
                                                <MDBox textAlign="left" ml={1.5} lineHeight={1}>
                                                    <Typography variant="h5" fontSize={14} color="dark" fontWeight="semibold">{item?.categoryName}</Typography>
                                                    <Typography variant="p" fontSize={12} color="secondary">ID: {item?.categoryId}</Typography>
                                                </MDBox>
                                            </Box>

                                            <Box color="#24CA49" display="flex" alignItems="center">
                                                <StatusText status={item?.status?.toLowerCase()} />
                                            </Box>
                                        </Stack>

                                        <Box sx={{ bgcolor: "#F2F3F7", px: 1.75, py: 1.25, borderRadius: 2.5, mt: 2.5 }}>
                                            <Typography variant="body2" fontSize={12} color="dark" className="truncate line-3">{item?.description?.replace(/<\/?[^>]+(>|$)/g, "")}</Typography>
                                        </Box>
                                    </CardContent>
                                    <CardActions sx={{ p: 0 }}>
                                        <ButtonGroup className='card-footer-action' fullWidth variant="outlined">
                                            <MDButton
                                                type='button'
                                                variant='outlined'
                                                color='dark'
                                                size="small"
                                                onClick={() => navigate(`/categories/${item?.categoryId}`)}
                                                startIcon={<EditIcon width='15px' height='15px' color="#0163FF" />}
                                            >
                                                Edit
                                            </MDButton>
                                            <MDButton
                                                type='button'
                                                variant='outlined'
                                                color='dark'
                                                size="small"
                                                onClick={() => {
                                                    dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: item?.categoryId }, modal: 'DELETE_MODAL' }))
                                                }}
                                                startIcon={<DeleteIcon width='15px' height='15px' color='#FD4438' />}
                                            >
                                                Delete
                                            </MDButton>
                                        </ButtonGroup>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))} 
                    </Grid> : <NoDataFound text="No Category Found" />
                }
            </Box>
        </CommonLayout>
    )
}