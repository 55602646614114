import React from 'react'
import Driver from './Driver'

export default function HyperwolfAdmin() {
  return (
    <div>
      <Driver />
    </div>
  )
}
