import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    isOpenDrawer: false,
    drawer: null,
    modal: null,
    modalData: {},
    drawerData: {},
    isLoading: false
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openCustomModalDialog: (state, action) => {
            state.isOpen = true;
            state.modal = action.payload.modal;
            state.modalData = action.payload.modalData;
        },
        closeCustomModalDialog: (state) => {
            state.isOpen = false;
            state.isLoading = false
            state.modal = null;
            state.modalData = {};
        },
        setModalLoader: (state, action) => {
            state.isLoading = action?.payload || false;
        },
        openCustomDrawer: (state, action) => {
            state.isOpenDrawer = true;
            state.drawer = action.payload.drawer;
            state.drawerData = action.payload.drawerData;
        },
        closeCustomDrawer: (state) => {
            state.isOpenDrawer = false;
            state.isLoading = false
            state.drawer = null;
            state.drawerData = {};
        },
    

    },
});

export const { openCustomModalDialog, closeCustomModalDialog, setModalLoader ,closeCustomDrawer ,openCustomDrawer } = modalSlice.actions;
export const modalReducer = modalSlice.reducer;