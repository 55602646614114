import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addRegionAPI, deleteRegionAPI, fetchRegionDetailsAPI, fetchRegionsAPI, updateRegionId } from "../../redux/apis/regions";

const initialState = {
  products: [],
  loading: false,
  error: null,
  singleProduct: null,
};

// Define your asynchronous thunk
export const fetchRegions = createAsyncThunk(
  'regions/fetchRegions',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await fetchRegionsAPI(filters);
      return res;
    } catch (error) {
      console.log("error", error.response);
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const addRegion = createAsyncThunk(
  'regions/addRegion',
  async (data, { rejectWithValue }) => {
    try {
      const res = await addRegionAPI(data);
      return res;
    } catch (error) {
      console.log("error", error.response);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateRegion = createAsyncThunk(
  'regions/updateRegion',
  async ({data,regionId}, { rejectWithValue }) => {
    try {
      const res = await updateRegionId(data,regionId);
      return res;
    } catch (error) {
      console.log("error", error.response);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteRegion = createAsyncThunk(
  'regions/deleteRegion',
  async (regionId, { rejectWithValue }) => {
    try {
      const res = await deleteRegionAPI(regionId);
      return res;
    } catch (error) {
      console.log("error", error.response);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchSingleRegion = createAsyncThunk(
  'regions/fetchSingleRegion',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await fetchRegionDetailsAPI(filters);
      return res;
    } catch (error) {
      console.log("error", error.response);
      return rejectWithValue(error?.response?.data);
    }
  }
);


const regionsSlice = createSlice({
  name: 'regions',
  initialState: {
    data: [],
    loading: false,
    error: null,
    regionDetails:{}
  },
  reducers: {
    // Other reducers can be added here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error state on pending
        state.regionDetails={}
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload; // Assuming action.payload contains regions data
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error payload in state
      });

    builder
      .addCase(addRegion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addRegion.fulfilled, (state, action) => {
        state.loading = false;
        // Handle successful API call: update state with received data if needed
      })
      .addCase(addRegion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle the error: store the error payload in state
      });

    builder
      .addCase(deleteRegion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRegion.fulfilled, (state, action) => {
        state.loading = false;
        // Handle successful deletion if needed
      })
      .addCase(deleteRegion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle the error: store the error payload in state
      });

    builder
      .addCase(fetchSingleRegion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleRegion.fulfilled, (state, action) => {
        state.loading = false;
        state.regionDetails = action.payload.regionDetails; // Update state with fetched region details
      })
      .addCase(fetchSingleRegion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error payload in state
      });
      builder
      .addCase(updateRegion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRegion.fulfilled, (state, action) => {
        state.loading = false;
        // Handle successful update if needed
      })
      .addCase(updateRegion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle the error: store the error payload in state
      });
  },
});

export const { /* Other action creators */ } = regionsSlice.actions;

export default regionsSlice.reducer;