import React from 'react'

export default function DeleteIcon({width = '24px', height = '24px', color = 'inherit'}) {
  return (
    <svg style={{width, height, color}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.45 4.5H2.55C2.27152 4.5 2.00445 4.61155 1.80754 4.81012C1.61062 5.00869 1.5 5.27801 1.5 5.55882C1.5 5.83964 1.61062 6.10896 1.80754 6.30752C2.00445 6.50609 2.27152 6.61765 2.55 6.61765H4.65V19.3235C4.65025 20.1659 4.9822 20.9737 5.57289 21.5694C6.16358 22.165 6.96464 22.4997 7.8 22.5H16.2C17.0354 22.4998 17.8364 22.165 18.4271 21.5694C19.0178 20.9737 19.3498 20.1659 19.35 19.3235V6.61765H21.45C21.7285 6.61765 21.9955 6.50609 22.1925 6.30752C22.3894 6.10896 22.5 5.83964 22.5 5.55882C22.5 5.27801 22.3894 5.00869 22.1925 4.81012C21.9955 4.61155 21.7285 4.5 21.45 4.5ZM10.95 16.1471C10.95 16.4279 10.8394 16.6972 10.6425 16.8958C10.4455 17.0943 10.1785 17.2059 9.9 17.2059C9.62152 17.2059 9.35445 17.0943 9.15754 16.8958C8.96062 16.6972 8.85 16.4279 8.85 16.1471V10.8529C8.85 10.5721 8.96062 10.3028 9.15754 10.1042C9.35445 9.90567 9.62152 9.79412 9.9 9.79412C10.1785 9.79412 10.4455 9.90567 10.6425 10.1042C10.8394 10.3028 10.95 10.5721 10.95 10.8529V16.1471ZM15.15 16.1471C15.15 16.4279 15.0394 16.6972 14.8425 16.8958C14.6455 17.0943 14.3785 17.2059 14.1 17.2059C13.8215 17.2059 13.5545 17.0943 13.3575 16.8958C13.1606 16.6972 13.05 16.4279 13.05 16.1471V10.8529C13.05 10.5721 13.1606 10.3028 13.3575 10.1042C13.5545 9.90567 13.8215 9.79412 14.1 9.79412C14.3785 9.79412 14.6455 9.90567 14.8425 10.1042C15.0394 10.3028 15.15 10.5721 15.15 10.8529V16.1471Z" fill="currentColor"/>
      <path d="M10 3H14C14.2652 3 14.5196 2.92098 14.7071 2.78033C14.8946 2.63968 15 2.44891 15 2.25C15 2.05109 14.8946 1.86032 14.7071 1.71967C14.5196 1.57902 14.2652 1.5 14 1.5H10C9.73478 1.5 9.48043 1.57902 9.29289 1.71967C9.10536 1.86032 9 2.05109 9 2.25C9 2.44891 9.10536 2.63968 9.29289 2.78033C9.48043 2.92098 9.73478 3 10 3Z" fill="currentColor"/>
    </svg>    
  )
}
