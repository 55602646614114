
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import MDButton from 'components/MDButton';
import EditIcon from 'common/customIcons/edit'
import { useEffect, useState } from 'react';
import defaultImage from 'assets/images/placeholder-image.png';
import colors from "assets/theme/base/colors";
import CommonLayout from 'layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import BrandsTable from './brands';
import { getMainBrand } from '../../redux/slices/brand';

export default function Brands() {
  const [currentTab, setCurrentTab] = useState("")
  const dispatch = useDispatch()
  const { mainBrandData = {} } = useSelector(state => state.brands)
  const { search } = useLocation()
  const navigate = useNavigate();
  const { grey } = colors;

  useEffect(() => {
    if (search) {
      const selectedTab = search?.split('=')?.[1] || "";
      if (selectedTab !== currentTab) {
        navigate(`/brands?brandTab=${selectedTab}`)
        setCurrentTab(selectedTab)
      }
    }
    else {
      navigate('/brands?brandTab=main_brands')
      setCurrentTab('')
    }
  }, [search])

  useEffect(() => {
    dispatch(getMainBrand())
  }, [])

  const uploadThumbnailImage = {
    borderRadius: "20px", 
    width: "160px", 
    height: "160px", 
    objectFit: "cover", 
    boxShadow: "0 0 1px rgb(0 0 0 / 20%)"
  };

  return (
    <>
      <CommonLayout>
        {
          currentTab && currentTab === 'main_brands' ? <Box p={{ xs: 2, sm: 3, lg: 4 }}>
            <Stack direction="row" justifyContent="space-between" spacing={3} mb={3}>
              <Typography variant='h4' fontWeight="semibold" ml={.75}>Brand Homepage</Typography>

              <MDButton
                type='button'
                variant='outlined'
                color='dark'
                size="small"
                startIcon={<EditIcon width='15px' height='15px' />}
                onClick={() => navigate('/brands/main-brand')}
              >
                Edit
              </MDButton>
            </Stack>

            <Card sx={{ p: 3, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
              <Typography variant='h5' fontWeight="semibold" mb={2}>Meta Image</Typography>
              <Stack direction="row" flexWrap="wrap" gap={1.25} mb={4}>
                <img style={uploadThumbnailImage} src={mainBrandData?.image || defaultImage} alt="Brand Name" />
              </Stack>

              <Grid container alignItems="center" spacing={{ xs: 3, md: 5 }} mb={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={0.5}>Title</Typography>
                    <Typography className="quill-text-editor-view" component="div" fontWeight="medium" variant="h6" dangerouslySetInnerHTML={{
                      __html: mainBrandData?.title || '-'
                    }}></Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={0.5}>Meta Title</Typography>
                    <Typography className="quill-text-editor-view" component="div" fontWeight="medium" variant="h6" dangerouslySetInnerHTML={{
                      __html: mainBrandData?.metaTitle || '-'
                    }}></Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={0.5}>Meta Description</Typography>
                    <Typography className="quill-text-editor-view" component="div" fontWeight="medium" variant="h6" dangerouslySetInnerHTML={{
                      __html: mainBrandData?.metaDescription || '-'
                    }}></Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Description</Typography>
                    <Typography className="quill-text-editor-view" component="div" fontWeight="medium" variant="h6" dangerouslySetInnerHTML={{
                      __html: mainBrandData?.description || '-'
                    }}></Typography>
                </Grid>
              </Grid>
            </Card>
          </Box> : currentTab && currentTab === 'brands' ? <BrandsTable /> : null
        }
      </CommonLayout>
    </>
  )
}
