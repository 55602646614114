import React from 'react'

export default function MilesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
      <path d="M23.0347 2.4162C10.6436 2.4162 0.598633 12.461 0.598633 24.8522C0.598633 30.2234 2.48653 35.1532 5.63431 39.0155C6.4579 40.026 7.96289 40.0167 8.88473 39.0949C9.81097 38.1687 9.79346 36.6744 8.99505 35.636C6.80729 32.7906 5.46705 29.2606 5.34519 25.423H7.89801C8.3937 25.423 8.79537 25.0214 8.79537 24.5256C8.79537 24.0302 8.3937 23.6285 7.89801 23.6285H5.37776C5.64897 19.6584 7.22788 16.0496 9.68879 13.2268L11.5233 15.0614C11.8736 15.412 12.442 15.412 12.7923 15.0614C13.1429 14.7109 13.1429 14.1428 12.7923 13.7924L10.9346 11.9344C13.8969 9.15851 17.811 7.38687 22.1373 7.17094V9.51455C22.1373 10.01 22.5392 10.4116 23.0347 10.4116C23.5302 10.4116 23.9319 10.01 23.9319 9.51455V7.17076C28.3184 7.38963 32.281 9.20865 35.2574 12.0512L33.5164 13.7922C33.1659 14.1426 33.1659 14.7107 33.5164 15.0612C33.867 15.4118 34.4351 15.4118 34.7856 15.0612L36.4918 13.3549C38.8891 16.1585 40.4246 19.7182 40.6916 23.6283H37.9212C37.4257 23.6283 37.0237 24.03 37.0237 24.5255C37.0237 25.0212 37.4257 25.4228 37.9212 25.4228H40.724C40.6022 29.2605 39.2617 32.7905 37.074 35.6358C36.2756 36.6742 36.2581 38.1685 37.1843 39.0947C38.1061 40.0165 39.6111 40.0258 40.4347 39.0153C43.5827 35.1531 45.4704 30.2232 45.4704 24.8521C45.4705 12.4609 35.4257 2.4162 23.0347 2.4162Z" fill="#FED366"/>
      <path d="M32.4164 15.9562C31.7538 15.3886 30.9507 16.2909 30.219 16.9346L21.1782 25.3729C20.6241 25.9648 20.7729 27.005 21.5109 27.6959L21.6594 27.8344C22.3974 28.5252 23.445 28.6051 23.9993 28.0134L31.5353 18.3327C32.2171 17.4395 33.0119 16.5442 32.5125 16.0477L32.4164 15.9562Z" fill="#FED366"/>
    </svg>
  )
}
