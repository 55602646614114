import { Typography, Box } from '@mui/material';
import React, { useEffect } from 'react'
import EastIcon from '@mui/icons-material/East';
import NearMeIcon from '@mui/icons-material/NearMe';
import streetMap from './../../../assets/images/Street-Map.png'
import { fetchRoutesDetails } from '../../../redux/slices/hyperdrive/driverAnalytics';
import CalculateMap from '../calculateMap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


const TaskRoute = ({ sendDataToParent = () => { }, taskId }) => {
    const dispatch = useDispatch()
    const { driverRoutesDetails = {} } = useSelector(state => state.driverAnalyticsHyperDrive)
    const { taskDetails } = useSelector(state => state?.drivers)
    console.log("taskDetails", taskDetails);

    const handleDriverDetails = () => {
        sendDataToParent(null);
    }

    // const fetch = async (taskId) => {
    //     try {
    //         const res = await dispatch(fetchRoutesDetails(taskId)).unwrap();

    //     } catch (err) {
    //         console.error("Response Error:", err);
    //         // Handle the error here
    //     }
    // }

    // useEffect(() => {
    //     fetch(taskId)
    // }, [taskId])

    return (
        <div className="next-drawer">
            <Box p={2} className='d-flex align-item-center jc-s-btwn gap-2 drawer-head'>
                <div className='d-flex flex-row gap-3'>
                    <Typography variant='h6' className='text-14 text-white-700'>Review Task</Typography>
                    <Typography className='text-14 text-black-400'> {taskDetails?.data?.taskDisplayId}</Typography>
                </div>
                <Typography style={{ cursor: "pointer" }} onClick={() => handleDriverDetails()} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                    Close <EastIcon />
                </Typography>
            </Box>

            <Box className='drawer-scroll-sec'>
                <Box p={2}>
                    <div className='d-flex align-item-top gap-3'>
                        <NearMeIcon style={{ color: '#0163FF', marginTop: "5px" }} />
                        <div className='d-flex align-item-top flex-column'>
                            <Typography className='text-14 text-white-700' fontWeight="semibold">Driver Location at</Typography>
                            <Typography className='text-20 text-black-400' fontWeight="semibold" mb={1}>1:23 AM</Typography>
                            <Typography className='text-14 text-white-700' fontWeight="semibold">22.872312.-117.5893 at 0.14 miles elevation</Typography>
                        </div>
                    </div>

                    <div className='street-map-view mt-4'>
                        {/* <CalculateMap route={"taskRoute"} routeMap={driverRoutesDetails?.data} /> */}
                        {/* <img src={taskDetails?.streetView ? taskDetails?.streetView : streetMap} alt="" /> */}
                        <img src={taskDetails?.data?.streetView} alt="" />

                    </div>
                </Box>
            </Box>
        </div>
    )
}

export default TaskRoute
