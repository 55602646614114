import { Avatar, Badge, Box, Button, Card, Chip, Grid, IconButton, Stack, SvgIcon, Typography, createSvgIcon } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AngleRightIcon from 'common/customIcons/angle_right';
import MDButton from 'components/MDButton';
import EditIcon from 'common/customIcons/edit';
import colors from "assets/theme/base/colors";
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import InventroyTable from '../inventory';
import BatchTable from '../batch';
import ProductTerpenoids from '../productTerpenoids';
import AddBatch from '../AddBatch';
import Zoom from 'react-medium-image-zoom'
import defaultProductImage from '../../../assets/images/placeholderproduct.svg'
import { capitalizeText } from 'utilities/common';
import ProductActivityLogs from '../ProductActivityLogs';


export default function ProductDetailsPage() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { productId } = useParams()
  const [currentTab, setCurrentTab] = useState('products')
  const { singleProduct = {}, loading } = useSelector(state => state.products)
  const { productDetails = {} } = singleProduct || {}
  const { productImages = [], productName, unitPrice, salePrice, categoryName, brandName, status, strainType, customWeight, thcData, seoData, productDescription, infoEffects = [], ingredientStatus, ingredients, instructionStatus, instructions } = productDetails || {}

  const { grey } = colors;

  const [isBatchAddOpen, setIsBatchAddOpen] = useState(false)


  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };


  const PlusIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
      <path d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" />
    </svg>,
    'Plus',
  );

  useEffect(() => {
    if (search) {
      setCurrentTab(search?.split('=')[1])
      setIsBatchAddOpen(false)
    }
  }, [search])

  if (!productId) {

  }

  const batchHandler = () => {
    setIsBatchAddOpen(!isBatchAddOpen)
  }

  const getUnit = (value) => {
    if (!value) return ''
    const matches = value.match(/^(\d+)([a-zA-Z%]+)/);
    if (matches && matches.length === 3) {
      const unit = matches[2]; // Extracts the unit
      return unit;
    }
    return ""
  }

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Stack direction="row" justifyContent="space-between" spacing={3} mb={3}>
          <Box display="flex" alignItems="center" color='text.main' lineHeight={1}>
            <IconButton  onClick={() =>navigate(-1)}>
            <AngleRightIcon fontSize="14px"  />
            </IconButton>
            <Typography variant='h6' fontWeight="semibold" ml={.75}>Products</Typography>
          </Box>

        {
          !isBatchAddOpen &&
          <MDButton
            type='button'
            variant='outlined'
            color='dark'
            size="small"
            startIcon={<EditIcon width='15px' height='15px' />}
            onClick={() => navigate(`/products/${singleProduct?.productDetails?.productId}?filter=${currentTab}`)}
          >
            Edit
          </MDButton>
        }

      </Stack>
      {
        currentTab === 'inventory' ?
          <InventroyTable productId={productId} totalProduct={singleProduct?.productDetails
            ?.totalQuantity} />
          : currentTab === 'batches' ? isBatchAddOpen ? <AddBatch batchHandler={batchHandler} /> : <BatchTable batchHandler={batchHandler} productId={productId} /> : currentTab === 'terpenoids' ? <ProductTerpenoids /> : 
          
            currentTab === 'activity_logs' ? <ProductActivityLogs/> :    <>
            <Card sx={{ p: 3, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} mb={3}>
                <Typography variant='h5' fontWeight="semibold">Product Images</Typography>

                <Box display="flex" alignItems="center" gap={1.25}>
                  {/* <Typography variant='h5' fontWeight="semibold">Status</Typography> */}

                  <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} >
                    {
                      status === 'active' && <MDButton
                        sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                        style={{ opacity: 1 }}
                        type='button'
                        variant={status === 'active' ? 'contained' : 'text'}
                        color={status === 'active' ? 'success' : 'dark'}
                        size="small"
                        disabled

                      >
                        Active
                      </MDButton>
                    }
                    {
                      status !== 'active' && <MDButton
                        sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                        type='button'
                        variant={status !== 'active' ? 'contained' : 'text'}
                        color={status !== 'active' ? 'error' : 'dark'}
                        size="small"
                        style={{ opacity: 1 }}
                        disabled
                      >
                        Inactive
                      </MDButton>
                    }
                  </Stack>
                </Box>
              </Stack>

              <Stack direction="row" spacing={1.25} mb={4}>
                {/* <Badge
               overlap="circular"
               anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
               badgeContent={
                 <PlusIcon color='white' fontSize='small' sx={{ backgroundColor: "#FD4438", borderRadius: 10, p: .25, mt: -4, mr: -4, cursor: "pointer" }} />
               }
             >
               <img style={{ borderRadius: "20px", width: "160px", height: "160px" }} src={productImage} alt="Product Name" />
             </Badge> */}
                {
                  productImages?.length > 0 ? productImages?.map((productImageUrl => {
                    return <Zoom key={productImageUrl} classDialog='custom-zoom'><img key={productImageUrl} style={{ borderRadius: "20px", width: "160px", height: "160px", objectFit: "cover" }} src={productImageUrl} alt="Product Name" /></Zoom>
                  })) : <img style={{ borderRadius: "20px", width: "160px", height: "160px", objectFit: "cover" }} src={defaultProductImage} alt="Product Name" />
                }
                {/* 
             <img style={{ borderRadius: "20px", width: "160px", height: "160px" }} src={productImage} alt="Product Name" />
             <img style={{ borderRadius: "20px", width: "160px", height: "160px" }} src={productImage} alt="Product Name" />
             <img style={{ borderRadius: "20px", width: "160px", height: "160px" }} src={productImage} alt="Product Name" />
             <img style={{ borderRadius: "20px", width: "160px", height: "160px" }} src={productImage} alt="Product Name" /> */}

                {/* <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="160px" borderRadius="20px" p={2} sx={{ cursor: "pointer" }}>
               <Typography variant='h5' fontWeight="medium">+</Typography>
               <Typography variant='h5' fontWeight="medium">Add More</Typography>
             </Box> */}
              </Stack>

              <Grid container alignItems="center" spacing={{ xs: 3, md: 5 }} mb={2}>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant='body2' color={grey[600]}>Product Name</Typography>
                  <Typography variant='h5' fontWeight="medium">{productName ? capitalizeText(productName) : "-"}</Typography>
                </Grid>
                {/* <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant='body2' color={grey[600]}>Total Price</Typography>
                  <Typography variant='h5' fontWeight="medium">${unitPrice || "-"} </Typography>
                </Grid> */}
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant='body2' color={grey[600]}>Unit Price</Typography>
                  <Typography variant='h5' fontWeight="medium">${unitPrice || 0} </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant='body2' color={grey[600]}>Sale Price</Typography>

                  <Typography variant='h5' fontWeight="medium" display="flex" alignItems="center" gap={.75}>

                    {
                      salePrice ? <>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="8" height="8" rx="4" fill="#24CA49" />
                        </svg>
                        {`$${salePrice}`}
                      </> : '-'
                    }

                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant='body2' color={grey[600]}>Category</Typography>
                  <Typography variant='h5' fontWeight="medium">{categoryName || "-"}</Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant='body2' color={grey[600]}>Brand name</Typography>
                  <Typography variant='h5' fontWeight="medium">{brandName || "-"}</Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant='body2' color={grey[600]}>Strain Type</Typography>
                  <Typography variant='h5' fontWeight="medium">{strainType || "-"}</Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant='body2' color={grey[600]}>Product Weight ({customWeight?.includes('mg') ? "mg" : "g"})</Typography>
                  <Typography variant='h5' fontWeight="medium">{customWeight || "-"}</Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography variant='body2' color={grey[600]}>THC ({thcData?.includes('%') ? "%" : "mg"})</Typography>
                  <Typography variant='h5' fontWeight="medium" lineHeight="1.25">{thcData || "-"}</Typography>
                </Grid>
                {/* <Grid item xs={12} sm={4} lg={3}>
               <Typography variant='body2' color={grey[600]}>Quantity</Typography>
               <Typography variant='h5' fontWeight="medium" lineHeight="1.25">10,000</Typography>
             </Grid> */}
                {/* <Grid item xs={12}>
                  <Typography variant='body2' color={grey[600]}>Heading</Typography>
                  <Typography variant='h5' fontWeight="regular" lineHeight="1.5">{seoData?.heading || "-"}</Typography>
                </Grid> */}
                <Grid item xs={12}>
                  <Typography variant='body2' color={grey[600]}>Description</Typography>
                  <Typography className="quill-text-editor-view" component="div" fontWeight="medium" variant="h6">{productDescription ? <div dangerouslySetInnerHTML={{ __html: productDescription }} /> : "-"}</Typography>
                </Grid>
                {
                  ingredientStatus && <Grid item xs={12}>
                    <Typography variant='body2' color={grey[600]}>Ingredients</Typography>
                    <Typography className="quill-text-editor-view" component="div" fontWeight="medium" variant="h6">{ingredients ? <div dangerouslySetInnerHTML={{ __html: ingredients }} /> : "-"}</Typography>
                  </Grid>
                }
                {
                  instructionStatus && <Grid item xs={12}>
                    <Typography variant='body2' color={grey[600]}>Instructions for use</Typography>
                    <Typography className="quill-text-editor-view" component="div" fontWeight="medium" variant="h6">{instructions ? <div dangerouslySetInnerHTML={{ __html: instructions }} /> : "-"}</Typography>
                  </Grid>
                }

              </Grid>
            </Card>
          </>
          }
    </Box>
  )
}
