import React, { useEffect, useRef, useState } from 'react';
import ReactQuill , {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { useDispatch } from 'react-redux';
import { uploadImage } from '../../redux/slices/common';

const CommonQuillEditor = ({ value, onChange, allowImage = true }) => {
  const dispatch = useDispatch()

  const [editorHtml, setEditorHtml] = useState('');

  const editorRef = useRef()

  const handleChange = (html) => {
    setEditorHtml(html);
    onChange && onChange(html);
  };

  const handleImageUpload = (file) => {
    const body = new FormData()
    body.append("filePath", file);
    dispatch(uploadImage(body)).unwrap().then((res => {
      const imageUrl = res.fileUrl;
      const cursorPosition = editor.getEditor().getSelection().index || 0;

      // Insert the image at the cursor position
      editor.getEditor().insertEmbed(cursorPosition, 'image', imageUrl);

      // Trigger a change event to update the state
      handleChange(editor.getEditor().getContents());
    })).catch((err) => {
    })

  };
  let editor = ''

  useEffect(() => {
    setEditorHtml(value)
  }, [value])

  return (
    <div>
      <ReactQuill
        className="quill-text-editor"
        theme="snow"
        value={editorHtml}
        onChange={handleChange}
        modules={{
          toolbar: {
            container: [
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              allowImage ? ['link', 'image'] : ['link'],
              ['clean'],
              [{ 'color': [] }, { 'background': [] }],
               [{ 'font': [] }],
               [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'align': [] }]
             
            ],
            // handlers: {
            //   image: handleImageUpload,
            // },
          },
        }}
        ref={(quill) => {
          if (quill) {
            editor = quill;
          }
        }}
      />
    </div>
  );
};

export default CommonQuillEditor;
