import AddPromotionForm from 'components/promotion/addPromotionForm'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { fetchSinglePromotion } from '../../../redux/slices/promotions';

export default function AddPromotion() {
  const { promotionId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (promotionId) {
      dispatch(fetchSinglePromotion(promotionId))
    }
  }, [promotionId])
  return (
    <CommonLayout>
      <AddPromotionForm />
    </CommonLayout>
  )
}
