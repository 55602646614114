import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import Fade from '@mui/material/Fade';
import colors from "assets/theme/base/colors";

export default function FilterMenu({ filter = null, handleFilter = () => { }, applyFilterHandler = () => { }, allowToday = true ,selectedMenu=""}) {
    const { primary } = colors;

    const handleClose = () => {
        handleFilter(null);
    };
    const handleClick = (filterValue) => {
        applyFilterHandler(filterValue)
        handleFilter(null);
    };

    const activeMenuItem = {
        color: `${primary.main} !important`,
        fontWeight: 600,
    };
    return (
        <Menu
            id=""
            anchorEl={filter}
            open={Boolean(filter)}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            TransitionComponent={Fade}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px -1px 4px rgba(0,0,0,0.05))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    }
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }} 
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={() => handleClick('today')} value="today" sx={selectedMenu==='today' ? activeMenuItem : ''}>Today</MenuItem>
            <MenuItem onClick={() => handleClick('week')} value="week" sx={selectedMenu==='week' ? activeMenuItem : ''}>Week</MenuItem>
            <MenuItem onClick={() => handleClick('month')} value="month" sx={selectedMenu==='month' ? activeMenuItem : ''}>Month</MenuItem>
        </Menu>
    )
}
