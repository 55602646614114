import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Typography, CircularProgress, Grid, Box, Select, MenuItem, Card, FormHelperText, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { validateBannerData } from '../../validations/homepageBanner'
import { openSnackbar, updateHomePageBanners, uploadImage } from '../../redux/slices/common';
import { isEmptyObject } from 'utilities/common';
import { useNavigate, useParams } from 'react-router-dom';

const initialEmployee = {
    banner_position: "",
    desktop_image: "",
    media_type: "",
    mobile_image: '',
    url: ""
}

const mediaTypes = [
    {
        label: "Video",
        value: "video"
    },
    {
        label: "Image",
        value: "image"
    },
]

function AddHomepageBanner(props) {
    const dispatch = useDispatch()

    const { modalData = {}, singleBanner = {} } = useSelector(state => state.common)
    const { isLoading = false } = useSelector(state => state.common)

    const { title = "Add", data: editData = {} } = modalData

    const [banners, setBanners] = useState({ ...initialEmployee })
    const [errors, setErrors] = useState({ ...initialEmployee })
    const isEditdata = false;
    const mobileImageRef = useRef()
    const desktopImageRef = useRef()
    const [isDesktopUploading, setIsDesktopUploading] = useState(false)
    const [isMobileUploading, setIsMobileUploading] = useState(false)
    const { bannerId = '' } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (isEditdata) {
            setBanners({ ...editData });
        }
    }, [isEditdata, editData]);

    const mediaType = useMemo(() => {
        return banners?.media_type
    }, [banners])

    const addBanner = () => {
        const { errors: validationErrors, isValid: isDataValid } = validateBannerData(banners);
        if (!isDataValid) {
            setErrors(validationErrors);
            return;
        }
        setErrors({})
        dispatch(updateHomePageBanners({ data: banners, id: (bannerId || '') })).unwrap().then(res => {
            navigate('/homepage-banners')
            showResponseMessage(res?.message,'success')
        }).catch((err)=>{
            showResponseMessage(err?.message,'error')
        });

    };
    

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const imageUploadHandler = (key, file) => {
        if (mediaType === 'video') {
            let fname = file.name
            let re = /(\.webm|\.mp4)$/i;
            if (!re.exec(fname)) {
                alert("File extension not supported!");
                return;
            }
        }
        if (mediaType === 'image') {
            let fname = file.name
            let re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
            if (!re.exec(fname)) {
                alert("File extension not supported!");
                return;
            }
        }
        if (key === 'desktop_image') {
            setIsDesktopUploading(true)
        }
        if (key === 'mobile_image') {
            setIsMobileUploading(true)
        }
        let formData = new FormData()
        formData.append("filePath", file);
        dispatch(uploadImage(formData)).unwrap().then((res) => {
            let errorsObj = { ...errors }
            if (errorsObj[key]) {
                delete errors[key]
                setErrors({ ...errors })
            }
            setBanners({ ...banners, [key]: res.fileUrl })
            if (key === 'desktop_image') {
                setIsDesktopUploading(false)
            }
            if (key === 'mobile_image') {
                setIsMobileUploading(false)
            }

        })
            .catch((err) => {
                setBanners({ ...banners, [key]: `${process.env.REACT_APP_PUBLIC_BASE_URL}test.jpg` })
                if (key === 'desktop_image') {
                    setIsDesktopUploading(false)
                }
                if (key === 'mobile_image') {
                    setIsMobileUploading(false)
                }
            });
    }

    useEffect(() => {
        if (!isEmptyObject(singleBanner)) {
            const { media_type, desktop_image, mobile_image, url } = singleBanner
            setBanners({ ...banners, media_type, desktop_image, mobile_image, url })
        }
    }, [singleBanner])

    const thumbnail = {
        width: "100%",
        maxWidth: "100%",
        height: "300px",
        borderRadius: "10px",
        display: "block"
    }

    return (
        <Box p={{ xs: 2, sm: 3, lg: 4 }}>
            <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
                { isLoading ? <Box p={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '300px' }}>
                        <CircularProgress size={20} />
                    </Box> : <>
                    <Typography variant='h4' fontWeight="semibold" color="secondary" mb={3}>{title} Banner</Typography>
                    
                    <Grid container spacing={2.5} mb={4.5}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant='body2' fontWeight="semibold" mb={1}>Media Type</Typography>
                            <Select key={mediaType} variant='outlined' name='mediaType' fullWidth value={mediaType} onChange={(e) => setBanners({ ...banners, media_type: e.target.value, desktop_image: "", mobile_image: "" })}>
                                <MenuItem value='video'>Video</MenuItem>
                                <MenuItem value='image'>Image</MenuItem>
                            </Select>
                            {
                                errors?.media_type && <FormHelperText error className="error">{errors?.media_type}</FormHelperText>
                            }
                        </Grid>
                    </Grid>
                    
                    <Grid container spacing={2.5} mb={4.5}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant='h5' fontWeight="semibold" mb={1}>Desktop {mediaType}</Typography>
                            <input accept={mediaType === 'video' ? ".mp4,.webm" : '.png,.jpg,.jpeg,.gif'} ref={desktopImageRef} style={{ display: 'none' }} type='file' onChange={(e) => imageUploadHandler('desktop_image', e.target.files[0])} />
                            {
                                isDesktopUploading ? <>
                                    <Box p={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '300px', backgroundColor: "#f7f7f7", borderRadius: "10px" }}>
                                        <CircularProgress size={20} />
                                    </Box>
                                </> : <Box p={2} style={{ backgroundColor: "#f7f7f7", borderRadius: "10px", cursor: 'pointer' }} onClick={() => {
                                    if (!mediaType) return alert('Plase select media type');
                                    desktopImageRef.current.click()
                                }}>
                                    {
                                        (!isDesktopUploading && banners?.desktop_image) ? mediaType === 'video' ? <video style={thumbnail} loop autoPlay playsInline muted>
                                            <source key={banners.desktop_image} src={banners.desktop_image} type="video/webm" />
                                            <source key={banners.desktop_image} src={banners.desktop_image} type="video/mp4" />
                                        </video> : <img style={thumbnail} src={banners.desktop_image} /> : <img style={thumbnail} src={`https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png`} />
                                    }
                                </Box>
                            }
                            {
                                errors?.desktop_image && <FormHelperText error className="error">{errors?.desktop_image}</FormHelperText>
                            }
                            {
                                banners?.banner_position && <Typography color='red'><strong>Note:</strong> {banners?.banner_position === 'left' ? "Banner image should be 873x365" : "Banner image should be 275x365"}  </Typography>
                            }

                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant='h5' fontWeight="semibold" mb={1}>Mobile {mediaType}</Typography>
                            <input accept={mediaType === 'video' ? ".mp4,.webm" : '.png,.jpg,.jpeg,.gif'} ref={mobileImageRef} type='file' style={{ display: 'none' }} onChange={(e) => imageUploadHandler('mobile_image', e.target.files[0])} />
                            {
                                isMobileUploading ? <>
                                    <Box p={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '300px', backgroundColor: "#f7f7f7", borderRadius: "10px" }}>
                                        <CircularProgress size={20} />
                                    </Box>

                                </> : <Box p={2} style={{ backgroundColor: "#f7f7f7", borderRadius: "10px", cursor: 'pointer' }} onClick={() => {
                                    if (!mediaType) return alert('Plase select media type');
                                    mobileImageRef.current.click()
                                }}>
                                    {
                                        banners?.mobile_image ? mediaType === 'video' ? <video style={thumbnail} loop autoPlay playsInline muted>
                                            <source key={banners.mobile_image} src={banners.mobile_image} style={thumbnail} type="video/mp4" />
                                            <source key={banners.mobile_image} src={banners.mobile_image} style={thumbnail} type="video/webm" />
                                        </video> : <img src={banners.mobile_image} style={thumbnail} /> : <img style={thumbnail} src={`https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png`} />
                                    }

                                </Box>
                            }
                            {
                                errors?.mobile_image && <FormHelperText error className="error">{errors?.mobile_image}</FormHelperText>
                            }
                            {
                                banners?.banner_position && <Typography color='red'><strong>Note:</strong> {banners?.banner_position === 'left' ? "Banner image should be 873x365" : "Banner image should be 275x365"}  </Typography>
                            }
                        </Grid>
                    </Grid>
                        
                    <Grid container spacing={2.5} mb={4}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant='body2' fontWeight="semibold" mb={1}>URL</Typography>
                            <TextField 
                                name="url"
                                value={banners?.url || ""}
                                onChange={(e) => { setBanners({ ...banners, url: e.target.value }) }}
                                label="URL"
                                fullWidth
                                error={Boolean(errors.url)}
                                helperText={errors.url}
                            />
                        </Grid>
                    </Grid>

                    <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25}>
                        <Button onClick={addBanner} variant="contained" color="primary">{isLoading ? <CircularProgress size={20} style={{ color: "white" }} /> : 'Submit'}</Button>
                    </Stack>
                </> }
            </Card>
        </Box>

    );
}



export default AddHomepageBanner