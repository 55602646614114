import AddCategoryForm from 'components/category/AddCategoryForm'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { fetchSingleCategory } from '../../../redux/slices/category'
import { useDispatch } from 'react-redux'

export default function AddCategory() {
  const dispatch = useDispatch()
  const { categoryId = "" } = useParams()
  useEffect(() => {
    if(categoryId){
      dispatch(fetchSingleCategory(categoryId))
    }
  }, [categoryId])
  return (
   <CommonLayout>
    <AddCategoryForm/>
   </CommonLayout>
  )
}
