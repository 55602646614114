import React, { useMemo } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);


const options = {
    responsive: true,
    plugins: {
        legend: {
            // position: 'top',
            display: true
        },
        legend: {
            display: false
        }
    },
    scales: {
        y: {
            display: false,
            ticks: {
                display: false,
            },
        },
        x: {
            // type: '',
            // position: 'bottom',
            display:false
          },
    }
};

export default function TrendChart({ inventoryTrend }) {

    const memoChartTrend = useMemo(() => {
        // const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        const chartData = {
            // labels,
            datasets: [
                {
                    data: [4, 3, 5, 5, 4, 6, 7, 9, 8, 10, 11],
                    borderColor: '#8676FF',
                    // pointBorderWidth: 5,
                    pointBorderColor: '#8676FF',
                    pointBackgroundColor: '#8676FF',
                    hoverBorderWidth: 5,
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 180);
                        gradient.addColorStop(0, "rgba(142, 12, 245, 1)");
                        gradient.addColorStop(1, "rgba(142, 12, 245, 0.00)");

                        // fill: linear - gradient(180deg, rgba(142, 12, 245, 0.16) 0 %, rgba(142, 12, 245, 0.00) 178.38 %);
                        return gradient;
                    },
                    pointRadius: 0,
                    lineTension: 0.4,
                    radius:"10px"
                }
            ],
        };
        if (inventoryTrend?.length) {
            chartData.labels = inventoryTrend
            chartData.datasets[0].data = inventoryTrend;


        }
        return chartData
    }, [])
    return <Line options={options} data={memoChartTrend} />;
}