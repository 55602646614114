/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

const { transparent, white, light } = colors;
const { borderRadius } = borders;

const iconButton = {
  styleOverrides: {
    // root: {
    //   backgroundColor: transparent.main,

    //   "&:hover": {
    //     backgroundColor: transparent.main,
    //   },
    // },
    root: ({ ownerState }) => ({
      ...(ownerState.variant && {
          backgroundColor: '#fff',
          borderRadius: borderRadius.md,
          boxShadow: '0px 2.767px 2.214px 0px rgba(5, 37, 135, 0.02), 0px 6.65px 5.32px 0px rgba(5, 37, 135, 0.02), 0px 12.522px 10.017px 0px rgba(5, 37, 135, 0.03), 0px 22.336px 17.869px 0px rgba(5, 37, 135, 0.04), 0px 41.778px 33.422px 0px rgba(5, 37, 135, 0.04), 0px 100px 80px 0px rgba(5, 37, 135, 0.06)',

          "&:hover": {
            backgroundColor: '#fff',
          },
        }),
        backgroundColor: ownerState.variant === 'primary' ? white.main  : transparent.main,

        ...(ownerState.variant && ownerState.variant === 'btn-filter' && {
          backgroundColor: "#fff",
          padding: "4px",
          boxShadow: "0 1px 4px -2px rgb(0 0 0 / 10%)",
          border: "1px solid #F1F1F1",

          "&:hover": {
            boxShadow: "0 1px 8px -4px rgb(0 0 0 / 20%)",
          },
        }),
    }),
    
  },


};

export default iconButton;
