import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cancelledOrderStatsAPI, categorySoldStatsAPI, completedOrderStatsAPI, creditCalculationsStatsAPI, memberCalculationsStatsAPI, memberStatsDataAPI, taxCalculationsStatsAPI, totalNewCustomersAPI, totalOrdersStatsAPI, totalProductsSoldAPI, totalProductsSoldStatsAPI, totalRevenueStatsAPI } from '../../redux/apis/dashboard';

export const totalCustomersCount = createAsyncThunk(
    'dashboard/totalCustomersCount',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await memberStatsDataAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);


export const totalNewCustomersStats = createAsyncThunk(
    'dashboard/totalNewCustomersStats',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await totalNewCustomersAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);


export const totalSoldCategories = createAsyncThunk(
    'dashboard/totalSoldCategories',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await categorySoldStatsAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);


export const taxCalculationStats = createAsyncThunk(
    'dashboard/taxCalculationStats',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await taxCalculationsStatsAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);


export const creditCalculationStats = createAsyncThunk(
    'dashboard/creditCalculationStats',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await creditCalculationsStatsAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const memberCalculationStats = createAsyncThunk(
    'dashboard/memberCalculationStats',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await memberCalculationsStatsAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const totalProductsSoldStats = createAsyncThunk(
    'dashboard/totalProductsSoldStats',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await totalProductsSoldStatsAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const totalProductsSold = createAsyncThunk(
    'dashboard/totalProductsSold',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await totalProductsSoldAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);


export const totalRevenueStats = createAsyncThunk(
    'dashboard/totalRevenueStats',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await totalRevenueStatsAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);


export const totalOrdersStats = createAsyncThunk(
    'dashboard/totalOrdersStats',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await totalOrdersStatsAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const cancelledOrderStats = createAsyncThunk(
    'dashboard/cancelledOrderStats',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await cancelledOrderStatsAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const completedOrdersStats = createAsyncThunk(
    'dashboard/completedOrdersStats',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await completedOrderStatsAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        totalCustomersCountData: null,
        fetchingCustomer: false,

        taxCalculationStatsData: null,
        fetchingTaxCalculationStats: false,

        totalSoldCategoriesData: null,
        fetchingSoldCategories: false,

        memberCalculationStatsData: null,
        fetchingMemberCalculationStats: false,

        creditCalculationStatsData: null,
        fetchingCreditCalculationStats: false,


        totalProductsSoldStatsData: null,
        fetchingTotalProductsSoldStats: false,
        errorTotalProductsSoldStats: null,


        totalRevenueStatsData: null,
        fetchingTotalRevenueStats: false,
        errorTotalRevenueStats: null,

        totalOrdersStatsData: null,
        fetchingTotalOrdersStats: false,
        errorTotalOrdersStats: null,

        totalProductsSoldData: null,
        fetchingTotalProductsSold: false,
        errorTotalProductsSold: null,


        completedOrdersStatsData: null,
        fetchingCompletedOrdersStats: false,
        errorCompletedOrdersStats: null,

        cancelledOrderStatsData: null,
        fetchingCancelledOrderStats: false,
        errorCancelledOrderStats: null,

        totalNewCustomersStatsData:null,
        totalNewCustomerStatsError:null,
        fetchingTotalNewCustomersStats:false

    },
    reducers: {
        // Other reducers if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(totalCustomersCount.pending, (state) => {
                state.fetchingCustomer = true;
                state.error = null;
            })
            .addCase(totalCustomersCount.fulfilled, (state, action) => {
                state.fetchingCustomer = false;
                state.totalCustomersCountData = action.payload;
            })
            .addCase(totalCustomersCount.rejected, (state, action) => {
                state.fetchingCustomer = false;
                state.error = action.payload;
            })
            .addCase(totalSoldCategories.pending, (state) => {
                state.fetchingSoldCategories = true;
                state.errorSoldCategories = null;
            })
            .addCase(totalSoldCategories.fulfilled, (state, action) => {
                state.fetchingSoldCategories = false;
                state.totalSoldCategoriesData = action.payload;
            })
            .addCase(totalSoldCategories.rejected, (state, action) => {
                state.fetchingSoldCategories = false;
                state.errorSoldCategories = action.payload;
            })
            .addCase(taxCalculationStats.pending, (state) => {
                state.fetchingTaxCalculationStats = true;
            })
            .addCase(taxCalculationStats.fulfilled, (state, action) => {
                state.fetchingTaxCalculationStats = false;
                state.taxCalculationStatsData = action.payload;
            })
            .addCase(taxCalculationStats.rejected, (state, action) => {
                state.fetchingTaxCalculationStats = false;
            })
            .addCase(memberCalculationStats.pending, (state) => {
                state.fetchingMemberCalculationStats = true;
            })
            .addCase(memberCalculationStats.fulfilled, (state, action) => {
                state.fetchingMemberCalculationStats = false;
                state.memberCalculationStatsData = action.payload;
            })
            .addCase(memberCalculationStats.rejected, (state, action) => {
                state.fetchingMemberCalculationStats = false;
            })

            .addCase(creditCalculationStats.pending, (state) => {
                state.fetchingCreditCalculationStats = true;
            })
            .addCase(creditCalculationStats.fulfilled, (state, action) => {
                state.fetchingCreditCalculationStats = false;
                state.creditCalculationStatsData = action.payload;
            })
            .addCase(creditCalculationStats.rejected, (state, action) => {
                state.fetchingCreditCalculationStats = false;
            })
            .addCase(totalProductsSoldStats.pending, (state) => {
                state.fetchingTotalProductsSoldStats = true;
                state.errorTotalProductsSoldStats = null;
            })
            .addCase(totalProductsSoldStats.fulfilled, (state, action) => {
                state.fetchingTotalProductsSoldStats = false;
                state.totalProductsSoldStatsData = action.payload;
            })
            .addCase(totalProductsSoldStats.rejected, (state, action) => {
                state.fetchingTotalProductsSoldStats = false;
                state.errorTotalProductsSoldStats = action.payload;
            })
            .addCase(totalRevenueStats.pending, (state) => {
                state.fetchingTotalRevenueStats = true; // Set the flag to true when fetching starts
                state.errorTotalRevenueStats = null;
            })
            .addCase(totalRevenueStats.fulfilled, (state, action) => {
                state.fetchingTotalRevenueStats = false; // Set the flag to false when fetching is successful
                state.totalRevenueStatsData = action.payload;
            })
            .addCase(totalRevenueStats.rejected, (state, action) => {
                state.fetchingTotalRevenueStats = false; // Set the flag to false when fetching is unsuccessful
                state.errorTotalRevenueStats = action.payload;
            }).addCase(totalOrdersStats.pending, (state) => {
                state.fetchingTotalOrdersStats = true;
                state.errorTotalOrdersStats = null;
            })
            .addCase(totalOrdersStats.fulfilled, (state, action) => {
                state.fetchingTotalOrdersStats = false;
                state.totalOrdersStatsData = action.payload;
            })
            .addCase(totalOrdersStats.rejected, (state, action) => {
                state.fetchingTotalOrdersStats = false;
                state.errorTotalOrdersStats = action.payload;
            }).addCase(totalProductsSold.pending, (state) => {
                state.fetchingTotalProductsSold = true;
                state.errorTotalProductsSold = null;
            })
            .addCase(totalProductsSold.fulfilled, (state, action) => {
                state.fetchingTotalProductsSold = false;
                state.totalProductsSoldData = action.payload;
            })
            .addCase(totalProductsSold.rejected, (state, action) => {
                state.fetchingTotalProductsSold = false;
                state.errorTotalProductsSold = action.payload;
            })
            .addCase(completedOrdersStats.pending, (state) => {
                state.fetchingCompletedOrdersStats = true;
                state.errorCompletedOrdersStats = null;
            })
            .addCase(completedOrdersStats.fulfilled, (state, action) => {
                state.fetchingCompletedOrdersStats = false;
                state.completedOrdersStatsData = action.payload;
            })
            .addCase(completedOrdersStats.rejected, (state, action) => {
                state.fetchingCompletedOrdersStats = false;
                state.errorCompletedOrdersStats = action.payload;
            }).addCase(cancelledOrderStats.pending, (state) => {
                state.fetchingCancelledOrderStats = true;
                state.errorCancelledOrderStats = null;
            })
            .addCase(cancelledOrderStats.fulfilled, (state, action) => {
                state.fetchingCancelledOrderStats = false;
                state.cancelledOrderStatsData = action.payload;
            })
            .addCase(cancelledOrderStats.rejected, (state, action) => {
                state.fetchingCancelledOrderStats = false;
                state.errorCancelledOrderStats = action.payload;
            })
            .addCase(totalNewCustomersStats.pending, (state) => {
                state.fetchingTotalNewCustomersStats = true;
                state.totalNewCustomerStatsError = null;
            })
            .addCase(totalNewCustomersStats.fulfilled, (state, action) => {
                state.fetchingTotalNewCustomersStats = false;
                state.totalNewCustomersStatsData = action.payload; // Assuming the API response contains the new customers' stats
            })
            .addCase(totalNewCustomersStats.rejected, (state, action) => {
                state.fetchingTotalNewCustomersStats = false;
                state.totalNewCustomerStatsError = action.payload; // Set the error message received from the API response
            });


    },
});

export const { /* other actions if needed */ } = dashboardSlice.actions;
export const selectTotalCustomersCount = (state) => state.dashboard.totalCustomersCountData;
export const selectFetchingCustomer = (state) => state.dashboard.fetchingCustomer;
export const selectError = (state) => state.dashboard.error;

export default dashboardSlice.reducer;
