import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Typography, List, ListItem, TextField, IconButton, InputAdornment, Box } from "@mui/material";
import driverPic from "../../assets/images/driver-pic.svg"
import DriverDetails from './DriverDetails';
import { useDispatch } from 'react-redux';
import { fetchDrivers } from '../../redux/slices/hyperdrive/driverAnalytics';
import SearchIcon from 'common/customIcons/search';
import { CloseRounded } from '@mui/icons-material';
import { useDebounce } from 'hooks/useDebounce';
import noDataFound from 'assets/images/nothing-found.svg';

const MapDriveList = ({ dataPassComponent }) => {
    if (!dataPassComponent || Object.keys(dataPassComponent).length === 0) {
        return <div>Loading...</div>;
    }
    const dispatch = useDispatch();
    const [driversList, setDriversList] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const [detailsDriver, setDetailsDriver] = useState(null)
    const searchTermRef = useRef("")
    const [searchTerm, setSearchTerm] = useState("")

    const loadMorePosts = useCallback(async (data) => {
        setLoading(true);
        try {
            const limit = 20;
            const skip = data ? 0 : page * limit;
            const res = await dispatch(fetchDrivers({
                skip,
                limit,
                driverName: searchTermRef.current,
                startDate: dataPassComponent?.startDate ? dataPassComponent?.startDate : "",
                endDate: dataPassComponent?.endDate ? dataPassComponent?.endDate : "",
                driverStatus: dataPassComponent?.driverStatus === "offDuty" ? false : dataPassComponent?.driverStatus === "inTransit" ? true : "",
                taskStatus: dataPassComponent?.taskStatus ? dataPassComponent?.taskStatus : ""
            })).unwrap();

            const newPosts = res?.data;

            if (newPosts?.length === 0) {
                setHasMore(false);
            } else {
                setDriversList((prevPosts) => [...prevPosts, ...newPosts]);
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [dispatch, page]);

    useEffect(() => {
        if (hasMore) {
            loadMorePosts();
        }
    }, [hasMore]);

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading || !hasMore) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const handleSearch = useDebounce(() => {
        setPage(0);
        setDriversList([]);
        loadMorePosts("page");
    }, 500);

    const searchHandler = useCallback((searchValue) => {
        setSearchTerm(searchValue);
        searchTermRef.current = searchValue;
        handleSearch();
    }, [handleSearch]);

    function handleDataFromChild(data) {
        setDetailsDriver(null)
    }

    // useEffect(() => {
    //     setPage(0);
    //     setHasMore(prev=>true)
    //     setDriversList([]);
    //     loadMorePosts("page");
    // }, [JSON.stringify(dataPassComponent)])

    return (
        <>
            <TextField className="custom-search-field dark-field w-100 p-3" placeholder="Search" label=""
                value={searchTerm} onChange={(e) => { searchHandler(e.target.value); setDetailsDriver(null) }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <IconButton size='small'>
                            <SearchIcon width='18px' height='18px' />
                        </IconButton>
                    </InputAdornment>,
                    endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                        <IconButton onClick={() => searchHandler('')} size='small'>
                            <CloseRounded />
                        </IconButton>
                    </InputAdornment> : null
                }}
            />
            <div className='hd-drivers-list-inner'>
                <List>
                    {driversList?.length === 0 ? (
                        <ListItem>
                            <Box textAlign="center" className="w-100 no-data-found mt-5">
                                <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Results Found" />
                                <p className='text-12'><strong>No Results Found</strong></p>
                            </Box>
                        </ListItem>
                    ) : (
                        driversList?.map((data, idx) => (
                            <>
                                <ListItem key={idx}
                                    ref={driversList.length === idx + 1 ? lastPostElementRef : null}
                                    className='d-flex align-item-top jc-s-btwn gap-2'
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setDetailsDriver(data?._id);
                                    }}
                                >
                                    <div className='d-flex flex-row gap-2'>
                                        <div className='d-flex'>
                                            <img className='mt-1' src={driverPic} alt='' width={28} height={28} />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <Typography variant='h6' className='text-14 text-white-700 text-transform-capitalize'>
                                                {data?.driverName}
                                            </Typography>
                                        </div>
                                    </div>
                                    <Typography className='d-flex align-item-center gap-1 text-12 white-space-nowrap'>
                                        <span className={data?.status ? 'dot-duty' : "dot-duty dot-off"}></span>
                                        <span className={data?.status ? 'driver-duty-txt' : 'driver-off-duty-txt'}>
                                            {data?.status ? "On duty" : "Off Duty"}
                                        </span>
                                    </Typography>
                                </ListItem>
                                {loading && <p>Loading...</p>}
                            </>
                        ))
                    )}
                </List>
                {
                    detailsDriver &&
                    <DriverDetails sendDataToParent={handleDataFromChild} driverId={detailsDriver} />
                }
            </div>
        </>
    );
};

export default MapDriveList;
