import OrderDetails from 'components/orders/OrderDetails'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSingleOrder } from '../../../../redux/slices/orders'
import HyperwolfOrderDetails from 'components/hyperwolf/orders/Orders'

export default function HyperwolfOrderDetailsPage() {
  const dispatch = useDispatch()
  const { orderId = "" } = useParams()
  useEffect(() => {
    if (orderId) {
      dispatch(fetchSingleOrder(orderId))
    }
  }, [orderId])
  return (
    <CommonLayout>
      <HyperwolfOrderDetails />
    </CommonLayout>
  )
}
