import axios from "axios";
import { axiosHyperwolfDevClient } from "axiosClient";
import { axiosHyperwolfClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchDriversAPI(filters) {
    return await axiosHyperwolfDevClient.get(`/api/v1/fleet/list?${getQueryString(filters)}`)
}

export async function addDriverAPI(data) {
    return await axiosHyperwolfDevClient.post('/api/v1/fleet/register',data)
}
// Rb2BmBfgxZL8dk34
export async function deleteDriverAPI(data) {
    return await axiosHyperwolfDevClient.delete('/api/v1/fleet/delete', {data: data})
}

export async function bulkDeleteDriverAPI(data) {
  return await axiosHyperwolfDevClient.delete('/api/v1/fleet/bulkDelete', {data: data})
}

export async function fetchDriverDetailsAPI(fleetId){
  return await axiosHyperwolfDevClient.get(`/api/v1/fleet/details?fleetId=${fleetId}`,
  )
}

export async function updateDriverAPI(data){
  return await axiosHyperwolfDevClient.put('/api/v1/fleet/edit',data,)
}

export async function getTransportationsAPI(){
  return await axiosHyperwolfDevClient.get(`/api/v1/transportationTypes/get`, 
  )
}

export async function fetchAdminsAPI(){
  return await axiosHyperwolfDevClient.get('/api/v1/fleet/getAdmins')
}

export async function exportCsvAPI() {
  return await axiosHyperwolfDevClient.get('/api/v1/fleet/exportDrivers')
}

export async function importFleetsAPI(data) {
  return await axiosHyperwolfDevClient.post('/api/v1/fleet/import',data)
}