import { Grid, IconButton } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import CommonLayout from "layouts/DashboardLayout";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import { Box, Card, CardContent, FormControl, InputLabel, MenuItem, Pagination, Paper, Select, Stack, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";
import { Circle, CircleNotifications, DragIndicator, FilterAltOutlined } from "@mui/icons-material";
import DotIndicatorIcon from "common/customIcons/dot_indicator";
import LineChart from "common/charts/LinChart";
import { DoughnutChart } from "common/charts/DoghnutChart";
import { totalCustomersCount, totalOrdersStats, totalProductsSold, totalRevenueStats } from '../../redux/slices/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { addCommas } from 'utilities/common';
import MDButton from 'components/MDButton';
import FilterMenu from 'common/FilterMenu';
import { addCommasWithToFixed } from 'utilities/common';

const ordersData = [4, 3, 5, 5, 4, 6, 7, 9, 8, 10, 11]

function pointRadiusLast(radius, length, initialArray) {
  var result = initialArray || [radius];
  while (result.length < length) result.unshift(0);
  return result;
}


const options = {
  responsive: true,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  plugins: {
    legend: {
      // position: 'right',
      display: false
    },
    tooltip: {
      backgroundColor: "#fdfdfd",
      bodyColor: "#16161E",
      titleColor: "#16161E",
      borderWidth: 1,
      borderColor: "#F1F1F1",
      padding: 8
    },
    title: {
      display: false,
    },
    x: {
      ticks: {
        display: false // remove this line to get autoscalling 
      }
    },
  },
};


const radardata = {
  labels: ['Child Mode', 'Pain Reli', 'Sleepy', 'Elevated', 'Munchies', 'Child Mode', 'Pain Reli', 'Sleepy', 'Elevated', 'Munchies'],
  datasets: [
    {
      data: [3, 1, 5, 4, 5, 3, 1, 5, 4, 5],
      backgroundColor: '#E3E37E',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1,
      borderColor: '#7A7A07',
      pointBackgroundColor: '#7A7A07',
      pointBorderWidth: 0,
      pointBorderColor: 'black',
      borderRadius: '10px'
    },
  ],
};




export default function RevenueCustomersAndOrdersStats() {
  const dispatch = useDispatch();
  const dashboardData = useSelector(state => state.dashboard)
  const { totalCustomersCountData = {}, totalRevenueStatsData = {}, totalOrdersStatsData = {}, totalProductsSoldData = {} } = dashboardData || {}
  const [revenueFilterOpen, setRevenueFilterOpen] = useState(false)
  const [topProductFilterOpen, setTopProductFilterOpen] = useState(false)
  const [totalOrderFilterOpen, setTotalOrderFilterOpen] = useState(false)



  const revenueChartData = useMemo(() => {
    let chartData = {
      labels: [],
      datasets: [
        {
          data: [],
          borderColor: '#8E0CF5',
          // pointBorderWidth: 5,
          pointBorderColor: '#8E0CF5',
          pointBackgroundColor: '#8E0CF5',
          hoverBorderWidth: 5,

          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 180);
            gradient.addColorStop(0, "rgba(142, 12, 245, 1)");
            gradient.addColorStop(1, "rgba(142, 12, 245, 0.00)");

            // fill: linear - gradient(180deg, rgba(142, 12, 245, 0.16) 0 %, rgba(142, 12, 245, 0.00) 178.38 %);
            return gradient;
          },
          pointRadius: 0,
          fill: true,
          lineTension: 0.2,
        },
      ],
    };
    chartData.labels = totalRevenueStatsData?.results?.map((product => product?.label))
    chartData.datasets[0].data = totalRevenueStatsData?.results?.map((product => product?.totalRevenue))
    return chartData
  }, [totalRevenueStatsData?.results])



  const totalOrdersData = useMemo(() => {
    let chartData = {
      labels: [],
      datasets: [
        {
          label: 'Dataset 1',
          data: [],
          borderColor: '#FF7A2F',
          // pointBorderWidth: 5,
          pointBorderColor: '#FF7A2F',
          pointBackgroundColor: '#FF7A2F',
          hoverBorderWidth: 5,

          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 180);
            gradient.addColorStop(0, "rgba(255, 122, 47, 1)");
            gradient.addColorStop(1, "rgba(255, 122, 47, 0.00)");

            // fill: linear-gradient(180deg, rgba(255, 122, 47, 0.16) 0%, rgba(255, 122, 47, 0.00) 178.38%);
            return gradient;
          },
          pointRadius: pointRadiusLast(5, ordersData.length),
          fill: true,
          lineTension: 0.2,
          //   pointStyle: 'rectRot',
        },
      ],
    };
    chartData.labels = totalOrdersStatsData?.results?.map((order => order?.label))
    chartData.datasets[0].data = totalOrdersStatsData?.results?.map((order => order?.totalOrders))
    return chartData
  }, [totalOrdersStatsData?.results])



  const customersChartData = useMemo(() => {
    let data = {
      labels: [],
      datasets: [
        {
          data: [],
          borderWidth: 1,
          backgroundColor: ['#24CA49', '#FE7E07'],
          fill: true,
          cutout: '90%',
          borderRadius: '10',
        },
      ],
    };
    data.labels = ['Active', 'Inactive']
    data.datasets[0].backgroundColor = ['#24CA49', '#FE7E07']
    data.datasets[0].data = [totalCustomersCountData?.activeMembersCount, totalCustomersCountData?.inactiveMembersCount]
    return data;
  }, [totalCustomersCountData])

  const chartInnerContent = useMemo(() => {
    return <Paper sx={{
      width: 100, // Set the width of the circle
      height: 100, // Set the height of the circle
      borderRadius: '50%', // Make it a circle by setting borderRadius to 50%
      backgroundColor: '#ffffff',
      boxShadow: '0px 0px 2.214px rgba(179, 158, 158, 0.02), 0px 0px 5.32px rgba(179, 158, 158, 0.03), 0px 0px 10.017px rgba(179, 158, 158, 0.04), 0px 0px 17.869px rgba(179, 158, 158, 0.04), 0px 0px 33.422px rgba(179, 158, 158, 0.05), 0px 0px 80px rgba(179, 158, 158, 0.07)', // Add a shadow with color rgba(0, 0, 0, 0.5)
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Typography variant='h5'>{totalCustomersCountData?.activePercentage}%</Typography>
    </Paper>


  }, [totalCustomersCountData])

  const totalProductsSoldChartData = useMemo(() => {
    let data = {
      labels: [],
      datasets: [
        {
          // label: '# of Votes',
          data: [],
          backgroundColor: '#3DD598',
          borderColor: '#3DD598',
          borderWidth: 1,
        },
      ],
    };
    data.labels = totalProductsSoldData?.results?.map((product => product?.label))
    data.datasets[0].data = totalProductsSoldData?.results?.map((product => product?.totalQty))
    return data
  }, [totalProductsSoldData?.results])





  const [topProductFilter, setTopProductFilter] = useState({
    revenueWeekMonthToday: 'month',
    totalOrdersWeekMonthToday: 'month',
    productsSoldWeekMonthDay: "month"
  })

  useEffect(() => {
    dispatch(totalRevenueStats({ dashboardQuery: topProductFilter.revenueWeekMonthToday }))

  }, [topProductFilter.revenueWeekMonthToday])


  useEffect(() => {
    dispatch(totalOrdersStats({ dashboardQuery: topProductFilter.totalOrdersWeekMonthToday }))

  }, [topProductFilter.totalOrdersWeekMonthToday])

  useEffect(() => {
    dispatch(totalProductsSold({ dashboardQuery: topProductFilter.productsSoldWeekMonthDay }))

  }, [topProductFilter.productsSoldWeekMonthDay])

  useEffect(() => {
    dispatch(totalCustomersCount())
  }, [])
  return (
    <>
      <Grid item xs={12} sm={6} xxl={2.85}>
        <Card variant='dashboardCard' sx={{ height: '100%', overflow: 'hidden' }}>
          <CardContent sx={{ p: 2.5 }}>
            <Stack direction='row' alignItems='start' justifyContent='space-between'>
              <Box>
                <Typography variant="body2">Total Products Sold</Typography>
                <Typography variant="h5" mt={1}>{totalProductsSoldData?.totalSum}</Typography>
              </Box>

              {/* filter ui  */}
              <IconButton variant='btn-filter' color='secondary' onClick={(e) => setTopProductFilterOpen(e.currentTarget)}>
                <FilterAltOutlined fontSize='medium' color='secondary' />
              </IconButton>
              <FilterMenu allowToday={true} selectedMenu={topProductFilter?.productsSoldWeekMonthDay} filter={topProductFilterOpen} handleFilter={setTopProductFilterOpen} applyFilterHandler={(value) => setTopProductFilter({ ...topProductFilter, productsSoldWeekMonthDay: value })} />
              {/* filter ui end  */}

              {/* <FormControl fullWidth sx={{ maxWidth: "108px" }}>
                <Select
                  value={topProductFilter?.productsSoldWeekMonthDay}
                  className="form-select form-dropdown"
                  name="productsSoldWeekMonthDay"
                  onChange={(e) => setTopProductFilter({ ...topProductFilter, productsSoldWeekMonthDay: e.target.value })}
                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="month">Month</MenuItem>

                </Select>
              </FormControl> */}
            </Stack>
          </CardContent>
          <CardContent sx={{ px: 2.5, pt: 0, pb: "10px !important", height: "100%" }}>
            <Bar data={totalProductsSoldChartData} options={options} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} xxl={2.85}>
        <Card variant='dashboardCard' sx={{ height: '100%', overflow: 'hidden' }}>
          <CardContent sx={{ p: 2.5 }}>
            <Stack direction='row' alignItems="start" justifyContent='space-between'>
              <Box>
                <Typography variant="body2">Total Revenue</Typography>
                <Typography variant="h5" mt={1}>${totalRevenueStatsData?.totalSum ? addCommasWithToFixed(totalRevenueStatsData?.totalSum) : 0}</Typography>
              </Box>

              {/* filter ui  */}
              <IconButton variant='btn-filter' color='secondary' onClick={(e) => setRevenueFilterOpen(e.currentTarget)}>
                <FilterAltOutlined fontSize='medium' color='secondary' />
              </IconButton>
              <FilterMenu allowToday={false} selectedMenu={topProductFilter?.revenueWeekMonthToday} filter={revenueFilterOpen} handleFilter={setRevenueFilterOpen} applyFilterHandler={(value) => setTopProductFilter({ ...topProductFilter, revenueWeekMonthToday: value })} />
              {/* filter ui end  */}
            </Stack>
          </CardContent>
          <LineChart chartData={revenueChartData} />
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} xxl={2.85}>
        <Card variant='dashboardCard' sx={{ height: '100%', overflow: 'hidden' }}>
          <CardContent sx={{ p: 2.5 }}>
            <Stack direction='row' alignItems='start' justifyContent='space-between'>
              <Box>
                <Typography variant="body2">Total Orders</Typography>
                <Typography variant="h5" mt={1}>{totalOrdersStatsData?.totalSum ? addCommas(totalOrdersStatsData?.totalSum) : 0}</Typography>
              </Box>

              {/* filter ui  */}
              <IconButton variant='btn-filter' color='secondary' onClick={(e) => setTotalOrderFilterOpen(e.currentTarget)}>
                <FilterAltOutlined fontSize='medium' color='secondary' />
              </IconButton>
              <FilterMenu allowToday={false} selectedMenu={topProductFilter?.totalOrdersWeekMonthToday} filter={totalOrderFilterOpen} handleFilter={setTotalOrderFilterOpen} applyFilterHandler={(value) => setTopProductFilter({ ...topProductFilter, totalOrdersWeekMonthToday: value })} />
              {/* filter ui end  */}

              {/* <FormControl fullWidth sx={{ maxWidth: "108px" }}>
                <Select
                  value={topProductFilter?.totalOrdersWeekMonthToday}
                  className="form-select form-dropdown"
                  name="totalOrdersWeekMonthToday"
                  onChange={(e) => setTopProductFilter({ ...topProductFilter, totalOrdersWeekMonthToday: e.target.value })}
                >
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="month">Month</MenuItem>
                </Select>
              </FormControl> */}
            </Stack>

          </CardContent>
          <LineChart chartData={totalOrdersData} />
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} xxl={3.45}>
        <Card variant='dashboardCard' sx={{ height: '100%', overflow: 'hidden' }}>
          <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", p: 2.5 }}>
            <Box display='flex' justifyContent='space-between'>
              <Box>
                <Typography variant="body2">Total Customers</Typography>
                <Typography variant="h5" mt={1}>{totalCustomersCountData?.totalMembers}</Typography>
              </Box>
              <Box sx={{ height: 140, width: 140 }}>
                <DoughnutChart data={customersChartData} chartInnerContent={chartInnerContent} />
              </Box>
            </Box>

            <Stack direction="row" flexWrap="wrap" gap={2} mt={3}>
              <Box display="inline-flex" alignItems="center" gap={1} minWidth={{xs: 80, xxl: 100, xxxl: 140}}>
                <DotIndicatorIcon style={{ width: "6px", height: "6px", color: "#24CA49", marginTop: "-1px" }} />
                <Typography variant="body2">Active</Typography>
                <Typography variant="body2" fontWeight="semibold">{totalCustomersCountData?.activeMembersCount}</Typography>
              </Box>

              <Box display="inline-flex" alignItems="center" gap={1} minWidth={{xs: 80, xxl: 100, xxxl: 140}}>
                <DotIndicatorIcon style={{ width: "6px", height: "6px", color: "#FE7E07", marginTop: "-1px" }} />
                <Typography variant="body2">Inactive</Typography>
                <Typography variant="body2" fontWeight="semibold">{totalCustomersCountData?.inactiveMembersCount}</Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}
