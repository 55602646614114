const { isEmptyObject } = require("utilities/common");

export function productValidations(product) {
    const errors = {};


    // Validate productName
    if (!product.productName) {
        errors.productName = 'Product name is required';
    }
    // if (!product.heading) {
    //     errors.heading = 'Heading is required';
    // }

    // Validate productImages
    // if (!Array.isArray(product.productImages) || product.productImages.length === 0) {
    //     errors.productImages = 'At least one product image is required';
    // }

    if (product?.ingredientStatus && !product?.ingredients) {
        errors.ingredients = 'Product Ingredients is required';
    }

    if (product?.instructionStatus && !product?.instructions) {
        errors.instructions = 'Product Instructions is required';
    }

    // if (typeof product.isSalePrice !== 'boolean') {
    //     errors.isSalePrice = 'isSalePrice must be a boolean';
    // }

    // Validate unitPrice if it's provided
    // if (typeof +product.unitPrice !== 'number' || +product.unitPrice <= 0) {
    //     errors.unitPrice = 'Unit price must be a positive number';
    // }
    // if (product.unitPrice && (typeof product.unitPrice !== 'number' || product.unitPrice <= 0)) {
    //     errors.unitPrice = 'Unit price must be a positive number';
    // }
    if (product?.salePrice === '0') {
        errors.salePrice = 'Sale price cannot be zero';
    }

    if ((product?.unitPrice && product?.salePrice) && +product?.salePrice > +product?.unitPrice) {
        errors.salePrice = 'Sale price cannot be greater than unit price';
    }

    // Validate salePrice if isSalePrice is true
    // if (typeof +product.salePrice !== 'number' || +product.salePrice <= 0) {
    //     errors.salePrice = 'Sale price must be a positive number';
    // }
    // if (product.isSalePrice && (typeof product.salePrice !== 'number' || product.salePrice <= 0)) {
    //     errors.salePrice = 'Sale price must be a positive number when isSalePrice is true';
    // }

    // Validate categoryName
    // if (!product.categoryName) {
    //     errors.categoryName = 'Category name is required';
    // }

    // // Validate brandName
    // if (!product.brandName) {
    //     errors.brandName = 'Brand name is required';
    // }

    // // Validate createdBy
    // if (!product.createdBy) {
    //     errors.createdBy = 'Created by is required';
    // }

    // if (!product.strainType) {
    //     errors.strainType = 'StrainType  is required';
    // }


    // if (!product.strainSlug) {
    //     errors.strainSlug = 'strainSlug  is required';
    // }
    // if (!product.categoryId) {
    //     errors.categoryId = 'category  is required';
    // }

    // if (!product.brandDescription) {
    //     errors.brandDescription = 'Brand Description  is required';
    // }

    // if (!product.sku) {
    //     errors.sku = 'sku  is required';
    // }

    // if (!product.productDescription) {
    //     errors.productDescription = 'Product Description   is required';
    // }


    // Validate tags as an array
    // if (Array.isArray(product.tags) && product?.tags?.length === 0) {
    //     errors.tags = 'Tags is required'
    // }
    // if (!Array.isArray(product.tags)) {
    //     errors.tags = 'Tags must be an array';
    // }

    // if (product.strainSlug && typeof product.strainSlug !== 'string') {
    //     errors.strainSlug = 'Strain slug must be a string';
    // }

    // Validate strainType if provided
    // if (product.strainType && typeof product.strainType !== 'string') {
    //     errors.strainType = 'Strain type must be a string';
    // }

    // Validate infoEffects as an array
    // if (Array.isArray(product.infoEffects) && product?.infoEffects?.length === 0) {
    //     errors.infoEffects = 'Info Effects is required'
    // }

    // if (!Array.isArray(product.infoEffects)) {
    //     errors.infoEffects = 'Info effects must be an array';
    // }

    // Validate categoryId if provided
    // if (product.categoryId && typeof product.categoryId !== 'string') {
    //     errors.categoryId = 'Category ID must be a string';
    // }

    // Validate sku if provided
    // if (product.sku && typeof product.sku !== 'string') {
    //     errors.sku = 'SKU must be a string';
    // }

    // Validate brandDescription if provided
    // if (product.brandDescription && typeof product.brandDescription !== 'string') {
    //     errors.brandDescription = 'Brand description must be a string';
    // }

    // Validate productDescription if provided
    // if (product.productDescription && typeof product.productDescription !== 'string') {
    //     errors.productDescription = 'Product description must be a string';
    // }

    // if (product.status && (typeof product.status !== 'string' || isEmptyString(product.status))) {
    //     errors.status = 'Batch status must be a non-empty string';
    // } else if (!product.status) {
    //     errors.status = 'status is required';
    // }

    // Validate purchaseDate if provided and not null
    // Validate batchStatus if provided
    // if (product.batchStatus && (typeof product.batchStatus !== 'string' || isEmptyString(product.batchStatus))) {
    //     errors.batchStatus = 'Batch status must be a non-empty string';
    // } else if (!product.batchStatus) {
    //     errors.batchStatus = 'Batch status is required';
    // }



    // Validate trackingSystem if provided
    // if (product.trackingSystem && (typeof product.trackingSystem !== 'string' || isEmptyString(product.trackingSystem))) {
    //     errors.trackingSystem = 'Tracking system must be a non-empty string';
    // } else if (!product.trackingSystem) {
    //     errors.trackingSystem = 'Tracking system is required';
    // }

    // // Validate purchaseQuantity if provided
    // if (product.purchaseQuantity && (typeof product.purchaseQuantity !== 'string' || isEmptyString(product.purchaseQuantity))) {
    //     errors.purchaseQuantity = 'Purchase quantity must be a non-empty string';
    // } else if (!product.purchaseQuantity) {
    //     errors.purchaseQuantity = 'Purchase quantity is required';
    // }

    // Validate currentQuantity if provided
    // if (product.currentQuantity && (typeof product.currentQuantity !== 'string' || isEmptyString(product.currentQuantity))) {
    //     errors.currentQuantity = 'Current quantity must be a non-empty string';
    // } else if (!product.currentQuantity) {
    //     errors.currentQuantity = 'Current quantity is required';
    // }

    // Validate unitCost if provided
    // if (product.unitCost && (typeof product.unitCost !== 'string' || isEmptyString(product.unitCost))) {
    //     errors.unitCost = 'Unit cost must be a non-empty string';
    // } else if (!product.unitCost) {
    //     errors.unitCost = 'Unit cost is required';
    // }

    // Validate thcUnit if provided
    // if (product.thcUnit && (typeof product.thcUnit !== 'string' || isEmptyString(product.thcUnit))) {
    //     errors.thcUnit = 'THC unit must be a non-empty string';
    // } else if (!product.thcUnit) {
    //     errors.thcUnit = 'THC unit is required';
    // }

    // // Validate thcValue if provided
    // if (product.thcValue && (typeof product.thcValue !== 'string' || isEmptyString(product.thcValue))) {
    //     errors.thcValue = 'THC value must be a non-empty string';
    // } else if (!product.thcValue) {
    //     errors.thcValue = 'THC value is required';
    // }

    // // Validate productWeightUnit if provided
    // if (product.productWeightUnit && (typeof product.productWeightUnit !== 'string' || isEmptyString(product.productWeightUnit))) {
    //     errors.productWeightUnit = 'Product weight unit must be a non-empty string';
    // } else if (!product.productWeightUnit) {
    //     errors.productWeightUnit = 'Product weight unit is required';
    // }

    // // Validate productWeightValue if provided
    // if (product.productWeightValue && (typeof product.productWeightValue !== 'string' || isEmptyString(product.productWeightValue))) {
    //     errors.productWeightValue = 'Product weight value must be a non-empty string';
    // } else if (!product.productWeightValue) {
    //     errors.productWeightValue = 'Product weight value is required';
    // }



    // Validate other properties accordingly...

    return { errors, isValid: isEmptyObject(errors) };
}


function isEmptyString(str) {
    return str.trim() === '';
}