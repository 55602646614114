import { Autocomplete, Box, Button, Card, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, OutlinedInput, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchBrands } from '../../../redux/slices/brand';
import MDButton from 'components/MDButton';
import { closeCustomDrawer } from '../../../redux/slices/modalSlice';
import { isNumber } from 'utilities/common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function hasAnyValue(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && (
            (typeof obj[key] === 'object' && hasAnyValue(obj[key])) ||
            (typeof obj[key] !== 'object' && obj[key] !== '')
        )
        ) {
            return true;
        }
    }
    return false;
}


export default function FilterOrders(props = {}) {
    const location = useLocation()
    const navigate = useNavigate()
    const { retailerId } = useParams()
    const [formData, setFormData] = useState({ orderPlatform: '', status: '' })
    const { applyOrderFilter, getQuryValues = {}, setIsLoading = () => { } } = props
    const dispatch = useDispatch()
    // let [filters, setFilters] = useState('')
    const [searchParams, setSearchParams] = useState();

    const applyFiltersHandler = () => {
        // setFilters('')
        let filters = ''
        console.log(filters, 'filter now')
        const { orderPlatform, status } = formData || {}
        let queryPayload = {
            orderPlatform: orderPlatform,
            status: status
        }
        if (queryPayload.orderPlatform === 'all') {
            queryPayload = { ...queryPayload, orderPlatform: '' }
        }
        if (queryPayload.status === 'all') {
            queryPayload = { ...queryPayload, status: '' }
        }

        const searchParams = new URLSearchParams();
        let filterValue = { ...queryPayload, filter: 'retailerOrders' } || {}
        Object.keys(filterValue).forEach((key => {
            searchParams.append(key, filterValue[key])
        }))
        navigate({ search: searchParams.toString() })
        applyOrderFilter({ queryPayload })

    }



    const clearAllHandler = () => {
        setFormData({ orderPlatform: '', status: '' })
        navigate(`/retailers/detail/${retailerId}?filter=retailerOrders`)
        applyOrderFilter({})
    }

    const showApplyButton = useMemo(() => {
        return hasAnyValue(formData)
    }, [formData])


    return (
        <Box className="cus-filter-sec" mb={1}>
            <Grid className='filter-box' container alignItems="center" spacing={1.5}>
                <Grid item xs={12} sm={12} md={12} xxl={12}>
                    <Box className="filter-price-unit">
                        <Stack direction='row' justifyContent='flex-end' width='100%' gap={2} >
                            <Grid item xs={12} sm={4} md={2} xxl={2}>
                                <Box className="filter-pro-status">
                                    <FormControl fullWidth>
                                        <InputLabel>Available</InputLabel>
                                        <Select size='small' value={formData?.orderPlatform} onChange={(e) => setFormData({ ...formData, orderPlatform: e.target.value })} fullWidth className='form-select' name='status' label='Product Status' >
                                            <MenuItem value='all'>All</MenuItem>
                                            <MenuItem value='ecommerce'>Ecommerce</MenuItem>
                                            <MenuItem value='store'>Store</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4} md={2} xxl={2}>
                                <Box className="filter-pro-status">
                                    <FormControl fullWidth>
                                        <InputLabel>Status</InputLabel>
                                        <Select size='small' value={formData?.status} onChange={(e) => setFormData({ ...formData, status: e.target.value })} fullWidth className='form-select' name='status' label='Product Status' >
                                            <MenuItem value='all'>All</MenuItem>
                                            <MenuItem value='awaiting_shipment'>In-progress</MenuItem>
                                            <MenuItem value='shipped'>Shipped</MenuItem>
                                            <MenuItem value='delivered'>Delivered</MenuItem>
                                            <MenuItem value='cancelled'>Cancelled</MenuItem>
                                            <MenuItem value='warranty'>Warranty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} xxl={1.5}>
                                <Box className="filter-btns">
                                    <Stack direction='row' alignItems="center" gap={1} className='w-100'>
                                        <Button className="filter-clear-all" onClick={clearAllHandler} size="small" variant="text" color="secondary" style={{ width: "100%" }}>
                                            Clear All
                                        </Button>
                                        <MDButton disabled={!showApplyButton} className="filter-apply" onClick={applyFiltersHandler} size="small" variant="contained" color="primary" style={{ width: "100%" }}>
                                            Apply
                                        </MDButton>
                                    </Stack>
                                </Box>
                            </Grid>

                        </Stack>


                    </Box>
                </Grid>
            </Grid>
        </Box>


    )


}