import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { bulkProductUpdate, openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog } from '../../redux/slices/modalSlice';

export default function BulkBrandUpdate({ closeModal }) {
    const { modalData, modal, isLoading = false } = useSelector(state => state.modal)
    const { isDeleting = false } = useSelector(state => state.common)
    const [brandName, setBrandName] = useState("")
    const [errors, setErrors] = useState({})
    const { brandsData = {} } = useSelector(state => state.brands)

    const { bulkProductIds, resetData = () => { } } = modalData
    const dispatch = useDispatch();


    const handleBulkUpdate = () => {
        if (!brandName) {
            setErrors({ brandName: 'Brand Name should not be empty' })
            return;
        }
        setErrors({})
        dispatch(bulkProductUpdate({ productIds: bulkProductIds, brandName: brandName })).unwrap().then((res => {
            dispatch(openSnackbar({ content: res?.message, color: 'success', icon: 'check' }))
            dispatch(closeCustomModalDialog({}))
            resetData()
        })).catch((err => {
            dispatch(openSnackbar({ content: err?.message, color: 'error', icon: 'warning' }))
            console.log("err", err)
        }))
    }
    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
                Update Product Brand
                <IconButton onClick={closeModal} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Box my={2}>
                    <FormControl fullWidth>
                        <InputLabel>Brand Name</InputLabel>
                        <Select
                            className="form-select"
                            error={Boolean(errors?.brandName)}
                            onChange={(e) => setBrandName(e.target.value)} label="Brand Name" name="brandName" value={brandName}>
                            {
                                brandsData?.brands?.filter((brand=>brand?.status==='active'))?.map((brandsData => {
                                    return <MenuItem key={brandsData.brandName} value={brandsData.brandName}>{brandsData?.brandName}</MenuItem>
                                }))
                            }
                        </Select>
                        {Boolean(errors?.brandName) && <FormHelperText error>{errors?.brandName}</FormHelperText>}

                    </FormControl>
                </Box>
            </DialogContent>

            <DialogActions>
                <MDButton onClick={closeModal} size="small" color="secondary">
                    Cancel
                </MDButton>
                <MDButton onClick={handleBulkUpdate} size="small" color='error'>
                    {
                        isLoading || isDeleting ? <CircularProgress size={16} color='light' /> : " Update"
                    }

                </MDButton>
            </DialogActions>
        </>
    )
}
