import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "./styles/index.scss"
import 'react-medium-image-zoom/dist/styles.css'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Material Dashboard 2 React Context Provider
// import { MaterialUIControllerProvider } from "context";
import { store } from "./redux/store";
import { Provider } from "react-redux";
const container = document.getElementById("app");
const root = createRoot(container);
// export const msalInstance = new PublicClientApplication({
//   clientId: "dfg",
//   authority: "https://login.microsoftonline.com/your-tenant-id",
//   redirectUri: "/",
//   postLogoutRedirectUri: "/",
// },);
// msalInstance.initialize().then(() => {
//   // Default to using the first account if no account is active on page load
//   if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//     // Account selection logic is app dependent. Adjust as needed for different use cases.
//     msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
//   }

//   // Optional - This will update account state if a user signs in from another tab or window
//   msalInstance.enableAccountStorageEvents();

//   msalInstance.addEventCallback((event) => {
//     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//       const account = event.payload.account;
//       msalInstance.setActiveAccount(account);
//     }
//   });
// });




// const msalConfiguration = {
//   auth: {
//     clientId: '1a5f0a47-1ffa-42f0-bbec-9786b90933e8',
//     authority: 'https://login.microsoftonline.com/60b03f2c-54e3-4f17-ad91-4164dda011b3',
//     redirectUri: 'http://localhost:3000',
//     extraQueryParameters: {
//       // Requesting roles claim
//       claims: { "id_token": { "roles": null } }
//     },
//     "accessTokenAcceptedVersion": 2,
//     "availableToOtherTenants": false,
//     "groupMembershipClaims": "SecurityGroup",
//     "identifierUris": [
//       "api://1a5f0a47-1ffa-42f0-bbec-9786b90933e8"
//     ],
//     "knownClientApplications": [],
//     "oauth2AllowUrlPathMatching": false,
//     "oauth2Permissions": [],
//     "oauth2RequirePostResponse": false,
//     "optionalClaims": {
//       "idToken": [
//         {
//           "name": "roles",
//           "source": null,
//           "essential": false,
//           "additionalProperties": []
//         }
//       ],
//       "accessToken": [
//         {
//           "name": "roles",
//           "source": null,
//           "essential": false,
//           "additionalProperties": []
//         }
//       ]
//     },
//     "orgRestrictions": [],
//   },

//   cache: {
//     cacheLocation: "sessionStorage",
//     storeAuthStateInCookie: true,
//   },
// };

root.render(
  <BrowserRouter>
      <Provider store={store}>
        {/* <MaterialUIControllerProvider> */}
        <App />
        {/* <App pca={msalInstance} /> */}
        {/* </MaterialUIControllerProvider> */}
      </Provider>
  </BrowserRouter>
);
