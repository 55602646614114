import { Typography, Box, Table } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import EastIcon from '@mui/icons-material/East';
import noDataFound from 'assets/images/nothing-found.svg';
import { AgGridTable } from 'components/tables/AgGridTable';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchTaskActivityLogs } from '../../../redux/slices/hyperdrive/drivers';
import { formatTimestampToDateView } from 'utilities/common';

const TaskLogs = ({ sendDataToParent = () => { }, taskId }) => {
    const dispatch = useDispatch()
    const mainGridApiRef = useRef('')
    const pageRef = useRef(0)
    const gridRefApi = useRef(null)
    const { taskActivityLogs = {}, loading: loading = false } = useSelector(state => state.drivers)
    console.log("taskActivityLogs", taskActivityLogs);

    const handleDriverDetails = () => {
        sendDataToParent(null);
    }
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Name',
            field: 'updatedBy',
            minWidth: 180,
            cellRenderer: useCallback((props) => (
                props?.value ? props?.value : "--"
            ), []),
        },
        {
            headerName: 'Date',
            field: 'createdDate',
            minWidth: 180,
            cellRenderer: useCallback((props) => (
                formatTimestampToDateView(props?.value)
            ), []),
        },
        {
            headerName: 'Edit tasks',
            field: 'createdDate',
            minWidth: 180,
            cellRenderer: useCallback((props) => (
                `${props?.data?.source}    ${props?.data?.previousChanges?.fleetName}  ->  ${props?.data?.newChanges?.fleetName} `
            ), []),
        },

    ]);

    const getRows = useCallback(async (params, filters) => {
        gridRefApi.current = params;
        try {
            const res = await dispatch(fetchTaskActivityLogs({
                data: {
                    skip: pageRef.current,
                    limit: 20,
                },
                taskId
            })).unwrap();
            const { taskdetails, total } = res
            const driverListArray = taskdetails?.map((item) => ({
                ...item,
            }));
            params.successCallback([...driverListArray], total);
            pageRef.current += 20;
        } catch (err) {
            params.successCallback([], 0);
        }
    }, [dispatch]);

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (loading) {
                mainGridApiRef.current.showLoadingOverlay();
            } else if (taskActivityLogs?.total === 0) {
                mainGridApiRef.current.showNoRowsOverlay();
            } else {
                mainGridApiRef.current.hideOverlay();
            }
        }
    }, [taskActivityLogs, loading]);

    return (
        <div className="next-drawer activity-log-drawer">
            <Box p={2} className='d-flex align-item-center jc-s-btwn gap-2 drawer-head'>
                <div className='d-flex flex-row gap-3'>
                    <Typography variant='h6' className='text-14 text-white-700'>Activity Log</Typography>
                </div>
                <Typography style={{ cursor: "pointer" }} onClick={() => handleDriverDetails()} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                    Close <EastIcon />
                </Typography>
            </Box>
            <Box className='drawer-scroll-sec'>
                {
                    taskActivityLogs?.taskdetails?.length !== 0 ?
                        <div className="ag-theme-quartz-dark">
                            <AgGridTable
                                col={columnDefs}
                                mainGridApiRef={mainGridApiRef}
                                getRows={getRows}
                                pageRef={pageRef}
                                isLoading={loading}
                                style={{ height: "64vh" }}
                                gridOptions={{ rowHeight: 64 }}
                                maxLimit={20}
                            />
                        </div>
                        : <Box textAlign="center" className="w-100 no-data-found mt-5">
                            <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Results Found" />
                            <p className='text-12'><strong>No Results Found</strong></p>
                        </Box>
                }
                {/* <Box p={2}>
                    <Table class='hw-table' border="0" cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                <th>
                                    <Typography class='w-100 d-inline-block text-black-400 text-12 text-left box-sizing-border'>Name</Typography>
                                </th>
                                <th>
                                    <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Date</Typography>
                                </th>
                                <th>
                                    <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Edit Tasks</Typography>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><Typography class='w-100 ellipsis-one text-white-700 text-12'>John Doe</Typography></td>
                                <td><Typography class='w-100 d-flex align-item-center gap-1 text-white-700 text-12'><AccessTimeFilledIcon style={{ width: "15px", height: "15px" }} /> 1d</Typography></td>
                                <td>
                                    <Typography class='w-100 ellipsis-one text-white-700 text-12 d-flex align-item-center gap-2'>
                                        Reassign Driver - John <EastIcon /> before 10:30
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Box> */}
            </Box>
        </div >
    )
}

export default TaskLogs
