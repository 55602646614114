
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {  fetchNotificationCountAPI, fetchNotificationCountDataAPI, fetchReadAllNotificationAPI, fetchReadSingleNotificationAPI } from "../../redux/apis/notifications"

const initialState = {
    addProductNotifications:[],
    notificationCount: 0,
    loading: false,
    notifications:{}
}

export const fetchAllNotificationsCountsData = createAsyncThunk(
    'approvals/fetchNotificationCounts',
    async (notificationType = '', {rejectWithValue})=>{
        try {
            const res = await fetchNotificationCountDataAPI(notificationType)
            console.log(res, 'res..')
            return res
        } catch (error) {
            console.log("error", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchNotificationCount = createAsyncThunk(
    'approvals/fetchNotificationCount',
    async ( notificationType = '', { rejectWithValue})=> {
        try {
            const res = await fetchNotificationCountAPI(notificationType)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchReadSingleNotification = createAsyncThunk(
    'approvals/fetchReadSingleNotification',
    async (filters, {rejectWithValue}) => {
        try {
            const res = await fetchReadSingleNotificationAPI(filters)
            return res
        } catch(error) {
            console.log( "error", error?.response)
            return rejectWithValue(error?.response.data)
        }
    }
)

export const fetchReadAllNotifications = createAsyncThunk(
    'approvals/fetchReadAllNotifications',
    async (notificationType = '', { rejectWithValue }) => {
      try {
        const res = await fetchReadAllNotificationAPI(notificationType)
        console.log(res, 'res..')
        return res
      } catch (error) {
        console.log("erro", error.response)
        return rejectWithValue(error?.response?.data)
      }
    }
  )

  const notificationSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        storeAddProductNotifications(state, action) {
            state.addProductNotifications=[...state.addProductNotifications, action.payload]
            localStorage.setItem('addProductNotifications',JSON.stringify([...state.addProductNotifications, action.payload]))
        },
        setNewAddProductNotifications(state, action) {
            state.addProductNotifications = action.payload
            localStorage.setItem('addProductNotifications', JSON.stringify(action.payload))
        }
    },
    extraReducers: (builder) => {
    builder
    .addCase(fetchAllNotificationsCountsData.pending, (state, action) => {
        state.loading = true;
        state.error = null;
    })
    .addCase(fetchAllNotificationsCountsData.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationCount = action.payload?.count
        state.addProductNotifications = action.payload?.data
    })
    .addCase(fetchAllNotificationsCountsData.rejected, (state,action) => {
        state.loading = false;
        state.error = action.payload;
    });

    builder
    .addCase(fetchReadAllNotifications.pending, (state,action) => {
        state.loading = false;
        state.notifications = action.payload;
    })
    .addCase(fetchReadAllNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload
        // You can update your state with the data received from the API if needed
      })
      .addCase(fetchReadAllNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // The error message returned from the API
      });

      builder
        .addCase(fetchNotificationCount.fulfilled, (state, action) => {
            console.log(action.payload)
            state.notificationCount = action.payload.count;
        })
        .addCase(fetchNotificationCount.rejected, (state, action) => {
            state.error = action.payload
        })

  },
});

export const {
    storeAddProductNotifications,
    setNewAddProductNotifications
} = notificationSlice.actions

export default notificationSlice.reducer;