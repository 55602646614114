import React, { useEffect, useMemo, useState } from 'react'
import Grid from "@mui/material/Grid";
import { Box, Card, CardContent, FormControl, IconButton, InputLabel, MenuItem, Pagination, Paper, Select, Stack, Typography } from "@mui/material";
import DotIndicatorIcon from "common/customIcons/dot_indicator";
import LineChart from 'common/charts/LinChart';
import { useDispatch, useSelector } from 'react-redux';
import { totalNewCustomersStats } from '../../redux/slices/dashboard';
import { FilterAltOutlined } from '@mui/icons-material';
import FilterMenu from 'common/FilterMenu';
const options = {
  maintainAspectRatio: false, // To allow custom height and width
  responsive: true,
  height: 300, // Specify the height in pixels
  width: 400,
  plugins: {
    legend: {
      // position: 'top',
      display: false
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    tooltip: {
      backgroundColor: "#fdfdfd",
      bodyColor: "#16161E",
      titleColor: "#16161E",
      borderWidth: 1,
      borderColor: "#F1F1F1",
      padding: 8
    },
  },
  scales: {
    y: {
      display: true,
      ticks: {
        beginAtZero: true,
        display: true,
        stepSize: 10,
        max: 100
      },
      grid: {
        display: false
      }
    },
    x: {
      display: true,
    },
  }
};
const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [10, 15, 25, 41, 34, 20, 65, 50, 10, 20],
      borderColor: '#00C2FF',
      backgroundColor: '#00C2FF',
      pointRadius: 3.5,
      lineTension: 0.5,
    },
    {
      label: 'Dataset 2',
      data: [10, 25, 35, 51, 54, 10, 35, 20, 30, 40],
      borderColor: '#A7FF62',
      backgroundColor: '#A7FF62',
      pointRadius: 3.5,
      lineTension: 0.5,

      // backgroundColor: (context) => {
      //     const ctx = context.chart.ctx;
      //     const gradient = ctx.createLinearGradient(0, 0, 0, 200);
      //     gradient.addColorStop(0, "#875AB5");
      //     gradient.addColorStop(1, "#EEE7F5");
      //     return gradient;
      //   },
    },
  ],
};

export default function NewCustomersStats() {
  const dispatch = useDispatch()
  const { totalNewCustomersStatsData = {} } = useSelector(state => state.dashboard)
  const [newCustomersFilters, setNewCustomersFilters] = useState({
    newCustomersOrderWeekMonthToday: 'month',
  })
  const [newCustomerFilterOpen, setNewCustomerFilterOpen] = useState(false)

  const totalCustomersChartData = useMemo(() => {
    const { results = [] } = totalNewCustomersStatsData || {}
    const formatedChartData = results?.map((orders => orders?.map((item => item?.totalMembers || 0))))
    let chartData = {
      labels: results[0]?.map((item => item?.label)),
      datasets: [
        {
          data: formatedChartData[0],
          borderColor: '#ED64F7',
          backgroundColor: '#ED64F7',
          pointRadius: 3.5,
          lineTension: 0.5,
        },
        {
          data: formatedChartData[1],
          borderColor: '#0BDB2C',
          backgroundColor: '#0BDB2C',
          pointRadius: 3.5,
          lineTension: 0.5,
        },
      ],
    };
    return chartData;
  }, [totalNewCustomersStatsData?.results])

  useEffect(() => {
    dispatch(totalNewCustomersStats({ dashboardQuery: newCustomersFilters?.newCustomersOrderWeekMonthToday }))
  }, [newCustomersFilters?.newCustomersOrderWeekMonthToday])
  return (
    <>
      <Grid item xs={12} md={6} lg={12} xxl={6}>
        <Card variant='dashboardCard' sx={{ height: '100%', overflow: 'hidden' }}>
          <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", p: 2.5 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={5} mb={4}>
              <Typography variant="h5" lineHeight={1.25}>New Customers</Typography>

              {/* filter ui  */}
              <IconButton variant='btn-filter' color='secondary' onClick={(e) => setNewCustomerFilterOpen(e.currentTarget)}>
                <FilterAltOutlined fontSize='medium' color='secondary' />
              </IconButton>
              <FilterMenu selectedMenu={newCustomersFilters?.newCustomersOrderWeekMonthToday} filter={newCustomerFilterOpen} handleFilter={setNewCustomerFilterOpen} applyFilterHandler={(value) => setNewCustomersFilters({ ...newCustomersFilters, newCustomersOrderWeekMonthToday: value })} />
              {/* filter ui end  */}

              {/* <FormControl fullWidth sx={{ maxWidth: "108px" }}>
                <Select
                  value={newCustomersFilters?.newCustomersOrderWeekMonthToday}
                  className="form-select form-dropdown"
                  name="newCustomersOrderWeekMonthToday"
                  onChange={(e) => setNewCustomersFilters({ ...newCustomersFilters, newCustomersOrderWeekMonthToday: e.target.value })}

                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="week">Weeks</MenuItem>
                  <MenuItem value="month">Months</MenuItem>
                </Select>
              </FormControl> */}
            </Stack>
            <Stack>
              <Box height={'300px'}>
                <LineChart chartData={totalCustomersChartData} options={options} />
              </Box>
            </Stack>
            <Stack direction="row" flexWrap="wrap" gap={2} mt={3}>
              <Box display="inline-flex" alignItems="center" gap={1} minWidth={{xs: 80, xxl: 100, xxxl: 140}}>
                <DotIndicatorIcon style={{ width: "6px", height: "6px", color: "#ED64F7", marginTop: "-1px" }} />
                <Typography variant="body2" fontWeight="semibold" sx={{ textTransform: 'capitalize' }}> {newCustomersFilters?.newCustomersOrderWeekMonthToday === 'today' ? 'Today' : <>This  {newCustomersFilters?.newCustomersOrderWeekMonthToday} </>} </Typography>
              </Box>

              <Box display="inline-flex" alignItems="center" gap={1} minWidth={{xs: 80, xxl: 100, xxxl: 140}}>
                <DotIndicatorIcon style={{ width: "6px", height: "6px", color: "#0BDB2C", marginTop: "-1px" }} />
                <Typography variant="body2" fontWeight="semibold" sx={{ textTransform: 'capitalize' }}> {newCustomersFilters?.newCustomersOrderWeekMonthToday === 'today' ? 'Yesterday' : <>Previous  {newCustomersFilters?.newCustomersOrderWeekMonthToday} </>} </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}
