import { isEmptyObject } from "utilities/common";

export function validateStrainData(strainData) {
    const {
        strainName,
        strainCategory,
        metaTitle,
        metaDescription,
        title,
        description,
        infoEffects,
        isPopularStrain,
        isBestStrain,
        flavors,
        title2,
        status,
        image,
    } = strainData;

    const errors = {};

    // Required fields
    if (!strainName || strainName.trim() === '') {
        errors.strainName = 'Strain Name is required.';
    }

    if (!metaDescription || metaDescription.trim()?.replace(/<\/?[^>]+(>|$)/g, "") === '') {
        errors.metaDescription = 'Meta description is required.';
    }

    // if (!title2 || title2.trim() === '') {
    //     errors.title2 = 'Title2 is required.';
    // }


    if (!title || title.trim()?.replace(/<\/?[^>]+(>|$)/g, "") === '') {
        errors.title = 'Title is required.';
    }

    if (!description || description.trim()?.replace(/<\/?[^>]+(>|$)/g, "") === '') {
        errors.description = 'Description is required.';
    }


    if (!strainCategory || strainCategory.trim() === '') {
        errors.strainCategory = 'Strain Category is required.';
    }

    if (!metaTitle || metaTitle.trim()?.replace(/<\/?[^>]+(>|$)/g, "") === '') {
        errors.metaTitle = 'Meta Title is required.';
    }

    // Additional validation for specific fields
    if (infoEffects && !Array.isArray(infoEffects)) {
        errors.infoEffects = 'Info Effects should be an array.';
    }

    if (flavors && !Array.isArray(flavors)) {
        errors.flavors = 'Flavors should be an array.';
    }

    if (typeof isPopularStrain !== 'boolean') {
        errors.isPopularStrain = 'isPopularStrain should be a boolean.';
    }

    if (typeof isBestStrain !== 'boolean') {
        errors.isBestStrain = 'isBestStrain should be a boolean.';
    }

    // if (title2 && typeof title2 !== 'string') {
    //     errors.title2 = 'Title2 should be a string.';
    // }

    // if (status !== true && status !== false) {
    //     errors.status = 'Status should be a boolean (true or false).';
    // }

    if (!image || typeof image !== 'string' || image.trim() === '') {
        errors.image = 'Stain image is required';
    }

    // Check if there are any errors
    const isValid = Object.keys(errors).length === 0;

    // Return an object with isValid and errors
    return {
        isValid,
        errors,
    };
}


export function validateMainStrain(strainData) {
    const {
        title,
        metaTitle,
        metaDescription,
        description,
        image
    } = strainData;

    const errors = {};

    if (!metaDescription || metaDescription.trim() === '') {
        errors.metaDescription = 'Meta description is required.';
    }
    if (!metaTitle || metaTitle.trim() === '') {
        errors.metaTitle = 'Meta title is required.';
    }
    if (!title || title.trim() === '') {
        errors.title = 'Title is required.';
    }

    if (!description || description.trim() === '') {
        errors.description = 'Description is required.';
    }



    if (!image || typeof image !== 'string' || image.trim() === '') {
        errors.image = 'Image URL is required and should be a non-empty string.';
    }

    // Check if there are any errors
    const isValid = Object.keys(errors).length === 0;

    // Return an object with isValid and errors
    return {
        isValid,
        errors,
    };
}


export function validateStrainDraftData(data = {}) {
    let errors = {};
    let isValid = true

    if (!data.strainCategory) {
        errors.strainCategory = "Strain Category is required!";
        isValid = false
    }
    if (!data.strainName) {
        errors.strainName = "Strain Name is required!";
        isValid = false
    }

    return {
        isValid,
        errors
    }
}



export function validateHyperwolfMainStrainData(data = {}) {
    let errors = {};

    if (!data.metaTitle) {
        errors.metaTitle = "Meta Title is required!";
    }

    if (!data.metaDescription) {
        errors.metaDescription = "Meta Description is required!";
    }
    if (!data.image) {
        errors.image = "Image is required!";
    }

    return {
        isValid: isEmptyObject(errors),
        errors
    }
}

export function validateHyperwolfStrainData(data = {}) {
    let errors = {};

    if (!data.strainName) {
        errors.strainName = "Strain Name is required!";
    }
    if (!data.strainCategory) {
        errors.strainCategory = "Strain Category is required!";
    }
    if (!data.metaTitle) {
        errors.metaTitle = "Meta Title is required!";
    }

    // if (!data.description) {
    //     errors.description = "Description is required!";
    // }

    if (!data.metaDescription) {
        errors.metaDescription = "Meta Description is required!";
    }
    if (!data.image) {
        errors.image = "Image is required!";
    }

    // if (!data.createdBy) {
    //     errors.createdBy = "Created By is required!";
    // }

    return {
        isValid: isEmptyObject(errors),
        errors
    }
}

