import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, IconButton, Slide, Stack, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Search from 'examples/Search'
import { useDebounce } from 'hooks/useDebounce'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import { openSnackbar } from '../../../redux/slices/common'
import { formatTimestampToDateView } from 'utilities/common'
import { deleteStrain, getAllHyperwolfStrains, setStrainInformation } from '../../../redux/slices/hyperwolf/strains'


const uploadThumbnailImage = {
    borderRadius: "20px",
    width: "100px",
    height: "100px",
    objectFit: "cover",
    boxShadow: "0 0 1px rgb(0 0 0 / 20%)",
    marginRight: '10px'
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
export default function ManageHyperwolfStrains() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const pageRef = useRef(0)
    const searchTermRef = useRef("")
    const mainGridApiRef = useRef('')
    const gridRefApi = useRef(null)
    const [searchTerm, setSearchTerm] = useState("")

    const handleSearch = useDebounce((term) => {
        console.log('Searching for:', term);
        pageRef.current = 0
        resetTable()
    }, 500);

    const { allStrains = {}, isLoading: loading = false } = useSelector(state => state.hyperwolfStrains)


    const deleteHandler = (strainId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteStrain(strainId)).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            pageRef.current = 0
            resetTable()
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Strain Name',
            field: 'strainName',
            key: "strainName",
            suppressMenu: false,
            minWidth: 240,
        },
        {
            headerName: 'Strain URL',
            field: 'canonical',
            suppressMenu: false,
            minWidth: 340,

        },
        {
            headerName: 'Meta Title',
            field: 'metaTitle',
            suppressMenu: false,
            minWidth: 130,
            cellRenderer: (props) => {
                return <span className='text-truncate'>{props?.value}</span>
            },
        },
        {
            headerName: 'Meta Description',
            field: 'metaDescription',
            suppressMenu: false,
            minWidth: 130,
            cellRenderer: (props) => {
                return <span className='text-truncate'>{props?.value}</span>
            },
        },
        {
            headerName: 'Created By',
            field: 'createdBy',
            suppressMenu: false,
            minWidth: 130,
            // cellRenderer: (props) => {
            //     return <span className='text-truncate'>{props?.value}</span>
            // },
        },
        {
            headerName: 'Created Date',
            field: 'createdDate',
            suppressMenu: false,
            minWidth: 80,
            cellRenderer: (props) => {
                return props?.value ? formatTimestampToDateView(props?.value) : "-"
            },
        },

        {
            headerName: 'Actions',
            cellRenderer: (props) => {
                return <Box display="inline-flex" alignItems="center">
                    <IconButton onClick={(e) => {
                        e.stopPropagation()
                        onEditClicked(props)
                    }} color='primary' size='small' variant="primary">
                        <EditIcon width='16px' height='16px' />
                    </IconButton>
                    <IconButton onClick={(e) => {
                        e.stopPropagation()
                        dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?._id, deleteType: 'strain' }, modal: 'DELETE_MODAL' }))
                    }} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
                        <DeleteIcon width='16px' height='16px' />
                    </IconButton>
                </Box>
            },
            cellStyle: { textAlign: 'right', justifyContent: 'end' },
            headerClass: 'right-aligned-cell-header',
            minWidth: 120,
            maxWidth: 100,
            pinned: 'right'
        },

    ]);

    function getSortInfo(data) {
        const result = {};
        data.forEach(item => {
            if (item?.colId === 'memberType') {
                result[item.colId] = item.sort === "asc" ? 'AdultUse' : "MedicinalUser";
            } else {
                result[item.colId] = item.sort === "asc";
            }

        });

        return result;
    }

    const getRows = async (params) => {
        gridRefApi.current = params;
        // let filter = {}
        // if (params?.sortModel.length) {
        //     filter = { ...getSortInfo(params?.sortModel) }
        // }
        dispatch(getAllHyperwolfStrains({ skip: pageRef.current,limit:10, searchTerm: searchTermRef.current })).unwrap().then(res => {
            const { strain = [], total } = res
            params.successCallback([...strain], total);
            pageRef.current = pageRef.current + 10
        })
    }

    const resetTable = () => {
        const dataSource = {
            getRows
        }
        mainGridApiRef.current.ensureIndexVisible(0, null);
        mainGridApiRef.current.setGridOption('datasource', dataSource);
    }

    const onRowClicked = (row) => {
        // if (row?.colDef?.headerName !== 'Actions' && row?.colDef?.headerName !== 'Notes') {
        //     navigate(`/members/detail/${row?.data.memberId}`)
        // }
    }


    const onEditClicked = (row) => {
        dispatch(setStrainInformation(row?.data))
        navigate('/hyperwolf/manage_strains/add')
    }

    const searchHandler = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        searchTermRef.current = value
        handleSearch(value);

        // Debounce the search callback
    };

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (loading) {
                mainGridApiRef.current.showLoadingOverlay()
            } else if (allStrains?.total == 0) {
                mainGridApiRef.current.showNoRowsOverlay()
            }
            else {
                mainGridApiRef.current.hideOverlay()
            }
        }

    }, [allStrains, loading])

    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={1} mb={3}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant='h4'>Manage Strains</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                        <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                            <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
                            <MDButton
                                startIcon={<AddRoundedIcon fontSize='large' />}
                                variant="contained" color="primary"
                                onClick={() => navigate('/hyperwolf/manage_strains/add')} >
                                Add Strain
                            </MDButton>
                        </Stack>
                    </Grid>
                </Grid>

                <AgGridTable col={columnDefs} maxLimit={10} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: "58vh" }} />
            </Box>

        </CommonLayout>
    )
}
