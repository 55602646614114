import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteMember, fetchMembers } from '../../redux/slices/member'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { sortAndFilter } from 'components/tables/AgGridTable'
import { useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { formatDate } from 'utilities/common'
import { DeleteOutline, Edit } from '@mui/icons-material'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice'
import { deleteRegion, fetchRegions } from '../../redux/slices/regions'
import { formatTimestampToDate } from 'utilities/common'
import { formatTimestampToTime } from 'utilities/common'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useDebounce } from 'hooks/useDebounce'
import Search from 'examples/Search'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import StatusText from 'common/component/StatusText'
import { openSnackbar } from '../../redux/slices/common'

export default function Regions() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { loading = false, data = {} } = useSelector(state => state.regions)
    const pageRef = useRef(0)
    const searchTermRef = useRef("")
    const mainGridApiRef = useRef('')
    const gridRefApi = useRef(null)
    const [searchTerm, setSearchTerm] = useState("")


    const handleSearch = useDebounce((term) => {
        // Perform search operation with the debounced term
        console.log('Searching for:', term);
        pageRef.current = 0
        searchFn()
        // mainGridApiRef.current.destroy()
        // getRows(gridRefApi.current)
    }, 500);

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Region Name',
            field: 'regionName',
            key: "fullName",
            suppressMenu: false,
            minWidth: 400,
            sortable: false
        },
        {
            headerName: 'Opening Hours',
            field: 'openingHours',
            suppressMenu: false,
            sortable: false

        },
        {
            headerName: 'Closing Hours',
            field: 'closingHours',
            suppressMenu: false,
            sortable: false

        },
        {
            headerName: 'Status',
            field: 'status',
            suppressMenu: false,
            sortable: false,

            cellRenderer: (props) => {
                return <StatusText status={props?.value} />
            },
        },
        {
            headerName: 'Action',
            cellRenderer: (props) => {
                return <Box display="inline-flex">
                    <IconButton onClick={() => onRowClicked(props)} color='primary' size='small' variant="primary">
                        <EditIcon width='16px' height='16px' />
                    </IconButton>
                    <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?.regionId }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
                        <DeleteIcon width='16px' height='16px' />
                    </IconButton>
                </Box>
            },
            cellStyle: { textAlign: 'right', justifyContent: 'end' },
            headerClass: 'right-aligned-cell-header',
            maxWidth: 100,
            pinned: 'right'
        },

    ]);

    const deleteHandler = (regionId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteRegion(regionId)).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            pageRef.current = 0
            const dataSource = {
                getRows
            }
            mainGridApiRef.current.ensureIndexVisible(0, null);
            mainGridApiRef.current.setGridOption('datasource', dataSource);
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const getRows = async (params) => {
        gridRefApi.current = params;
        dispatch(fetchRegions({ skip: pageRef.current, limit: 100, userType: 'admin', searchTerm: searchTermRef.current })).unwrap().then(res => {
            const { regions, total } = res
            const regionsArray = regions?.map((item => {
                const date = new Date(item?.createdDate)
                return { ...item, openingHours: item?.openingHours ? formatTimestampToTime(+item?.openingHours) : '-', closingHours: item?.closingHours ? formatTimestampToTime(+item?.closingHours) : "-", joinedDate: formatDate(date), id: item.memberId, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}` }
            }))
            // const dataAfterSortingAndFiltering = sortAndFilter(
            //     regionsArray,
            //     params.sortModel,
            //     params.filterModel
            // );
            // const rowsThisPage = dataAfterSortingAndFiltering.slice(
            //     params.startRow,
            //     params.endRow
            // );
            // let lastRow = -1;
            // if (total <= params.endRow) {
            //     lastRow = dataAfterSortingAndFiltering.length;
            // }
            params.successCallback([...regionsArray], total);
            pageRef.current = pageRef.current + 20
        })
    }

    const onRowClicked = (row) => {
        navigate(`/regions/${row?.data.regionId}`)
    }

    const searchHandler = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        searchTermRef.current = value
        handleSearch(value);

        // Debounce the search callback
    };

    const searchFn = () => {
        const dataSource = {
            getRows
        }
        mainGridApiRef.current.ensureIndexVisible(0, null);
        mainGridApiRef.current.setGridOption('datasource', dataSource);
    }

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (loading) {
                mainGridApiRef.current.showLoadingOverlay()
            } else if (data?.total == 0) {
                mainGridApiRef.current.showNoRowsOverlay()
            }
            else {
                mainGridApiRef.current.hideOverlay()
            }
        }

    }, [data, loading])

    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant='h4'>Regions</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                            <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
                            <MDButton
                                startIcon={<AddRoundedIcon fontSize='large' />}
                                variant="contained" color="primary"
                                onClick={() => navigate('/regions/add')} >
                                Add Region
                            </MDButton>
                        </Stack>
                    </Grid>
                </Grid>

                <AgGridTable col={columnDefs} mainGridApiRef={mainGridApiRef} getRows={getRows} pageRef={pageRef} isLoading={loading} style={{ height: "70vh" }} maxLimit={100} />
            </Box>
        </CommonLayout>
    )
}
