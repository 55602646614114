import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteMember, fetchMembers } from '../../redux/slices/member'
import { Avatar, Box, ButtonGroup, Card, CardActions, CardContent, Grid, IconButton, Stack, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { sortAndFilter } from 'components/tables/AgGridTable'
import { useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { formatDate } from 'utilities/common'
import { DeleteOutline, Edit } from '@mui/icons-material'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Search from 'examples/Search'
import { useDebounce } from 'hooks/useDebounce'
import { deleteMainTerpenoid, deleteTerpenoid, fetchTerpenoids } from '../../redux/slices/terpenoids'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import StatusText from 'common/component/StatusText'
import { openSnackbar } from '../../redux/slices/common'
import MDBox from 'components/MDBox'
import ActiveIcon from 'common/customIcons/active'
import NoDataFound from 'common/component/NoDataFound'


export default function Terpenoids() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")


  const handleSearch = useDebounce((term) => {
    // Perform search operation with the debounced term
    pageRef.current = 0
    searchFn()
    // mainGridApiRef.current.destroy()
    // getRows(gridRefApi.current)
  }, 500);

  const { isLoading = false, isFetching = false, terpenoidsList = [] } = useSelector(state => state.terpenoids)

  const deleteHandler = (terpeneId) => {
    dispatch(setModalLoader(true))
    dispatch(deleteMainTerpenoid(terpeneId)).unwrap().then((res) => {
      showError(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      pageRef.current = 0
      getTerpenoidsList()
      // getRows(gridRefApi.current)
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }


  const [columnDefs, setColumnDefs] = useState([
    {
      field: 'terpeneName', 
      suppressMenu: false,
    },
    {
      field: 'status',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <StatusText status={props?.data?.status} />
      },
    },
    // { field: 'address', suppressMenu: false },
    {
      field: 'terpeneImage', 
      key: "fullName", 
      suppressMenu: false,
      cellRenderer: (props) => {
        if (!props?.data?.terpeneImage) return "-"
        return <img height={100} style={{ padding: '10px 0px', objectFit: 'cover', borderRadius: '10px' }} width={100} src={props?.value} />
      },
      height: 200
    },
    {
      headerName: 'Actions',
      cellRenderer: (props) => {
        return <Box>
          <IconButton onClick={() => onEditClicked(props)} color='primary' size='small' variant="primary">
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?.terpeneId }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
        </Box>
      },
      width: 100, // Set a width for the action column
    },

  ]);

  const getTerpenoidsList = () => {
    dispatch(fetchTerpenoids({ limit: 100, skip: pageRef.current, searchTerm: searchTermRef.current })).unwrap()
  }

  const getRows = async (params) => {
    gridRefApi.current = params;
    dispatch(fetchTerpenoids({ limit: 100, skip: pageRef.current, searchTerm: searchTermRef.current })).unwrap().then(res => {
      const { terpenoids, total } = res
      const terpenoidsArray = terpenoids?.map((item => {
        const date = new Date(item?.createdDate)
        return { ...item, status: item?.status ? "Active" : "Inactive", joinedDate: formatDate(date), id: item.terpeneId, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}` }
      }))
      const dataAfterSortingAndFiltering = sortAndFilter(
        terpenoidsArray,
        params.sortModel,
        params.filterModel
      );
      const rowsThisPage = dataAfterSortingAndFiltering.slice(
        params.startRow,
        params.endRow
      );
      let lastRow = -1;
      if (total <= params.endRow) {
        lastRow = dataAfterSortingAndFiltering.length;
      }
      params.successCallback([...dataAfterSortingAndFiltering], lastRow);
      pageRef.current =pageRef.current + 20
    })
  }

  const searchFn = () => {
    // const dataSource = {
    //   getRows
    // }
    // mainGridApiRef.current.setGridOption('datasource', dataSource);
    getTerpenoidsList()
  }

  const onRowClicked = (row) => {
    navigate(`/terpenoids/detail/${row?.data.terpeneId}`)
  }

  const onEditClicked = (row) => {
    navigate(`/terpenoids/${row?.data.terpeneId}`)
  }

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value
    handleSearch(value);

    // Debounce the search callback
  };

  useEffect(() => {
    getTerpenoidsList()
  }, [])

  return (
    <CommonLayout>
      <Box p={{xs: 2, sm: 3, lg: 4}}>
        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4'>Terpenes</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stack direction="row" justifyContent={{xs: "start", sm: "end"}} flexWrap="wrap" alignItems="stretch" gap={2}>
              <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
              <MDButton
                startIcon={<AddRoundedIcon fontSize='large' />}
                variant="contained" color="primary"
                onClick={() => navigate('/terpenoids/add')} >
                Add Terpene
              </MDButton>
            </Stack>
          </Grid>
        </Grid>

        {
          terpenoidsList?.terpenoids?.length > 0 ? <Grid container spacing={2} mb={4}>
            {terpenoidsList?.terpenoids?.map((item, index) => (
              <Grid item xs={12} sm={6} xl={4} key={index}>
                <Card sx={{ boxShadow: 0, height: '100%', justifyContent: 'space-between' }}>
                  <CardContent sx={{ p: 2.5 }}>
                    <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                      <Box display="flex">
                        <Avatar
                          alt="Product Name"
                          src={item?.terpeneImage}
                          sx={{ width: 68, height: 68, borderRadius: 2 }}
                        />
                        <MDBox textAlign="left" ml={1.5} lineHeight={1}>
                          <Typography className='text-14' variant="h5" color="dark" fontWeight="semibold">{item?.terpeneName}</Typography>
                          <Typography className='text-12' variant="p" color="secondary">ID: {item?.terpeneId}</Typography>
                        </MDBox>
                      </Box>
                      <Box color="#24CA49" display="flex" alignItems="center">
                        {/* <ActiveIcon width="18px" height="18px" /> */}
                        <StatusText status={item?.status?.toLowerCase()} />
                        {/* <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">{item?.status}</Typography> */}
                      </Box>
                    </Stack>

                    <Box sx={{ bgcolor: "#F2F3F7", px: 1.75, py: 1.25, borderRadius: 2.5, mt: 2.5 }}>
                      <Typography variant="body2" fontWeight="medium" color="dark">{item?.subText || "-"}</Typography>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ p: 0 }}>
                    <ButtonGroup className='card-footer-action' fullWidth variant="outlined">
                      <MDButton
                        type='button'
                        variant='outlined'
                        color='dark'
                        size="small"
                        onClick={() => navigate(`/terpenoids/${item?.terpeneId}`)}
                        startIcon={<EditIcon width='15px' height='15px' color="#0163FF" />}
                      >
                        Edit
                      </MDButton>
                      <MDButton
                        type='button'
                        variant='outlined'
                        color='dark'
                        size="small"
                        onClick={() => {
                          dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: item?.terpeneId }, modal: 'DELETE_MODAL' }))
                        }}
                        startIcon={<DeleteIcon width='15px' height='15px' color='#FD4438' />}
                      >
                        Delete
                      </MDButton>
                    </ButtonGroup>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid> : <NoDataFound text="No Terpenoid Found" />
        }

        {/* <Box mb={2} display='flex' justifyContent='space-between' alignItems='center' >
            <h5>Members</h5>
            <MDButton onClick={() => navigate('/members/add')} variant="contained" color="primary" >Add Member</MDButton>
          </Box> */}

        {/* <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={isLoading || isFetching}  /> */}
      </Box>
    </CommonLayout>
  )
}
