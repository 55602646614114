import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { bulkProductUpdate, openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog } from '../../redux/slices/modalSlice';

export default function BulkDeleteModal({ closeModal }) {
    const { modalData, modal, isLoading = false } = useSelector(state => state.modal)
    const { isDeleting = false } = useSelector(state => state.common)

    const { bulkProductIds, resetData = () => { } } = modalData
    const dispatch = useDispatch();


    const bulkDeleteProduct = () => {
        dispatch(bulkProductUpdate({ productIds: bulkProductIds, delete: true })).unwrap().then((res => {
            dispatch(openSnackbar({ content: res?.message, color: 'success', icon: 'check' }))
            dispatch(closeCustomModalDialog({}))
            resetData()
        })).catch((err => {
            dispatch(openSnackbar({ content: err?.message, color: 'error', icon: 'warning' }))
            console.log("err", err)
        }))
    }
    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
                Delete Confirmation

                <IconButton onClick={closeModal} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this item?
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <MDButton onClick={closeModal} size="small" color="secondary">
                    Cancel
                </MDButton>
                <MDButton onClick={bulkDeleteProduct} size="small" color='error'>
                    {
                        isLoading || isDeleting ? <CircularProgress size={16} color='light' /> : " Delete"
                    }

                </MDButton>
            </DialogActions>
        </>
    )
}
