function isValidDate(dateString) {
    // Check if the date string is a valid date format
    return !isNaN(new Date(dateString).getTime());
}
function splitCamelCase(text) {
    // Split camelCase string into separate words
    return text.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function batchValidation(batchData) {
    const errors = {};
    let isValid = true;

    // Check for required keys
    const requiredKeys = ['purchaseDate'];

    for (const key of requiredKeys) {
        if (!batchData.hasOwnProperty(key) || batchData[key] === null || batchData[key] === undefined || batchData[key] === '') {
            errors[key] = `The ${splitCamelCase(key)} field is required.`;
            isValid = false;
        }
    }

    // Validate date formats for purchaseDate and expirationDate
    if (batchData.purchaseDate && !isValidDate(batchData.purchaseDate)) {
        errors.purchaseDate = 'Invalid date format for the purchase date.';
        isValid = false;
    }

    // if (batchData.expirationDate && !isValidDate(batchData.expirationDate)) {
    //     errors.expirationDate = 'Invalid date format for the expiration date.';
    //     isValid = false;
    // }

    // const numericKeys = ['purchaseQuantity', 'currentQuantity', 'unitCost'];

    // for (const key of numericKeys) {
    //   batchData[key] = parseFloat(batchData[key]);

    //   if (isNaN(batchData[key]) || batchData[key] === 0) {
    //     errors[key] = `The ${splitCamelCase(key)} must be a non-zero number.`;
    //     isValid = false;
    //   }
    // }


    // Check if purchaseQuantity is less than or equal to currentQuantity
    // if (batchData.purchaseQuantity && batchData.currentQuantity && batchData.purchaseQuantity > batchData.currentQuantity) {
    //     errors.purchaseQuantity = 'The purchase quantity cannot be greater than the current quantity.';
    //     isValid = false;
    // }
    // Check if purchaseQuantity is less than or equal to currentQuantity
    if (batchData.purchaseQuantity && batchData.currentQuantity && (+batchData.purchaseQuantity < +batchData.currentQuantity)) {
        errors.currentQuantity = 'The current quantity cannot be greater than the purchase quantity.';
        isValid = false;
    }



    // Additional validations can be added here based on your requirements

    return { errors, isValid };
}
