import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { bulkProductUpdate, openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog } from '../../redux/slices/modalSlice';

export default function BulkProductCategoryUpdate({ closeModal }) {
    const { modalData, modal, isLoading = false } = useSelector(state => state.modal)
    const { isDeleting = false, categoriesData = {} } = useSelector(state => state.common)
    const [productCategory, setProductCategory] = useState("")
    const [errors, setErrors] = useState({})
    const { bulkProductIds, resetData = () => { } } = modalData
    const dispatch = useDispatch();
    const { categories = [] } = categoriesData || {}

    const handleBulkUpdate = () => {
        if (!productCategory) {
            setErrors({ productCategory: 'Category should not be empty' })
            return;
        }
        setErrors({})
        dispatch(bulkProductUpdate({ productIds: bulkProductIds, categoryName: productCategory })).unwrap().then((res => {
            dispatch(openSnackbar({ content: res?.message, color: 'success', icon: 'check' }))
            dispatch(closeCustomModalDialog({}))
            resetData()
        })).catch((err => {
            dispatch(openSnackbar({ content: err?.message, color: 'error', icon: 'warning' }))
            console.log("err", err)
        }))
    }
    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
                Update Product Category
                <IconButton onClick={closeModal} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {/* <DialogContentText>
                    Are you sure you want to delete this item?
                </DialogContentText> */}
                <Box my={2}>
                    <FormControl fullWidth>
                        <InputLabel>Category</InputLabel>
                        <Select
                            className="form-select"
                            error={Boolean(errors?.productCategory)}
                            onChange={(e) => setProductCategory(e.target.value)} label="Category" name="productCategory" value={productCategory}>
                            {
                                categories?.filter(category=>category?.status==='active')?.map((category => {
                                    return <MenuItem key={category.categoryId} value={category?.categoryName}>{category?.categoryName}</MenuItem>
                                }))
                            }

                        </Select>
                        {Boolean(errors?.productCategory) && <FormHelperText error>{errors?.productCategory}</FormHelperText>}

                    </FormControl>
                </Box>
            </DialogContent>

            <DialogActions>
                <MDButton onClick={closeModal} size="small" color="secondary">
                    Cancel
                </MDButton>
                <MDButton onClick={handleBulkUpdate} size="small" color='error'>
                    {
                        isLoading || isDeleting ? <CircularProgress size={16} color='light' /> : " Update"
                    }

                </MDButton>
            </DialogActions>
        </>
    )
}
