import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchRetailersAPI, fetchRetailerDetailsAPI, addRetailerAPI, updateRetailerAPI, deleteRetailerAPI, updateRetailerBranchAPI } from "../apis/retailers"
import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";
import { openSnackbar } from "./common";


export const fetchRetailers = createAsyncThunk(
    'retailers/fetchRetailers',
    async (filters, { rejectWithValue }) => {
        try{
            const res = await axiosClient.get(`/api/v1/admin/retailer/all?${getQueryString(filters)}`);
            console.log(res, 'ress')
            return res
        } catch (error) {
            console.log("error", error)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchRetailerDetails = createAsyncThunk(
    'retailers/fetchRetailerDetails',
    async (retailerId, { rejectWithValue }) => {
        try{
            const res = await fetchRetailerDetailsAPI(retailerId)
            return res.retailer
        } catch (error) {
            console.log("error", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const addRetailer = createAsyncThunk(
    'retailers/addRetailer',
    async (data, { rejectWithValue }) => {
        try {
            const res = await addRetailerAPI(data)
            return res
        } catch (error) {
            console.log("error", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const updateRetailer = createAsyncThunk(
    'retailers/updateRetailer',
    async ({data, retailerId}, { rejectWithValue }) => {
        try {
            const res = await updateRetailerAPI(data, retailerId)
            return res
        } catch(error) {
            console.log("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const updateRetailerBranch = createAsyncThunk(
    'retailers/updateRetailerBranch',
    async({data, branchId}, { rejectWithValue }) => {
        try {
            const res = await updateRetailerBranchAPI(data, branchId)
            return res
        } catch(error) {
            console.error("erro", error?.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const deleteRetailer = createAsyncThunk(
    'retailers/deleteRetailer',
    async (retailerId, { rejectWithValue }) =>{
        try {
            const res = await deleteRetailerAPI(retailerId)
            return res
        } catch (error) {
            console.log("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

const retailersSlice = createSlice({
    name: 'retailers',
    initialState: {
        retailers: [],
        retailerDetails: {},
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        // Extra reducer for the fulfilled action of fetchRetailers
    builder.addCase(fetchRetailers.fulfilled, (state, action) => {
        state.loading = false;
        state.retailers = action.payload;
        state.retailerDetails = {}
        state.error = null;
    });

    // Extra reducer for the rejected action of fetchRetailers
    builder.addCase(fetchRetailers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })

    // Extra reducer for the pending action of fetchRetailers
    builder.addCase(fetchRetailers.pending, (state) => {
        state.loading = true;
    });

    // Extra reducer for the fulfilled action of fetchRetailersDetails
    builder.addCase(fetchRetailerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.retailerDetails = action.payload;
        state.error = null;
    });

    // Extra reducer for the rejected action of fetchRetailersDetails
    builder.addCase(fetchRetailerDetails.rejected, (state,action) => {
        state.loading = false;
        state.error = action.payload;
    });

    // Extra reducer for the pending action of fetchRetailersDetails
    builder.addCase(fetchRetailerDetails.pending, (state) => {
        state.loading = true;
    });

    // Extra reducer for the pending action of AddRetailer
    builder.addCase(addRetailer.pending, (state) => {
        state.loading = true;
        state.error = null;
    })

    // Extra reducer for the fulfilled action of AddRetailer
    builder.addCase(addRetailer.fulfilled, (state, action) => {
        console.log('gettt')
        state.loading = false;
        state.addedRetailer = action.payload;
        state.error = null;
    });

    // Extra reducer for the rejected action of AddRetailer
    builder.addCase(addRetailer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    // Extra reducer for UpdateRetailer
    builder
    .addCase(updateRetailer.pending, (state,action) => {
        state.loading = true;
        state.error = null;
    })
    .addCase(updateRetailer.fulfilled, (state,action) => {
        state.loading = false;
        state.error = action.payload;
    })
    .addCase(updateRetailer.rejected, (state,action) => {
        state.loading = false;
        state.error = action.payload;
    })

    builder
    .addCase(updateRetailerBranch.pending, (state, action) => {
        state.loading = true;
        state.error = null
    })
    .addCase(updateRetailerBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })
    .addCase(updateRetailerBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })

    // Extra reducer for DeleteRetailer
    // builder
    // .addCase(deleteRetailer.pending, (state, action) => {
    //     state.loading = true;
    //     state.error = null;
    // })
    // .addCase(deleteRetailer.fulfilled, (state, action) => {
    //     state.loading = false;
    // })
    // .addCase(deleteRetailer.rejected, (state,action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    // });

}
,

});

export const { } = retailersSlice.actions;

export default retailersSlice.reducer;