import React from 'react'

export default function RevenueIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.19667 13.2008C3.75 13.6958 4.3775 14.0883 5.05 14.3833L8.58667 12.1625C9.31833 11.7008 10.2983 11.81 10.9108 12.4258L12.1 13.615C13.8825 12.3008 15 10.1983 15 7.91667C15 4.01083 11.8225 0.833333 7.91667 0.833333C4.01083 0.833333 0.833333 4.01083 0.833333 7.91667C0.833333 9.9325 1.69417 11.8583 3.19667 13.2008ZM8.23333 8.54167H7.6C6.62583 8.54167 5.83333 7.74917 5.83333 6.775C5.83333 5.89917 6.465 5.17833 7.29167 5.03167V4.375C7.29167 4.03 7.57167 3.75 7.91667 3.75C8.26167 3.75 8.54167 4.03 8.54167 4.375V5H9.375C9.72 5 10 5.28 10 5.625C10 5.97 9.72 6.25 9.375 6.25H7.6C7.315 6.25 7.08333 6.48167 7.08333 6.76667C7.08333 7.06 7.315 7.29167 7.6 7.29167H8.23333C9.2075 7.29167 10 8.08417 10 9.05833C10 9.93417 9.36833 10.655 8.54167 10.8017V11.4575C8.54167 11.8025 8.26167 12.0825 7.91667 12.0825C7.57167 12.0825 7.29167 11.8025 7.29167 11.4575V10.8333H6.45833C6.11333 10.8333 5.83333 10.5533 5.83333 10.2083C5.83333 9.86333 6.11333 9.58333 6.45833 9.58333H8.23333C8.51833 9.58333 8.75 9.35167 8.75 9.06667C8.75 8.77333 8.51833 8.54167 8.23333 8.54167Z" fill="currentColor"/>
      <path d="M19.1667 10H15.8333C15.4967 10 15.1917 10.2033 15.0633 10.5142C14.9342 10.8258 15.0058 11.1842 15.2442 11.4225L16.3217 12.5L12.9167 15.905L10.1725 13.1608C9.9 12.8867 9.47167 12.8392 9.145 13.0417L0.395 18.4583C0.00333306 18.7008 -0.116667 19.2142 0.125 19.605C0.2825 19.86 0.555 20 0.834166 20C0.984166 20 1.135 19.96 1.27167 19.875L9.46 14.8058L12.3267 17.6725C12.6525 17.9983 13.1792 17.9983 13.505 17.6725L17.5 13.6783L18.5775 14.7558C18.7367 14.915 18.95 15 19.1667 15C19.2742 15 19.3825 14.9792 19.4858 14.9367C19.7975 14.8075 20 14.5033 20 14.1667V10.8333C20 10.3733 19.6275 10 19.1667 10Z" fill="currentColor"/>
    </svg>    
  )
}
