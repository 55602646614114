import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addRetailerProductAPI, addRetailerProductFromMasterCatalogAPI, deleteRetailerProductAPI, fetchRetailerProductDetailsAPI, fetchRetailerProductsAPI } from "../apis/retailerProducts"

const initialState = {
    products: [],
    loading: false,
    error: null,
    singleProduct: null,
}

export const fetchRetailerProducts = createAsyncThunk(
    'retailerProducts/fetchProducts',
    async ({  filters, payload} , { rejectWithValue }) => {
        try {
            const res = await fetchRetailerProductsAPI(  filters, payload );
            return res;
        } catch (error) {
            console.error("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const fetchRetailerSingleProduct = createAsyncThunk(
    'retailerProducts/fetchSingleProduct',
    async ({productId, retailerId}, { rejectWithValue }) => {
        try {
            const res = await fetchRetailerProductDetailsAPI({retailerId, productId});
            console.log(res, 'response of retailer products')
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const addRetailerProductsFromMasterCatalog = createAsyncThunk(
    'retailerProducts/addRetailerProducts',
    async ({ products, retailerId}, { rejectWithValue }) => {
        console.log(retailerId, 'retailerId')
        try {
            const res = await addRetailerProductFromMasterCatalogAPI(products, retailerId);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
)

export const deleteRetailerProduct = createAsyncThunk(
    'retailerProducts/deleteProduct',
    async ({productId,retailerId}, { rejectWithValue }) => {
        try {
            const res = await deleteRetailerProductAPI({productId,retailerId})
            return res;
        } catch (error) {
            console.error("error", error.response);
            return rejectWithValue(error.response?.data)
        }
    }
)


// Create a slice
const retailerProductSlice = createSlice({
    name: 'retailerProducts',
    initialState,
    reducers: {
        clearProducts: (state) => {
            state.products= [];
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchRetailerProducts.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.singleProduct = {}
        })
        .addCase(fetchRetailerProducts.fulfilled, (state, action) => {
            state.loading = false;
            state.products = action.payload;
        })
        .addCase(fetchRetailerProducts.rejected, (state,action) => {
            state.loading = false;
            state.error = action.payload
        });
        builder
        .addCase(fetchRetailerSingleProduct.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchRetailerSingleProduct.fulfilled, (state, action) => {
            state.loading = false;
            state.singleProduct = action.payload;
        })
        .addCase(fetchRetailerSingleProduct.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder
        .addCase(addRetailerProductsFromMasterCatalog.pending, (state) => {
            state.isUpdating = true;
            state.error = null;
        })
        .addCase(addRetailerProductsFromMasterCatalog.fulfilled, (state,action) => {
            state.isUpdating = false;
        })
        .addCase(addRetailerProductsFromMasterCatalog.rejected, (state,action) => {
            state.isUpdating = false;
        });

    }
})

export const { } = retailerProductSlice.actions;

export default retailerProductSlice.reducer;