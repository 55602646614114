import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addInventoryItemAPI, deleteInventoryItemAPI, fetchInventoryDetailsAPI, updateInventoryItemAPI } from '../apis/inventory';
import axiosClient from "../../axiosClient/index";
import { getQueryString } from "utilities/common";

export const fetchInventory = createAsyncThunk(
  'inventory/fetchInventory',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await axiosClient.get(`/api/v1/admin/inventory/get?${getQueryString(filters)}`);
      return res
    } catch (error) {
      console.log("error", error.response)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const fetchInventoryDetails = createAsyncThunk(
  'inventory/fetchInventoryDetails',
  async (inventoryId, { rejectWithValue }) => {
    try {
      const res = await fetchInventoryDetailsAPI(inventoryId)
      return res
    } catch (error) {
      console.log("error", error.response)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const addInventoryItem = createAsyncThunk(
  'inventory/addInventoryItem',
  async (data, { rejectWithValue }) => {
    try {
      const res = await addInventoryItemAPI(data)
      return res
    } catch (error) {
      console.log("error", error.response)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const updateInventoryItem = createAsyncThunk(
  'inventory/updateInventoryItem',
  async ({ data, inventoryId }, { rejectWithValue }) => {
    try {
      const res = await updateInventoryItemAPI(data, inventoryId)
      return res
    } catch (error) {
      console.log("error", error.response)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const deleteInventoryItem = createAsyncThunk(
  'inventory/deleteInventoryItem',
  async (inventoryId, { rejectWithValue }) => {
    try {
      const res = await deleteInventoryItemAPI(inventoryId)
      return res
    } catch (error) {
      console.log("error", error.response)
      return rejectWithValue(error?.response?.data)
    }
  }
)

const inventorySlice = createSlice({
  name: 'inventory',
  initialState: {
    inventory: [],
    inventoryDetails: null,
    loading: false,
    error: null,
  },
  reducers: {
    // Define additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInventory.fulfilled, (state, action) => {
      state.loading = false;
      state.inventory = action.payload; 
      state.inventoryDetails = null;
      state.error = null;
    });

    builder.addCase(fetchInventory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; 
    });

    builder.addCase(fetchInventory.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchInventoryDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.inventoryDetails = action.payload.inventoryDetails;
      state.error = null;
    });

    builder.addCase(fetchInventoryDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; 
    });

    builder.addCase(fetchInventoryDetails.pending, (state) => {
      state.loading = true;
    });

   builder.addCase(updateInventoryItem.pending, (state) => {
      // Handle pending state if needed
      state.loading = true
    })
    .addCase(updateInventoryItem.fulfilled, (state, action) => {
      state.loading = false
      // Handle fulfilled state, action.payload contains the resolved value
    })
    .addCase(updateInventoryItem.rejected, (state, action) => {
      state.loading = false
      // Handle rejected state, action.payload contains the error value
    });

    builder.addCase(addInventoryItem.pending, (state) => {
      // Handle pending state if needed
      state.loading = true
    })
    .addCase(addInventoryItem.fulfilled, (state, action) => {
      state.loading = false
      // Handle fulfilled state, action.payload contains the resolved value
    })
    .addCase(addInventoryItem.rejected, (state, action) => {
      state.loading = false
      // Handle rejected state, action.payload contains the error value
    });

    // Other reducers for update, delete, and add actions can be added here similarly
  },
});

export const { /* Any additional actions */ } = inventorySlice.actions;

export default inventorySlice.reducer;
