export const validateTerpenoid = (data) => {
    const errors = {};
  
    // Validation for terpeneName
    if (!data.terpeneName.trim()) {
      errors.terpeneName = 'Terpene name is required';
    } else if (data.terpeneName.length > 255) {
      errors.terpeneName = 'Terpene name should be at most 255 characters';
    }
  
  
    // Validation for status
    if (!data.status.trim()) {
      errors.status = 'Status is required';
    } else if (!['Active', 'Inactive','active','inactive'].includes(data.status)) {
      errors.status = 'Status should be either Active or Inactive';
    }
  
    // Validation for createdBy
    if (!data.createdBy.trim()) {
      errors.createdBy = 'Created by is required';
    } else if (data.createdBy.length > 255) {
      errors.createdBy = 'Created by should be at most 255 characters';
    }
  
    // Validation for terpeneImage (assuming it's a file object)
    if (!data.terpeneImage) {
      errors.terpeneImage = 'Terpene image is required';
    }
  
    // Check if any errors occurred
    const isValid = Object.keys(errors).length === 0;
  
    return { isValid, errors };
  };
  
  export default validateTerpenoid;
  

  function validateTerpene(terpeneObject, index) {
    const errors = {};
    let isValid = true;
  
    // Validate terpeneName
    if (!terpeneObject.terpeneName || terpeneObject.terpeneName?.trim() === "") {
      errors.terpeneName = `Terpene name is required.`;
      isValid = false;
    }
  
    // Validate terpeneValue
    if (!terpeneObject.terpeneValue || terpeneObject.terpeneValue?.trim() === "") {
      errors.terpeneValue = `Terpene value is required.`;
      isValid = false;
    }
  
    return { errors, isValid };
  }
  
  // export function validateTerpeneArray(terpeneArray) {
  //   const errors = [];
  //   let isValid = true;
  
  //   for (let i = 0; i < terpeneArray.length; i++) {
  //     const terpeneObject = terpeneArray[i];
  //     const { errors: objectErrors, isValid: objectIsValid } = validateTerpene(terpeneObject, i);
  
  //     if (!objectIsValid) {
  //       errors.push(objectErrors);
  //       isValid = false;
  //     }
  //   }z
  
  //   return { errors, isValid };
  // }


  export function validateTerpeneArray(terpeneArray) {
    let errors = {};
    let isValid = true;
  
    for (let i = 0; i < terpeneArray.length; i++) {
      const terpeneObject = terpeneArray[i];
      const { errors: objectErrors, isValid: objectIsValid } = validateTerpene(terpeneObject, i);
  
      if (!objectIsValid) {
        errors = {
          ...errors,
          [i]: objectErrors
        }
        isValid = false;
      }
    }
    return { errors, isValid };
  }
  
