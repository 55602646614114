import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import {  openSnackbar } from '../../redux/slices/common'
import StatusText from 'common/component/StatusText'
import { deleteMemberShip, fetchMemberships } from '../../redux/slices/memberships'


export default function MemberShips() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const pageRef = useRef(0)
    const searchTermRef = useRef("")
    const mainGridApiRef = useRef('')
    const gridRefApi = useRef(null)

    const { memberships = {},   loading = false } = useSelector(state => state.memberships)


    const deleteHandler = (mebershipId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteMemberShip(mebershipId)).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            pageRef.current = 0
            const dataSource = {
                getRows
            }
            mainGridApiRef.current.ensureIndexVisible(0, null);
            mainGridApiRef.current.setGridOption('datasource', dataSource);
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const thumbnail = {
        maxWidth: "120px",
        height: "80px",
        borderRadius: "6px",
        objectFit: "cover"
    }

    const [columnDefs, setColumnDefs] = useState([

        {
            headerName: 'Promotion Name',
            field: 'promoName',
            suppressMenu: false,
            minWidth: 180,
            cellStyle: { whiteSpace: "normal", lineHeight: "1.5" }
        },
        {
            headerName: 'Discount Type',
            field: 'discountType',
            suppressMenu: false,
            minWidth: 120,
        },
        {
            headerName: 'Discount Amount',
            field: 'discountAmount',
            suppressMenu: false,
            minWidth: 120,
            cellRenderer: (props) => {
                return props?.value ? <> {props?.data?.discountType === 'Cash' && '$'}{props?.value}{props?.data?.discountType === 'Percentage' && '%'}</> : '-'
            },
        },
        
        {
            headerName: 'Status',
            field: 'status',
            suppressMenu: false,
            cellRenderer: (props) => {
                return <StatusText status={props?.data?.status} />
            },
            minWidth: 120,
        },
        // {
        //     headerName: 'Promotion Type',
        //     field: 'promotionType',
        //     suppressMenu: false,
        //     minWidth: 120,
        //     cellRenderer: (props) => {
        //         return props?.value ? props?.value : 'Cart'
        //     },
        // },
        // {
        //     headerName: 'Media Type',
        //     field: 'media_type',
        //     suppressMenu: false,
        //     minWidth: 120,
        // },
        {
            headerName: 'Actions',
            cellRenderer: (props) => {
                return <Box display="inline-flex" alignItems="center">
                    <IconButton onClick={(e) => {
                        e.stopPropagation()
                        onEditClicked(props)
                    }} color='primary' size='small' variant="primary">
                        <EditIcon width='16px' height='16px' />
                    </IconButton>
                    <IconButton onClick={(e) => {
                        e.stopPropagation()
                        dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?._id, deleteType: 'membership' }, modal: 'DELETE_MODAL' }))
                    }} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
                        <DeleteIcon width='16px' height='16px' />
                    </IconButton>
                </Box>
            },
            cellStyle: { textAlign: 'right', justifyContent: 'end' },
            headerClass: 'right-aligned-cell-header',
            minWidth: 120,
            maxWidth: 100,
            pinned: 'right'
        },

    ]);

    function getSortInfo(data) {
        const result = {};
        data.forEach(item => {
            if (item?.colId === 'memberType') {
                result[item.colId] = item.sort === "asc" ? 'AdultUse' : "MedicinalUser";
            } else {
                result[item.colId] = item.sort === "asc";
            }

        });

        return result;
    }

    const getRows = async (params) => {
        gridRefApi.current = params;
        let filter = {}
        if (params?.sortModel.length) {
            filter = { ...getSortInfo(params?.sortModel) }
        }
        // params.successCallback(banners, 15);
        dispatch(fetchMemberships({ skip: pageRef.current, searchTerm: searchTermRef.current, ...filter })).unwrap().then(res => {
            console.log("res", res)
            const { promotions = [], total } = res
            const promotionsArray = promotions?.map((item => {
                return { ...item, status: item?.status ? "Active" : "Inactive" }
            }))
            params.successCallback(promotionsArray, total);
            pageRef.current = pageRef.current + 20
        }).catch(() => {
            params.successCallback([], 0);
        })
    }

    const searchFn = () => {
        const dataSource = {
            getRows
        }
        mainGridApiRef.current.ensureIndexVisible(0, null);
        mainGridApiRef.current.setGridOption('datasource', dataSource);
    }



    const onEditClicked = (row) => {
        navigate(`/memberships/${row?.data?._id}/update`)
    }


    useEffect(() => {
        if (mainGridApiRef?.current) {
            console.log("loading", loading)
            if (loading) {
                mainGridApiRef.current.showLoadingOverlay()
            } else if (memberships?.total == 0) {
                mainGridApiRef.current.showNoRowsOverlay()
            }
            else {
                mainGridApiRef.current.hideOverlay()
            }
        }

    }, [memberships, loading])
    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant='h4'>Membership</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                        <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                            <MDButton
                                startIcon={<AddRoundedIcon fontSize='large' />}
                                variant="contained" color="primary"
                                onClick={() => navigate('/memberships/add')} >
                                Add Membership
                            </MDButton>
                        </Stack>
                    </Grid>
                </Grid>

                <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: "70vh" }}
                // gridOptions={{
                //     rowHeight: 100,
                // }} 
                />
            </Box>
        </CommonLayout>
    )
}
