import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteRetailer, fetchRetailers } from '../../redux/slices/retailerSlice'
import { Box, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { formatDate } from 'utilities/common'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Search from 'examples/Search'
import { useDebounce } from 'hooks/useDebounce'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import StatusText from 'common/component/StatusText'
import { openSnackbar } from '../../redux/slices/common'
import { formatUsPhoneNumber } from 'utilities/common'
import MDBox from 'components/MDBox'
import { formatTimestampToWithoutTime } from 'utilities/common'
import FilterRetailers from 'common/CommonDrawer/retailers/FilterRetailer'
import { formatAddress } from 'utilities/common'

export default function Retailers() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const pageRef = useRef(0)
    const searchTermRef = useRef("")
    const mainGridApiRef = useRef("")
    const gridRefApi = useRef(null)
    const [searchTerm, setSearchTerm] = useState("")
    const retailersFiltersPayload = useRef({})


    const handleSearch = useDebounce((term) => {
    pageRef.current = 0
    searchFn()
    }, 500);

    const { retailers = {}, loading = false } = useSelector(state => state.retailers)

    const { paidRetailersCount = 0, unpaidRetailersCount = 0, totalRetailers = 0 } = retailers || {}


    const deleteHandler = (retailerId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteRetailer(retailerId)).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            pageRef.current = 0
            searchFn()
            getRows(gridRefApi.current)
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    const showError = (message, errorType) => {
        if (errorType === 'success') {
          dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errorType === 'error') {
          dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
      }


      const applyRetailerFilter = (filterPayload) => {
        pageRef.current = 0
         retailersFiltersPayload.current = filterPayload
         console.log(retailersFiltersPayload.current, 'ret')
        const dataSource = {
          getRows: (params) => {
          const page = params.endRow / 20;
          dispatch(fetchRetailers({ skip: pageRef.current, searchTerm: searchTermRef.current,limit:20,  ...filterPayload?.queryPayload  })).unwrap()
          .then(res => {
            const { retailers, total } = res
            console.log(retailers, 'retailetsgfs')
            const retailersArray = retailers?.map((item => {
              let valiDateFrom = item?.valiDateFrom ? new Date(item?.valiDateFrom) : null;
              let valiDateTo = item?.valiDateTo ? new Date(item?.valiDateTo) : null;
                const date = item?.valiDateFrom ? new Date(item?.valiDateFrom) :  "" ||  item?.valiDateTo? new Date(item?.valiDateTo) :  ""
                console.log(item, 'item..')
                let validity = "";
                if (valiDateFrom) {
                    validity = `${formatTimestampToWithoutTime(valiDateFrom)} to ${valiDateTo ? formatTimestampToWithoutTime(valiDateTo) : "-"}`;
                } else {
                    validity = "Lifetime";
                }

            

                return {
                    ...item,  status: item?.status ==="pending" ? "Pending" : item?.status === "active" ?  "Active" : "Inactive",
                    createdDate: formatTimestampToWithoutTime(item?.createdDate),
                    valiDateTo: valiDateTo ? formatDate(valiDateTo) : "-",
                      validity: validity ==='Lifetime' ? "Lifetime": formatTimestampToWithoutTime(valiDateTo),
                      address: item.address ?  formatAddress(item?.address) : '-',
                    }
            }))
            params.successCallback([...retailersArray], total);
            pageRef.current = pageRef.current + 20
        })
        }
        }
        if (mainGridApiRef?.current) {
          mainGridApiRef.current.ensureIndexVisible(0, null);
          mainGridApiRef.current.setGridOption('datasource', dataSource);
        }
      }


    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Retailer Id',
            field: 'retailerId',
            key: 'retailerId',
            suppressMenu: false,
            cellRenderer: (props) => {
                return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: "pointer", whiteSpace: "normal", textTransform: 'capitalize'}}>{props?.data?.retailerId}</Typography>
            },
            minWidth: 160,
        },
        {
            headerName: 'Retailer Name',
            field: 'retailerName',
            suppressMenu: false,
            minWidth: 160
        },
        {
            headerName: 'Contact Name',
            field: 'contactName',
            suppressMenu: true,
            minWidth: 160,
    },
        {
            headerName: 'E-mail',
            field: 'email',
            suppressMenu: false,
            minWidth: 300,
            wrapText: true,
            autoHeight: true,
            cellRenderer: (props) => {
              return (
              <MDBox textAlign="left" lineHeight={1.75}  mt={.125}>
              <Typography variant="h6" fontSize={14} color="dark" lineHeight={1.25} fontWeight="semibold" className="truncate line-1">{(props?.data?.email)}</Typography>
              <Typography variant="p" fontSize={12} color="secondary" lineHeight={1.25}>{props?.data?.phone ? formatUsPhoneNumber(props?.data?.phone) : '-'}</Typography>
            </MDBox>
              )
              }
        },
        {
            headerName: 'Status',
            field: 'status',
            suppressMenu: false,
            cellRenderer: (props) => {
              if (props?.data?.status !=="pending") {
              return <StatusText status={props?.data?.status} />
              }
            },
            minWidth: 100
          },
        {
            headerName: 'Address',
            field: 'address',
            suppressMenu: false,
            minWidth: 300,
            cellRenderer: (props) => {
              return <Tooltip title={props?.value} arrow placement="top">
                  <Typography className="truncate line-2" variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: "normal" }}>{props?.value}</Typography>
              </Tooltip>
          },
        },
        {
            headerName: 'Subscription',
            field: 'subscription',
            suppressMenu: false,
            minWidth: 120,
            cellRenderer: (props) => {
              return  <div className={`${props.value === "Paid" ?   'paid-badge':  props.value ==="Suspended" ? 'suspended-badge' : 'unpaid-badge'}`}>{ props.value}</div>
            },
        },
        {
          headerName: 'Onboarding Date',
          field: 'createdDate',
          suppressMenu: false,
          minWidth: 160
      },
        {
            headerName: 'Valid Upto',
            field: 'validity',
            suppressMenu: false,
            minWidth: 160
        },
        {
            headerName: 'Action',
            suppressMenu: true,
            minWidth: 80,
            cellRenderer: (props) => {
            return <Box className="action-edit w-100 jc-end" display="flex">
             <IconButton onClick={(e) => {
            e.stopPropagation()
            onEditClicked(props)
          }} color='primary' size='small' variant="primary">
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={(e) => {
            e.stopPropagation()
            dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?.retailerId, deleteType: 'retailer' }, modal: 'DELETE_MODAL' }))
          }} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
                </Box>
            }
        }

    ])

    const calculateHundredYearsFromTimeStamp= (timestamp) => {
      const date = new Date(timestamp);
      date.setFullYear(date.getFullYear() + 100)
      return date;
    };


    const getRows = async (params) => {
        gridRefApi.current = params;
    console.log(gridRefApi.current, 'curent')
        let filter = {}
        dispatch(fetchRetailers({ skip: pageRef.current, searchTerm: searchTermRef.current,limit:20, ...filter })).unwrap().then(res => {
            const { retailers, total } = res
      const today = new Date();
            const retailersArray = retailers?.map((item => {
              let valiDateFrom = item?.valiDateFrom ? new Date(item?.valiDateFrom) : null;
              let valiDateTo = item?.valiDateTo ? new Date(item?.valiDateTo) : null;
              let isSuspended = valiDateTo && valiDateTo < today;
           
                const date = item?.valiDateFrom ? new Date(item?.valiDateFrom) :  "" ||  item?.valiDateTo? new Date(item?.valiDateTo) :  ""
                let validity = "";
                if (valiDateFrom) {
                    validity = `${formatTimestampToWithoutTime(valiDateFrom)} to ${valiDateTo ? formatTimestampToWithoutTime(valiDateTo) : "-"}`;
                } else {
                    validity = "Lifetime";
                }


                return {
                    ...item,  status: item?.status ==="pending" ? "Pending" : item?.status === "active" ?  "Active" : "Inactive",
                    createdDate: formatTimestampToWithoutTime(item?.createdDate),
                    valiDateFrom: valiDateFrom ? formatDate(valiDateFrom) : "-",
                    valiDateTo: valiDateTo ? formatDate(valiDateTo) : "-",
                      validity: validity ==='Lifetime' ? "Lifetime": formatTimestampToWithoutTime(valiDateTo),
                      address: item.address ?  formatAddress(item?.address) : '-',
                      subscription: isSuspended ? "Suspended" : (item.subscription)
                  }
            }))
            params.successCallback([...retailersArray], total);
            pageRef.current = pageRef.current + 20
        }).catch((error) => {
          console.error("Error fetching rows:", error);
          params.successCallback([],0)
        })
    }


    const searchFn = () => {
        const dataSource = {
          getRows
        }
        mainGridApiRef.current.ensureIndexVisible(0, null);
        mainGridApiRef.current.setGridOption('datasource', dataSource);
      }

    const onRowClicked = (row) => {
        if (row?.colDef?.headerName !== 'Action') {
          navigate(`/retailers/detail/${row?.data.retailerId}?filter=retailerDetails`)
        }
      }

    const onEditClicked = (row) => {
        navigate(`/retailers/${row?.data.retailerId}`)
    }

    const searchHandler = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        searchTermRef.current = value
        handleSearch(value);
      };

      useEffect(() => {
    console.log(loading, 'loading')
        if (mainGridApiRef?.current) {
          if (loading) {
            mainGridApiRef.current.showLoadingOverlay()
          } else if (retailers?.total == 0) {
            mainGridApiRef.current.showNoRowsOverlay()
          }
          else {
            mainGridApiRef.current.hideOverlay()
          }
        }

      }, [retailers, loading])


    return (<>
        <CommonLayout>
          <Box p={{ xs: 2, sm: 3, lg: 4 }} pb={{ lg: 2 }}>
            <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
            <Grid item xs={12} sm={4}>
            <Typography variant='h4'>Retailers</Typography>
            </Grid>

            <Grid item xs={12} sm={8}>
                <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
                <MDButton
                startIcon={<AddRoundedIcon fontSize='large' />}
                variant="contained" color="primary"
                onClick={() => navigate('/retailers/add')} >
                Add Retailer
                </MDButton>
                </Stack>
            </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
          </Grid>
            <FilterRetailers applyRetailerFilter={applyRetailerFilter}/>
          <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} onRowClicked={onRowClicked} style={{ height: "58vh" }} />

            </Box>
        </CommonLayout>
        </> )

}