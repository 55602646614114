import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMainFAQAPI, updateMainFAQAPI } from '../../redux/apis/faq';

// Define the async thunk
export const getMainFAQ = createAsyncThunk(
    'mainFAQ/getMainFAQ',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await getMainFAQAPI();
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const updateMainFAQ = createAsyncThunk(
    'mainFAQ/updateMainFAQ',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateMainFAQAPI(data);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);



// Create a slice
const mainFAQSlice = createSlice({
    name: 'mainFAQ',
    initialState: {
        faqs: [],
        loading: false, // Use loading flag instead of status
        error: null
    },
    reducers: {
        // Additional reducers can go here
        // For example:
        clearFAQs: state => {
            state.faqs = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getMainFAQ.pending, (state) => {
                state.loading = true; // Set loading to true when the request starts
            })
            .addCase(getMainFAQ.fulfilled, (state, action) => {
                state.loading = false; // Set loading to false when the request is successful
                state.faqs = action.payload.faq;
            })
            .addCase(getMainFAQ.rejected, (state, action) => {
                state.loading = false; // Set loading to false when the request fails
                state.error = action.payload;
            });
        builder
            .addCase(updateMainFAQ.pending, (state) => {
                state.loading = true; // Set loading to true when the request starts
            })
            .addCase(updateMainFAQ.fulfilled, (state, action) => {
                state.loading = false; // Set loading to false when the request is successful
            })
            .addCase(updateMainFAQ.rejected, (state, action) => {
                state.loading = false; // Set loading to false when the request fails
                state.error = action.payload;
            });
    }
});

// Export action creators and reducer
export const { clearFAQs } = mainFAQSlice.actions;
export default mainFAQSlice.reducer;
