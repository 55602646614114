import { Badge, Box, Button, Card, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { validateStrainData } from 'validations/strain'
import defaultImage from 'assets/images/placeholder-image.png';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import { validateMainBrand } from 'validations/brand';
import { openSnackbar, uploadImage } from '../../redux/slices/common';
import { isEmptyObject } from 'utilities/common';
import { getMainStrain, updateMainStrain } from '../../redux/slices/strain';
import CommonQuillEditor from '../../common/editor';
import { useNavigate } from 'react-router-dom';
import colors from "assets/theme/base/colors";
import { getMainBrand, updateMainBrand } from '../../redux/slices/brand';

export default function MainBrandForm() {
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const { isLoading, mainBrandData, loading = false } = useSelector(state => state.brands)
    const { isUploading } = useSelector(state => state.common)
    const { grey } = colors;

    const [mainBrand, setMainBrand] = useState({
        image: '',
        metaTitle: '',
        metaDescription: '',
        title: '',
        description: '',
    });
    const [flavors, setFlavors] = useState([])
    const [selectedFlavors, setSelectedFlavors] = useState([])
    const navigate = useNavigate()

    const onChangehandler = (e) => {
        const { name, value } = e.target;
        setMainBrand({ ...mainBrand, [name]: value });
    };

    const onEditorChangehandler = (value, name) => {
        setMainBrand({ ...mainBrand, [name]: value });
    };

    const brandImageUploader = async (e) => {
        if (e.target.files.length) {
            const file = e.target.files[0];
            const body = new FormData()
            body.append("filePath", file);
            // res.fileUrl
            dispatch(uploadImage(body)).unwrap().then((res => {
                setMainBrand({ ...mainBrand, image: res.fileUrl })
            }))
        }

    }

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const addMainStrain = () => {
        const { errors: validationErrors, isValid: isDataValid } = validateMainBrand(mainBrand);
        if (!isDataValid) {
            setErrors(validationErrors);
            return;
        }
        setErrors({})
        dispatch(updateMainBrand(mainBrand)).unwrap().then((res) => {
            showError(res?.message, 'success')
            navigate('/brands')
        })

    };
    useEffect(() => {
        if (!isEmptyObject(mainBrandData)) {
            setMainBrand({ ...mainBrand, image: mainBrandData?.image, title: mainBrandData?.title, description: mainBrandData?.description, metaDescription: mainBrandData?.metaDescription, metaTitle: mainBrandData?.metaTitle })
        }
    }, [mainBrandData])

    useEffect(() => {
        dispatch(getMainBrand())
    }, [])

    const uploadThumbnailImage = {
        borderRadius: "20px",
        width: "160px",
        height: "160px",
        objectFit: "cover",
        boxShadow: "0 0 1px rgb(0 0 0 / 20%)"
    };
    const badgeCircularProgressIcon = {
        backgroundColor: "#fff",
        borderRadius: 10,
        p: .5,
        mr: 14,
        mt: 14
    };

    return (
        <Box p={{ xs: 2, sm: 3, lg: 4 }}>
            <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
                <Typography variant='h4' fontWeight="semibold" color="secondary" mb={4.5}>Edit Main Brand</Typography>

                <Typography variant='h5' fontWeight="semibold" mb={2}>Meta Image</Typography>
                <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1.25} mb={5}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                        badgeContent={
                            isUploading ? <CircularProgress size={32} color='primary' sx={badgeCircularProgressIcon} /> : ""
                        }
                    >
                        <img style={uploadThumbnailImage} src={mainBrand?.image || defaultImage} alt="" />
                    </Badge>
                    <label htmlFor="image-input">
                        <MDButton
                            sx={{ minWidth: "180px" }}
                            type='button'
                            variant='outlined'
                            color='secondary'
                            component='span'
                            disabled={isUploading}
                        >
                            Change Image
                        </MDButton>
                    </label>
                    <MDInput
                        id="image-input"
                        type="file"
                        name="image"
                        onChange={brandImageUploader}
                        fullWidth
                        disabled={isUploading}
                        style={{ display: 'none' }}
                    />
                    {Boolean(errors?.image) && <FormHelperText sx={{ width: "100%", ml: 0, mt: -.5 }} error>{errors?.image}</FormHelperText>}
                </Stack>

                {/* <Typography variant='h5' fontWeight="semibold" mb={2}>Strain Info</Typography> */}
                <Grid container spacing={3.5}>
                    <Grid item xs={12}>
                        {/* <TextField
                            className="form-input"
                            fullWidth
                            name="title"
                            value={mainBrand.title || ""}
                            onChange={onChangehandler}
                            label="Title"
                            error={Boolean(errors.title)}
                            helperText={errors?.title}
                        /> */}
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Title</Typography>
                        <CommonQuillEditor
                            value={mainBrand?.title || ""}
                            onChange={(value) => setMainBrand({ ...mainBrand, title: value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <TextField
                            className="form-input"
                            fullWidth
                            name="metaTitle"
                            value={mainBrand.metaTitle || ""}
                            onChange={onChangehandler}
                            label="Meta Title"
                            error={Boolean(errors.metaTitle)}
                            helperText={errors?.metaTitle}
                        /> */}
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Meta Title</Typography>
                        <CommonQuillEditor
                            value={mainBrand?.metaTitle || ""}
                            onChange={(value) => setMainBrand({ ...mainBrand, metaTitle: value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <TextField
                            className="form-input"
                            fullWidth
                            multiline
                            rows={4}
                            name="metaDescription"
                            value={mainBrand.metaDescription || ""}
                            onChange={onChangehandler}
                            label="Meta Description"
                            error={Boolean(errors.metaDescription)}
                            helperText={errors?.metaDescription}
                        /> */}
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Meta Description</Typography>
                        <CommonQuillEditor
                            value={mainBrand?.metaDescription || ""}
                            onChange={(value) => setMainBrand({ ...mainBrand, metaDescription: value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <FormControl fullWidth>
                            <TextField
                                className="form-input"
                                fullWidth
                                multiline
                                rows={4}
                                name="description"
                                value={mainBrand.description || ""}
                                onChange={onChangehandler}
                                label="Description"
                                error={Boolean(errors.description)}
                                helperText={errors?.description}
                            />
                        </FormControl> */}
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Description</Typography>
                        <CommonQuillEditor
                            value={mainBrand?.description || ""}
                            onChange={(value) => setMainBrand({ ...mainBrand, description: value })}
                        />
                    </Grid>
                </Grid>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
                    <MDButton
                        sx={{ minWidth: "140px" }}
                        type='submit'
                        variant='contained'
                        color='primary'
                        onClick={addMainStrain}
                        loading={loading}
                    >
                        {loading || isLoading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Update'}
                    </MDButton>
                    <MDButton
                        sx={{ minWidth: "140px" }}
                        type='button'
                        variant='outlined'
                        color='dark'
                        onClick={() => navigate('/brands?orderFilter=main_brands')}
                    >
                        Cancel
                    </MDButton>
                </Stack>
            </Card>
        </Box>
    )
}
