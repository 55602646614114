import React from 'react'

export default function ReassignDriverIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
      <g clipPath="url(#clip0_8329_66994)">
        <path d="M6.5 9.74219C4.42795 9.74219 2.74219 11.4279 2.74219 13.5H3.75781C3.75781 12.9271 3.93453 12.3947 4.23617 11.9543L5.41722 13.1354C5.37862 13.2499 5.35742 13.3724 5.35742 13.5H7.64258C7.64258 13.3724 7.62138 13.2499 7.58281 13.1354L8.76385 11.9543C9.06547 12.3947 9.24219 12.9271 9.24219 13.5H10.2578C10.2578 11.4279 8.57205 9.74219 6.5 9.74219ZM6.13537 12.4172L4.95432 11.2361C5.39472 10.9345 5.92709 10.7578 6.5 10.7578C7.07291 10.7578 7.60528 10.9345 8.04565 11.2362L6.86461 12.4172C6.75007 12.3786 6.62754 12.3574 6.5 12.3574C6.37246 12.3574 6.24993 12.3786 6.13537 12.4172ZM11.0962 8.90379C10.3882 8.19585 9.54558 7.67176 8.62619 7.3545C9.61089 6.6763 10.2578 5.54126 10.2578 4.25781C10.2578 2.18576 8.57205 0.5 6.5 0.5C4.42795 0.5 2.74219 2.18576 2.74219 4.25781C2.74219 5.54126 3.38912 6.6763 4.37384 7.35453C3.45445 7.67176 2.61178 8.19585 1.90381 8.90381C0.676127 10.1315 0 11.7638 0 13.5H1.01562C1.01562 10.4759 3.4759 8.01562 6.5 8.01562C9.5241 8.01562 11.9844 10.4759 11.9844 13.5H13C13 11.7638 12.3239 10.1315 11.0962 8.90379ZM3.75781 4.25781C3.75781 2.74578 4.98794 1.51562 6.5 1.51562C8.01206 1.51562 9.24219 2.74578 9.24219 4.25781C9.24219 5.76985 8.01206 7 6.5 7C4.98794 7 3.75781 5.76985 3.75781 4.25781Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_8329_66994">
          <rect width="13" height="13" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
