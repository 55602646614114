import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from "prop-types";

export default function CustomDrawer(props) {
    const { children, customFunction = () => { }, maxWidth, className = "" } = props;

    const [open, setModalOpen] = useState(true)


    function handleClose() {
        setModalOpen(false)
        customFunction();
    }

    return (
        <Drawer
            className={`cus-drawer ${className}`}
            open={open}
            anchor='right'
            onClose={handleClose}
            aria-labelledby="form-Dr-title"
            fullWidth
        >

            {children}
        </Drawer>
    );
}

CustomDrawer.propTypes = {
    children: PropTypes.node.isRequired,
    customFunction: PropTypes.func,
    maxWidth: PropTypes.string,
    className: PropTypes.string,
};