import React from 'react'

export default function RetailersIcon({width = '24px', height = '24px', color = 'inherit'}) {
  return (
<svg style={{width, height, color}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.9831 9.05138L19.965 4.97662C19.8401 4.476 19.3901 4.125 18.8737 4.125H5.12738C4.611 4.125 4.161 4.476 4.035 4.97662L3.01687 9.05138C3.00562 9.09525 3 9.14138 3 9.1875C3 10.7378 4.19813 12 5.67188 12C6.528 12 7.29187 11.5736 7.78125 10.9121C8.27063 11.5736 9.0345 12 9.89062 12C10.7467 12 11.5106 11.5736 12 10.9121C12.4894 11.5736 13.2521 12 14.1094 12C14.9666 12 15.7294 11.5736 16.2188 10.9121C16.7081 11.5736 17.4709 12 18.3281 12C19.8019 12 21 10.7378 21 9.1875C21 9.14138 20.9944 9.09525 20.9831 9.05138Z" fill="#9A9AAF"/>
<path d="M18.3281 13.1249C17.562 13.1249 16.8341 12.8909 16.2188 12.4634C14.988 13.3195 13.2308 13.3195 12 12.4634C10.7692 13.3195 9.012 13.3195 7.78125 12.4634C7.16587 12.8909 6.438 13.1249 5.67188 13.1249C5.1195 13.1249 4.59863 12.9955 4.125 12.775V18.7499C4.125 19.3709 4.629 19.8749 5.25 19.8749H9.75V15.3749H14.25V19.8749H18.75C19.371 19.8749 19.875 19.3709 19.875 18.7499V12.775C19.4014 12.9955 18.8805 13.1249 18.3281 13.1249Z" fill="#9A9AAF"/>
</svg>


  )
}