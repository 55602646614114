import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addStartTaskAPI } from '../../../redux/apis/hyperdrive/createTask';

const initialState = {
  taskDrive: [],
  loading: false,
  error: null,
  flavors: [],
  getTaskDrive: {}
};

export const addStartTask = createAsyncThunk(
  'common/addStartTask',
  async ({data}, { rejectWithValue }) => {
    try {
      const res = await addStartTaskAPI(data); 
      return res;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);

const tasksDriveSlice = createSlice({
  name: 'taskHyperDrive',
  initialState,
  reducers: {
    setCommonHyperdrive: (state, action) => {
      return { ...state, taskDrive: action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addStartTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addStartTask.fulfilled, (state, action) => {      
        state.loading = false;
        state.mainStartTaskData = action.payload;
      })
      .addCase(addStartTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { taskDrive } = tasksDriveSlice.actions;

export default tasksDriveSlice.reducer;