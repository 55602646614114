import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Card, Paper, Stack, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import { Download } from '@mui/icons-material';
import boxShadow from 'assets/theme/functions/boxShadow';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// import './RevenueChart.css';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const getGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, chartArea.bottom);

    // Adjust gradient stops to match the image:
    gradient.addColorStop(0, '#60DDF6'); // Light blue at the top
    gradient.addColorStop(0.7, '#60DDF6'); // Maintain light blue
    gradient.addColorStop(1, 'rgba(0, 128, 255, 0.2)'); // Transition to transparent dark blue

    return gradient;
};

const CompletedTaskChart = ({ chartData, completedTaskGraphFilter, changeFilterHandler }) => {
    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Succeeded',
                data: [3000, 4000, 5000, 2000, 3000, 10000, 2000, 4000, 7000, 5000, 2000, 3000],
                // backgroundColor: (context) => {
                //     const chart = context.chart;
                //     const { ctx, chartArea } = chart;

                //     if (!chartArea) {
                //       return null;
                //     }
                //     return getGradient(ctx, chartArea);
                //   },
                backgroundColor: '#06D7F6',
                borderColor: '#06D7F6',
                borderWidth: 1,
                barPercentage: 0.4,
                categoryPercentage: 0.5,

            },
            {
                label: 'Failed',
                data: [1000, 2000, 1000, 1000, 2000, 5000, 1000, 2000, 3000, 3000, 2000, 4000],
                backgroundColor: '#4807EA',
                borderColor: '#4807EA',
                borderWidth: 1,
                barPercentage: 0.4,
                categoryPercentage: 0.5,
            },
        ],
    };

    const options = {
        responsive: true,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                stacked: true,
            },
            y: {
                beginAtZero: true,
                stacked: true,
                stackWeight: 1,
                grid: {
                    color: 'rgba(200, 200, 200, 0.2)',
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    return (
        <div className="cus-inner-height">
            <Card component={Paper} sx={{ padding: 3, backgroundColor: '#000000' }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={2}>
                    <Typography fontWeight={600} className='text-16 text-white-900'>Completed Tasks</Typography>
                    <Stack direction='row' alignItems='center' gap={2}>
                        <Typography className='text-14 text-black-600' fontWeight={600}>Group By:</Typography>
                        <Stack direction='row' gap={1.5} alignItems='center' className='group-by'>
                            <MDButton
                                variant='contained'
                                color={completedTaskGraphFilter?.groupBy === 'lastWeek' ? 'primary' : "light"}
                                size="small"
                                className="line-height-normal"
                                onClick={() => changeFilterHandler('lastWeek')}
                            >
                                Last Week
                            </MDButton>
                            <MDButton
                                variant='contained'
                                size="small"
                                color={completedTaskGraphFilter?.groupBy === 'last24hour' ? 'primary' : "light"}
                                className="line-height-normal"
                                onClick={() => changeFilterHandler('last24hour')}

                            >
                                Last 24hours
                            </MDButton>
                            <MDButton
                                variant='contained'
                                color={completedTaskGraphFilter?.groupBy === 'last30days' ? 'primary' : "light"}
                                size="small"
                                className="line-height-normal"
                                onClick={() => changeFilterHandler('last30days')}

                            >
                                Last 30days
                            </MDButton>

                        </Stack>
                        <MDButton
                            variant='contained'
                            color='primary'
                            sx={{ borderRadius: '10rem', marginLeft: '30px', minWidth: '120px' }}
                        >
                            <FileDownloadOutlinedIcon /> &nbsp; {" "} CSV
                        </MDButton>
                    </Stack>
                </Stack>
                <Bar data={chartData} options={{
                    ...options,
                    elements: {
                        bar: {
                            borderRadius: 5,
                        }
                    }
                }} />
            </Card>
        </div>
    );
};

export default CompletedTaskChart;
