import { Close } from '@mui/icons-material'
import { Box, Button, FormControl, FormControlLabel, FormHelperText, Grid, Menu, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import GoogleSuggestion from 'components/googleMaps/GoogleSuggestion'
import moment from 'moment'
import ReassignDriverIcon from 'common/customIcons/reassigndriver'
import MapDriveListDrawer from './MapDriveListDrawer';
import { addStartTask } from '../../../redux/slices/hyperdrive/createTask';
import { openSnackbar } from '../../../redux/slices/common';
import MDButton from 'components/MDButton';
import { useSelector } from 'react-redux';
import DateTask from 'components/dateRangePicker/DateTask';
import RegionListDrawer from './RegionList';

export default function StartTask({ closeModal }) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const addressError = useRef(false)
    const { loading: loading = false } = useSelector(state => state?.taskHyperDrive)

    const [formData, setFormData] = useState({
        // task_id: "",
        taskDescription: "",
        address: "",
        taskAssignmentMode: "",
        taskType: "startTask",
        fleetIds: ""
    })
    const [timeRange, setTimeRange] = useState({
        taskStartTime: null,
        taskEndTime: null,
    })
    const today = new Date();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showManualList, setShowManualList] = useState(false);
    const [regionList, setRegionList] = useState(false);
    const open = Boolean(anchorEl);
console.log("regionList",regionList);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        // setShowManualList(true)
    };

    const startRangeHandler = (event, name) => {
        const formatDateString = (event) => {
            const date = moment(event);
            if (!date.isValid()) {
                return '';
            }
            return date.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        };
        setTimeRange(prevData => {
            const newData = {
                ...prevData,
                [name]: formatDateString(event)
            };
            const startDate = moment(newData.taskStartTime);
            const endDate = moment(newData.taskEndTime);
            if (name === 'taskStartTime' && newData.taskEndTime) {
                if (endDate.isBefore(startDate)) {
                    setErrors(prevData => ({ ...prevData, taskStartTime: "Start date must be before the end date." }))
                    // alert('Start date must be before the end date.');
                    return prevData;
                } else if (endDate.isSame(startDate)) {
                    setErrors(prevData => ({ ...prevData, taskStartTime: "Start date and end date cannot be the same." }));
                    return prevData;
                }
            }
            if (name === 'taskEndTime' && newData.taskStartTime) {
                if (endDate.isBefore(startDate)) {
                    setErrors(prevData => ({ ...prevData, taskEndTime: "End date must be after the start date." }))
                    // alert('End date must be after the start date.');
                    return prevData;
                } else if (endDate.isSame(startDate)) {
                    setErrors(prevData => ({ ...prevData, taskEndTime: "Start date and end date cannot be the same." }));
                    return prevData;
                }
            }
            setErrors({ taskStartTime: "", taskEndTime: "" })
            return newData;
        });

    }

    const startTaskHandler = (event) => {
        const { name, value } = event.target;

        setFormData(prevData => {
            const newData = {
                ...prevData,
                [name]: value
            };

            if (name === 'taskAssignmentMode') {
                if (value === "driver") {
                    setShowManualList(true)
                    setRegionList(false)
                }
                else {
                    // setErrors({ fleetId: "" })
                    setRegionList(true)
                    setShowManualList(false)
                }
            }

            return newData;
        });
    };


    const validateTask = () => {
        let isError = false
        let errors = {}
        if (!formData?.taskDescription?.trim()) {
            isError = true
            errors = { ...errors, taskDescription: 'Task Description is required' }
        }
        if (timeRange?.taskStartTime == null) {
            isError = true
            errors = { ...errors, taskStartTime: 'Start Date is required' }
        }
        if (timeRange?.taskEndTime == null) {
            isError = true
            errors = { ...errors, taskEndTime: 'End Date is required' }
        }
        if (formData?.address == "" || formData?.address?.address == "") {
            isError = true
            errors = { ...errors, address: 'Address is required' }
        }
        return { errors, isError }
    }


    const submitHandler = () => {
        const validationResult = validateTask();
        let isError = validationResult.isError;
        let errors = { ...validationResult.errors };
        setErrors(errors);
        if (isError) return;
        if (formData?.taskAssignmentMode === "manual") {
            if (!formData?.fleetId?.trim()) {
                isError = true;
                errors = { ...errors, fleetId: 'Fleet ID is required' };
                setErrors(errors);
                return false
            }
        }
        const payload = { ...formData, ...timeRange }

        dispatch(addStartTask({ data: payload }))
            .unwrap()
            .then(res => {
                showResponseMessage(res?.message, 'success');
                closeModal()
            })
            .catch(err => {
                showResponseMessage(err?.message, 'error');
            });

    }
    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const googleAddressErrorHandler = (status = false) => {
        addressError.current = status
        if (status) {
            setErrors({ ...errors, address: 'Address is required' })
        }
        else {
            setErrors(prevErrors => ({ ...prevErrors, address: '' }));
        }
    }

    function handleDataFromChild() {
        setShowManualList(false)
        setRegionList(false)
    }

    const handleFleetValueChange = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            fleetId: value
        }));
    };

    const handleAddressChange = (addressed) => {
        const address = {
            address1: addressed?.address || "",
            city: addressed?.city || "",
            state: addressed?.state || "",
            zipcode: addressed?.zipcode || "",
            country: addressed?.country || "USA",
            latitude: addressed?.lat ? addressed.lat.toFixed(8) : "",
            longitude: addressed?.long ? addressed.long.toFixed(8) : "",

        };
        setFormData({ ...formData, address });
    }
    return (
        <Box className="cus-wrapper-drawer">
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} style={{ borderBottom: "1px solid  #1E1E24" }} className="drawer-head-sec">
                <Typography className="text-14 text-white-700">Create Start Task</Typography>
                <Close onClick={closeModal} style={{ cursor: "pointer", color: "#ffffff" }} />
            </Box>

            <Box className="drawer-scroll-sec">
                <Box px={2} py={4}>

                    <Stack direction='column' mb={4}>
                        <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Task Info</Typography>
                        <Stack direction='column' gap={2} mb={1} className='dark-field'>
                            {/* <TextField
                                fullWidth
                                helperText={errors.task_id}
                                error={errors?.task_id}
                                label={'Task ID'}
                                name="task_id"
                                onChange={(e) => startTaskHandler(e)}
                                className="form-input"
                                value={formData?.task_id || ''}
                            /> */}
                            <TextField
                                fullWidth
                                helperText={errors.taskDescription}
                                error={errors?.taskDescription}
                                label={'Task Description'}
                                name="taskDescription"
                                onChange={(e) => startTaskHandler(e)}
                                className="form-input"
                                value={formData?.taskDescription || ''}
                            />
                        </Stack>
                    </Stack>

                    <Stack direction='column' mb={4}>
                        <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Start Task Location</Typography>
                        <Stack direction='column' gap={2} mb={1} className='dark-field hd-google-suggestions'>
                            <GoogleSuggestion addressValue={formData?.address?.address} googleAddressErrorHandler={googleAddressErrorHandler}
                                onClearAddress={(address) => setFormData({ ...formData, address })}
                                addressChangeHandler={(address) => handleAddressChange(address)} />
                            {errors?.address && <FormHelperText error>{errors?.address}</FormHelperText>}
                        </Stack>
                    </Stack>

                    <Stack direction='column' mb={4}>
                        <Typography fontWeight={600} className='text-20 text-white-700'>Time Window</Typography>
                        <Typography mb={2} fontWeight={400} className='text-16 text-black-600'>For tasks with a schedule or estimated fullfillment time.</Typography>

                        <Grid container spacing={2} className='drawer-date-field dark-field dark-calendar'>
                            {/* <Grid item lg={6} md={6} sm={6} xs={6}>
                                <TextField
                                    helperText={errors.taskStartTime}
                                    error={errors?.taskStartTime}
                                    type='datetime-local'
                                    name="taskStartTime"
                                    label="Start"
                                    onChange={(e) => startTaskHandler(e)}
                                    value={formData?.taskStartTime ? moment(formData?.taskStartTime).format('YYYY-MM-DDTHH:mm') : ''}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: 220 }}
                                    inputProps={{
                                        min: moment().format('YYYY-MM-DDTHH:mm') // Disable past dates
                                    }}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <TextField
                                    helperText={errors?.taskEndTime}
                                    error={errors?.taskEndTime}
                                    type='datetime-local'
                                    name="taskEndTime"
                                    label="End"
                                    onChange={(e) => startTaskHandler(e)}
                                    value={formData?.taskEndTime ? moment(formData?.taskEndTime).format('YYYY-MM-DDTHH:mm') : ''}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: 220 }}
                                    disabled={!formData?.taskStartTime}
                                    inputProps={{
                                        min: moment().format('YYYY-MM-DDTHH:mm') // Disable past dates
                                    }}
                                />
                            </Grid> */}
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <DateTask selected={timeRange?.taskStartTime} onChange={startRangeHandler} name={"taskStartTime"} placeholderText={"Start"} minDate={today} />
                                {
                                    errors?.taskStartTime && <span className='error_class'>{errors?.taskStartTime}</span>
                                }
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <DateTask selected={timeRange?.taskEndTime} onChange={startRangeHandler} name={"taskEndTime"} placeholderText={"End"} minDate={today} />
                                {
                                    errors?.taskEndTime && <span className='error_class'>{errors?.taskEndTime}</span>
                                }
                            </Grid>
                        </Grid>
                    </Stack>

                    <Stack>
                        <div className='d-flex reassign-driver-btn'>
                            <Button
                                id=""
                                aria-controls={open ? '' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                className='d-flex gap-2 text-white-900'
                            >
                                <ReassignDriverIcon />
                                <Typography fontWeight="semibold" className='text-12 text-white-900 text-transform-none'>Assign Driver</Typography>
                                <KeyboardArrowDownIcon />
                                <span style={{ color: "red" }}>{errors?.fleetId}</span>
                            </Button>
                            <Menu
                                id=""
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{ className: "hd-dropdown reassign-driver-dropdown" }}
                            >
                                <FormControl>
                                    <RadioGroup
                                        value={formData?.taskAssignmentMode}
                                        aria-labelledby=""
                                        defaultValue=""
                                        name="taskAssignmentMode"
                                        className='radio-colors'
                                        onChange={(e) => startTaskHandler(e)}
                                    >
                                        <FormControlLabel className='field-off-duty' value="driver" control={<Radio />} label="Driver List" />
                                        <FormControlLabel className='field-idle' value="region" control={<Radio />} label="Region List" />                                </RadioGroup>
                                </FormControl>
                            </Menu>
                        </div>
                    </Stack>

                </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} gap={2} style={{ borderTop: "1px solid  #1E1E24" }} className="drawer-bottom-btns">
                <Button
                    variant="outlined"
                    color="secondary"
                    className='d-flex align-item-center gap-2'
                    onClick={closeModal}
                >
                    Cancel
                </Button>
                <MDButton
                    variant="contained"
                    color="primary"
                    className='d-flex align-item-center gap-2 green-btn'
                    onClick={() => submitHandler()}
                    disabled={loading}
                    loading={loading}
                >
                    Create Task
                </MDButton>
            </Box>

            {showManualList &&
                <MapDriveListDrawer sendDataToParent={handleDataFromChild} onValueChange={handleFleetValueChange} breakTask={"startTask"} />
            }
            {
                regionList &&
                <RegionListDrawer sendDataToParent={handleDataFromChild} onValueChange={handleFleetValueChange} breakTask={"startTask"} />
            }
        </Box>
    )
}
