import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchInfoEffectsAPI, uploadImageAPI } from '../apis/common'
import { createBrandAPI, deletBrandAPI, fetchBrandDetailsAPI, fetchBrandsAPI, getMainBrandsAPI, updateBrandAPI, updateMainBrandAPI } from "../../redux/apis/brand";

export const fetchBrands = createAsyncThunk(
    'brand/fetchBrands',
    async (data, { rejectWithValue }) => {
        try {
            const res = await fetchBrandsAPI(data);
            return res
        } catch (error) {
            console.log("erro", error)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const createBrand = createAsyncThunk(
    'brand/createBrand',
    async (data, { rejectWithValue }) => {
        try {
            const res = await createBrandAPI(data);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response);
        }
    }
);


export const deleteBrand = createAsyncThunk(
    'brand/deleteBrand',
    async (data, { rejectWithValue }) => {
        try {
            const res = await deletBrandAPI(data);
            return res
        } catch (error) {
            console.log("erro", error)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchSingleBrand = createAsyncThunk(
    'brand/fetchSingleBrand',
    async (brandId, { rejectWithValue }) => {
        try {
            const res = await fetchBrandDetailsAPI(brandId);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response);
        }
    }
);

export const updateBrand = createAsyncThunk(
    'brand/updateBrand',
    async ({ data, brandId }, { rejectWithValue }) => {
        try {
            const res = await updateBrandAPI(data, brandId)
            return res
        } catch (error) {
            console.log("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const getMainBrand = createAsyncThunk(
    'strain/getMainBrand',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await getMainBrandsAPI(filters);
            return res;
        } catch (error) {
            console.log("error444", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const updateMainBrand = createAsyncThunk(
    'strain/updateMainBrand',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateMainBrandAPI(data);
            return res;
        } catch (error) {
            console.log("error444", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);




// Create your brandSlice
const brandSlice = createSlice({
    name: 'brand',
    initialState: {
        // Your initial state here
        isLoading: false,
        brandsData: null,
        fetchBrandsError: null,
        isDeleting: false,
        isDeleting: false,
        isUpdating: false,
        mainBrandData: {}

    },
    reducers: {
        // Other reducers if any
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBrands.pending, (state) => {
            // Set the pending state to true
            state.isLoading = true;
            state.singleBrand = {}
            state.fetchBrandsError = null; // Reset any previous errors
        });
        builder.addCase(fetchBrands.fulfilled, (state, action) => {
            // Handle successful fetch of brands
            state.brandsData = action.payload;
            state.fetchBrandsError = null;
            state.isLoading = false
        });
        builder.addCase(fetchBrands.rejected, (state, action) => {
            // Handle fetch errors
            state.isLoading = false
            state.fetchBrandsError = action.payload;
        });

        builder
            .addCase(deleteBrand.pending, (state) => {
                state.isDeleting = true;
                state.error = null;
            })
            .addCase(deleteBrand.fulfilled, (state, action) => {
                state.isDeleting = false;
                // Handle successful deletion if needed
            })
            .addCase(deleteBrand.rejected, (state, action) => {
                state.isDeleting = false;
                state.error = action.payload; // Handle the error: store the error payload in state
            });
        builder
            .addCase(createBrand.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createBrand.fulfilled, (state, action) => {
                state.isLoading = false;
                // You can update your state with the data from the API response if needed
            })
            .addCase(createBrand.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // The error from the API response
            });

        builder
            .addCase(fetchSingleBrand.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchSingleBrand.fulfilled, (state, action) => {
                state.isLoading = false;
                state.singleBrand = action.payload?.brandDetails; // Assuming the payload contains the brand details
            })
            .addCase(fetchSingleBrand.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // The error from the API response
            });

        builder
            .addCase(updateBrand.pending, (state) => {
                state.isUpdating = true;
                state.error = null;
            })
            .addCase(updateBrand.fulfilled, (state) => {
                state.isUpdating = false;
            })
            .addCase(updateBrand.rejected, (state, action) => {
                state.isUpdating = false;
                state.error = action.payload; // The error from the API response
            }).addCase(getMainBrand.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getMainBrand.fulfilled, (state, action) => {
                state.isLoading = false;
                state.mainBrandData = action.payload;
            })
            .addCase(getMainBrand.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            }).addCase(updateMainBrand.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateMainBrand.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updateMainBrand.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export default brandSlice.reducer;
