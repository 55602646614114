import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useState } from 'react'
import { TextField, Grid, Typography, Card, Box, Stack, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import MDButton from 'components/MDButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addBreaks, updateBreak } from '../../../redux/slices/hyperwolf/driver/breaks';
import { openSnackbar } from '../../../redux/slices/common';
import { MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { isEmptyObject } from 'utilities/common';
import { formatTimestampToTime } from 'utilities/common';
import { convertToTimestamp } from 'utilities/common';
import { convertToTimestampWithMoment } from 'utilities/common';
import { breakValidation } from 'validations/hyperwolf/break';
import timezone from 'dayjs/plugin/timezone';
import { formatTimestampToTimeWithTimezone } from 'utilities/common';
dayjs.extend(timezone);


export default function CreateBreak() {
  const navigate = useNavigate()
  const { breakDetails, loading=false } =useSelector(state => state.breaks ||  {}) 
  console.log(useSelector(state => state.breaks), 'breakDetails')
  const { breakId } = useParams()
  const timeZoneData = ["PST"]
  const [fromTime,setFromTime] = useState(null)
  const [toTime,setToTime] = useState(null)
  const [sendBreakAt, setSendBreakAt] = useState(null)
  const [formData, setFormData] = useState( {
    breakName : '',
    timezone:'',
    fromTime:fromTime,
    toTime:toTime,
    duration:'',
    sendBreakAt:sendBreakAt,
    status:'active'
})

const [errors, setErrors] = useState({})
const dispatch= useDispatch()

const handleChange = (e) => {
  console.log(e, 'e log')
  const {name, value} = e.target;
  setFormData({ ...formData, [name]:value });
}

console.log(formData, 'FORMDATA')

useEffect(() => {
  if (!isEmptyObject(breakDetails) && breakId ) {

    if (breakDetails && breakDetails.data && breakDetails.data.data) {
      const { fromTime, toTime, sendBreakAt } = breakDetails.data.data;
      const formatTime = (timeStr, tz) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        const utcDate = dayjs.utc().set('hour', hours).set('minute', minutes);
        const localDate = utcDate.tz(tz).local(); // Convert to local time
        return localDate.format('HH:mm');
      };
      
      const convertTimeToTimestamp = (timeString) => {
        if (!timeString) return null;
        const dateTimeString = `${moment().format('YYYY-MM-DD')}T${timeString}`;
        return moment(dateTimeString, 'YYYY-MM-DDTHH:mm').valueOf();
      };

      const fromTimeStamp = convertTimeToTimestamp(formatTime(fromTime));
      const toTimeStamp = convertTimeToTimestamp(formatTime(toTime));
      const sendBreakAtTimeStamp = convertTimeToTimestamp(formatTime(sendBreakAt));

      console.log(fromTimeStamp, 'FTS')

    let payload = { ...breakDetails?.data.data,
        // fromTime: dayjs(fromTimeStamp).valueOf()
        // fromTime: setFromTime(dayjs(fromTimeStamp)),
        fromTime: setFromTime(dayjs(fromTimeStamp)),
        toTime : setToTime(dayjs(toTimeStamp)),
        sendBreakAt: setSendBreakAt(dayjs(sendBreakAtTimeStamp))
    }

    setFormData({...payload})

  }
}},[breakDetails])


const handleSubmit = (e) => {
  e.preventDefault();
  const { errors, isValid } = breakValidation({ ...formData, fromTime: fromTime, toTime: toTime, sendBreakAt: sendBreakAt })
  console.log(errors, 'errorscccc')
  if (!isValid) {
    setErrors(errors)
    return;
  }
  setErrors({})

  const payload = {
    breakName : formData.breakName,
    timezone:formData.timezone,
    fromTime:formatTimestampToTimeWithTimezone(dayjs(fromTime).valueOf(), fromTime.timezone),
    toTime:formatTimestampToTimeWithTimezone(dayjs(toTime).valueOf(), toTime.timezone),
    duration: calculateTimeDifferenceinminutes(fromTime,toTime),
    sendBreakAt: formatTimestampToTimeWithTimezone(dayjs(sendBreakAt).valueOf(), sendBreakAt.timezone),
    status: formData.status
  }

  if (breakId) {
    dispatch(updateBreak({id:breakId, data: {...payload}})).unwrap().then((res) => {
      showError(res?.data?.message, 'success')
      navigate('/hyperwolf/driver?driverFilter=breaks')
    }).catch((err) => {
      showError(err?.message, "error")
    })
  }
  else {
  dispatch(addBreaks(payload)).unwrap().then((res) => {
    showError(res?.data.message, 'success')
    navigate('/hyperwolf/driver?driverFilter=breaks')
  }).catch((err) => {
    showError(err?.message, "error")
  })
}
}

const showError = (message, errortype) => {
  if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
  }
  if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
  }
}

console.log(timeZoneData , 'tzdata')

const handleFromTimeChange = (newTime) => {
  setFromTime(newTime);
  let errorsObj = {...errors}
  delete errorsObj.fromTime
  console.log(errorsObj, 'llll')
};

const handleToTimeChange = (newTime) => {
  setToTime(newTime);
};

const handleSendBreakChange = (newTime) => {
  setSendBreakAt(newTime)
}

const calculateTimeDifferenceinminutes = (fromTime,toTime) => {
    const diffInMiliSec = toTime?.diff(fromTime);
    const diffInMinutes = moment.duration(diffInMiliSec).asMinutes()
    console.log(diffInMinutes, 'diff in minutes')
    return diffInMinutes;
}

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }} className="create-break">
        <form style={{ marginTop: 0 }}>
          <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
            <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
              <Typography variant='h4' fontWeight="semibold" color="secondary">{breakId ? 'Update Break' : 'Add Break'}</Typography>
              <Box display="flex" alignItems="center" gap={1.25}>
                {/* <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography> */}
                <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
                  <MDButton
                    sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                    type='button'
                    variant={formData?.status === "active" ? 'contained' : 'text'}
                    color= {formData?.status === 'active' ? 'success' : 'dark'}
                    size="small"
                    onClick= {() => {
                      setFormData({ ...formData, status: 'active' })
                    }}
                  >
                    Active
                  </MDButton>
                  <MDButton
                    sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                    type='button'
                    variant={formData?.status !== "active" ? 'contained' : 'text'}
                    color= {formData?.status !== 'active' ? 'error' : 'dark'}
                    size="small"
                    onClick= {() => {
                      setFormData({ ...formData, status: 'inactive' })
                    }}
                  >
                    Inactive
                  </MDButton>
                </Stack>
              </Box>
            </Stack>


            <Grid container spacing={2} mb={1}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  placeholder="Break Name"
                  name="breakName"
                  value={formData.breakName}
                  error={errors.breakName}
                  helperText={errors.breakName}
                  onChange={handleChange}
                />  
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Time Zone</InputLabel>
                                    <Select
                                        className="form-select"
                                        error={Boolean(errors?.strainType)}
                                        onChange={handleChange} label="Time Zone" name="timezone" value={formData.timezone}>
                                        {
                                            timeZoneData?.map(((timezone, index) => {
                                                return <MenuItem key={index} value={timezone}>{timezone}</MenuItem>
                                            }))
                                        }
                                    </Select>
                                    {Boolean(errors?.timezone) && <FormHelperText error>{errors?.timezone}</FormHelperText>}
                                </FormControl>
                            </Grid>
              {/* <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  placeholder="Time Zone"
                  name="timezone"
                  value={formData.timezone}
                  onChange={handleChange}
                />
              </Grid> */}
                                    <Grid item xs={12} md={6} lg={3}>
                                    <FormControl fullWidth>
                                        <MobileTimePicker
                                            className="mobile-time-picker"
                                            label="From Time"
                                            value={fromTime}
                                            onChange={handleFromTimeChange}
                                            // ampm={false}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                error={Boolean(errors.fromTime)}
                                                helperText={errors.fromTime}
                                            />}
                                        />
                                        {Boolean(errors.fromTime) && <FormHelperText error>{errors.fromTime}</FormHelperText>}
                                    </FormControl>
                                </Grid>
              {/* <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  placeholder="From Time"
                  name="fromTime"
                  value={formData.fromTime}
                  onChange={handleChange}
                  type='number'
                />
              </Grid> */}
              <Grid item xs={12} md={6} lg={3}>
                                    <FormControl fullWidth>
                                        <MobileTimePicker
                                            className="mobile-time-picker"
                                            label="To Time"
                                            value={toTime}
                                            onChange={handleToTimeChange}
                                            // ampm={false}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                error={Boolean(errors.toTime)}
                                                helperText={errors.toTime}
                                            />}
                                        />
                                        {Boolean(errors.toTime) && <FormHelperText error>{errors.toTime}</FormHelperText>}
                                    </FormControl>
                                </Grid>
              {/* <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  placeholder="To Time"
                  name="toTime"
                  value={formData.toTime}
                  type='number'
                  onChange={handleChange}
                />
              </Grid> */}
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  placeholder="Brake Duration Minutes"
                  name="duration"
                  value={calculateTimeDifferenceinminutes(fromTime,toTime)}
                  type='number'
                  onChange={handleChange}
                  // disabled
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                                    <FormControl fullWidth>
                                        <MobileTimePicker
                                            className="mobile-time-picker"
                                            label="Send Break At"
                                            value={sendBreakAt}
                                            onChange={handleSendBreakChange}
                                            // ampm={false}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                error={Boolean(errors.sendBreakAt)}
                                                helperText={errors.sendBreakAt}
                                            />}
                                        />
                                        {Boolean(errors.sendBreakAt) && <FormHelperText error>{errors.sendBreakAt}</FormHelperText>}
                                    </FormControl>
                                </Grid>
              {/* <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  placeholder="Send Break at"
                  name="sendBreakAt"
                  value={formData.sendBreakAt}
                  onChange={handleChange}
                />
              </Grid> */}
            </Grid>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
              <MDButton
                  sx={{ minWidth: "140px" }}
                  type='submit'
                  variant='contained'
                  color='primary'
                  onClick = {handleSubmit}
              >
                Save
              </MDButton>
              <MDButton
                sx={{ minWidth: "140px" }}
                type='button'
                variant='outlined'
                color='dark'
                onClick={() => navigate('/hyperwolf/driver?driverFilter=breaks')}
              >
                Cancel
              </MDButton>
            </Stack>
          </Card>
        </form>
      </Box>
    </CommonLayout>
  )
}
