import React, { useEffect } from 'react'
import Roles from './Roles'
import Users from './Users'
import { useSearchParams } from 'react-router-dom'

export default function UsersAndRoles() {
    const [searchParams, setSearchParams] = useSearchParams();
    let roleTab = searchParams.get('roleTab')

    useEffect(()=>{
        if(!roleTab){
            setSearchParams({roleTab:'users'})
        }
    },[roleTab])
    return (
        roleTab === 'users' ? <Users /> : <Roles />
    )
}
