import AddCannabinoidsForm from 'components/cannabinoids/addCannabinoidsForm'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { getSingleCannabinoid } from '../../redux/slices/cannabinoids'

export default function AddCannabinoid() {
  const dispatch = useDispatch()
  const { cannabinoidId = "" } = useParams()
  useEffect(() => {
    if (cannabinoidId) {
      dispatch(getSingleCannabinoid(cannabinoidId))
    }
  }, [cannabinoidId])
  return (
    <CommonLayout>
      <AddCannabinoidsForm />
    </CommonLayout>
  )
}
