import axiosClient from "axiosClient";
import { axiosHyperwolfClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchTerpenoidsAPI(filters) {
    return await axiosClient.get(`/api/v1/product/terpenoids/?${getQueryString(filters)}`);
}

export async function fetchTerpenoidDetailsAPI(terpenoidId) {
    return await axiosClient.get(`/api/v1/product/terpenoids/${terpenoidId}`);
}

export async function addTerpenoidAPI(data) {
    return await axiosClient.post(`/api/v1/product/terpenoids`, data);
}

export async function updateTerpenoidAPI(data, terpenoidId) {
    return await axiosClient.put(`/api/v1/product/terpenoids/${terpenoidId}`, data);
}
export async function updateMemberAPI(data, terpenoidId) {
    return await axiosClient.put(`/api/v1/product/terpenoids/update/${terpenoidId}`, data);
}

export async function deleteTerpenoidAPI({ terpeneId, productId }) {
    return await axiosClient.post(`/api/v1/product/delete/terpenoids/${productId}`, { terpeneId });
}

export async function deleteMainTerpenoidAPI(terpeneId) {
    return await axiosClient.delete(`/api/v1/product/terpenoids/${terpeneId}`);
}

export async function fetchMemberOrdersAPI(data, terpenoidId) {
    return await axiosClient.get(`/api/v1/product/terpenoids/?terpenoidId=${terpenoidId}`, data);
}


export async function addTerpenoidToProductAPI(data, productId) {
    return await axiosClient.post(`/api/v1/product/assign/terpenoids/${productId}`, { terpenoidsData: data });
}

export async function updateTerpenoidToProductAPI(data, productId) {
    return await axiosClient.put(`/api/v1/product/update/terpenoids/${productId}`, { ...data });
}

// cannabinoids


export async function getMainCannabinoidAPI() {
    return await axiosHyperwolfClient.get(`/api/v1/cannabinoid/main/page`);
}

export async function updateMainCannabinoidAPI(data) {
    return await axiosHyperwolfClient.post(`/api/v1/cannabinoid/update/main/metas`, data);
}


export async function getCannabinoidsListAPI(data) {
    return await axiosHyperwolfClient.get(`/api/v1/cannabinoid?${getQueryString(data)}&userType=admin`);
}

export async function createCannabinoidAPI(data) {
    return await axiosHyperwolfClient.post(`/api/v1/cannabinoid/create`, data);
}

export async function updateCannabinoidAPI({ data, id }) {
    return await axiosHyperwolfClient.put(`/api/v1/cannabinoid/update/${id}`, data);
}

export async function getSingleCannabinoidAPI(id) {
    return await axiosHyperwolfClient.get(`/api/v1/cannabinoid/${id}`);
}


export async function deleteCannabinoidAPI(id) {
    return await axiosHyperwolfClient.delete(`/api/v1/cannabinoid/delete/${id}`);
}
export async function getCannabinoidBannersAPI() {
    return await axiosHyperwolfClient.get(`/api/v1/banners/cannabinoid`);
}
export async function createCannabinoidBannersAPI(data) {
    return await axiosHyperwolfClient.post(`/api/v1/banners/cannabinoid`, data);
}


export async function updateSingleCannabinoidBannerAPI({ data, id }) {
    return await axiosHyperwolfClient.put(`/api/v1/banners/cannabinoid/${id}`, data);
}

export async function getSingleCannabinoidBannerAPI(id) {
    return await axiosHyperwolfClient.get(`/api/v1/banners/cannabinoid/${id}`);
}

export async function deleteCannabinoidBannerAPI(id) {
    return await axiosHyperwolfClient.delete(`/api/v1/banners/cannabinoid/${id}`);
}