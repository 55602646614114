// import React from 'react'

// const TaskDrawer = () => {
//   return (
//     <div>

//     </div>
//   )
// }

// export default TaskDrawer

import TaskDetails from 'components/hyperdrive/Tasks/TaskDetails'
import React from 'react'

export default function TaskDrawer({ closeModal }) {
    return (
        <TaskDetails />
    )
}
