export function validateMainBrand(brandData) {
    const {
        title,
        metaTitle,
        metaDescription,
        description,
        image
    } = brandData;

    const errors = {};

    if (!metaDescription || metaDescription.trim() === '') {
        errors.metaDescription = 'Meta description is required.';
    }
    if (!metaTitle || metaTitle.trim() === '') {
        errors.metaTitle = 'Meta title is required.';
    }
    if (!title || title.trim() === '') {
        errors.title = 'Title is required.';
    }

    if (!description || description.trim() === '') {
        errors.description = 'Description is required.';
    }



    if (!image || typeof image !== 'string' || image.trim() === '') {
        errors.image = 'Image URL is required and should be a non-empty string.';
    }

    // Check if there are any errors
    const isValid = Object.keys(errors).length === 0;

    // Return an object with isValid and errors
    return {
        isValid,
        errors,
    };
}