import AddWebCategoryForm from 'components/webCategory/addWebCategoryForm'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSingleWebCategory } from '../../../redux/slices/category'

export default function AddWebCategory() {
  const dispatch = useDispatch()
  const { webCategoryId = "" } = useParams()
  useEffect(() => {
    if(webCategoryId){
      dispatch(fetchSingleWebCategory(webCategoryId))
    }
  }, [webCategoryId])
  return (
    <CommonLayout>
        <AddWebCategoryForm/>
    </CommonLayout>
  )
}
