import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, Container, Grid, InputLabel, TextField, Typography, Select, FormControl, MenuItem, FormHelperText, Autocomplete, Stack, Badge, CircularProgress, InputAdornment, Divider, Button, IconButton } from '@mui/material';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import { fetchCategories, openSnackbar, uploadImage } from '../../redux/slices/common';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { createCategory, createWebCategory, deleteWebCategory, fetchWebCategories } from '../../redux/slices/category';
import { useNavigate, useParams } from 'react-router';
import { isEmptyObject } from 'utilities/common';
import { categoryValidations, webCategoriesValidation } from '../../validations/category';
import defaultImage from 'assets/images/placeholder-image.png';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import CommonQuillEditor from 'common/editor';
import { grey } from '@mui/material/colors';
import { AccountCircle, RemoveCircle } from '@mui/icons-material';

const initialState = {
  webCategoryName: '',
  status: 'active',
  image: '',
  bannerImage: '',
  metaTitle: '',
  metaDescription: '',
  heading: '',
  createdBy: '',
  adminCategories: [],
  bottomText: "",
  faq: []
};
const AddWebCategoryForm = () => {
  const { user } = useSelector(state => state.auth)
  const [formData, setFormData] = useState({ ...initialState, createdBy: user?.name || "" });
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { webCategoryId = "" } = useParams()
  const { singleWebCategory = {}, webCategoriesList = {}, isCreating = false, loading = false } = useSelector((state => state.category))
  const { categoriesData = [], isUploading = false } = useSelector(state => state.common)
  const uploadedImageRef = useRef()

  const [errors, setErrors] = useState({})

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const productImageUploadHandler = async (e,imageType) => {
    uploadedImageRef.current = imageType;
    if (e.target.files.length) {
      const file = e.target.files[0];
      const body = new FormData()
      body.append("filePath", file);
      // res.fileUrl
      dispatch(uploadImage(body)).unwrap().then((res => {
        setFormData({ ...formData, [imageType]: res.fileUrl })
      }))
    }

  }
  const onSubmit = () => {
    const { isValid, errors } = webCategoriesValidation(formData)
    if (!isValid) {
      setErrors(errors)
      return;
    }
    setErrors({})
    dispatch(createWebCategory(formData)).unwrap().then((res) => {
      showError(res?.message, 'success')
      navigate('/manageCategories')
    }).catch((err) => {
      showError(err?.message, 'error')
    })
  }

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  const deleteHandler = (webCategoryId) => {
    dispatch(setModalLoader(true))
    dispatch(deleteWebCategory(webCategoryId)).unwrap().then((res) => {
      showError(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      navigate('/manageCategories')
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }

  const subCatOptions = useMemo(() => {
    let newCategories = categoriesData?.categories || []
    if (categoriesData?.categories && webCategoriesList?.values?.length > 0 && webCategoryId) {
      // const { values = [] } = webCategoriesList || {}
      // const usedCategories = values?.map((cat => cat.adminCategories)).flat();
      // newCategories = categoriesData?.categories?.filter((category => usedCategories?.every((usedCat => usedCat.categoryId !== category?.categoryId)))) || categoriesData?.categories
      newCategories = categoriesData?.categories?.filter((category => singleWebCategory.adminCategories?.every((usedCat => usedCat.categoryId !== category?.categoryId)))) || categoriesData?.categories
      // if(webCategoryId){
      //   newCategories = [...newCategories]
      // }
      // else{
      //   newCategories = [...newCategories]
      // }
    }
    return newCategories
  }, [categoriesData?.categories, webCategoriesList, formData?.adminCategories])

  useEffect(() => {
    dispatch(fetchWebCategories({ limit: 100, userType: 'admin', skip: 0, searchTerm: "" }))
  }, [])

  useEffect(() => {
    if (!isEmptyObject(singleWebCategory)) {
      setFormData({ ...formData, ...singleWebCategory })
    }
  }, [singleWebCategory])

  const uploadThumbnailImage = {
    borderRadius: "20px",
    width: "160px",
    height: "160px",
    objectFit: "cover",
    boxShadow: "0 0 1px rgb(0 0 0 / 20%)"
  };
  const badgeCircularProgressIcon = {
    backgroundColor: "#fff",
    borderRadius: 10,
    p: .5,
    mr: 14,
    mt: 14
  };

  const handleChangeFAQ = (value, fieldType, idx) => {
    let FAQArray = [...formData?.faq]
    let faqItem = FAQArray?.find(((faq, _idx) => _idx === idx))
    if (faqItem) {
      const faqItemIdx = FAQArray.indexOf(faqItem)
      faqItem = {
        ...faqItem,
        [fieldType]: value
      }
      FAQArray[faqItemIdx] = faqItem;
      setFormData({ ...formData, faq: FAQArray })
    }

  }

  const addFAQHandler = () => {
    let newFAQ = [...formData?.faq]
    let faqItem = {
      question: '',
      answer: ""
    }
    newFAQ = [...newFAQ, faqItem]
    setFormData({ ...formData, faq: newFAQ })
  }

  const handleRemoveFAQ = (faQIdx) => {
    let FAQArray = [...formData?.faq]
    FAQArray = FAQArray?.filter(((faq, _idx) => _idx !== faQIdx))
    setFormData({ ...formData, faq: FAQArray })
  }

  useEffect(() => {
    setFormData({ ...formData, createdBy: user?.name })
  }, [user])

  useEffect(() => {
    dispatch(fetchCategories({ limit: 1000, skip: 0 }))
  }, [])


  return (

    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
        <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
          <Typography variant='h4' fontWeight="semibold" color="secondary">Add Web Category</Typography>

          <Box display="flex" alignItems="center" gap={1.25}>
            <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
            <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
              <MDButton
                sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                type='button'
                variant={formData?.status === 'active' ? 'contained' : 'text'}
                color={formData?.status === 'active' ? 'success' : 'dark'}
                size="small"
                onClick={() => setFormData({ ...formData, status: 'active' })}
              >
                Active
              </MDButton>
              <MDButton
                sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                type='button'
                variant={formData?.status !== 'active' ? 'contained' : 'text'}
                color={formData?.status !== 'active' ? 'error' : 'dark'}
                size="small"
                onClick={() => setFormData({ ...formData, status: 'inactive' })}
              >
                Inactive
              </MDButton>
            </Stack>
          </Box>
        </Stack>

        <Typography variant='h5' fontWeight="semibold" mb={2}>Web Category Image</Typography>
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1.25} mb={4.5}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
            badgeContent={
              isUploading && uploadedImageRef.current === 'image' ? <CircularProgress size={32} color='primary' sx={badgeCircularProgressIcon} /> : ""
            }
          >
            <img style={uploadThumbnailImage} src={formData?.image || defaultImage} alt="documentId" />
          </Badge>
          <label htmlFor="image-input">
            <MDButton
              sx={{ minWidth: "180px" }}
              type='button'
              variant='outlined'
              color='secondary'
              component='span'
              disabled={isUploading}
            >
              {
                formData?.image ? "Change Image" : "Add Image"
              }
            </MDButton>
          </label>
          <MDInput
            id="image-input"
            type="file"
            name="image"
            onChange={(e) => productImageUploadHandler(e, 'image')}
            fullWidth
            disabled={isUploading}
            style={{ display: 'none' }}
          />
          {Boolean(errors?.image) && <FormHelperText sx={{ width: "100%", ml: 0, mt: -.5 }} error>{errors?.image}</FormHelperText>}
        </Stack>

        <Typography variant='h5' fontWeight="semibold" mb={2}>Banner Image</Typography>
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1.25} mb={4.5}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
            badgeContent={
              isUploading && uploadedImageRef.current === 'bannerImage' ? <CircularProgress size={32} color='primary' sx={badgeCircularProgressIcon} /> : ""
            }
          >
            <img style={uploadThumbnailImage} src={formData?.bannerImage || defaultImage} alt="documentId" />
          </Badge>
          <label htmlFor="bannerImage-input">
            <MDButton
              sx={{ minWidth: "180px" }}
              type='button'
              variant='outlined'
              color='secondary'
              component='span'
              disabled={isUploading}
            >
              {
                formData?.bannerImage ? "Change Image" : "Add Image"
              }
            </MDButton>
          </label>
          <MDInput
            id="bannerImage-input"
            type="file"
            name="bannerImage"
            onChange={(e) => productImageUploadHandler(e, 'bannerImage')}
            fullWidth
            disabled={isUploading}
            style={{ display: 'none' }}
          />
          {Boolean(errors?.bannerImage) && <FormHelperText sx={{ width: "100%", ml: 0, mt: -.5 }} error>{errors?.bannerImage}</FormHelperText>}
        </Stack>

        <Typography variant='h5' fontWeight="semibold" mb={2}>Category Info</Typography>
        <Grid container spacing={2.5} mb={4.5}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              label="Category Name*"
              value={formData.webCategoryName}
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
              name='webCategoryName'
              error={Boolean(errors?.webCategoryName)}
              helperText={errors?.webCategoryName}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Description*</Typography>
            <CommonQuillEditor
              value={formData?.description || ""}
              onChange={(value) => setFormData({ ...formData, description: value })}
            />
            {Boolean(errors?.description) && <FormHelperText error>{errors?.description}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Bottom Text</Typography>
            <CommonQuillEditor
              value={formData?.bottomText || ""}
              onChange={(value) => setFormData({ ...formData, bottomText: value })}
            />
            {Boolean(errors?.bottomText) && <FormHelperText error>{errors?.bottomText}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} >
            <Box display='flex' justifyContent='space-between' alignItems={'center'}>
              <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={2}>FAQ</Typography>
              {
                formData?.faq?.length > 0 && <Button
                  sx={{ minWidth: "140px" }}
                  type='button'
                  onClick={addFAQHandler}
                  variant='contained'
                  color='primary'
                >Add More</Button>
              }
            </Box>
            {
              formData?.faq?.length > 0 ? <>
                {
                  formData?.faq?.map(((faq, idx) => {
                    return <Grid key={idx} item xs={12} sm={12} lg={12} my={2}>
                      <Grid container alignItems='flex-start'>
                        <Grid item xs={12} lg={6} md={6}>
                          <Box display='flex' justifyContent='center' alignItems={'flex-start'}>
                            <Box width={'100%'}>
                              <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Question</Typography>
                              <CommonQuillEditor
                                allowImage={false}
                                value={faq?.question || ""}
                                onChange={(value) => handleChangeFAQ(value, 'question', idx)}
                              />
                            </Box>
                            <Box>
                              <IconButton onClick={() => handleRemoveFAQ(idx)}>
                                <RemoveCircle color='error' />
                              </IconButton>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container alignItems='flex-start' mt={2}>
                        <Grid item xs={12} lg={6} md={6}>
                          <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Answer</Typography>
                          <CommonQuillEditor
                            allowImage={false}
                            value={faq?.answer || ""}
                            onChange={(value) => handleChangeFAQ(value, 'answer', idx)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  }))
                }
              </> : <Box display='flex' justifyContent='space-between' alignItems={'center'} my={3}>
                <Button
                  sx={{ minWidth: "140px" }}
                  type='button'
                  onClick={addFAQHandler}
                  variant='contained'
                  color='primary'
                >Add FAQ</Button>
              </Box>
            }

          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={subCatOptions?.length > 0 ? subCatOptions?.filter((subcat => subcat?.status === 'active')) : []}
              getOptionLabel={(option) => option.categoryName}
              filterSelectedOptions
              onChange={(val, selectedOption, details) => {
                setFormData({ ...formData, adminCategories: selectedOption })
              }}
              value={formData?.adminCategories}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors?.adminCategories)}
                  helperText={errors?.adminCategories}
                  label="Sub Categories"
                  placeholder="Categories"
                />
              )}

            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              className="form-input created-by"
              label="Created By"
              value={formData.createdBy}
              fullWidth
              disabled
              variant="outlined"
              onChange={handleInputChange}
              name='createdBy'
              error={Boolean(errors?.createdBy)}
              helperText={errors?.createdBy}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle fontSize='medium' color='secondary' />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              label="Meta Title*"
              value={formData.metaTitle}
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
              name='metaTitle'
              error={Boolean(errors?.metaTitle)}
              helperText={errors?.metaTitle}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            {/* <TextField
              label="Heading"
              value={formData.heading}
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
              name='heading'
              error={Boolean(errors?.heading)}
              helperText={errors?.heading}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Meta Description*</Typography>
            <CommonQuillEditor
              value={formData?.metaDescription || ""}
              onChange={(value) => setFormData({ ...formData, metaDescription: value })}
            />
            {Boolean(errors?.metaDescription) && <FormHelperText error>{errors?.metaDescription}</FormHelperText>}
          </Grid>

        </Grid>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
          <MDButton
            sx={{ minWidth: "140px" }}
            type='button'
            variant='contained'
            color='primary'
            disabled={isUploading || loading || isCreating}
            onClick={onSubmit}
            loading={isCreating || loading}
          >
            {webCategoryId ? "Update" : " Save"}
          </MDButton>

          {
            webCategoryId && <MDButton
              sx={{ minWidth: "140px", backgroundColor: "#F1F1F1", color: "#FD4438" }}
              type='button'
              variant='contained'
              color='error'
              onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: webCategoryId }, modal: 'DELETE_MODAL' }))}

            >
              Delete
            </MDButton>

          }
          <MDButton
            sx={{ minWidth: "140px" }}
            type='button'
            variant='outlined'
            color='dark'
            onClick={() => navigate('/manageCategories')}
          >
            Cancel
          </MDButton>
        </Stack>
      </Card>
    </Box>
  );
};

export default AddWebCategoryForm;
