import { Avatar, Box, Card, CardContent, Chip, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import React, { useMemo } from 'react'
import StaticsOverview from 'common/StaticsOverview'
import ActiveIcon from 'common/customIcons/active'
import EditIcon from 'common/customIcons/edit'
import InProgressIcon from 'common/customIcons/inprogress'
import AngleRightIcon from 'common/customIcons/angle_right'
import colors from "assets/theme/base/colors";
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getPercentage } from 'utilities/common'
import { formatAddress } from 'utilities/common'
import InActiveIcon from 'common/customIcons/inactive'
import { addSpaceBeforeCapital } from 'utilities/common'
import { formatUsPhoneNumber } from 'utilities/common'
import { capitalizeText } from 'utilities/common'
import { orderDetailStatus } from 'utilities/common'
import defaultProductImage from '../../assets/images/placeholderproduct.svg'
import CartIcon from 'common/customIcons/cart'
import StoreIcon from 'common/customIcons/store'
import InventoryIcon from 'common/customIcons/inventory'
import PaymentIcon from 'common/customIcons/payment'
import { LocalShippingOutlined, SupportAgent } from '@mui/icons-material'
import { closeCustomModalDialog, openCustomModalDialog } from '../../redux/slices/modalSlice'
import { openSnackbar } from '../../redux/slices/common'
import { useDispatch } from 'react-redux'
import { fetchSingleOrder, warrantyStatusUpdate } from '../../redux/slices/orders'

export default function OrderDetails() {
    const { grey } = colors;
    const { order = {} } = useSelector(state => state.orders)
    const { items, userData, orderId, emailStatus = "", deliveryAddress = {}, trackingId = '', serviceName = '' } = order
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isShippingFree = useMemo(() => {
        return order?.subTotal?.toFixed(2) > 100 ? true : false
    }, [order?.subTotal])

    const warrantyStatusHanlder = (status, orderId, reason, returnItems = []) => {
        dispatch(warrantyStatusUpdate({ returnApprovedStatus: status, orderId, approvalComment: reason, products: returnItems })).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success')
            dispatch(fetchSingleOrder(orderId))
            dispatch(closeCustomModalDialog())
        }).catch((err) => {
            showResponseMessage(err?.message, 'error')
        })
    }

    const warrantyDialogHandler = (status, orderId, returnItems = []) => {
        dispatch(openCustomModalDialog({ modal: 'WARRANTY_REASON', modalData: { status, orderId, warrantyStatusHanlder, returnItems } }))
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }


    return (
        <Box p={{ xs: 2, sm: 3, lg: 4 }}>
            <Box onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }} display="flex" alignItems="center" color='text.main' lineHeight={1} mb={3.5}>
                <AngleRightIcon fontSize="14px" />
                <Typography variant='h6' fontWeight="semibold" ml={.75}>Orders</Typography>
            </Box>

            <Paper elevation={0} style={{ borderRadius: "12px" }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="start" justifyContent="space-between" bgcolor="white" p={{ xs: 1, md: 2 }}>
                    <StaticsOverview
                        color="warning"
                        iconShadow="iconShadowWarning"
                        icon={<CartIcon />}
                        title="Order ID"
                        count={orderId}
                    />
                    {
                        trackingId && <StaticsOverview
                            color="primary"
                            iconShadow="iconShadowPrimary"
                            icon={<LocalShippingOutlined />}
                            title="Tracking ID"
                            count={trackingId || '-'}
                        />
                    }
                    {
                        serviceName && <StaticsOverview
                            color="success"
                            iconShadow="iconShadowSuccess"
                            icon={<SupportAgent />}
                            title="Service"
                            count={serviceName || '-'}
                        />
                    }
                    <StaticsOverview
                        color="info"
                        iconShadow="iconShadowInfo"
                        icon={<InventoryIcon />}
                        title="Inventory"
                        count="Safe"
                    />
                    <StaticsOverview
                        color="success"
                        iconShadow="iconShadowSuccess"
                        icon={<StoreIcon />}
                        title="Store"
                        count="Hyperwolf L.E."
                    />
                    <StaticsOverview
                        color="primary"
                        iconShadow="iconShadowInfo"
                        icon={<PaymentIcon />}
                        title="Payment Type"
                        count={(order?.walletPointsUsed > 0 && ((order?.subTotal - (order?.walletPointsUsed || 0)) > 0 ? (order?.subTotal - (order?.walletPointsUsed || 0))?.toFixed(2) : 0) === 0) ? 'Wallet' : (order?.walletPointsUsed > 0 && (order?.subTotal - order?.walletPointsUsed) > 0) ? 'Credit/Wallet' : order?.paymentOption}
                    />
                    <StaticsOverview
                        color="#8E0CF5"
                        iconShadow="iconShadowViolet"
                        icon={<InProgressIcon />}
                        title="Status"
                        count={orderDetailStatus?.find((status => status.key === emailStatus))?.value}
                    />
                </Stack>
            </Paper>

            <Grid container spacing={2.4} my={3}>
                <Grid item xs={12} md={7}>
                    <Card variant='detailsCard' sx={{ height: '100%', justifyContent: 'space-between' }}>
                        <CardContent sx={{ p: { xs: 2, md: 3 }, pb: 0 }}>
                            {
                                order?.returnItems?.length > 0 && <>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} mb={3}>
                                        <Typography variant='h4'>Return Items</Typography>
                                        {
                                            order?.warrantyStatus === 'approved' && <Box color="#24CA49" display="flex" alignItems="center">
                                                <ActiveIcon width="16px" height="16px" flex="none" />
                                                <Typography variant='body2' fontWeight="medium" ml={.5} textTransform="capitalize">Approved</Typography>
                                            </Box>
                                        }

                                        {
                                            order?.warrantyStatus === 'rejected' && <Box color="#FD4438" display="flex" alignItems="center">
                                                <InActiveIcon width="16px" height="16px" flex="none" />
                                                <Typography variant='body2' fontWeight="medium" ml={.5} textTransform="capitalize">Decline</Typography>
                                            </Box>
                                        }

                                        {
                                            !order?.warrantyStatus && <Box display="inline-flex" gap={1} className="invite-action">
                                                <MDButton onClick={() => warrantyDialogHandler(true, orderId, order?.returnItems)} variant='outlined' size='small' color='success' sx={{ fontWeight: 500 }}>Approve</MDButton>
                                                <MDButton onClick={() => warrantyDialogHandler(false, orderId, order?.returnItems)} variant='outlined' size='small' color='error' sx={{ fontWeight: 500 }}>Decline</MDButton>
                                            </Box>
                                        }

                                    </Stack>

                                    <List>
                                        {

                                            order?.items?.filter((orderProduct => order?.returnItems?.find((returnProduct => returnProduct?.productId === orderProduct?.product?.productId))))?.map((product => {
                                                const { productId, productImages, userData = {}, productName = "-", category, isSalePrice, salePrice, unitPrice, brandName, customWeight="" } = product?.product
                                                    || {}
                                                const { quantity } = product
                                                return <ListItem key={productId} alignItems="center" sx={{ mt: 2.5 }}>
                                                    <ListItemAvatar sx={{ mr: 1.5 }} className="pro-avatar-sec">
                                                        <Avatar
                                                            alt="Product Name"
                                                            src={productImages?.[0] || defaultProductImage}
                                                            sx={{ width: 82, height: 82, borderRadius: 4 }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <React.Fragment>
                                                                <Typography variant='body2' color="secondary">{brandName}</Typography>
                                                                <Link to={`/products/details/${productId}`}>
                                                                    <Typography variant="h6" fontWeight="semibold">{productName}</Typography>
                                                                </Link>
                                                                <Typography variant='body2' color="secondary">
                                                                    <span className='mr-3'>Category: <span style={{ color: "#000000" }}>{category?.categoryName}</span></span>
                                                                    <span>Wt: <span style={{ color: "#000000" }}>{customWeight}</span></span>
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography variant="h6" fontWeight="medium" component="span" mr={1}>${isSalePrice ? salePrice : unitPrice}</Typography>
                                                                {
                                                                    isSalePrice && <Typography variant="h6" fontWeight="medium" component="span" color="secondary" mr={1} sx={{ textDecoration: "line-through" }}>${unitPrice}</Typography>
                                                                }
                                                                {
                                                                    isSalePrice && getPercentage(unitPrice, salePrice) > 0 && <Typography variant="h6" fontWeight="medium" component="span" color="success.main">{getPercentage(unitPrice, salePrice)}% Off</Typography>
                                                                }

                                                                {
                                                                    order?.promotionData?.promoName && order?.promotionData?.promotionType?.toLowerCase() === 'product' && isSalePrice &&
                                                                    <Box mt={1}>
                                                                        <Chip label="Promo Applied" variant="outlined" color='warning' className='promo-chip chip-square' disabled />
                                                                    </Box>
                                                                }

                                                            </React.Fragment>
                                                        }
                                                    />
                                                    <Box textAlign="end">
                                                        {
                                                            order?.warrantyStatus === 'rejected' ? <Chip label="Rejected" variant="outlined" color='error' className='promo-chip chip-square' /> : order?.warrantyStatus === 'approved' ? <Chip label="Returned" variant="outlined" color='success' className='promo-chip chip-square' /> : null
                                                        }
                                                        <Typography variant="h6" fontWeight="medium" mt={5}>x{quantity}</Typography>
                                                    </Box>

                                                </ListItem>
                                            }))
                                        }

                                    </List>

                                    {
                                        order?.returnComments && <Box mt={3} border={1} borderColor="#9A9AAF" borderRadius={4}>
                                            <CardContent sx={{ px: 3, py: 2 }}>
                                                <Typography variant='h6' fontWeight="semibold" mb={0}>Return Request Reason</Typography>
                                                <Typography variant='body2'>{order?.returnComments}</Typography>
                                            </CardContent>
                                        </Box>
                                    }
                                    <Divider sx={{ my: 3 }} />

                                </>
                            }

                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} mb={3}>
                                <Typography variant='h4'>Order Details</Typography>
                                {
                                    (emailStatus !== 'delivered' && emailStatus !== 'cancelled') && <Link to={`/orders/${orderId}`}>
                                        <MDButton
                                            type='button'
                                            variant='outlined'
                                            color='dark'
                                            size="small"
                                            startIcon={<EditIcon width='15px' height='15px' />}
                                            sx={{ borderRadius: 1.5 }}
                                        >
                                            Edit
                                        </MDButton>
                                    </Link>
                                }
                            </Stack>

                            <Typography variant='h6' fontWeight="semibold" mb={1}>Product</Typography>
                            <List>
                                {
                                    items?.map((productItem => {
                                        console.log(productItem?.product)
                                        const { productId, productImages, userData = {}, productName = "-", category, isSalePrice, salePrice, unitPrice, brandName, customWeight="" } = productItem?.product
                                            || {}
                                        const { quantity = 0 } = productItem
                                        return <ListItem key={productId} alignItems="center" sx={{ mt: 2.5 }}>
                                            <ListItemAvatar sx={{ mr: 1.5 }} className="pro-avatar-sec">
                                                <Avatar
                                                    alt="Product Name"
                                                    src={productImages?.[0] || defaultProductImage}
                                                    sx={{ width: 82, height: 82, borderRadius: 4 }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <React.Fragment>
                                                        <Typography variant='body2' color="secondary">{brandName}</Typography>
                                                        <Link to={`/products/details/${productId}`}>
                                                            <Typography variant="h6" fontWeight="semibold">{productName}</Typography>
                                                        </Link>
                                                        <Typography variant='body2' color="secondary">
                                                            <span className='mr-3'>Category: <span style={{ color: "#000000" }}>{category?.categoryName}</span></span>
                                                            <span>Wt: <span style={{ color: "#000000" }}>{customWeight}</span></span>
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography variant="h6" fontWeight="medium" component="span" mr={1}>${isSalePrice ? salePrice : unitPrice}</Typography>
                                                        {
                                                            isSalePrice && <Typography variant="h6" fontWeight="medium" component="span" color="secondary" mr={1} sx={{ textDecoration: "line-through" }}>${unitPrice}</Typography>
                                                        }
                                                        {
                                                            isSalePrice && getPercentage(unitPrice, salePrice) > 0 && <Typography variant="h6" fontWeight="medium" component="span" color="success.main">{getPercentage(unitPrice, salePrice)}% Off</Typography>
                                                        }

                                                        {
                                                            order?.promotionData?.promoName && order?.promotionData?.promotionType?.toLowerCase() === 'product' && isSalePrice &&
                                                            <Box mt={1}>
                                                                <Chip label="Promo Applied" variant="outlined" color='warning' className='promo-chip chip-square' disabled />
                                                            </Box>
                                                        }

                                                    </React.Fragment>
                                                }
                                            />
                                            <Typography variant="h6" fontWeight="medium" mt={5}>x{quantity}</Typography>
                                        </ListItem>
                                    }))
                                }
                            </List>

                            <Divider sx={{ my: 3 }} />

                            <Typography variant='h6' fontWeight="semibold" mb={1}>Order Summary</Typography>
                        </CardContent>

                        <List sx={{ py: 2, background: "#F1F1F1" }}>
                            <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography variant="h6" fontWeight="medium" color="secondary">Subtotal</Typography>
                                        </React.Fragment>
                                    }
                                />
                                <Typography variant="h5" fontWeight="semibold">${order?.subTotal?.toFixed(2)}</Typography>
                            </ListItem>
                            <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography variant="h6" fontWeight="medium" color="secondary">Shipping</Typography>
                                        </React.Fragment>
                                    }
                                />
                                {
                                    !order?.shippingCharges ? <Typography variant="h5" fontWeight="semibold" color="success.main">Free</Typography> : <Typography variant="h5" fontWeight="semibold" >${order?.shippingCharges}</Typography>
                                }
                            </ListItem>

                            <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography variant="h6" fontWeight="medium" color="secondary">Shipping Type</Typography>
                                        </React.Fragment>
                                    }
                                />
                                <Typography variant="h5" fontWeight="semibold" >{order?.shippingType ? capitalizeText(order?.shippingType) : "-"}</Typography>
                            </ListItem>

                            <Divider sx={{ my: 1 }} />

                            <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography variant="h6" fontWeight="medium" color="secondary">Total</Typography>
                                        </React.Fragment>
                                    }
                                />
                                <Typography variant="h5" fontWeight="bold">${(order?.subTotal + order?.shippingCharges)?.toFixed(2)}</Typography>

                            </ListItem>
                            {
                                order?.promotionData?.promoName && order?.promotionData?.promoDiscount && order?.promotionData?.promotionType !== 'Product' &&
                                <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Box display='flex' gap={0.5}>
                                                    <Typography variant="h6" fontWeight="medium" color="success.main">{order?.promotionData?.promoName} </Typography>
                                                    <Typography variant="h6" fontWeight="medium" color="secondary" >(Promo code)</Typography>
                                                </Box>
                                            </React.Fragment>
                                        }
                                    />
                                    <Typography variant="h5" fontWeight="semibold" color="success.main">-${order?.promotionData?.promoDiscount?.toFixed(2)}</Typography>

                                </ListItem>
                            }
                            {
                                order?.afterTaxDiscount > 0 && <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography variant="h6" fontWeight="medium" color="secondary">  Rewards Discount</Typography>
                                            </React.Fragment>
                                        }
                                    />
                                    <Typography variant="h6" style={{ color: '#28B446' }}>
                                        -(${(parseFloat(order && order.afterTaxDiscount).toFixed(2)) || 0})
                                    </Typography>
                                </ListItem>
                            }
                            {
                                order?.walletPointsUsed > 0 && <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography variant="h6" fontWeight="medium" color="secondary">Wallet deducted amount</Typography>
                                            </React.Fragment>
                                        }
                                    />
                                    <Typography variant="h5" fontWeight="semibold" >-${order?.walletPointsUsed?.toFixed(2)}</Typography>
                                </ListItem>
                            }
                            <Divider sx={{ my: 1 }} />

                            <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography variant="h6" fontWeight="medium">Total Amount Paid</Typography>
                                        </React.Fragment>
                                    }
                                />
                                <Typography variant="h5" fontWeight="bold">${order?.total ? order?.total?.toFixed(2) : 0}</Typography>

                            </ListItem>
                        </List>
                        <CardContent sx={{ p: { xs: 2, md: 3 } }}>
                            <Typography variant='h6' fontWeight="semibold" mb={1}>Delivery Address</Typography>
                            <Typography variant='h6' fontWeight="regular" lineHeight="1.85">
                                {
                                    deliveryAddress?.apartment && <>
                                        <b style={{ fontWeight: 600 }}>Apartment Number: </b>{deliveryAddress.apartment || '-'} <br/>
                                    </> 
                                    
                                }
                                <b style={{ fontWeight: 600 }}>Address: </b>{deliveryAddress?.address || '-'} <br />
                                <b style={{ fontWeight: 600 }}>City: </b>{deliveryAddress.city || '-'} <br />
                                <b style={{ fontWeight: 600 }}>State: </b>{deliveryAddress.state || '-'} <br />
                                <b style={{ fontWeight: 600 }}>Zip Code: </b>{deliveryAddress.zipcode || '-'}<br />

                            </Typography>
                        </CardContent>
                        {
                            order?.memo && <CardContent sx={{ p: { xs: 2, md: 3 } }}>
                                <Typography variant='h6' fontWeight="semibold" mb={1}>Instructions</Typography>
                                <Typography variant='body2'>{order?.memo}</Typography>
                            </CardContent>
                        }
                    </Card>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Card variant='detailsCard' sx={{ height: '100%', justifyContent: 'space-between' }}>
                        <CardContent sx={{ p: { xs: 2, md: 3 } }}>
                            <Typography variant='h4' mb={2}>Member Details</Typography>

                            <Grid container alignItems="center" spacing={3.5}>
                                <Grid item xs={12}>
                                    <Typography variant='caption' color={grey[600]}>Name</Typography>
                                    <Link to={`/members/detail/${userData?.memberId}`}>
                                        <Typography variant='h6' fontWeight="medium" lineHeight="1.25">{userData?.fullName}</Typography>
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='caption' color={grey[600]}>Member Address</Typography>
                                    {
                                        userData?.deliveryAddress ? <Typography variant='h6' fontWeight="medium" lineHeight="1.25">{formatAddress({ ...userData?.deliveryAddress, zipcode: userData?.deliveryAddress?.zipcode })}</Typography> : <Typography variant='h6' fontWeight="medium" lineHeight="1.25">-</Typography>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='caption' color={grey[600]}>Phone</Typography>
                                    <Typography variant='h6' fontWeight="medium" lineHeight="1.25">+1 {userData?.phone ? formatUsPhoneNumber(userData?.phone) : "-"}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='caption' color={grey[600]}>Email</Typography>
                                    <Typography variant='h6' fontWeight="medium" lineHeight="1.25">{userData?.email}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='caption' color={grey[600]}>Member Type</Typography>
                                    <Typography variant='h6' fontWeight="medium" lineHeight="1.25">{userData?.memberType ? addSpaceBeforeCapital(userData?.memberType) : "-"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='caption' color={grey[600]}>Member Group</Typography>
                                    <Typography variant='h6' fontWeight="medium" lineHeight="1.25">{userData?.memberShipGroup || '-'}</Typography>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
