import OrderDetails from 'components/orders/OrderDetails'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSingleOrder } from '../../../redux/slices/orders'

export default function OrderDetailsPage() {
  const dispatch = useDispatch()
  const { orderId = "" } = useParams()
  useEffect(() => {
    if (orderId) {
      dispatch(fetchSingleOrder(orderId))
    }
  }, [orderId])
  return (
    <CommonLayout>
      <OrderDetails />
    </CommonLayout>
  )
}
