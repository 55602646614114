export function regionValidation(data) {
    const errors = {};

    // Validate openingHours
    // if (!data.openingHours || data.openingHours === null || data.openingHours === undefined) {
    //     errors.openingHours = 'Opening hours are required.';
    // }

    // // Validate closingHours
    // if (!data.closingHours || data.closingHours === null || data.closingHours === undefined) {
    //     errors.closingHours = 'Closing hours are required.';
    // }

    // Validate openingHours
    // if (data.openingHours && !isValidTimeFormat(data.openingHours)) {
    //     errors.openingHours = 'Opening hours are not a valid time.';
    // }

    // // Validate closingHours
    // if (data.closingHours && !isValidTimeFormat(data.closingHours)) {
    //     errors.closingHours = 'Closing hours are not a valid time.';
    // }


    // Validate regionName
    if (!data.regionName || data.regionName.trim() === '' || data.regionName === undefined) {
        errors.regionName = 'Region name is required.';
    }

    // Validate uploadedKML
    if (!data.uploadedKML || data.uploadedKML.trim() === '' || data.uploadedKML === undefined) {
        errors.uploadedKML = 'Uploaded KML is required.';
    }
    const isValid = Object.keys(errors).length === 0;

    return { errors, isValid };
}

function isValidTimeFormat(time) {
    // Parse the time string into a Date object
    const parsedTime = new Date(`2000-01-01T${time}`);
  
    // Check if the parsed time is a valid Date and the time components are valid
    return !isNaN(parsedTime.getTime()) && parsedTime.toISOString().includes(time);
  }