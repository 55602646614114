import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function deleteCategoryAPI(cateogryId) {
    return await axiosClient.delete(`/api/v1/admin/category/${cateogryId}`);
}

export async function createCategoryAPI(data) {
    return await axiosClient.post(`/api/v1/admin/category`, data);
}

export async function updateCategoryAPI(data, cateogryId) {
    return await axiosClient.put(`/api/v1/admin/category/update/${cateogryId}`, data);
}

export async function fetchCategoryDetailsAPI(cateogryId) {
    return await axiosClient.get(`/api/v1/admin/category/${cateogryId}`);
}

export async function fetchWebCategoriesAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/category/web?${getQueryString(filters)}`);
}

export async function createWebCategoryAPI(data) {
    return await axiosClient.post(`/api/v1/admin/category/create/update`, data);
}

export async function deleteWebCategoryAPI(webCategoryId) {
    return await axiosClient.delete(`/api/v1/admin/category/web/${webCategoryId}`);
}
export async function fetchWebCategoryDetailsAPI(webCategoryId) {
    return await axiosClient.get(`/api/v1/admin/category/web/${webCategoryId}`);
}

export async function updateWebCategoryOrdersAPI(data) {
    return await axiosClient.post('/api/v1/admin/category/update/multiple',data);
}