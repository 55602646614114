import React, { useEffect } from 'react'
import CommonLayout from 'layouts/DashboardLayout'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AddCannabinoidBannerForm from 'components/cannabinoids/AddCannabinoidBannerForm'
import { getSingleCannabinoidBanner } from '../../../../redux/slices/cannabinoids'

export default function AddCannabinoidBanner() {
    const { bannerId } = useParams()
    const dispatch = useDispatch()
    useEffect(() => {
        if (bannerId) {
            dispatch(getSingleCannabinoidBanner(bannerId)).unwrap().then(() => { }).catch(err => { })
        }
    }, [bannerId])
    return (
        <CommonLayout>
            <AddCannabinoidBannerForm />
        </CommonLayout>
    )
}
