import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, Card, Typography, Container, FormControl, InputLabel, Select, MenuItem, FormHelperText, CircularProgress, Box, Stack, Badge, InputAdornment } from '@mui/material';
import MDButton from 'components/MDButton';
import validateTerpenoid from '../../validations/terpenoids';
import { useDispatch, useSelector } from 'react-redux';
import { createTerpenoid, deleteTerpenoid, updateTerpenoid } from '../../redux/slices/terpenoids';
import { useNavigate, useParams } from 'react-router';
import { openSnackbar, uploadImage } from '../../redux/slices/common';
import { isEmptyObject } from 'utilities/common';
import defaultImage from 'assets/images/placeholder-image.png';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import { AccountCircle } from '@mui/icons-material';

const AddTerpenoidForm = () => {
    const { user } = useSelector(state => state.auth)
    const { terpeneDetails = {}, isLoading = false, } = useSelector(state => state.terpenoids)
    const { isUploading = false } = useSelector(state => state.common)
    const { terpenoidId = "" } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        terpeneName: '',
        status: 'active',
        createdBy: user?.name || "",
        terpeneImage: null, // Changed to a file object
    });
    const [errors, setErrors] = useState()

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const terpenoidImageUploadHandler = async (e) => {
        if (e.target.files.length) {
            const file = e.target.files[0];
            const body = new FormData()
            body.append("filePath", file);
            // res.fileUrl
            dispatch(uploadImage(body)).unwrap().then((res => {
                showError(res?.message, 'success')
                setFormData({ ...formData, terpeneImage: res.fileUrl })
            })).catch((err) => {
                showError(err?.message, 'error')
            })
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const { isValid, errors } = validateTerpenoid(formData)
        if (!isValid) {
            setErrors(errors)
            return;
        }
        setErrors({})
        if (terpenoidId) {
            dispatch(updateTerpenoid({ data: formData, terpenoidId })).unwrap().then((res) => {
                showError(res?.message, 'success')
                navigate('/terpenoids')
            }).catch((err) => {
                showError(err?.message, 'success')
            })
        } else {
            dispatch(createTerpenoid({ data: formData })).unwrap().then((res) => {
                showError(res?.message, 'success')
                navigate('/terpenoids')
            }).catch((err) => {
                showError(err?.message, 'error')
            })
        }

    };

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }


    const deleteHandler = (terpeneId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteTerpenoid(terpeneId)).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            navigate('/terpenoids')
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    useEffect(() => {
        if (!isEmptyObject(terpeneDetails)) {
            setFormData({ ...formData, ...terpeneDetails })
        }
    }, [terpeneDetails])

    const uploadThumbnailImage = {
        borderRadius: "20px",
        width: "160px",
        height: "160px",
        objectFit: "cover",
        boxShadow: "0 0 1px rgb(0 0 0 / 20%)"
    };
    const badgeCircularProgressIcon = {
        backgroundColor: "#fff",
        borderRadius: 10,
        p: .5,
        mr: 14,
        mt: 14
    };

    useEffect(() => {
        setFormData({ ...formData, createdBy: user?.name })
    }, [user])


    return (
        <Box p={{ xs: 2, sm: 3, lg: 4 }}>
            <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
                    <Typography variant='h4' fontWeight="semibold" color="secondary">{terpenoidId ? 'Update' : "Add"} Terpene</Typography>

                    <Box display="flex" alignItems="center" gap={1.25}>
                        <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
                        <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
                            <MDButton
                                sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                                type='button'
                                variant={formData?.status === 'active' ? 'contained' : 'text'}
                                color={formData?.status === 'active' ? 'success' : 'dark'}
                                size="small"
                                onClick={() => setFormData({ ...formData, status: 'active' })}
                            >
                                Active
                            </MDButton>
                            <MDButton
                                sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                                type='button'
                                variant={formData?.status !== 'active' ? 'contained' : 'text'}
                                color={formData?.status !== 'active' ? 'error' : 'dark'}
                                size="small"
                                onClick={() => setFormData({ ...formData, status: 'inactive' })}
                            >
                                Inactive
                            </MDButton>
                        </Stack>
                    </Box>
                </Stack>

                <Typography variant='h5' fontWeight="semibold" mb={2}>Terpene Image*</Typography>
                <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1.25} mb={4.5}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                        badgeContent={
                            isUploading ? <CircularProgress size={32} color='primary' sx={badgeCircularProgressIcon} /> : ""
                        }
                    >
                        <img style={uploadThumbnailImage} src={formData?.terpeneImage || defaultImage} alt="documentId" />
                    </Badge>

                    <label htmlFor="terpeneImageInput">
                        <MDButton
                            sx={{ minWidth: "180px" }}
                            type='button'
                            variant='outlined'
                            color='secondary'
                            component='span'
                        >
                            {!formData?.terpeneImage ? 'Add' : 'Change'} Image
                        </MDButton>
                    </label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={terpenoidImageUploadHandler}
                        style={{ display: 'none' }}
                        id="terpeneImageInput"
                    />
                    {
                        errors?.terpeneImage && <FormHelperText sx={{ width: "100%", ml: 0, mt: -.5 }} error>{errors?.terpeneImage}</FormHelperText>
                    }
                </Stack>

                <Typography variant='h5' fontWeight="semibold" mb={2}>Terpene Info</Typography>
                <Grid container spacing={2.5} mb={4}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Terpene Name*"
                            fullWidth
                            variant="outlined"
                            name="terpeneName"
                            value={formData.terpeneName}
                            onChange={handleInputChange}
                            error={Boolean(errors?.terpeneName)}
                            helperText={errors?.terpeneName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            className="form-input created-by"
                            label="Created By"
                            fullWidth
                            variant="outlined"
                            name="createdBy"
                            disabled
                            value={formData.createdBy}
                            onChange={handleInputChange}
                            error={Boolean(errors?.createdBy)}
                            helperText={errors?.createdBy}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle fontSize='medium' color='secondary' />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                        <TextField className="form-input" fullWidth label="Terpene ID" name="terpeneID" />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            label="Sub-text"
                            fullWidth
                            variant="outlined"
                            name="subText"
                            value={formData?.subText || ""}
                            onChange={handleInputChange}
                            error={Boolean(errors?.subText)}
                            helperText={errors?.subText}
                            className="form-input"
                            multiline rows={4}
                        />
                        {/* <TextField className="form-input" fullWidth multiline rows={4} label="Sub-text" name="subText" /> */}
                    </Grid>
                </Grid>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
                    <MDButton
                        sx={{ minWidth: "140px" }}
                        type='button'
                        variant='contained'
                        color='primary'
                        onClick={handleSubmit}
                        loading={isLoading}
                    >
                        {terpenoidId ? 'Update' : "Save"}
                    </MDButton>

                    {
                        terpenoidId && <MDButton
                            sx={{ minWidth: "140px", backgroundColor: "#F1F1F1", color: "#FD4438" }}
                            type='button'
                            variant='contained'
                            color='error'
                            onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: terpenoidId }, modal: 'DELETE_MODAL' }))}

                        >
                            Delete
                        </MDButton>
                    }

                    <MDButton
                        sx={{ minWidth: "140px" }}
                        type='button'
                        variant='outlined'
                        color='dark'
                        onClick={() => navigate('/terpenoids')}
                    >
                        Cancel
                    </MDButton>
                </Stack>
            </Card>
        </Box>
    );
};

export default AddTerpenoidForm;
