import { Close } from '@mui/icons-material'
import { Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import React from 'react'

export default function PickupTask() {
    return (
        <div style={{ color: 'white' }}>
            <Box padding={1}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography className='text-14' fontWeight={600}>Create Pick Up Task</Typography>
                    <Close />
                </Stack>
            </Box>
            <Divider sx={{ background: 'white' }} />
            <Box padding={1.5}>
                <Typography my={1} fontWeight={600}>Importance of Task</Typography>
                <FormControl>
                    <FormLabel sx={{ fontSize: 16 }} color='secondary' id="demo-row-radio-buttons-group-label">If the order is highly critical and cannot be delayed click the checkbox</FormLabel>
                    <RadioGroup
                        sx={{ mt: 1 }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel value="critical" control={<Radio color='error' />} label="Critical" />
                        <FormControlLabel value="high" control={<Radio />} label="High" />
                        <FormControlLabel value="medium" control={<Radio />} label="Medium" />
                        <FormControlLabel value="low" control={<Radio />} label="Low" />
                    </RadioGroup>
                </FormControl>
                <Stack direction='column' gap={2} my={2}>
                    <Typography fontWeight={600} className='text-16'>Task Info</Typography>
                    <MDInput
                        fullWidth
                        name='task_id'
                        label='Task Id'
                    />
                    <MDInput
                        fullWidth
                        name='task_id'
                        label='Pickup Location'
                    // multiline
                    // rows={3}
                    />
                </Stack >
                <Stack direction='column' gap={2} my={2}>
                    <Box>
                        <Typography fontWeight={600} className='text-16'>Task Window</Typography>
                        <Typography className='text-12' color={grey[600]} fontWeight={600} >For tasks with a schedule or estimated fullfillment time.</Typography>
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <MDInput
                                fullWidth
                                name='start'
                                label='Start'
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <MDInput
                                fullWidth
                                name='end'
                                label='End'
                            />
                        </Grid>
                    </Grid>
                </Stack >
                <Stack direction='column' gap={2} my={2}>
                    <Box>
                        <Typography fontWeight={600} className='text-16'>Item Details</Typography>
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <MDInput
                                fullWidth
                                name='item_description'
                                label='Description of the item(s) to be picked up'
                                className="form-input"
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <MDInput
                                fullWidth
                                name='special_instructions'
                                label='Special Instructions'
                                className="form-input"
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
            <Divider />
            <Stack direction='row' gap={2} px={2} alignItems='center' >
                <MDButton sx={{ padding: '10px 100px' }}>Cancel</MDButton>
                <MDButton sx={{ padding: '10px 100px' }} color='primary'>Create Task</MDButton>

            </Stack>
        </div>
    )
}
