import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { bulkProductUpdate, openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog } from '../../redux/slices/modalSlice';
import { isNumber } from 'utilities/common';

export default function BulkProductTagsUpdate({ closeModal }) {
    const { modalData, modal, isLoading = false } = useSelector(state => state.modal)
    const { isDeleting = false } = useSelector(state => state.common)
    const [tags, setTags] = useState([])
    const [errors, setErrors] = useState({})
    const { brandsData = {} } = useSelector(state => state.brands)

    const { bulkProductIds, resetData = () => { } } = modalData
    const dispatch = useDispatch();


    const handleBulkUpdate = () => {
        if (!tags) {
            setErrors({ tags: 'Tags should not be empty' })
            return;
        }
        setErrors({})
        dispatch(bulkProductUpdate({ productIds: bulkProductIds, tags: tags?.split(',') })).unwrap().then((res => {
            dispatch(openSnackbar({ content: res?.message, color: 'success', icon: 'check' }))
            dispatch(closeCustomModalDialog({}))
            resetData()
        })).catch((err => {
            dispatch(openSnackbar({ content: err?.message, color: 'error', icon: 'warning' }))
            console.log("err", err)
        }))
    }
    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
                Update Product Tags
                <IconButton onClick={closeModal} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {/* <DialogContentText>
                    Are you sure you want to delete this item?
                </DialogContentText> */}
                <Box my={2}>
                    <TextField error={Boolean(errors?.tags)} helperText={errors?.tags} value={tags} fullWidth name='tags' placeholder='Tags' onChange={(e) => {
                        setTags(e.target.value)
                    }} />
                </Box>
            </DialogContent>

            <DialogActions>
                <MDButton onClick={closeModal} size="small" color="secondary">
                    Cancel
                </MDButton>
                <MDButton onClick={handleBulkUpdate} size="small" color='error'>
                    {
                        isLoading || isDeleting ? <CircularProgress size={16} color='light' /> : " Update"
                    }

                </MDButton>
            </DialogActions>
        </>
    )
}
