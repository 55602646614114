import React from 'react'

export default function StoreIcon({width = '20px', height = '20px', flex = 'none'}) {
  return (
    <svg style={{width, height, flex}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.1257 7.00457L18.0914 2.86514C17.9646 2.35657 17.5074 2 16.9828 2H3.01826C2.49369 2 2.03655 2.35657 1.90855 2.86514L0.87426 7.00457C0.862831 7.04915 0.857117 7.096 0.857117 7.14286C0.857117 8.71772 2.07426 10 3.5714 10C4.44112 10 5.21712 9.56686 5.71426 8.89486C6.2114 9.56686 6.9874 10 7.85712 10C8.72683 10 9.50283 9.56686 9.99998 8.89486C10.4971 9.56686 11.272 10 12.1428 10C13.0137 10 13.7885 9.56686 14.2857 8.89486C14.7828 9.56686 15.5577 10 16.4286 10C17.9257 10 19.1428 8.71772 19.1428 7.14286C19.1428 7.096 19.1371 7.04915 19.1257 7.00457Z" fill="currentColor"/>
      <path d="M16.4285 11.1429C15.6502 11.1429 14.9108 10.9051 14.2857 10.4709C13.0354 11.3406 11.2502 11.3406 9.99994 10.4709C8.74966 11.3406 6.96451 11.3406 5.71423 10.4709C5.08908 10.9051 4.34965 11.1429 3.57137 11.1429C3.01023 11.1429 2.48108 11.0114 1.99994 10.7874V16.8571C1.99994 17.488 2.51194 18 3.1428 18H7.71423V13.4286H12.2857V18H16.8571C17.4879 18 17.9999 17.488 17.9999 16.8571V10.7874C17.5188 11.0114 16.9897 11.1429 16.4285 11.1429Z" fill="currentColor"/>
    </svg>
  )
}
