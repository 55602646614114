import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setLayout } from "../../../redux/slices/materialUISlice";
import { useDispatch, useSelector } from "react-redux";

// Images
import { Box, Button, Card, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { forgotPassword, loginUser } from "../../../redux/slices/authSlice";
import { openSnackbar } from "../../../redux/slices/common";
import hyperwolfLogo from "assets/images/hyperwolf.svg";
import authImg from "assets/images/auth.jpg";
import colors from "assets/theme/base/colors";

function validateEmail(email) {
  // Check if email is empty
  if (!email) {
    return false; // Email is empty, return false
  }

  // Regular expression pattern for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if email matches the regular expression pattern
  return emailRegex.test(email);
}


function Basic() {
  const navigate = useNavigate()
  const [rememberMe, setRememberMe] = useState(false);
  const { logging = false, loading = false } = useSelector(state => state.auth)
  // const { instance, accounts, inProgress } = useMsal();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({})
  const [error, setErrors] = useState("")

  const handleSubmit = async () => {
    let isValid = true
    if (!userData?.email) {
      setErrors("Email is required")
      isValid = false
    }
    if (userData?.email && !validateEmail(userData?.email)) {
      isValid = false
      setErrors("Enter valid email")
    }
    if (!isValid) return;
    setErrors("")
    dispatch(forgotPassword(userData)).unwrap().then((res) => {
      navigate('/login')
      showError(res?.message, 'success')
    }).catch((err) => {
      showError(err?.message, 'error')
    })
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }


  // const isAuthenticated = useIsAuthenticated();
  // const handleLogout = () => {
  //   console.log(isAuthenticated, "isAuthenticated")
  //   // logout();
  //   if (isAuthenticated)
  //     instance.logoutRedirect()
  //   console.log('Logged out');
  // };

  // const handleGetToken = async () => {
  //   console.log(instance, accounts, inProgress, "accounts")

  // };
  const onChangeHandler = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setLayout("singup"))
  }, [pathname]);

  const { primary } = colors;

  return (
    <Grid className="auth-pg" alignItems="center" container>
      <Grid item xs={12} md={6} p={2.5} pt={{ xs: 8, md: 2.5 }}>
        <Stack textAlign="center" direction="column" alignItems="center" justifyContent="center" maxWidth="500px" mx="auto" height="100%">
          <Link to='/login'>
            <img src={hyperwolfLogo} alt="HYPERWOLF" height="32px" />
          </Link>

          <Box textAlign="center" mt={6} mb={4}>
            <Typography variant="h3" fontWeight="semibold">Forgot Password</Typography>
            {/* <Typography variant="body1" mt={1}>Welcome! Please Sign In to continue.</Typography> */}
          </Box>

          <TextField error={Boolean(error)} helperText={error} fullWidth label='Enter Email' onChange={onChangeHandler} name="email" />

          <MDButton onClick={handleSubmit} sx={{ mt: 3, minWidth: "160px" }} disabled={loading} variant="contained" color="primary">Submit</MDButton>

          <Typography mt={2} variant='h5' fontWeight="medium">
            Back to login? &nbsp;
            <Link to='/login' style={{color: primary.main}}>Log in</Link>
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6} p={2.5}>
        <img src={authImg} alt="HYPERWOLF" className="auth-banner-img" />
      </Grid>
    </Grid>
  );
}

export default Basic;
