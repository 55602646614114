import MainBrandForm from 'components/brands/mainBrandForm'
import CommonLayout from 'layouts/DashboardLayout'

export default function AddMainBrand() {
  return (
    <CommonLayout>
      <MainBrandForm />
    </CommonLayout>
  )
}
