import { Badge, List, ListItem, Typography } from '@mui/material'
import StatusTask from 'common/component/StatusTask'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getDriverAnalyticsInfo } from '../../../redux/slices/hyperdrive/driverAnalytics';
import { useDispatch } from 'react-redux';
import SkeletonText from 'common/component/SkeletonText';
import { formatTimestampToDateView } from 'utilities/common';
import { openCustomDrawer } from '../../../redux/slices/modalSlice';

const DriverTask = ({ driverId }) => {
    const dispatch = useDispatch()
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();

    const loadMorePosts = useCallback(async () => {
        setLoading(true);
        try {
            const limit = 20;
            const skip = page * limit;
            const res = await dispatch(getDriverAnalyticsInfo({
                driverId,
                data: {
                    analyticType: "tasks",
                    skip,
                    limit: 20
                }
            })).unwrap();
            let newPosts = res?.data?.tasks
            console.log("posts res?.data?.tasks", res?.data);

            if (newPosts?.length === 0) {
                setHasMore(false);
            } else {
                setPosts((prevPosts) => [...prevPosts, ...newPosts]);
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [dispatch, page]);

    useEffect(() => {
        if (hasMore) {
            loadMorePosts();
        }
    }, [loadMorePosts, hasMore]);

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading || !hasMore) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );
    const onRowClicked = useCallback((data) => {
        dispatch(openCustomDrawer({
            drawer: 'TASK_DETAIL',
            drawerData: data?._id
        }))
    }, []);

    return (
        <List className="d-flex flex-column gap-3">
            {
                posts ? <>
                    {
                        posts?.map((data, idx) => {
                            return (
                                <ListItem
                                    onClick={() => onRowClicked(data)}
                                    ref={posts.length === idx + 1 ? lastPostElementRef : null}
                                    key={data?._id} className="d-flex flex-column p-3 bg-grey-900 border-radius-8 border-none gap-3">
                                    <div className="w-100 d-flex align-item-center gap-2">
                                        <div className="d-flex align-item-center">
                                            <LocationOnIcon className="text-black-400" />
                                        </div>
                                        <div className="d-flex flex-column gap-2 driver-tabs-con">
                                            <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                <div className="d-flex align-item-center gap-2">
                                                    <Badge className="text-black-400 bg-black-700 text-10 cus-badge">Drop off</Badge>
                                                    <StatusTask value={data?.taskStatus} />
                                                </div>
                                                <Typography className="text-black-400 text-12">{formatTimestampToDateView(data?.createdDate)}</Typography>
                                            </div>
                                            <Typography variant='h5' className='text-white-700 text-14 f-wrap line-height-normal'>
                                                {data?.address ? `${data?.address?.address1}, ${data?.address?.city}, ${data?.address?.state}, ${data?.address?.country}, ${data?.address?.zipcode || data?.address?.zipCode}` : ""}                                                          </Typography>
                                            <Typography className="text-white-700 text-12 line-height-normal">Stephanie Nicol</Typography>
                                        </div>
                                    </div>
                                    <div className="w-100 d-flex">
                                        {/* <CalculateMap route={"detailRoute"} routeMap={data?.coordinates} /> */}
                                    </div>
                                </ListItem>
                            )
                        })
                    }
                </>
                    : <SkeletonText variant={"rounded"} height={70} />
            }
        </List>
    )
}

export default DriverTask
