import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from "prop-types";
export default function CustomDialog(props) {
    const { children, customFunction = () => { }, maxWidth, className = "" } = props;
    const [open, setModalOpen] = useState(true)
    function handleClose() {
        setModalOpen(false)
        customFunction();
    }
    return (
        <Dialog
            open={open}
            // onClose={handleClose}
            aria-labelledby="form-dialog-title"
            // maxWidth={maxWidth}
            maxWidth={maxWidth}
            className={`cus-modal ${className}`}
            fullWidth
        >
            {/* <DialogTitle id="draggable-dialog-title">
                Modal Title
                <IconButton edge='end' onClick={handleClose} className="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle> */}
            {/* <DialogContent className="dailogBox"> */}
                {children}
            {/* </DialogContent> */}
        </Dialog>
    );
}
CustomDialog.propTypes = {
    children: PropTypes.node.isRequired,
    customFunction: PropTypes.func,
    maxWidth: PropTypes.string,
    className: PropTypes.string,
};