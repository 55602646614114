import React from 'react'

export default function PaymentIcon({width = '20px', height = '20px', flex = 'none'}) {
  return (
    <svg style={{width, height, flex}} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 12C16.7571 12 19 9.75723 19 7C19 4.24291 16.7571 2 14 2C11.2429 2 9 4.24291 9 7C9 9.75723 11.2429 12 14 12ZM14 7.55556C13.081 7.55556 12.3333 6.80787 12.3333 5.88889C12.3333 5.21774 12.7326 4.61502 13.3505 4.35359C13.3811 4.34057 13.4136 4.34129 13.4444 4.3303V3.66667C13.4444 3.35966 13.693 3.11111 14 3.11111C14.307 3.11111 14.5556 3.35966 14.5556 3.66667V4.33131C14.8432 4.42853 15.1238 4.5829 15.3743 4.82552C15.5945 5.03877 15.6 5.39034 15.3861 5.61111C15.1729 5.83145 14.8207 5.83623 14.6005 5.62312C14.3304 5.36097 14.0298 5.27156 13.784 5.37674C13.578 5.46412 13.4444 5.66479 13.4444 5.88889C13.4444 6.19546 13.6934 6.44444 14 6.44444C14.919 6.44444 15.6667 7.19213 15.6667 8.11111C15.6667 8.80932 15.2261 9.43866 14.5702 9.67737C14.5654 9.67925 14.5603 9.67839 14.5556 9.68012V10.3333C14.5556 10.6405 14.307 10.8889 14 10.8889C13.693 10.8889 13.4444 10.6405 13.4444 10.3333V9.67622C13.0786 9.55266 12.728 9.318 12.4282 8.96079C12.2308 8.72584 12.2611 8.37529 12.4967 8.17839C12.7316 7.98148 13.083 8.01186 13.2789 8.24682C13.5731 8.59563 13.9067 8.73611 14.19 8.63354C14.4086 8.55382 14.5556 8.34389 14.5556 8.11111C14.5556 7.80454 14.3066 7.55556 14 7.55556Z" fill="currentColor"/>
      <path d="M1.5 19H3V13H1.5C1.22383 13 1 13.2238 1 13.5V18.5C1 18.7762 1.22383 19 1.5 19Z" fill="currentColor"/>
      <path d="M17.3951 11.7875L14.2673 15.1496C14.1506 15.2171 13.8391 15.5985 13.1753 15.6712H9.14883C8.83026 15.6712 8.58011 15.427 8.58011 15.1163C8.58011 14.8056 8.83026 14.5615 9.14883 14.5615H12.5106C12.8799 14.5615 13.1783 14.2161 13.064 13.8737C12.7672 12.9847 11.9107 12.3423 10.9005 12.3423H9.41039L9.05597 12.0419C8.29516 11.3974 7.32804 10.9859 6.32034 11.0004C5.48445 11.0124 4.64929 11.288 4 11.7372V19H12.9671C13.4342 19 13.8769 18.8156 14.1971 18.4841C15.6823 16.9459 19.8859 12.5017 19.8859 12.4089C20.0733 12.2001 20.018 11.8975 19.8064 11.7099C19.1285 11.0957 18.0605 11.1165 17.3951 11.7875Z" fill="currentColor"/>
    </svg>
  )
}
