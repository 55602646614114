import React from 'react'

export default function StocksIcon() {
  return (
    <svg style={{width: "1em", height: "1em"}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6231 2.16681C12.5752 2.1124 12.5173 2.06946 12.4531 2.04076C12.3889 2.01206 12.3199 1.99824 12.2505 2.00018H7.74955C7.67957 1.9997 7.6102 2.01417 7.54541 2.04277C7.48062 2.07136 7.42168 2.11351 7.37198 2.16681C7.27416 2.27441 7.21892 2.41901 7.21798 2.56994V7.73001H12.782V2.56994C12.7793 2.41837 12.7223 2.27387 12.6231 2.16681ZM10.3776 4.31684H9.61748C9.48601 4.30304 9.36399 4.23677 9.27521 4.13094C9.18643 4.02511 9.13724 3.88731 9.13724 3.74439C9.13724 3.60147 9.18643 3.46367 9.27521 3.35784C9.36399 3.25202 9.48601 3.18575 9.61748 3.17195H10.3776C10.5091 3.18575 10.6311 3.25202 10.7199 3.35784C10.8086 3.46367 10.8578 3.60147 10.8578 3.74439C10.8578 3.88731 10.8086 4.02511 10.7199 4.13094C10.6311 4.23677 10.5091 4.30304 10.3776 4.31684Z" fill="currentColor"/>
      <path d="M4.43598 8.88023C4.36641 8.87952 4.2974 8.89373 4.23293 8.92205C4.16847 8.95036 4.10983 8.99222 4.0604 9.0452C4.01098 9.09817 3.97174 9.16122 3.94497 9.2307C3.91819 9.30018 3.90441 9.37471 3.90441 9.44999V14.0403H9.46848V8.88023H4.43598ZM7.06401 11.17H6.30889C6.2351 11.1778 6.16063 11.1687 6.09026 11.1435C6.01989 11.1182 5.95519 11.0773 5.90029 11.0234C5.8454 10.9695 5.80153 10.9038 5.77152 10.8304C5.7415 10.7571 5.72599 10.6778 5.72599 10.5976C5.72599 10.5174 5.7415 10.438 5.77152 10.3647C5.80153 10.2914 5.8454 10.2256 5.90029 10.1717C5.95519 10.1178 6.01989 10.0769 6.09026 10.0517C6.16063 10.0264 6.2351 10.0174 6.30889 10.0251H7.06401C7.1378 10.0174 7.21227 10.0264 7.28264 10.0517C7.353 10.0769 7.41771 10.1178 7.47261 10.1717C7.5275 10.2256 7.57136 10.2914 7.60138 10.3647C7.6314 10.438 7.64691 10.5174 7.64691 10.5976C7.64691 10.6778 7.6314 10.7571 7.60138 10.8304C7.57136 10.9038 7.5275 10.9695 7.47261 11.0234C7.41771 11.0773 7.353 11.1182 7.28264 11.1435C7.21227 11.1687 7.1378 11.1778 7.06401 11.17Z" fill="currentColor"/>
      <path d="M15.9616 9.04683C15.9126 8.99394 15.8545 8.952 15.7906 8.92341C15.7266 8.89482 15.6581 8.88014 15.589 8.8802H10.5316V14.0403H16.0957V9.44996C16.0996 9.30208 16.0517 9.15807 15.9616 9.04683ZM13.7111 11.17H12.9311C12.8573 11.1777 12.7829 11.1687 12.7125 11.1434C12.6421 11.1182 12.5774 11.0773 12.5225 11.0234C12.4676 10.9695 12.4238 10.9038 12.3938 10.8304C12.3637 10.7571 12.3482 10.6778 12.3482 10.5975C12.3482 10.5173 12.3637 10.438 12.3938 10.3647C12.4238 10.2913 12.4676 10.2256 12.5225 10.1717C12.5774 10.1178 12.6421 10.0769 12.7125 10.0516C12.7829 10.0264 12.8573 10.0174 12.9311 10.0251H13.6862C13.76 10.0174 13.8345 10.0264 13.9049 10.0516C13.9752 10.0769 14.0399 10.1178 14.0948 10.1717C14.1497 10.2256 14.1936 10.2913 14.2236 10.3647C14.2536 10.438 14.2691 10.5173 14.2691 10.5975C14.2691 10.6778 14.2536 10.7571 14.2236 10.8304C14.1936 10.9038 14.1497 10.9695 14.0948 11.0234C14.0399 11.0773 13.9752 11.1182 13.9049 11.1434C13.8345 11.1687 13.76 11.1777 13.6862 11.17H13.7111Z" fill="currentColor"/>
      <path d="M17.4171 15.1852H2.5829C2.50911 15.1775 2.43464 15.1865 2.36427 15.2118C2.2939 15.237 2.22919 15.2779 2.1743 15.3318C2.11941 15.3857 2.07554 15.4515 2.04552 15.5248C2.01551 15.5982 2 15.6775 2 15.7577C2 15.8379 2.01551 15.9172 2.04552 15.9906C2.07554 16.0639 2.11941 16.1296 2.1743 16.1836C2.22919 16.2375 2.2939 16.2784 2.36427 16.3036C2.43464 16.3288 2.50911 16.3379 2.5829 16.3301H4.17263V17.4804C4.18539 17.6227 4.24664 17.7547 4.34445 17.8507C4.44226 17.9468 4.56962 18 4.70171 18C4.83381 18 4.96117 17.9468 5.05898 17.8507C5.15679 17.7547 5.21804 17.6227 5.2308 17.4804V16.3301H14.7692V17.4804C14.782 17.6227 14.8432 17.7547 14.941 17.8507C15.0388 17.9468 15.1662 18 15.2983 18C15.4304 18 15.5577 17.9468 15.6556 17.8507C15.7534 17.7547 15.8146 17.6227 15.8274 17.4804V16.3301H17.4171C17.4909 16.3379 17.5654 16.3288 17.6357 16.3036C17.7061 16.2784 17.7708 16.2375 17.8257 16.1836C17.8806 16.1296 17.9245 16.0639 17.9545 15.9906C17.9845 15.9172 18 15.8379 18 15.7577C18 15.6775 17.9845 15.5982 17.9545 15.5248C17.9245 15.4515 17.8806 15.3857 17.8257 15.3318C17.7708 15.2779 17.7061 15.237 17.6357 15.2118C17.5654 15.1865 17.4909 15.1775 17.4171 15.1852Z" fill="currentColor"/>
    </svg>
  )
}
