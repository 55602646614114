import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
     getAllProductsAPI,
     addCategoryAPI,
     getAllBlazeCategoryAPI,
     getAllCategoryAPI,
     getInfoAndEffectAPI,
     getSingleProductDetailsAPI,
     updateCategoriesAPI,
     updateSingleProductAPI,
     deleteCategoryAPI,
     getAllCategoryImagesAPI,
     getAllBrandsByProductAPI,
     getAllBmwBrandsAPI,
     addBrandToBmwAPI,
     updateBrandFromBmwAPI,
     deleteBrandFromBmwAPI,
     syncProductAdminAPI,
     getThresholdAdminAPI,
     updateThresholdAPI
     } from '../../../redux/apis/hyperwolf/products';

// Thunk for fetching products
export const getAllProducts = createAsyncThunk(
    'products/getAllProducts',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getAllProductsAPI(data);
            return response?.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getSingleProductDetails = createAsyncThunk(
    'product/getSingleProductDetails',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getSingleProductDetailsAPI(data);
            return response?.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getInfoAndEffect = createAsyncThunk(
    'infoAndEffect/getInfoAndEffect',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getInfoAndEffectAPI(data);
            return response?.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateSingleProduct = createAsyncThunk(
    'product/updateSingleProduct',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await updateSingleProductAPI(id, data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);

export const addCategory = createAsyncThunk(
    'category/addCategory',
    async ({ data, id }, { rejectWithValue }) => {
        try {
            const response = await addCategoryAPI(data, id);
            let txt = id ? "Updated" : "Added";
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);

export const getAllCategory = createAsyncThunk(
    'category/getAllCategory',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getAllCategoryAPI(data);
            return response?.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getAllBlazeCategory = createAsyncThunk(
    'blazeCategory/getAllBlazeCategory',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getAllBlazeCategoryAPI(data);
            return response?.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const updateCategories = createAsyncThunk(
    'categories/updateCategories',
    async (data, { rejectWithValue }) => {
        try {
            const response = await updateCategoriesAPI(data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);

export const deleteCategory = createAsyncThunk(
    'category/deleteCategory',
    async (data, { rejectWithValue }) => {
        try {
            const response = await deleteCategoryAPI(data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);

export const getAllCategoryImages = createAsyncThunk(
    'categoryImages/getAllCategoryImages',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getAllCategoryImagesAPI(data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);

export const getAllBrandsByProducts = createAsyncThunk(
    'brands/getAllBrandsByProducts',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getAllBrandsByProductAPI(data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);

export const getAllBmwBrands = createAsyncThunk(
    'bmwBrands/getAllBmwBrands',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getAllBmwBrandsAPI(data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }

);

export const addBrandToBmw = createAsyncThunk(
    'bmwBrands/addBrandToBmw',
    async (data, { rejectWithValue }) => {
        try {
            const response = await addBrandToBmwAPI(data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);

export const updateBrandFromBmw = createAsyncThunk(
    'bmwBrands/updateBrandFromBmw',
    async (data, { rejectWithValue }) => {
        try {
            const response = await updateBrandFromBmwAPI(data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);

export const deleteBrandFromBmw = createAsyncThunk(
    'bmwBrands/deleteBrandFromBmw',
    async (data, { rejectWithValue }) => {
        try {
            const response = await deleteBrandFromBmwAPI(data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);

export const syncProductAdmin = createAsyncThunk(
    'productAdmin/syncProductAdmin',
    async (data, { rejectWithValue }) => {
        try {
            const response = await syncProductAdminAPI(data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);

export const getThreshold = createAsyncThunk(
    'threshold/getThreshold',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getThresholdAdminAPI(data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);


export const updateThreshold = createAsyncThunk(
    'threshold/updateThreshold',
    async (data, { rejectWithValue }) => {
        try {
            const response = await updateThresholdAPI(data);
            return response?.data;
        } catch (err) {
            console.log("err",err)
            return rejectWithValue(err);
        }
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        product: null,
        categories: {},
        blazeCategories: {},
        brands: [],
        bmwBrands: [],
        categoryImages: [],
        threshold: null,
        loading: false,
        error: null,
        syncProductLoader:false,
        singleCategory:{}
    },
    reducers: {
        setCategoryInformation:(state,action)=>{
            state.singleCategory =action.payload
        }
        // You can add additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload; // Changed items to products
            })
            .addCase(getAllProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch products';
            });

        builder
            .addCase(getSingleProductDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSingleProductDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.product = action.payload; // Store the product details
            })
            .addCase(getSingleProductDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch product details';
            });
        builder
            .addCase(getInfoAndEffect.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getInfoAndEffect.fulfilled, (state, action) => {
                state.loading = false;
                state.info = action.payload.info; // Assuming response contains 'info'
                state.effect = action.payload.effect; // Assuming response contains 'effect'
            })
            .addCase(getInfoAndEffect.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch info and effect';
            });
        builder
            .addCase(updateSingleProduct.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateSingleProduct.fulfilled, (state, action) => {
                state.loading = false;
                // Optionally update the product in the state
                // state.product = action.payload;
            })
            .addCase(updateSingleProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to update product';
            });
        builder
            .addCase(addCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.category = action.payload; // Store the added/updated category
            })
            .addCase(addCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to add/update category';
            });
            builder
            .addCase(getAllCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = action.payload; // Store the list of categories
            })
            .addCase(getAllCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch categories';
            });
            builder
            .addCase(getAllBlazeCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllBlazeCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.blazeCategories = action.payload; // Store the list of Blaze categories
            })
            .addCase(getAllBlazeCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch Blaze categories';
            });
            builder
            .addCase(updateCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateCategories.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateCategories.rejected, (state, action) => {
                state.loading = false;
            });
            builder
            .addCase(deleteCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.loading = false;
                // Handle the logic to update the categories state after deletion if needed
                // For example, you might filter out the deleted category from the list
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to delete category';
            });
            builder
            .addCase(getAllCategoryImages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllCategoryImages.fulfilled, (state, action) => {
                state.loading = false;
                state.categoryImages = action.payload; // Store the list of category images
            })
            .addCase(getAllCategoryImages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch category images';
            });
            builder
            .addCase(getAllBrandsByProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllBrandsByProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.brands = action.payload; // Store the list of brands
            })
            .addCase(getAllBrandsByProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch brands by product';
            });
            builder
            .addCase(getAllBmwBrands.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllBmwBrands.fulfilled, (state, action) => {
                state.loading = false;
                state.brands = action.payload; // Store the list of BMW brands
            })
            .addCase(getAllBmwBrands.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch BMW brands';
            });
            builder
            .addCase(addBrandToBmw.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addBrandToBmw.fulfilled, (state, action) => {
                state.loading = false;
                // Optionally, update the state with the new brand
                // Example: add the new brand to the list
                state.brands.push(action.payload);
            })
            .addCase(addBrandToBmw.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to add brand';
            });

            builder
            .addCase(updateBrandFromBmw.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateBrandFromBmw.fulfilled, (state, action) => {
                state.loading = false;
                // Update the state with the updated brand
                // Example: find and update the brand in the list
                state.brands = state.brands.map(brand =>
                    brand.id === action.payload.id ? action.payload : brand
                );
            })
            .addCase(updateBrandFromBmw.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to update brand';
            });
            builder
            .addCase(deleteBrandFromBmw.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteBrandFromBmw.fulfilled, (state, action) => {
                state.loading = false;
                // Remove the deleted brand from the state
                // Example: filter out the deleted brand from the list
                state.brands = state.brands.filter(brand => brand.id !== action.payload.id);
            })
            .addCase(deleteBrandFromBmw.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to delete brand';
            });

            builder
            .addCase(syncProductAdmin.pending, (state) => {
                state.syncProductLoader = true;
                state.error = null;
            })
            .addCase(syncProductAdmin.fulfilled, (state, action) => {
                state.syncProductLoader = false;
                state.product = action.payload; // Store the synced product data
            })
            .addCase(syncProductAdmin.rejected, (state, action) => {
                state.syncProductLoader = false;
                state.error = action.payload || 'Failed to sync product';
            });
            builder
            .addCase(getThreshold.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getThreshold.fulfilled, (state, action) => {
                state.loading = false;
                state.threshold = action.payload; // Store the threshold data
            })
            .addCase(getThreshold.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to get threshold';
            });
            builder
            .addCase(updateThreshold.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateThreshold.fulfilled, (state, action) => {
                state.loading = false;
                state.threshold = action.payload; // Update the threshold data
            })
            .addCase(updateThreshold.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to update threshold';
            });
    },
});
export const {setCategoryInformation} = productsSlice.actions;
export default productsSlice.reducer;
