import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { bulkProductUpdate, openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog } from '../../redux/slices/modalSlice';
import { isNumber } from 'utilities/common';

export default function BulkSalePriceUpdate({ closeModal }) {
    const { modalData, modal, isLoading = false } = useSelector(state => state.modal)
    const { isDeleting = false } = useSelector(state => state.common)
    const [salePercentage, setsalePercentage] = useState("")
    const [errors, setErrors] = useState({})

    const { bulkProductIds, resetData = () => { } } = modalData
    const dispatch = useDispatch();


    const handleBulkUpdate = () => {
        if (!salePercentage) {
            setErrors({ salePercentage: 'Sale Percentage should not be empty' })
            return;
        }
        if (+salePercentage <= 0) {
            setErrors({ salePercentage: 'Sale Percentage should not be zero' })
            return;
        }
        setErrors({})
        dispatch(bulkProductUpdate({ productIds: bulkProductIds, salePricePercentage: salePercentage })).unwrap().then((res => {
            dispatch(openSnackbar({ content: res?.message, color: 'success', icon: 'check' }))
            dispatch(closeCustomModalDialog({}))
            resetData()
        })).catch((err => {
            dispatch(openSnackbar({ content: err?.message, color: 'error', icon: 'warning' }))
            console.log("err", err)
        }))
    }
    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
                Update Sale Price
                <IconButton onClick={closeModal} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Box my={2}>
                    <TextField error={Boolean(errors?.salePercentage)}  helperText={errors?.salePercentage} fullWidth value={salePercentage} name='salePercentage' placeholder='Percentage (%)' onChange={(e) => {
                       let numberRegex =/^[0-9]*\.?[0-9]*$/
                       if (e.target.value?.toString() &&  !numberRegex.test(e.target.value)) return;
                       if (e.target.value && e.target.value > 100) return;
                        setsalePercentage(e.target.value)
                    }} />
                </Box>
            </DialogContent>

            <DialogActions>
                <MDButton onClick={closeModal} size="small" color="secondary">
                    Cancel
                </MDButton>
                <MDButton onClick={handleBulkUpdate} size="small" color='error'>
                    {
                        isLoading || isDeleting ? <CircularProgress size={16} color='light' /> : " Update"
                    }

                </MDButton>
            </DialogActions>
        </>
    )
}
