
// @mui material components
import Grid from "@mui/material/Grid";
import CommonLayout from "layouts/DashboardLayout";
import { Box } from "@mui/material";
import Home from "./Home";


function HyperDriveHomePage() {

  return (
    <CommonLayout>
      <Home />
    </CommonLayout>
  );
}

export default HyperDriveHomePage;
