import axiosClientHyperDrive from "axiosClient/indexHyperdrive";
import { getQueryString } from "utilities/common";

// Main Setting API
export function getAnalyzeDataAPI(data) {
    return axiosClientHyperDrive.get(`api/v1/admin/analyze?${getQueryString(data)}`);
}

export function getAnalyzeGraphDataAPI(data) {
    return axiosClientHyperDrive.get(`api/v1/admin/analyze/graph?${getQueryString(data)}`);
}
