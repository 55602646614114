import { isEmptyObject } from "utilities/common";



export function driverValidation(fields, driverId) {

    const errors = { };
    if (!fields.fleetName) {
        errors.fleetName = 'Driver name is required'
    }

    if (!fields.fleetPhone) {
        errors.fleetPhone = 'Driver phone number is required'
    }

    if(!fields.fleetEmail) {
        errors.fleetEmail ="Driver email is required"
    }

    if (!fields?.regionData?.regionId) {
        errors.region = 'Region is required';
    }

    if (!fields?.terminalData?.terminalId) {
        errors.terminal = 'Terminal is required';
    }

    if (!fields.fleetTransportationTypeId) {
        console.log(fields.transportationTypeId, 'TRANSPORTATION')
        errors.fleetTransportationTypeId = "Transportation type is required"
    }

//     if(!fields.fleetVehicleDetails.licensePlate) {
//         errors.licencePlate = 'License plate is required'
//     }


//     if(!fields.fleetVehicleDetails.year) {
//         errors.year = 'Year is required'
//     }

//     if(!fields.fleetVehicleDetails.policyNumber) {
//         errors.policyNumber = 'Policy number is required'
//     }


// if (!fields.fleetVehicleDetails.expirationDate && !fields.expirationDate){
//     errors.expirationDate = 'Expiration date is required'
// }


//     if(!fields.fleetOtherInfo.licenseNumber) {
//         errors.licenseNumber = 'Driver license number is required'
//     }



// if (!fields.fleetOtherInfo.dob && !fields.dob){
//     errors.dob = 'Dob is required'
// }



// if (!fields.fleetOtherInfo.licenseExpirationDate && !fields.licenseExpirationDate){
//     errors.licenseExpirationDate = 'License expiration date is required'
// }



//     if(!fields.policyFile) {
//         errors.policyFile = 'Policy File is required'
//     }

//     if(!fields.idFile) {
//         errors.idFile = 'Id File is required'
//     }


    return { errors, isValid: isEmptyObject(errors) };
}
