import { createOrderAPI, deleteOrderAPI, fetchOrderDetailsAPI, fetchOrdersAPI, fetchMemberOrdersAPI, fetchWarrantyOrdersAPI, warrantyOrderAPI } from "../../redux/apis/order";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Your fetchOrders async thunk
export const fetchOrders = createAsyncThunk(
    'orders/fetchOrders',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchOrdersAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);


export const fechWarrantyOrders = createAsyncThunk(
    'orders/fechWarrantyOrders',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchWarrantyOrdersAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const fetchMemberOrderList = createAsyncThunk(
    'orders/fetchMemberOrderList',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchMemberOrdersAPI(filters);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);



export const fetchSingleOrder = createAsyncThunk(
    'orders/fetchSingleOrder',
    async (orderId, { rejectWithValue }) => {
        try {
            const res = await fetchOrderDetailsAPI(orderId);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);
export const warrantyStatusUpdate = createAsyncThunk(
    'orders/warrantyStatusUpdate',
    async (data, { rejectWithValue }) => {
        try {
            const res = await warrantyOrderAPI(data);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);



export const createOrder = createAsyncThunk(
    'orders/createOrder',
    async ({ data, orderId }, { rejectWithValue }) => {
        try {
            const res = await createOrderAPI(data, orderId);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const deleteOrder = createAsyncThunk(
    'orders/deleteOrder',
    async (orderId, { rejectWithValue }) => {
        try {
            const res = await deleteOrderAPI(orderId);
            return res;
        } catch (error) {
            console.log("error", error.response);
            return rejectWithValue(error.response?.data);
        }
    }
);

// Create a slice for orders
const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        orders: [],
        isLoading: false, // Flag to track loading status
        error: null,
        order: {},
        isUpdatingStatus:false
    },
    reducers: {
        // You can define additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, (state) => {
                state.isLoading = true; // Set isLoading to true when fetching starts
                state.error = null; // Reset error when starting a new fetch
                state.order = {}
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.isLoading = false; // Set isLoading to false when fetching completes
                state.orders = action.payload; // Update orders with the fetched data
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.isLoading = false; 
                state.error = action.payload; // Update error in case of failure
            });
        builder
            .addCase(fechWarrantyOrders.pending, (state) => {
                state.isLoading = true; // Set isLoading to true when fetching starts
                state.error = null; // Reset error when starting a new fetch
                state.order = {}
            })
            .addCase(fechWarrantyOrders.fulfilled, (state, action) => {
                state.isLoading = false; // Set isLoading to false when fetching completes
                state.orders = action.payload; // Update orders with the fetched data
            })
            .addCase(fechWarrantyOrders.rejected, (state, action) => {
                state.isLoading = false; 
                state.error = action.payload; // Update error in case of failure
            });

        builder
            .addCase(warrantyStatusUpdate.pending, (state) => {
                state.isUpdatingStatus = true; // Set isLoading to true when fetching starts
            })
            .addCase(warrantyStatusUpdate.fulfilled, (state, action) => {
                state.isUpdatingStatus = false; 
             
            })
            .addCase(warrantyStatusUpdate.rejected, (state, action) => {
                state.isUpdatingStatus = false; 
                
            });
        builder
            .addCase(createOrder.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.isLoading = false;
                // Handle updating state after successful batch creation if needed
            })
            .addCase(createOrder.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteOrder.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteOrder.fulfilled, (state, action) => {
                state.isLoading = false;
                // Handle updating state after successful deletion if needed
            })
            .addCase(deleteOrder.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        builder.addCase(fetchSingleOrder.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });

        // When the fetchSingleOrder is fulfilled (success)
        builder.addCase(fetchSingleOrder.fulfilled, (state, action) => {
            state.isLoading = false;
            state.order = action.payload.orderDetails;
        });

        // When the fetchSingleOrder is rejected (failure)
        builder.addCase(fetchSingleOrder.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export const { /* any additional actions */ } = ordersSlice.actions;
export default ordersSlice.reducer;
