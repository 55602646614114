import React from 'react'

export default function ProductInactiveIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.23079 8.80111C9.20981 8.60654 9.19782 8.40598 9.19782 8.20542C9.19782 8.00187 9.20981 7.80131 9.23079 7.60375H3.73128L3.26674 5.84064C3.19781 5.57722 2.95805 5.39462 2.68832 5.39462H0.599402C0.269731 5.39462 0 5.66403 0 5.9933C0 6.32258 0.269731 6.59198 0.599402 6.59198H2.22678L2.69131 8.35809C2.69131 8.36108 2.69431 8.36108 2.69431 8.36108L4.15086 13.9707C4.21979 14.2341 4.45955 14.4167 4.73228 14.4167H4.8042C4.71729 14.6322 4.66934 14.8657 4.66934 15.1112C4.66934 16.1529 5.51749 17 6.56045 17C7.60341 17 8.45156 16.1529 8.45156 15.1112C8.45156 14.8657 8.40361 14.6322 8.3167 14.4167H9.74327C9.65636 14.6322 9.60841 14.8657 9.60841 15.1112C9.60841 16.1529 10.4566 17 11.4995 17C12.5425 17 13.3906 16.1529 13.3906 15.1112C13.3906 14.8657 13.3427 14.6322 13.2558 14.4167H13.3277C13.6004 14.4167 13.8402 14.2341 13.9091 13.9707L13.9721 13.7282C13.3906 13.6444 12.8392 13.4678 12.3297 13.2194C10.6454 12.3932 9.43758 10.7438 9.23079 8.80111ZM7.25276 15.1112C7.25276 15.4913 6.94407 15.8027 6.56045 15.8027C6.17983 15.8027 5.86814 15.4913 5.86814 15.1112C5.86814 14.728 6.17983 14.4167 6.56045 14.4167C6.94407 14.4167 7.25276 14.728 7.25276 15.1112ZM12.1918 15.1112C12.1918 15.4913 11.8801 15.8027 11.4995 15.8027C11.1159 15.8027 10.8072 15.4913 10.8072 15.1112C10.8072 14.728 11.1159 14.4167 11.4995 14.4167C11.8801 14.4167 12.1918 14.728 12.1918 15.1112Z" fill="currentColor"/>
      <path d="M17.8932 5.10089C16.1809 3.39064 13.3942 3.39276 11.6819 5.10301C9.96953 6.81326 9.96953 9.59454 11.6819 11.3048C13.3963 13.0172 16.1809 13.0172 17.8932 11.3069C19.6056 9.59666 19.6077 6.81326 17.8932 5.10089ZM15.2845 9.54798L14.7865 9.05056L14.2885 9.54798C14.0554 9.78081 13.676 9.78293 13.4408 9.54798C13.2077 9.31515 13.2077 8.93415 13.4408 8.70132L13.9388 8.2039L13.4429 7.70861C13.2077 7.47366 13.2098 7.09478 13.4429 6.86195C13.6781 6.627 14.0554 6.627 14.2906 6.86195L14.7865 7.35724L15.2845 6.85983C15.5197 6.62488 15.8991 6.627 16.1322 6.85983C16.3674 7.09478 16.3674 7.47154 16.1322 7.70649L15.6342 8.2039L16.1322 8.70132C16.3674 8.93627 16.3674 9.31303 16.1322 9.54798C15.8991 9.78081 15.5197 9.78292 15.2845 9.54798Z" fill="currentColor"/>
    </svg>    
  )
}
