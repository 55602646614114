import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NotificationDrawer from './NotificationDrawer';
import { fetchAllNotificationsCountsData, fetchNotificationCount } from '../../redux/slices/notifications';
import { useDispatch } from 'react-redux';


const NotificationCount = () => {
    const dispatch = useDispatch()
    const { addProductNotifications = 0 } = useSelector(state => state.notifications)
    const count = useSelector(state => state.notifications.notificationCount);
    const [showNotificationDrawer, setShowNotificationDrawer] = useState(false)

    const handleShowNotificationDrawer = () => {
        setShowNotificationDrawer(!showNotificationDrawer)
    }

    // useEffect(() => {
    //     const countResponse = dispatch(fetchNotificationCount({ notificationType: 'approvals'})).unwrap().then((res) => {
    //         setCount(res.count)
    //     })

    // }, [addProductNotifications.length, addProductNotifications[0]?.readStatus])

    // useEffect(() => {
    //     setCountNew(count)

    // },[])



    return (
        <div className='notifiction-dawer' onClick={handleShowNotificationDrawer}>
            <div className='icon-wrapper'>
                <NotificationsNoneIcon />
            </div>
            {count > 0 &&
                <div className='count-number'>{count}</div>
            }
            {showNotificationDrawer && <NotificationDrawer open={showNotificationDrawer} onClose={handleShowNotificationDrawer} /> }
        </div>
       )

}

export default NotificationCount;