import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Typography, List, ListItem, FormControl, FormControlLabel, Radio, RadioGroup, Box, Checkbox } from "@mui/material";
import driverPic from "../../../assets/images/driver-pic.svg"
import { useDispatch } from 'react-redux';
import EastIcon from '@mui/icons-material/East';
import { useSelector } from 'react-redux';
import { fetchRegionList } from '../../../redux/slices/hyperdrive/driverAnalytics';

const RegionListDrawer = ({ sendDataToParent = () => { }, onValueChange, breakTask }) => {
    const dispatch = useDispatch();
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedValueBreaks, setSelectedValueBreaks] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const searchTermRef = useRef("")
    const { regionList = {} } = useSelector(state => state.driverAnalyticsHyperDrive)
    console.log("regionListposts", regionList);

    useEffect(() => {
        dispatch(fetchRegionList())
    }, [])

    const handleDriverDetails = () => {
        sendDataToParent(null);
    }

    const handleChangeTask = (event) => {
        const { name, value } = event.target;
        onValueChange(value);
        setSelectedValue(value);
        // handleDriverDetails()
    };
    const handleChangeTaskBreaks = (event, id) => {
        if (event.target.checked) {
            setSelectedValueBreaks(prev => [...prev, id]);
        } else {
            setSelectedValueBreaks(prev => prev.filter(value => value !== id));
        }
    };
    useEffect(() => {
        onValueChange(selectedValueBreaks);
    }, [selectedValueBreaks])

    return (
        <div className="next-drawer">
            <Box p={2} className='d-flex align-item-center jc-s-btwn gap-2 drawer-head'>
                <div className='d-flex flex-row gap-3'>
                    <Typography variant='h6' className='text-14 text-white-700'>Region List</Typography>
                </div>
                <Typography style={{ cursor: "pointer" }} onClick={() => handleDriverDetails()} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                    Close <EastIcon />
                </Typography>
            </Box>

            <Box className='drawer-scroll-sec'>
                <Box p={2} className='hd-drivers-listing'>
               
                            <List>
                                {regionList?.regions?.map((data, idx) => (
                                    <React.Fragment key={idx}>
                                        <ListItem
                                            className='d-flex align-item-top jc-s-btwn gap-2'
                                        >
                                            <div className='d-flex flex-row gap-2'>
                                                <div className='d-flex radio-select'>
                                                    <FormControl>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedValueBreaks.includes(data?.id)}
                                                                    onChange={(e) => handleChangeTaskBreaks(e, data?.id)}
                                                                />
                                                            }
                                                            label=""
                                                        />
                                                    </FormControl>
                                                </div>
                                                {/* <div className='d-flex'>
                                                    <img className='mt-1 round_img' src={data?.driverImage ? data?.driverImage : driverPic} alt='' width={28} height={28} />
                                                </div> */}
                                                <div className='d-flex flex-column'>
                                                    <Typography variant='h6' className='text-14 text-white-700'>
                                                        {data?.name}
                                                    </Typography>
                                                
                                                </div>
                                            </div>
                                            
                                        </ListItem>
                                        {loading && <p>Loading...</p>}
                                    </React.Fragment>
                                ))}
                            </List> 
               


                </Box>
            </Box>
        </div>
    );
};

export default RegionListDrawer;
