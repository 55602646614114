import AddTerpenoidForm from 'components/terpenoids/addTerepnoidForm'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { fetchSingleTerpenoid } from '../../redux/slices/terpenoids'
import { useDispatch } from 'react-redux'

export default function AddTerpenoids() {
  const dispatch = useDispatch()
  const { terpenoidId = "" } = useParams()
  useEffect(() => {
    if (terpenoidId) {
      dispatch(fetchSingleTerpenoid(terpenoidId))
    }
  }, [terpenoidId])
  return (
    <CommonLayout>
      <AddTerpenoidForm />
    </CommonLayout>
  )
}
