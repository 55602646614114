import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import React from 'react'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';

export default function DeleteModal({ closeModal }) {
    const { modalData, modal, isLoading = false } = useSelector(state => state.modal)
    const { deleteHandler, deleteId, deleteType = '' } = modalData
    console.log(modalData, 'modalData')
    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
                Delete Confirmation

                <IconButton onClick={closeModal} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this {deleteType ? deleteType : "item"}?
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <MDButton onClick={closeModal} size="small" color="secondary">
                    Cancel
                </MDButton>
                <MDButton onClick={() => deleteHandler(deleteId)} size="small" color='error'>
                    {
                        isLoading ? <CircularProgress size={16} color='light' /> : " Delete"
                    }

                </MDButton>
            </DialogActions>
        </>
    )
}
