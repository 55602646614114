import AddRetailerForm from 'components/retailers/addRetailerForm'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchRetailerDetails } from '../../../redux/slices/retailerSlice'

export default function AddRetailer() {
  const dispatch = useDispatch()
  const { retailerId = "" } = useParams()
  useEffect(() => {
    if(retailerId){
      dispatch(fetchRetailerDetails(retailerId))
    }
  }, [retailerId])
  return (
    <CommonLayout>
        <AddRetailerForm/>  
    </CommonLayout>
  )
}