import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import {
  TextField,
  Grid,
  Typography,
  Card,
  Box,
  Select,
  MenuItem,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Badge,
  CircularProgress,
  InputLabel,
  FormControl,
  FormHelperText,
  RadioGroup,
  Radio
} from '@mui/material';
import MDButton from 'components/MDButton';
import Zoom from 'react-medium-image-zoom'
import InputMask from 'react-input-mask';
import ImageDragger from 'common/imageDragger';
import iconCar from '../../../assets/images/icon-car.svg';
import iconBicycle from '../../../assets/images/icon-bicycle.svg';
import iconBike from '../../../assets/images/icon-bike.svg';
import iconTruck from '../../../assets/images/icon-truck.svg';
import iconDriverPlaceholder from '../../../assets/images/add-driver-placeholder.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addDriver, fetchDriverDetails, fetchFleets, fetchTransportations, updateDriver } from '../../../redux/slices/hyperwolf/driver/driver';
import { openSnackbar } from '../../../redux/slices/common';
import { uploadImage } from '../../../redux/slices/common';
import MDInput from 'components/MDInput';
import PlusIcon from 'common/customIcons/plus';
import { fetchDriversRegions } from '../../../redux/slices/hyperwolf/driver/driverRegion';
import { driverValidation } from '../../../validations/hyperwolf/driver';
import { DatePicker } from '@mui/x-date-pickers';
import CalendarIcon from 'common/customIcons/calendar';
import { isEmptyObject } from 'utilities/common';
import dayjs from 'dayjs';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { fetchDriversTerminals } from '../../../redux/slices/hyperwolf/driver/driverTerminals';


export default function CreateDriver() {
  const { driverDetails, loading = false } = useSelector(state => state.adminDrivers || {})
  const { adminDrivers } = useSelector(state => state.adminDrivers || {})

  console.log(useSelector(state => state.adminDrivers), 'driver details')
  const [allFleets, setAllFleets] = useState([]) 
  const { isUploading = false } = useSelector(state => state.common)
  const uploadedFileField = useRef(null)
  const [regions, setRegions] = useState([])
  const [terminals, setTerminals] = useState([])
  const [selectedRegion, setSelectedRegion] = useState('')
  const [selectedTerminal, setSelectedTerminal] = useState('')

  const { driverId } = useParams()
  const [isPolicyPdf, setIsPolicyPdf] = useState(false)
  const [isPdf, setIsPdf] = useState(false)
  const [transportations, setTransportations] = useState([])




  useEffect(() => {
    if (adminDrivers && adminDrivers.data && adminDrivers.data.fleets) {
      console.log(adminDrivers.data.fleets, 'FINAL123');
      setAllFleets([...adminDrivers.data.fleets]);
    }
  }, [adminDrivers]);


  const alreadySelectedRegion = allFleets.map(region => region.regionData.regionId).filter(id => id !== undefined && id !== null);
  console.log(alreadySelectedRegion, 'Alredy')

  const alreadySelectedTerminal = allFleets.map(terminal => terminal.terminalData?.terminalId).filter(id => id !==undefined  && id !== null);


  const [formData, setFormData] = useState({
      fleetName: '',
      fleetPhone: '',
      fleetEmail: '',
      fleetImage:'',
      regionData: {
                regionId:'',
                regionName:''
      },
      terminalData: {
                terminalId:'',
                terminalName:''
      },
      fleetTransportationTypeId:'',
      fleetVehicleDetails:{
          licensePlate:'',
          color:'',
          year:'',
          make:'',
          model:'',
          insuranceProvider:'',
          policyNumber:'',
          expirationDate:''
      },
      policyFile:'',
      fleetOtherInfo: {
          licenseNumber:'',
          dob:'',
          licenseExpirationDate:''
      },
      fleetStatus: 'active',
      idFile:''
  });
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleCalChange = (e) => {
    console.log(e.target, 'target/...')
    const { name, value } = e.target;
    console.log(name, value, 'target')
    setFormData({ ...formData, [name]: value });
  };

  const handleChange = (e) => {
    console.log(e.target, 'e')
    const { name, value } = e.target;

    if (name.startsWith('fleetVehicleDetails.')) {
      const field = name.split('.')[1];
      setFormData(prevData => ({
        ...prevData,
        fleetVehicleDetails: {
          ...prevData.fleetVehicleDetails,
          [field]: value
        }
      }))
    }
    else if (name.startsWith('fleetOtherInfo.')) {
      const field = name.split('.')[1];
      setFormData(prevData => ({
        ...prevData,
        fleetOtherInfo: {
          ...prevData.fleetOtherInfo,
          [field]: value
        }
      })) ;
    }
     else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }))
    }

  }

  // const handleCheckBoxChange = (e) => {
  //   const { value, checked} = e.target;
  //   console.log(value, 'hh')
  //   setFormData(prevData => {
  //     const newTransportationTypeIds = checked ?
  //      [...prevData.fleetTransportationTypeIds, value] :
  //       prevData.fleetTransportationTypeIds.filter(id => id !== value);

  //       return { 
  //         ...prevData,
  //         fleetTransportationTypeIds: newTransportationTypeIds
  //       };
  //   })
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    const {errors, isValid } = driverValidation(formData, driverId)
    if (selectedRegion) {
      delete errors.region;
  }
  if (selectedTerminal) {
    delete errors.terminal
  }

    console.log(Object.keys(errors).length, 'errors..')
    if (Object.keys(errors).length > 0){
      console.log('yes it is not valid...', errors, isValid)
       setErrors(errors)
    console.log(errors, 'errors after')

       return;
    }
    setErrors({})

    const payload = {
      fleetName: formData?.fleetName,
      fleetPhone: formData?.fleetPhone?.replace(/[^\d]/g, ''),
      fleetEmail: formData?.fleetEmail,
      fleetImage: formData?.fleetImage,
      regionData: {
                  // regionId: driverId ? formData.regionData.regionId : selectedRegion?.id || '',
                  // regionName: driverId ? formData.regionData.regionName :selectedRegion?.name || ''  
                  regionId: selectedRegion?.id,
                  regionName: selectedRegion?.name 
                  // regionId : '61538d2ca755e9326af2a0cb',
                  // regionName: 'Employee Orders'
                },
      terminalData: {
                  terminalId: selectedTerminal?.id,
                  terminalName: selectedTerminal?.name
      },
      fleetTransportationTypeId: formData.fleetTransportationTypeId,
      fleetVehicleDetails: {
                    licensePlate: formData?.fleetVehicleDetails.licensePlate,
                    color: formData?.fleetVehicleDetails.color,
                    year: formData?.fleetVehicleDetails.year,
                    make: formData?.fleetVehicleDetails.make,
                    model: formData?.fleetVehicleDetails.model,
                    insuranceProvider: formData?.fleetVehicleDetails.insuranceProvider,
                    policyNumber: formData?.fleetVehicleDetails.policyNumber,
                    expirationDate: dayjs(formData?.expirationDate).valueOf().toString(),
                    },
      policyFile: formData.policyFile,
      fleetOtherInfo: {
        licenseNumber: formData.fleetOtherInfo.licenseNumber,
        dob:dayjs(formData.dob).valueOf().toString(),
        licenseExpirationDate: dayjs(formData.licenseExpirationDate).valueOf().toString()
    },
      fleetStatus: formData.fleetStatus,
      idFile: formData.idFile
    }


    if (driverId) {
      console.log( payload, 'xxxx')
        dispatch(updateDriver( {...payload, fleetId : formData?._id })).unwrap().then((res) => {
          showError(res?.data.message, 'success')
          navigate('/hyperwolf/driver')
        })
    }
    else{
      dispatch(addDriver(payload)).unwrap().then((res) => {
        console.log(res, 'resss')
        showError(res?.data.message, 'success')
        navigate('/hyperwolf/driver')
      }). catch((err) => {
        showError(err?.message, "error")
      })
    }
    
  }

  useEffect(() => {

    if (!isEmptyObject(driverDetails)) {
      console.log(driverDetails?.data.data, 'DRIVERDETAILS')
      const dobTimestamp = parseInt(driverDetails?.data.data.fleetOtherInfo?.dob, 10);
      console.log(driverDetails?.data.data.fleetOtherInfo?.dob, 'raw dob value');
      console.log(dobTimestamp, 'dobTimeStamp')
      const licenseExpirationDateTimestamp = parseInt(driverDetails?.data.data.fleetOtherInfo?.licenseExpirationDate, 10);
      const expirationDateTimestamp = parseInt(driverDetails?.data.data.fleetVehicleDetails?.expirationDate, 10);
      let payload = { ... driverDetails.data.data, 
        fleetOtherInfo: {
          ...driverDetails.data.data.fleetOtherInfo,
           dob: dobTimestamp 
           ? dayjs(dobTimestamp) 
           : '',
           licenseExpirationDate :licenseExpirationDateTimestamp 
           ? dayjs(licenseExpirationDateTimestamp) 
           : ''
        },
        fleetVehicleDetails: {
          ...driverDetails.data.data.fleetVehicleDetails,
         expirationDate: expirationDateTimestamp 
         ? dayjs(expirationDateTimestamp) 
         : '',
      }}
      console.log(payload, 'PAYLOAD')
    setFormData({...payload})

  }
  }, [driverDetails])

  console.log(formData, 'XYZ')


  const uploadThumbnailImage = {
    borderRadius: "20px",
    width: "160px",
    height: "160px",
    objectFit: "cover",
    boxShadow: "0 0 1px rgb(0 0 0 / 20%)"
};

const circularProgressIcon = {
    backgroundColor: "#fff",
    borderRadius: 10,
    p: .5,
    position: "absolute"
};
const badgeCircularProgressIcon = {
    backgroundColor: "#fff",
    borderRadius: 10,
    p: .5,
    mr: 14,
    mt: 14
};



  const showError = (message, errortype) => {
    if (errortype === 'success') {
        dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
        dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
}
  
const driverImageUploader = async (e) => {
  uploadedFileField.current = 'fleetImage'
  if (e.target.files.length) {
    const file = e.target.files[0];
    const body = new FormData()
    body.append("filePath", file);
    dispatch(uploadImage(body)).unwrap().then((res  => {
      setFormData({ ...formData, fleetImage: res.fileUrl })
      console.log(formData, 'after file upload 1')
    }))
  }
}

const driverPolicyFileUploader = async (e) => {
  if (e.target.files[0].type === 'application/pdf' ) {
    setIsPolicyPdf(true)
  } 
  else {
    setIsPolicyPdf(false)
  }
  const file = e.target.files[0];
  if ( !(file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
    showError('Only PDF/image format is required', 'error')
    return;
}
  uploadedFileField.current = 'policyFile'
  if (e.target.files.length) {
    const file = e.target.files[0];
    const body = new FormData()
    body.append("filePath", file);
    console.log(file, 'FILE')
    console.log(body, 'BODY')


    try {
      const response = await dispatch(uploadImage(body)).unwrap();
      setFormData({ ...formData, policyFile: response?.fileUrl });
      console.log(formData, 'after file upload 2');
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
}

const driverIdFileUploader = async (e) => {
  if (e.target.files[0].type === 'application/pdf') {
    setIsPdf(true)
  } 
  else {
    setIsPdf(false)
  }
  const file = e.target.files[0];
  if ( !(file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
    showError('Only PDF/image format is required', 'error')
    return;
}
  uploadedFileField.current = 'IdFile'
  if (e.target.files.length) {
    const file = e.target.files[0];
    const body = new FormData()
    body.append("filePath", file);
    dispatch(uploadImage(body)).unwrap().then((res  => {
      setFormData({ ...formData, idFile: res.fileUrl })
      console.log(formData, 'after file upload 3')
    }))
  }
}

useEffect(()=> {
  dispatch(fetchDriversRegions())
        .unwrap()
        .then((res) => {
          setRegions(res?.data.regions || [])
  })
},[])

useEffect(()=> {
  dispatch(fetchDriversTerminals())
        .unwrap()
        .then((res) => {
          console.log('res terminals', res)
          setTerminals(res?.data.terminals || [])
  })
},[])

useEffect(() => {
  dispatch(fetchTransportations())
  .unwrap()
  .then((res) => {
    console.log(res.data.transportations, 'transportations')
    const transportationData = res?.data?.transportations 
    const isActive = transportationData.filter(transport => transport.isActive === true)
    console.log(isActive, 'ACT')
    setTransportations(isActive)
  })},[])

console.log(transportations, 'transportations')

useEffect (() => {
  if (driverId) {
    const driverRegionData = regions.find(region => region.id === formData?.regionData?.regionId)
    setSelectedRegion(driverRegionData)
  }  
}, [regions, driverId])

useEffect (() => {
  if (driverId) {
    const driverTerminalData = terminals.find(terminal => terminal.id === formData?.terminalData?.terminalId)
    setSelectedTerminal(driverTerminalData)
  }  
}, [terminals, driverId])



console.log(alreadySelectedRegion, 'WWWWWW')
console.log(regions, 'WWWWWWWW123')
const remainingRegions = regions.filter(reg => !alreadySelectedRegion.includes(reg.id))
console.log(remainingRegions, 'REG')
const remainingTerminals = terminals.filter(ter => !alreadySelectedTerminal.includes(ter.id))

const handleRegionChange = (e) => {
  const selectedId = e.target.value;
  const selectedRegionData = regions.find(region => region.id === selectedId);
  setSelectedRegion(selectedRegionData) 
  let errorsObj = {...errors}
  console.log(errorsObj, 'err123')
  delete errorsObj.region
  delete errorsObj.selectedRegion
}

const handleTerminalChange =(e) =>{
  const selectedTerminalId = e.target.value;
  console.log(selectedTerminalId, 'SELECTED')
  const selectedTerminalData = terminals.find(terminal => terminal.id === selectedTerminalId)
  setSelectedTerminal(selectedTerminalData)
  let errorsObj = {...errors}
  console.log(errorsObj, 'err123')
  delete errorsObj.terminal
  delete errorsObj.selectedTerminal
}

console.log('Policy File:', formData.policyFile);
console.log('ID File:', formData.idFile);
console.log('Driver Image', formData.fleetImage)
console.log(regions, 'rEGIONS')
console.log(formData?.policyFile,  'type')
console.log(isPdf, 'is pdf')

  return (
      <Box p={{ xs: 2, sm: 3, lg: 4 }} className="create-driver">
        <form style={{ marginTop: 0 }}>
          <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
            <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
            <Typography variant='h4' fontWeight="semibold" color="secondary">{ driverId ? 'Update Driver'  : 'Add Driver'}</Typography>

            { driverId && formData.fleetStatus !== 'pending'  && 
            <>
              <Box display="flex" alignItems="center" gap={1.25}>
                {/* <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography> */}
                <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
                  <MDButton
                    sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                    type='button'
                    variant={formData?.fleetStatus === "active" ? 'contained' : 'text'}
                    color= {formData?.fleetStatus === 'active' ? 'success' : 'dark'}
                    size="small"
                    onClick= {() => {
                      setFormData({ ...formData, fleetStatus: 'active' })
                    }}
                  >
                    Active
                  </MDButton>
                  <MDButton
                    sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                    type='button'
                    size="small"
                    variant={formData?.fleetStatus !== "active" ? 'contained' : 'text'}
                    color= {formData?.fleetStatus !== 'active' ? 'error' : 'dark'}
                    onClick= {() => {
                      setFormData({ ...formData, fleetStatus: 'inactive' })
                    }}
                  >
                    Inactive
                  </MDButton>

                </Stack>
              </Box>
              </>
                  }

            </Stack>

            <Grid container spacing={2} mb={4.5}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h5' fontWeight="semibold" color="secondary">Add Driver Photo</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Stack direction="row" flexWrap="wrap" gap={2} mb={2} alignItems="center">
                  {/* <ImageDragger images={[]} imageDragHandler="" /> */}
                 
                  {formData.fleetImage ?
                    <Zoom classDialog='custom-zoom' zoomImg={formData?.idFile} zoomMargin={10}   >
                      <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" borderRadius="50%" p={2} objectFix="cover" overflow="hidden" style={{ width:"100px", height:"100px" }}>
                        <img style={{ maxWidth:"100px" }} src={formData.fleetImage ? formData.fleetImage : iconDriverPlaceholder} /> 
                        {
                          isUploading && uploadedFileField.current === 'fleetImage' ? <CircularProgress size={32} color='primary' sx={badgeCircularProgressIcon} /> : ""
                        }
                      </Box>
                    </Zoom>
                    :
                    <label htmlFor='driver-image-input'>
                      <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" borderRadius="50%" p={2} objectFix="cover" overflow="hidden" style={{ width:"100px", height:"100px" }}>
                        
                        {!formData?.fleetImage && isUploading && uploadedFileField.current === 'fleetImage' ? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> :
                        <img style={{ maxWidth:"100px" }} src={formData.fleetImage ? formData.fleetImage : iconDriverPlaceholder} />
                        }
                      </Box>
                    </label>                 
                  }                  
                  {formData.fleetImage && 
                    <label htmlFor='driver-image-input'>
                      <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="row" borderRadius="50px" objectFix="cover" overflow="hidden" style={{ width: "140px", padding: "8px 10px" }} className="cur-pointer">
                        <Typography variant='h6' fontWeight="medium" textAlign="center" display="flex" alignItems="center" justifyContent="center" gap={1}>
                          <AddRoundedIcon />
                          <span>Update</span>
                        </Typography>
                        {/* <CircularProgress size={32} color='primary' /> :  */}
                        </Box>
                    </label>
                  }
                  <MDInput
                    id="driver-image-input"
                    type="file"
                    name="IDFile"
                    onChange={driverImageUploader}
                    fullWidth
                    style={{ display: 'none' }}
                    accept=".png, .jpg, .jpeg"
                  />
                </Stack>
              </Grid>
              {driverId && 
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label=""
                    className="form-input"
                    error={errors.fleetName}
                    helperText= {errors.fleetName}
                    fullWidth
                    placeholder="FleetId"
                    name="fleetName"
                    value={formData?.fleetDisplayId || ""}
                    disabled
                    onChange={handleChange}
                  />
                </Grid>
              }
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  error={errors.fleetName}
                  helperText= {errors.fleetName}
                  fullWidth
                  placeholder="Name"
                  name="fleetName"
                  value={formData?.fleetName || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                {/* <TextField */}
                <InputMask
                  label=""
                  className="form-input"
                  error={errors.fleetPhone}
                  helperText= {errors.fleetPhone}
                  fullWidth
                  placeholder="Phone"
                  name="fleetPhone"
                  value= {formData?.fleetPhone}
                  onChange={handleChange}
                  mask='(999) 999-9999'
                  maskChar=''
                >
                 {(inputProps) => <TextField {...inputProps} disableUnderline />}
                 </InputMask>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  error={errors.fleetEmail}
                  helperText= {errors.fleetEmail}
                  fullWidth
                  placeholder="Email"
                  name="fleetEmail"
                  onChange={handleChange}
                  value= {formData?.fleetEmail}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
              <FormControl error={Boolean(errors?.region)} fullWidth className='ha-field-design'>
              {driverId && !regions.length?  
              <InputLabel>
                Fetching Region
                <CircularProgress size={14} color='primary' justifyContent="flex-end" style={{marginLeft: "6px"}}/> 
              </InputLabel> 
              
              :<InputLabel id="user-type-label">Assign Region*</InputLabel>}
                <Select size='small'
                className='form-select' 
                name='region' 
                label='Assign Region' 
                placeholder="Assign Region" 
                value={selectedRegion ? selectedRegion.id : ''}
                // value= '61538d2ca755e9326af2a0cb'
                error={Boolean(errors.region)}
                // helperText= {errors.region}
                onChange={handleRegionChange}>
                {!regions.length ?
                <MenuItem>
                  <span>Fetching Regions...</span>
                  <CircularProgress size={14} color='primary' justifyContent="flex-end" style={{marginLeft: "auto"}}/> 
                </MenuItem>
:

                driverId && regions && regions.map(region => (
                  <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                ))
                }
                     {!driverId && remainingRegions && remainingRegions.map(region => (
                  <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                ))
                }
                </Select>
                {errors?.region && <FormHelperText error>{errors?.region}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
              <FormControl error={Boolean(errors?.region)} fullWidth className='ha-field-design'>
              
              {driverId && !terminals.length?  
              <InputLabel>
                Fetching Terminal
                <CircularProgress size={14} color='primary' justifyContent="flex-end" style={{marginLeft: "6px"}}/> 
              </InputLabel> 
              
              :
              <InputLabel id="user-type-label">Assign Terminal*</InputLabel>
              }
                <Select size='small'
                className='form-select' 
                name='region' 
                label='Assign Terminal' 
                placeholder="Assign Terminal" 
                value={selectedTerminal ? selectedTerminal.id : ''}
                // value= '61538d2ca755e9326af2a0cb'
                error={Boolean(errors.terminal)}
                // helperText= {errors.region}
                onChange={handleTerminalChange}>
                {!terminals.length ?
                <MenuItem>
                  <span>Fetching Terminal..</span>
                  <CircularProgress size={14} color='primary' justifyContent="flex-end" style={{marginLeft: "auto"}}/> 
                </MenuItem>
                :  
                              
                driverId && terminals && terminals.map(terminal => (
                  <MenuItem key={terminal.id} value={terminal.id}>{terminal.name}</MenuItem>
                ))
                }
                     {!remainingTerminals ?
                      <MenuItem>loading</MenuItem>

                     :
                     !driverId && remainingTerminals && remainingTerminals.map(terminal => (
                  <MenuItem key={terminal.id} value={terminal.id}>{terminal.name}</MenuItem>
                ))
                }
                </Select>
                {errors?.terminal && <FormHelperText error>{errors?.terminal}</FormHelperText>}
                </FormControl>
              </Grid>


            </Grid>

            

            <Grid container spacing={2} mb={4.5}>
              <Grid item xs={12} md={12} lg={12}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant='h5' fontWeight="semibold" color="secondary">Transportation</Typography>
                  <Typography variant="body2" color="secondary" mb={1}>Used for ETAs, Optimizations, and notification</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl className='d-flex custom-checkbox-ui '>
                    <RadioGroup
                      value={formData.fleetTransportationTypeId}
                      name='fleetTransportationTypeId'
                      label=""
                      onChange={handleChange}
                      color="secondary"
                    >
                      {
                        transportations.map(vehicle => (                                
                        <React.Fragment key={vehicle.value}>
                          <FormControlLabel 
                          value={vehicle._id} 
                          control={<Radio color="secondary" />} 
                          label={vehicle.name} />
                        </React.Fragment>
                        ))
                      }
                    </RadioGroup>
                {errors?.fleetTransportationTypeId && <FormHelperText error>{errors?.fleetTransportationTypeId}</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={4.5}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h5' fontWeight="semibold" color="secondary">Vehicle Details</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  error={errors.licencePlate}
                  helperText= {errors.licencePlate}
                  placeholder="License Plate"
                  name="fleetVehicleDetails.licensePlate"
                  value={formData.fleetVehicleDetails?.licensePlate}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  placeholder="Color"
                  name="fleetVehicleDetails.color"
                  value={formData.fleetVehicleDetails?.color}
                  onChange={handleChange}

                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  error={errors.year}
                  helperText= {errors.year}
                  fullWidth
                  placeholder="Year"
                  name="fleetVehicleDetails.year"
                  value={formData.fleetVehicleDetails?.year}
                  onChange={handleChange}
                  type='number'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  placeholder="Make"
                  name="fleetVehicleDetails.make"
                  value={formData.fleetVehicleDetails?.make}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  placeholder="Model"
                  name="fleetVehicleDetails.model"
                  value={formData.fleetVehicleDetails?.model}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  placeholder="Insurance Provider"
                  name="fleetVehicleDetails.insuranceProvider"
                  value={formData.fleetVehicleDetails?.insuranceProvider}
                  onChange={handleChange}
                  type=''
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  error={errors.policyNumber}
                  helperText= {errors.policyNumber}
                  fullWidth
                  placeholder="Policy Number"
                  name="fleetVehicleDetails.policyNumber"
                  value={formData.fleetVehicleDetails.policyNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <DatePicker
                  sx={{ width: "100%" }}
                  label="Expiration Date*"
                  error={errors?.expirationDate}
                  helperText={errors?.expirationDate}
                  name="fleetVehicleDetails.expirationDate"
                  value={formData?.fleetVehicleDetails.expirationDate || null}
                  // onChange={handleChange}
                  disablePast
                  onChange={(value) => setFormData({ ...formData, expirationDate: value })}
                  // InputLabelProps={{ shrink: true }}
                  slots={{
                      openPickerIcon: CalendarIcon
                  }}
                  slotProps={{
                      openPickerButton: {
                          color: 'secondary',
                      },
                  }}
                  onError={() => {
                      return Boolean(errors?.expirationDate)
                  }}
                  format='MM/DD/YYYY'
                />
                {Boolean(errors?.expirationDate) && <FormHelperText error>{errors?.expirationDate}</FormHelperText>}
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={4.5}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h5' fontWeight="semibold" color="secondary">Policy File (Image /pdf)</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Stack direction={`${isPolicyPdf ? 'column' : 'row'}`} alignItems="start"  gap={1.25} style={{ position: "relative" }}>
                {formData?.policyFile &&
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    className="upload-loader"
                    style={{position: "relative"}}
                    badgeContent={
                      <>
                        {
                            !isUploading && <PlusIcon color='transparent' onClick={() => setFormData({ ...formData, policyFile:'' })} fontSize='small' sx={{ backgroundColor: "#FD4438", borderRadius: 10, p: .25, mt: -4, mr: -4, cursor: "pointer" }} />
                        }
                        {
                            isUploading && uploadedFileField.current === 'policyFile' && !formData?.policyFile?.endsWith('.pdf') ? <CircularProgress size={32} color='primary' className='circular-loader' sx={badgeCircularProgressIcon} /> : ""
                        }
                      </>
                    }
                  >

                    {formData?.policyFile && !(isPolicyPdf || formData?.policyFile?.endsWith('.pdf')) && <Zoom classDialog='custom-zoom' zoomImg={formData?.policyFile} zoomMargin={10} ><img style={uploadThumbnailImage} src={formData?.policyFile} alt="documentId" /></Zoom>}
                  </Badge>
                }

                  <label htmlFor='driver-policy-file-input'>
                    <Box position="relative" bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="160px" minHeight="160px" borderRadius="20px" p={2} className="cur-pointer">
                      {
                        ( !formData?.policyFile && isUploading && uploadedFileField.current === 'policyFile')  ||  (formData?.policyFile &&  uploadedFileField.current === 'policyFile' &&isUploading && formData?.policyFile?.endsWith('.pdf') ) ? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> :
                        <Typography variant='h6' fontWeight="medium" textAlign="center">+<br /> Upload <br />Document</Typography>
                      }
                    </Box>
                  </label>

                  <MDInput
                    id="driver-policy-file-input"
                    type="file"
                    name="IDFile"
                    onChange={driverPolicyFileUploader}
                    fullWidth
                    style={{ display: 'none' }}
                    accept=".png, .jpg, .jpeg, .pdf"
                  />
                </Stack>

                {formData?.policyFile && (isPolicyPdf || formData?.policyFile?.endsWith('.pdf')) &&
                  <div className='mt-2'>
                    <Link to={formData.policyFile} style={{ color: 'inherit' }} >
                      <Typography variant='body2' fontWeight="medium" sx={{ wordBreak: "break-all" }}>{formData.policyFile}</Typography>
                    </Link>
                  </div>
                }
                {
                  errors?.policyFile && <FormHelperText sx={{ width: "100%", ml: 0, mt: -.001 }} error={errors?.policyFile}>{errors?.policyFile}</FormHelperText>
                }
              </Grid>
            </Grid> 

            <Grid container spacing={2} mb={4.5}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h5' fontWeight="semibold" color="secondary">Other Info</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  error={errors.licenseNumber}
                  helperText= {errors.licenseNumber}
                  placeholder="Drivers license number"
                  name="fleetOtherInfo.licenseNumber"
                  value={formData.fleetOtherInfo.licenseNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <DatePicker
                  sx={{ width: "100%" }}
                  label="Date of birth*"
                  error={errors?.dob}
                  helperText={errors?.dob}
                  name="dob"
                  value={formData?.fleetOtherInfo.dob || null}
                  onChange={(value) => setFormData({ ...formData, dob: value })}
                  // InputLabelProps={{ shrink: true }}
                  slots={{
                      openPickerIcon: CalendarIcon
                  }}
                  disableFuture
                  slotProps={{
                      openPickerButton: {
                          color: 'secondary',
                      },
                  }}
                  onError={() => {
                      return Boolean(errors?.dob)
                  }}
                  format='MM/DD/YYYY'
                />
              {Boolean(errors?.dob) && <FormHelperText error>{errors?.dob}</FormHelperText>}
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <DatePicker
                  sx={{ width: "100%" }}
                  label="License Expiration Date*"
                  error={errors?.licenseExpirationDate}
                  helperText={errors?.licenseExpirationDate}
                  name="dob"
                  value={formData?.fleetOtherInfo.licenseExpirationDate || null}
                  onChange={(value) => setFormData({ ...formData, licenseExpirationDate: value })}
                  // InputLabelProps={{ shrink: true }}
                  disablePast
                  slots={{
                      openPickerIcon: CalendarIcon
                  }}
                  slotProps={{
                      openPickerButton: {
                          color: 'secondary',
                      },
                  }}
                  onError={() => {
                      return Boolean(errors?.dob)
                  }}
                  format='MM/DD/YYYY'
                />
                {Boolean(errors?.licenseExpirationDate) && <FormHelperText error>{errors?.licenseExpirationDate}</FormHelperText>}
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h5' fontWeight="semibold" color="secondary">ID File (Image /pdf)</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Stack direction={`${isPdf ? 'column' : 'row'}`} alignItems="start"  gap={1.25} style={{ position: "relative" }}>
                {formData?.idFile &&
                  <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      className="upload-loader"
                      badgeContent={
                          <>
                              {
                                  !isUploading && <PlusIcon color='transparent' onClick={() => setFormData({ ...formData, idFile:'' })} fontSize='small' sx={{ backgroundColor: "#FD4438", borderRadius: 10, p: .25, mt: -4, mr: -4, cursor: "pointer" }} />
                              }
                              {
                                  isUploading &&  uploadedFileField.current === 'IdFile'  && !formData?.policyFile?.endsWith('.pdf') ? <CircularProgress size={32} color='primary' className='circular-loader' sx={badgeCircularProgressIcon} /> : ""
                              }
                          </>
                      }
                  >
                {formData?.idFile && !(isPdf || formData?.idFile?.endsWith('.pdf')) &&  <Zoom classDialog='custom-zoom' zoomImg={formData?.idFile} zoomMargin={10}   ><img style={uploadThumbnailImage} src={formData?.idFile} alt="IdFile" /></Zoom>}             
                  </Badge>
                  }
                  <label htmlFor='driver-id-file-input'>
                    <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="160px" minHeight="160px" borderRadius="20px" p={2} className="cur-pointer">
                   {
                    !formData?.idFile && isUploading && uploadedFileField.current === 'IdFile' ? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> :
                      <Typography variant='h6' fontWeight="medium" textAlign="center">+<br /> Upload <br />Document</Typography>
                   }
                      </Box>
                  </label>
                  <MDInput
                    id="driver-id-file-input"
                    type="file"
                    name="IDFile"
                    onChange={driverIdFileUploader}
                    fullWidth
                    style={{ display: 'none'  }}
                    accept=".png, .jpg, .jpeg"
                />
                </Stack>
                {formData?.idFile && (isPdf || formData?.idFile?.endsWith('.pdf')) &&
                  <div className='mt-2'>
                    <Link to={formData.idFile} style={{ color: 'inherit' }} >
                      <Typography variant='body2' fontWeight="medium" sx={{ wordBreak: "break-all" }}>{formData.idFile}</Typography>
                    </Link>
                  </div>
                }
                {
                  errors?.idFile && <FormHelperText sx={{ width: "100%", ml: 0, mt: -.001 }} error={errors?.idFile}>{errors?.idFile}</FormHelperText>
                }
              </Grid>
            </Grid>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
              <MDButton
                  sx={{ minWidth: "140px" }}
                  type='submit'
                  variant='contained'
                  onClick = {handleSubmit}
                  color='primary'
              >
                {driverId  ?  'Update' : 'Save'}
              </MDButton>
              <MDButton
                sx={{ minWidth: "140px" }}
                type='button'
                variant='outlined'
                color='dark'
                onClick={() => navigate('/hyperwolf/driver')}
              >
                Cancel
              </MDButton>
            </Stack>
          </Card>
        </form>
      </Box>
  )
}
