import React from 'react'
import { Skeleton } from '@mui/material';

const SkeletonText = ({ variant = "text", height = "10" }) => {
    return (
        <div className='skeleton'>
            <Skeleton variant={variant} sx={{ fontSize: '1.5rem' }} height={height} />
        </div>
    )
}

export default SkeletonText
