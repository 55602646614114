import { Badge, List, ListItem, Typography } from '@mui/material'
import StatusTask from 'common/component/StatusTask'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getDriverAnalyticsInfo } from '../../../redux/slices/hyperdrive/driverAnalytics';
import { useDispatch } from 'react-redux';
import SkeletonText from 'common/component/SkeletonText';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { formatTimestampToWithoutTimeToday } from 'utilities/common';
import { formatTimestampToTime } from 'utilities/common';

const DriverCheckInOut = ({ driverId }) => {
    const dispatch = useDispatch()
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();

    const loadMorePosts = useCallback(async () => {
        setLoading(true);
        try {
            const limit = 20;
            const skip = page * limit;
            const res = await dispatch(getDriverAnalyticsInfo({
                driverId,
                data: {
                    analyticType: "checklist",
                    skip,
                    limit: 20
                }
            })).unwrap();
            let newPosts = res?.data?.checkList

            if (newPosts?.length === 0) {
                setHasMore(false);
            } else {
                setPosts((prevPosts) => [...prevPosts, ...newPosts]);
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [dispatch, page]);

    useEffect(() => {
        if (hasMore) {
            loadMorePosts();
        }
    }, [loadMorePosts, hasMore]);

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading || !hasMore) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    return (
        <List className="d-flex flex-column gap-3">
            {posts ? <>   {
                posts?.map((data,idx) => {
                    return (
                        <ListItem ref={posts.length === idx + 1 ? lastPostElementRef : null}
                            key={data?._id} className="d-flex flex-column p-3 bg-grey-900 border-radius-8 border-none gap-3">
                            <div className="w-100 d-flex flex-column">
                                <Typography variant='h4' className='text-white-700 text-16 f-wrap line-height-normal'>
                                    {formatTimestampToWithoutTimeToday(data?.checkIn)}
                                </Typography>

                                <div className="w-100 d-flex align-item-star my-2 gap-3">
                                    <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                        <AccessTimeIcon className="text-purple-100 text-24" />
                                        <div className="w-100 d-flex flex-column align-item-center">
                                            <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                <Typography className="text-black-200 text-14">Check In</Typography>
                                                <Typography className="text-black-500 text-14">{data?.checkIn ? `${formatTimestampToTime(data?.checkIn)}AM` : "-"}</Typography>
                                            </div>
                                            <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                <Typography className="text-black-200 text-14">Check Out</Typography>
                                                <Typography className="text-black-500 text-14">{data?.checkOut ? `${formatTimestampToTime(data?.checkOut)}PM` : "-"}</Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                        <PaidOutlinedIcon className="text-yellow-100 text-24" />
                                        <div className="w-100 d-flex flex-column align-item-center">
                                            <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                <Typography className="text-black-200 text-14">Starting Cash</Typography>
                                                <Typography className="text-black-500 text-14">{data?.drawerStartingCash == "" ? `$${data?.drawerStartingCash}` : "0"}</Typography>
                                            </div>
                                            <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                <Typography className="text-black-200 text-14">Total Cash</Typography>
                                                <Typography className="text-black-500 text-14">{data?.drawerTotalCash == "" ? `$${data?.drawerTotalCash}` : "0"}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Typography className="text-black-400 font-w-600 text-14">Total Mileage</Typography>

                                <div className="w-100 d-flex gap-3">
                                    <Typography className="text-black-400 text-14">
                                        Approved Miles
                                        <span className="text-white-900 font-w-600 text-16 ml-2">--</span>
                                    </Typography>
                                    <Typography className="text-black-400 text-14">
                                        Total Hours
                                        <span className="text-white-900 font-w-600 text-16 ml-2">{data?.totalHours}</span>
                                    </Typography>
                                </div>
                            </div>
                        </ListItem>

                    )
                })
            }
            </>
                : <SkeletonText variant={"rounded"} height={70} />
            }
        </List>
    )
}

export default DriverCheckInOut
