import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);


const defaultOptions = {
    responsive: true,
    plugins: {
        legend: {
            // position: 'top',
            display: true
        },
        legend: {
            display: false
        },
        tooltip: {
            backgroundColor: "#fdfdfd",
            bodyColor: "#16161E",
            titleColor: "#16161E",
            borderWidth: 1,
            borderColor: "#F1F1F1",
            padding: 8
        },
    },
    scales: {
        y: {
            display: false,
            ticks: {
                beginAtZero: true,
                display: false,
                stepSize: 10,
                min: -100,
                max: 100
            },
        },
        x: {
            display: false,
        },
    }
};
export default function LineChart({ chartData, options =  defaultOptions  }) {
    return <Line options={options} data={chartData} />;
}