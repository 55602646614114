import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField, Card, Typography, FormHelperText, CircularProgress, Box, Stack, Badge, InputAdornment, IconButton } from '@mui/material';
import MDButton from 'components/MDButton';
import { useDispatch, useSelector } from 'react-redux';
import { createTerpenoid, deleteTerpenoid, updateTerpenoid } from '../../redux/slices/terpenoids';
import { useNavigate, useParams } from 'react-router';
import { openSnackbar, uploadImage } from '../../redux/slices/common';
import { isEmptyObject } from 'utilities/common';
import defaultImage from 'assets/images/placeholder-image.png';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import { AccountCircle } from '@mui/icons-material';
import { validateCannabinoids } from 'validations/cannabinoids';
import CancelIcon from '@mui/icons-material/Cancel';
import CommonQuillEditor from 'common/editor';
import { grey } from '@mui/material/colors';
import { createCannabinoid, deleteCannabinoid, updateCannabinoid } from '../../redux/slices/cannabinoids';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AngleRightIcon from 'common/customIcons/angle_right';

const circularProgressIcon = {
    backgroundColor: "#fff",
    borderRadius: 10,
    p: .5,
    position: "absolute"
};

const uploadThumbnailImage = {
    borderRadius: "20px",
    width: "160px",
    height: "160px",
    objectFit: "cover",
    boxShadow: "0 0 1px rgb(0 0 0 / 20%)",
    cursor: "move"
};
const addCannabinoidsForm = () => {
    const { user } = useSelector(state => state.auth)
    // const { singleCannabinoid = {}, isLoading = false, } = useSelector(state => state.terpenoids)
    const { singleCannabinoid = {}, isLoading = false, } = useSelector(state => state.cannabinoids)
    const { isUploading = false } = useSelector(state => state.common)
    const { cannabinoidId = "" } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        status: true,
        createdBy: user?.name || "",
        tags: [],
        cannabinoidName: '',
        createdBy: user?.name || "",
        listImage: '',
        detailImage: '',
        detailImageAlt: '',
        listImageAlt: ''
    });
    const currentImageUploadFieldRef = useRef()
    const [errors, setErrors] = useState()

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const removeListsImageHandler = (productIdx = '') => {
        // if (!productIdx) return;
        setFormData({ ...formData, listImage: '' })

    }

    const listImageUploadHandler = async (e) => {
        currentImageUploadFieldRef.current = 'list_image'
        // if(!isAllowProductUpload) return showResonseMessage('Please add product name , brand name and category name first','error');
        if (e.target.files.length) {
            const file = e.target.files[0];
            const body = new FormData()
            body.append("filePath", file);
            dispatch(uploadImage(body)).unwrap().then((res => {
                let newErrors = { ...errors }
                delete newErrors.listImage
                setErrors(newErrors)
                setFormData({ ...formData, listImage: res.fileUrl })
            })).catch((err) => {
                showResonseMessage(err?.message, 'error')
            })
        }

    }


    const detailPageImageUploadHandler = async (e) => {
        // if(!isAllowProductUpload) return showResonseMessage('Please add product name , brand name and category name first','error');
        currentImageUploadFieldRef.current = 'detail_page_image'
        if (e.target.files.length) {
            const file = e.target.files[0];
            const body = new FormData()
            body.append("filePath", file);
            dispatch(uploadImage(body)).unwrap().then((res => {
                let newErrors = { ...errors }
                delete newErrors.detailImage
                setErrors(newErrors)
                setFormData({ ...formData, detailImage: res.fileUrl })
            })).catch((err) => {
                showResonseMessage(err?.message, 'error')
            })
        }

    }

    const terpenoidImageUploadHandler = async (e) => {
        if (e.target.files.length) {
            const file = e.target.files[0];
            const body = new FormData()
            body.append("filePath", file);
            // res.fileUrl
            dispatch(uploadImage(body)).unwrap().then((res => {
                showResponseMessage(res?.message, 'success')
                setFormData({ ...formData, image: res.fileUrl })
            })).catch((err) => {
                showResponseMessage(err?.message, 'error')
            })
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const { isValid, errors } = validateCannabinoids(formData)
        if (!isValid) {
            setErrors(errors)
            return;
        }
        setErrors({})
        if (cannabinoidId) {
            dispatch(updateCannabinoid({ data: formData, id: cannabinoidId })).unwrap().then((res) => {
                showResponseMessage(res?.message, 'success')
                navigate('/manage-cannabinoids?cannabinoidTab=cannabinoids')
            }).catch((err) => {
                showResponseMessage(err?.message, 'error')
            })
        } else {
            dispatch(createCannabinoid(formData)).unwrap().then((res) => {
                showResponseMessage(res?.message, 'success')
                navigate('/manage-cannabinoids?cannabinoidTab=cannabinoids')
            }).catch((err) => {
                showResponseMessage(err?.message, 'error')
            })
        }

    };

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }


    const deleteHandler = (cannabinoidId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteCannabinoid(cannabinoidId)).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            navigate('/manage-cannabinoids?cannabinoidTab=cannabinoids')
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showResponseMessage(err?.message, 'error')
        })
    }

    useEffect(() => {
        if (!isEmptyObject(singleCannabinoid)) {
            setFormData({ ...formData, ...singleCannabinoid })
        }
    }, [singleCannabinoid])

    const uploadThumbnailImage = {
        borderRadius: "20px",
        width: "160px",
        height: "160px",
        objectFit: "cover",
        boxShadow: "0 0 1px rgb(0 0 0 / 20%)"
    };
    const badgeCircularProgressIcon = {
        backgroundColor: "#fff",
        borderRadius: 10,
        p: .5,
        mr: 14,
        mt: 14
    };

    useEffect(() => {
        setFormData({ ...formData, createdBy: user?.name })
    }, [user])

    return (
        <Box p={{ xs: 2, sm: 3, lg: 4 }}>
            <Card sx={{ p: { xs: 2, md: 3 }, mb: 2, borderRadius: 2.5, boxShadow: "none" }}>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
                    <Box display="flex" alignItems="center" color='text.main' lineHeight={1}>
                        <IconButton onClick={() => navigate('/manage-cannabinoids?cannabinoidTab=cannabinoids')}>
                            <AngleRightIcon fontSize="14px" />
                        </IconButton>
                        <Typography variant='h4' fontWeight="semibold" color="secondary" ml={.75}>
                            {cannabinoidId ? 'Edit' : "Add"} Cannabinoid
                        </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" gap={1.25}>
                        <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
                        <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
                            <MDButton
                                sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                                type='button'
                                variant={formData?.status ? 'contained' : 'text'}
                                color={formData?.status ? 'success' : 'dark'}
                                size="small"
                                onClick={() => setFormData({ ...formData, status: true })}
                            >
                                Active
                            </MDButton>
                            <MDButton
                                sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                                type='button'
                                variant={!formData?.status ? 'contained' : 'text'}
                                color={!formData?.status ? 'error' : 'dark'}
                                size="small"
                                onClick={() => setFormData({ ...formData, status: false })}
                            >
                                Inactive
                            </MDButton>
                        </Stack>
                    </Box>
                </Stack>

                {/* <Typography variant='h5' fontWeight="semibold" mb={2}>Cannabinoids Banner*</Typography>
                <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2} mb={4.5}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                        badgeContent={
                            isUploading ? <CircularProgress size={32} color='primary' sx={badgeCircularProgressIcon} /> : ""
                        }
                    >
                        <img style={uploadThumbnailImage} src={formData?.image || defaultImage} alt="documentId" />
                    </Badge>

                    <label htmlFor="terpeneImageInput">
                        <MDButton
                            sx={{ minWidth: "180px" }}
                            type='button'
                            variant='outlined'
                            color='secondary'
                            component='span'
                        >
                            {!formData?.image ? 'Add' : 'Change'} Image
                        </MDButton>
                    </label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={terpenoidImageUploadHandler}
                        style={{ display: 'none' }}
                        id="terpeneImageInput"
                    />
                    {
                        errors?.image && <FormHelperText sx={{ width: "100%", ml: 0, mt: -.5 }} error>{errors?.image}</FormHelperText>
                    }
                </Stack> */}

                {/* <Typography variant='h5' fontWeight="semibold" mb={2}>Cannabinoids Info</Typography> */}
                <Grid container spacing={2.5} mb={2}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Cannabinoid Name*"
                            fullWidth
                            variant="outlined"
                            name="cannabinoidName"
                            value={formData.cannabinoidName}
                            onChange={handleInputChange}
                            error={Boolean(errors?.cannabinoidName)}
                            helperText={errors?.cannabinoidName}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                            className="form-input created-by"
                            label="Created By"
                            fullWidth
                            variant="outlined"
                            name="createdBy"
                            disabled
                            value={formData.createdBy}
                            onChange={handleInputChange}
                            error={Boolean(errors?.createdBy)}
                            helperText={errors?.createdBy}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle fontSize='medium' color='secondary' />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Grid container>
                            <Grid item xs={12} sm={6} lg={6}>
                                <TextField
                                    label="Tags*"
                                    fullWidth
                                    variant="outlined"
                                    name="tags"
                                    value={formData?.tags?.join(',')}
                                    onChange={(e) => setFormData({ ...formData, tags: e.target.value ? e.target.value?.split(',') : [] })}
                                    error={Boolean(errors?.tags)}
                                    helperText={errors?.tags}
                                />
                            </Grid>

                            {
                                formData?.tags?.length > 0 && <Grid item xs={12} sm={12} lg={12}>
                                    <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" gap="10px" flexWrap="wrap" my={2}>
                                        {
                                            formData?.tags?.map(((tag, tagIndex) => {
                                                if (!tag) return;
                                                return <Box key={tagIndex} sx={{ bgcolor: "#F2F3F7", px: 2, py: 0.75, borderRadius: 2 }}>
                                                    <Typography fontWeight="medium" color="dark" className='fs-x-small d-flex align-item-center gap-1'>
                                                        <span>{tag}</span>
                                                        <CancelIcon onClick={() => {
                                                            setFormData({ ...formData, tags: formData?.tags?.filter((tag, index) => index !== tagIndex) })
                                                        }} color='error' style={{ width: "16px", height: "16px", cursor: "pointer" }} />
                                                    </Typography>
                                                </Box>
                                            }))
                                        }
                                    </Stack>
                                </Grid>
                            }



                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                        <TextField className="form-input" fullWidth label="Terpene ID" name="terpeneID" />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            label="Title"
                            fullWidth
                            variant="outlined"
                            name="title"
                            value={formData?.title || ""}
                            onChange={handleInputChange}
                            error={Boolean(errors?.title)}
                            helperText={errors?.title}
                            className="form-input"
                            multiline rows={4}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Description1</Typography>
                        <CommonQuillEditor
                            value={formData?.description1 || ""}
                            onChange={(value) => setFormData({ ...formData, description1: value })}
                        />
                        {
                            errors?.description1 && <FormHelperText error>{errors?.description1}</FormHelperText>
                        }
                        {/* <TextField className="form-input" fullWidth multiline rows={4} label="Sub-text" name="description" /> */}
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Description2</Typography>
                        <CommonQuillEditor
                            value={formData?.description2 || ""}
                            onChange={(value) => setFormData({ ...formData, description2: value })}
                        />
                        {
                            errors?.description2 && <FormHelperText error>{errors?.description2}</FormHelperText>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Meta Title"
                            fullWidth
                            variant="outlined"
                            name="metaTitle"
                            value={formData?.metaTitle || ""}
                            onChange={handleInputChange}
                            error={Boolean(errors?.metaTitle)}
                            helperText={errors?.metaTitle}
                            className="form-input"
                            multiline rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Meta Description"
                            fullWidth
                            variant="outlined"
                            name="metaDescription"
                            value={formData?.metaDescription || ""}
                            onChange={handleInputChange}
                            error={Boolean(errors?.metaDescription)}
                            helperText={errors?.metaDescription}
                            className="form-input"
                            multiline rows={4}
                        />
                    </Grid>



                    <Grid item xs={12}>
                        <Stack fullWidth direction="column" alignItems='flex-start' flexWrap="wrap" gap={1.25} mb={2}>
                            <Box width='100%'  mt={2}>
                                <Typography fontSize={15} mb={2} color={grey[600]}>Upload Image for list (351x199)</Typography>

                                <Stack direction="row" flexWrap="wrap" gap={1.25} mb={1}>
                                    {
                                        formData?.listImage && <>

                                            <img src={formData?.listImage} alt={formData?.listImage} style={uploadThumbnailImage} />
                                        </>
                                    }
                                    <label htmlFor='list-image-input'>
                                        <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="160px" minHeight="160px" borderRadius="20px" p={2} sx={{ cursor: 'pointer' }}>
                                            {
                                                isUploading && currentImageUploadFieldRef.current === 'list_image' ? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> : <Typography variant='h5' fontWeight="medium" textAlign="center">+<br />  {formData?.listImage ? "Update" : 'Add'}</Typography>
                                            }
                                        </Box>
                                    </label>
                                    <TextField
                                        id="list-image-input"
                                        type='file'
                                        name='productImageFile'
                                        onChange={listImageUploadHandler}
                                        sx={{ display: 'none' }}
                                    />

                                </Stack>
                                {Boolean(errors?.listImage) &&
                                    <FormHelperText error>{errors?.listImage}</FormHelperText>
                                }
                                <Grid container mt={3}>
                                    <Grid item xs={3} lg={3}>
                                        <TextField
                                            label="Alt text for list image"
                                            fullWidth
                                            variant="outlined"
                                            name="listImageAlt"
                                            value={formData?.listImageAlt || ""}
                                            onChange={handleInputChange}
                                            error={Boolean(errors?.listImageAlt)}
                                            helperText={errors?.listImageAlt}
                                            className="form-input"
                                        />
                                    </Grid>
                                </Grid>


                            </Box>

                            <Box width='100%' mt={3}>
                                <Typography fontSize={15} mb={2} color={grey[600]}>Upload Image for detail page (1920x390)</Typography>

                                <Stack direction="row" flexWrap="wrap" gap={1.25} mb={1}>

                                    {
                                        formData?.detailImage && <>

                                            <img src={formData?.detailImage} alt={formData?.detailImage} style={uploadThumbnailImage} />
                                        </>
                                    }
                                    <label htmlFor='detail-page-image-input'>
                                        <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="160px" minHeight="160px" borderRadius="20px" p={2} sx={{ cursor: 'pointer' }}>
                                            {
                                                isUploading && currentImageUploadFieldRef.current === 'detail_page_image' ? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> : <Typography variant='h5' fontWeight="medium" textAlign="center">+<br /> {formData?.detailImage ? "Update" : 'Add'}</Typography>
                                            }
                                        </Box>
                                    </label>
                                    <TextField
                                        id="detail-page-image-input"
                                        type='file'
                                        name='productImageFile'
                                        onChange={detailPageImageUploadHandler}
                                        sx={{ display: 'none' }}
                                    />

                                </Stack>
                                {Boolean(errors?.detailImage) &&
                                    <FormHelperText error>{errors?.detailImage}</FormHelperText>
                                }
                                <Grid container mt={3}>
                                    <Grid item xs={3} lg={3}>
                                        <TextField
                                            label="Alt text for detail image"
                                            fullWidth
                                            variant="outlined"
                                            name="detailImageAlt"
                                            value={formData?.detailImageAlt || ""}
                                            onChange={handleInputChange}
                                            error={Boolean(errors?.detailImageAlt)}
                                            helperText={errors?.detailImageAlt}
                                            className="form-input"
                                        />
                                    </Grid>
                                </Grid>

                            </Box>

                        </Stack>
                    </Grid>

                </Grid>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={2} mb={2}>
                    <MDButton
                        sx={{ minWidth: "140px" }}
                        type='button'
                        variant='contained'
                        color='primary'
                        onClick={handleSubmit}
                        loading={isLoading}
                    >
                        {cannabinoidId ? 'Update' : "Save"}
                    </MDButton>

                    {/* {
                        cannabinoidId && <MDButton
                            sx={{ minWidth: "140px", backgroundColor: "#F1F1F1", color: "#FD4438" }}
                            type='button'
                            variant='contained'
                            color='error'
                            onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: cannabinoidId }, modal: 'DELETE_MODAL' }))}

                        >
                            Delete
                        </MDButton>
                    } */}

                    <MDButton
                        sx={{ minWidth: "140px" }}
                        type='button'
                        variant='outlined'
                        color='dark'
                        onClick={() => navigate('/manage-cannabinoids?cannabinoidTab=cannabinoids')}
                    >
                        Cancel
                    </MDButton>
                </Stack>
            </Card>
        </Box>
    );
};

export default addCannabinoidsForm;
