import axios from 'axios';
import { getData } from 'utilities/common';
import { AccessTokenId } from 'utilities/constants';

// Create an instance of Axios with default configurations
const axiosClientHyperDrive = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_HYPER_DRIVE, // Replace with your axiosClient base URL
    headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
    }
});

// Request interceptor: Modify requests before they are sent
axiosClientHyperDrive.interceptors.request.use(
    (config) => {
        const user = getData('login-user-info')
        if (user) {
            const parseUser = JSON.parse(user)
            config.headers['Authorization'] = 'Bearer ' + parseUser?.access_token
            // config.headers['Authorization'] = 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmbGVldElkIjoiNjZjNTk5ZWUzZDg2MzkxMTA4YTQ4NTMxIiwiZmxlZXRFbWFpbCI6InNocnV0aUB0ZWNoaW5kdXN0YW4uY29tIiwiaWF0IjoxNzI1OTcxNjg5LCJleHAiOjE3MjYxNDQ0ODl9.xDdMt4D2LDvDcLiZuqCxzcIg35QKWMN6BcQ3vEOa-oQ'

        }
        // config.headers['Content-Type'] = 'application/json';
        return config
    },
    (error) => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

// Response interceptor: Modify responses before they are handled by the calling code
axiosClientHyperDrive.interceptors.response.use(
    (response) => {
        // Modify response data or perform actions before passing it to the calling code
        return response?.data;
    },
    (error) => {
        const originalRequest = error.config
        if (
            error?.response?.status === 401
        ) {
            //   router.push('/login')
            window.location.href = "/login"
            return Promise.reject(error)
        }

        // if (error.response.status === 401 && !originalRequest._retry) {
        //     originalRequest._retry = true
        //     const refreshToken = localStorageService.getRefreshToken()
        //     return axios
        //         .post('/auth/token', {
        //             refresh_token: refreshToken
        //         })
        //         .then(res => {
        //             if (res.status === 201) {
        //                 localStorageService.setToken(res.data)
        //                 axios.defaults.headers.common['Authorization'] =
        //                     'Bearer ' + localStorageService.getAccessToken()
        //                 return axios(originalRequest)
        //             }
        //         })
        // }
        return Promise.reject(error)
    }
);

export default axiosClientHyperDrive;