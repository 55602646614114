import { Box, Card, CardContent, CardHeader, Divider, FormControlLabel, FormGroup, Grid, List, ListItem, ListItemText, Switch, Typography } from '@mui/material'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { fetchPaymentPlatformStatus, fetchSignupStatus, openSnackbar, updatePaymentPlatformStatus, updateSignupStatus } from '../../redux/slices/common'
import icon1 from '../../assets/images/persona.png';
import icon2 from '../../assets/images/age-checker.png';
import icon3 from '../../assets/images/registration.png';
import leisurepay_logo from '../../assets/images/leisurepay-logo.jpg'
import maverick_logo from '../../assets/images/maverick_logo.jpeg'


export default function ManageSignupType() {
    const dispatch = useDispatch()
    const { signupStatus = {}, isLoading = false, paymentPlatformStatus = {} } = useSelector(state => state.common)

    console.log("paymentPlatformStatus", paymentPlatformStatus)

    const onStatusChangeHandler = (e) => {
        let payload = {
            "personaStatus": "inactive",
            "registerStatus": "inactive",
            "agecheckerStatus": "inactive"
        }
        payload = {
            ...payload,
            [e.target.name]: e.target.checked ? 'active' : 'inactive'
        }
        if (Object.values(payload).every(status => status === 'inactive')) {
            showResponseMessage('Atleast one registration should be active', 'error')
            return;
        }
        dispatch(updateSignupStatus(payload)).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success')
            dispatch(fetchSignupStatus())
        })
    }

    const onPaymentStatusChangeHandler = (e) => {
        let payload = {
            "maverickStatus": "inactive",
            "leisurepayStatus": "inactive"
        }
        payload = {
            ...payload,
            [e.target.name]: e.target.checked ? 'active' : 'inactive'
        }
        if (Object.values(payload).every(status => status === 'inactive')) {
            showResponseMessage('Atleast one platform should be active', 'error')
            return;
        }
        dispatch(updatePaymentPlatformStatus(payload)).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success')
            dispatch(fetchPaymentPlatformStatus())
        })
    }
    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    useEffect(() => {
        dispatch(fetchSignupStatus())
        dispatch(fetchPaymentPlatformStatus())

    }, [])
    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={6}>
                        <Typography variant='h4' mb={3}>Members Registration</Typography>
                        <Card>
                            {/* <CardHeader>Manage Singup Type</CardHeader> */}
                            <CardContent sx={{ px: 4, py: 3 }}>
                                {/* <FormGroup onChange={onStatusChangeHandler}>
                                    <FormControlLabel disabled={isLoading} name='personaStatus' control={<Switch checked={signupStatus?.personaStatus === 'active'} />} label="Persona" />
                                    <FormControlLabel  disabled={isLoading} name='agecheckerStatus' control={<Switch checked={signupStatus?.agecheckerStatus === 'active'} />} label="AgeChecker" />
                                    <FormControlLabel disabled={isLoading} name='registerStatus' control={<Switch checked={signupStatus?.registerStatus === 'active'} />} label="Register" />
                                </FormGroup> */}

                                <FormGroup onChange={onStatusChangeHandler}>
                                    <List>
                                        <ListItem alignItems="center" sx={{ justifyContent: "space-between" }}>
                                            <Typography variant="h5" fontWeight="medium" display="inline-flex" alignItems="center" gap={1}>
                                                <img src={icon1} height="28px" />
                                                Persona:
                                            </Typography>
                                            <FormControlLabel disabled={isLoading} name='personaStatus' control={<Switch checked={signupStatus?.personaStatus === 'active'} />} sx={{ m: 0 }} />
                                        </ListItem>
                                        <Divider />
                                        <ListItem alignItems="center" sx={{ justifyContent: "space-between" }}>
                                            <Typography variant="h5" fontWeight="medium" display="inline-flex" alignItems="center" gap={1}>
                                                <img src={icon2} height="28px" />
                                                AgeChecker:
                                            </Typography>
                                            <FormControlLabel disabled={isLoading} name='agecheckerStatus' control={<Switch checked={signupStatus?.agecheckerStatus === 'active'} />} sx={{ m: 0 }} />
                                        </ListItem>
                                        <Divider />
                                        <ListItem alignItems="center" sx={{ justifyContent: "space-between" }}>
                                            <Typography variant="h5" fontWeight="medium" display="inline-flex" alignItems="center" gap={1}>
                                                <img src={icon3} height="28px" />
                                                Register:
                                            </Typography>
                                            <FormControlLabel disabled={isLoading} name='registerStatus' control={<Switch checked={signupStatus?.registerStatus === 'active'} />} sx={{ m: 0 }} />
                                        </ListItem>
                                    </List>
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={6}>
                        <Typography variant='h4' mb={3}>Payment Platform</Typography>
                        <Card>
                            {/* <CardHeader>Manage Singup Type</CardHeader> */}
                            <CardContent sx={{ px: 4, py: 3 }}>
                                {/* <FormGroup onChange={onStatusChangeHandler}>
                                    <FormControlLabel disabled={isLoading} name='personaStatus' control={<Switch checked={signupStatus?.personaStatus === 'active'} />} label="Persona" />
                                    <FormControlLabel  disabled={isLoading} name='agecheckerStatus' control={<Switch checked={signupStatus?.agecheckerStatus === 'active'} />} label="AgeChecker" />
                                    <FormControlLabel disabled={isLoading} name='registerStatus' control={<Switch checked={signupStatus?.registerStatus === 'active'} />} label="Register" />
                                </FormGroup> */}

                                <FormGroup onChange={onPaymentStatusChangeHandler}>
                                    <List>
                                        <ListItem alignItems="center" sx={{ justifyContent: "space-between" }}>
                                            <Typography variant="h5" fontWeight="medium" display="inline-flex" alignItems="center" gap={1}>
                                                <img src={maverick_logo} height="28px" />
                                                Mevrick:
                                            </Typography>
                                            <FormControlLabel disabled={isLoading} name='maverickStatus' control={<Switch checked={paymentPlatformStatus?.maverickStatus === 'active'} />} sx={{ m: 0 }} />
                                        </ListItem>
                                        <Divider />
                                        <ListItem alignItems="center" sx={{ justifyContent: "space-between" }}>
                                            <Typography variant="h5" fontWeight="medium" display="inline-flex" alignItems="center" gap={1}>
                                                <img src={leisurepay_logo} height="28px" />
                                                Leisurepay:
                                            </Typography>
                                            <FormControlLabel disabled={isLoading} name='leisurepayStatus' control={<Switch checked={paymentPlatformStatus?.leisurepayStatus === 'active'} />} sx={{ m: 0 }} />
                                        </ListItem>
                                        <Divider />
                                    </List>
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

        </CommonLayout>
    )
}
