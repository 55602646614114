import AddMemberShipForm from 'components/memberShips/addMemberShipForm'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchMemberShipDetails } from '../../../redux/slices/memberships'

export default function AddMemberShip() {
    const dispatch = useDispatch()
    const { memberShipId = '' } = useParams()
    useEffect(() => {
        if (memberShipId) {
            dispatch(fetchMemberShipDetails(memberShipId))
        }
    }, [memberShipId])
    return (
        <CommonLayout>
            <AddMemberShipForm />
        </CommonLayout>
    )
}
