import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import React from 'react'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';

export default function ChangeOrderStatus({ closeModal }) {
    const { modalData, modal, isLoading = false } = useSelector(state => state.modal)
    const { updateStatusHandler, deleteId } = modalData
    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
                Update Confirmation

                <IconButton onClick={closeModal} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Are you sure you want to update status of this order?
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <MDButton onClick={closeModal} size="small" color="secondary">
                    Cancel
                </MDButton>
                <MDButton onClick={() => updateStatusHandler(deleteId)} size="small" color='error'>
                    {
                        isLoading ? <CircularProgress size={16} color='light' /> : "Update"
                    }

                </MDButton>
            </DialogActions>
        </>
    )
}


// import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
// import MDButton from 'components/MDButton'
// import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
// import CloseIcon from '@mui/icons-material/Close';

// export default function ChangeOrderStatus({ closeModal }) {
//     const { modalData, modal, isLoading = false } = useSelector(state => state.modal)
//     const { updateStatusHandler, deleteId } = modalData
//     const [changeStatusReason, setWarrantyReason] = useState("")
//     const [errors, setErrors] = useState({})
//     const submitHandler = (id) => {
//         if (!changeStatusReason) {
//             setErrors({ changeStatusReason: 'Reason is required' })
//             return;
//         }
//         setErrors({})
//         // changeStatusReason(id,)
//         // dispatch(warrantyStatusUpdate({ approvalComment: changeStatusReason, orderId })).unwrap().then((res => {
//         //     dispatch(openSnackbar({ content: res?.message, color: 'success', icon: 'check' }))
//         //     dispatch(closeCustomModalDialog({}))

//         // })).catch((err => {
//         //     dispatch(openSnackbar({ content: err?.message, color: 'error', icon: 'warning' }))
//         //     console.log("err", err)
//         // }))
//     }
//     return (
//         <>
//             <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
//                 Update Confirmation

//                 <IconButton onClick={closeModal} edge='end' size='small'>
//                     <CloseIcon fontSize='medium' />
//                 </IconButton>
//             </DialogTitle>

//             <DialogContent>
//                 <Box pt={1}>
//                     <TextField fullWidth className="form-input" multiline rows={3}
//                         error={Boolean(errors?.changeStatusReason)}
//                         helperText={errors?.changeStatusReason}
//                         onChange={(e) => setWarrantyReason(e.target.value)} label={`Reason`} name="changeStatusReason" value={changeStatusReason}
//                     />
//                 </Box>
//             </DialogContent>

//             <DialogActions>
//                 <MDButton onClick={closeModal} size="small" color="secondary">
//                     Cancel
//                 </MDButton>
//                 <MDButton onClick={() => submitHandler(deleteId)} size="small" color='error'>
//                     {
//                         isLoading ? <CircularProgress size={16} color='light' /> : "Update"
//                     }

//                 </MDButton>
//             </DialogActions>
//         </>
//     )
// }
