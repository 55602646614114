import { Box, Grid, Typography, Card, Skeleton } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getDriverAnalyticsInfo } from '../../../../redux/slices/hyperdrive/driverAnalytics'
import { useSelector } from 'react-redux'
import SkeletonText from 'common/component/SkeletonText'

const DriverInfo = ({ driverId }) => {
  const dispatch = useDispatch()
  const { driverDetails } = useSelector(state => state?.driverAnalyticsHyperDrive)
  const driverInfo = driverDetails?.data?.driverInfo
  useEffect(() => {
    dispatch(getDriverAnalyticsInfo({ driverId, data: {} })).unwrap().then(res => { })
  }, [])


  return (
    <Card sx={{ padding: 3, backgroundColor: '#1E1E24', mt: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant='h4' className='text-white-700' fontSize="20px">Driver Info</Typography>
        <Box display="flex" alignItems="center" className="duty-color">
          <Typography color={driverInfo?.fleetOnDutyStatus ? "#24CA49" : "#FD4438"} variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize" className='d-flex align-item-center gap-2 white-space-nowrap'>
            <span className={driverInfo?.fleetOnDutyStatus ? "dot-duty on-duty" : 'dot-duty off-duty'}></span>
            {driverInfo?.fleetOnDutyStatus ? "On Duty" : "Off Duty"}
          </Typography>
        </Box>
      </Box>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Driver ID</Typography>
          {driverInfo ? <Typography variant='h5' className='text-black-500'>{driverInfo?.fleetDisplayId}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Driver Name</Typography>
          {driverInfo ? <Typography variant='h5' className='text-black-500'>{driverInfo?.fleetName}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Vehicle Info</Typography>
          {driverInfo ?
            <Typography variant='h5' className='text-black-500'>{driverInfo?.fleetVehicleDetails?.make}, {driverInfo?.fleetVehicleDetails?.model}, {driverInfo?.fleetVehicleDetails?.color}</Typography>
            : <SkeletonText />}</Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Check In</Typography>
          {driverInfo ? <Typography variant='h5' className='text-black-500'>{driverInfo?.checkIn}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Check Out</Typography>
          {driverInfo ? <Typography variant='h5' className='text-black-500'>{driverInfo?.checkOut}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Drawer Starting Cash</Typography>
          {driverInfo ? <Typography variant='h5' className='text-black-500'>{driverInfo?.drawerStartingCash}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Today Tasks</Typography>
          {driverInfo ? <Typography variant='h5' className='text-black-500'>{driverInfo?.todayTaskCount}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Scheduled Tasks</Typography>
          {driverInfo ? <Typography variant='h5' className='text-black-500'>{driverInfo?.scheduledTask}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Total Break Taken</Typography>
          {driverInfo ? <Typography variant='h5' className='text-black-500'>{driverInfo?.totalBreakDown}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Total Miles</Typography>
          {driverInfo ? <Typography variant='h5' className='text-black-500'>{driverInfo?.totalMiles}</Typography> : <SkeletonText />}
        </Grid>
      </Grid>
    </Card>
  )
}

export default DriverInfo
