import React from 'react';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Autocomplete } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CustomMultiSelect(props) {

    const { options = [], name, handleChange = () => { }, value, label, placeholder, className, multiple = true, required = false, ...rest } = props
    return (
        <Autocomplete
            multiple={multiple}
            className={className}
            id="checkboxes-tags-demo"
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => { return (option && option[name]) || "" }}
            onChange={(e, val) => { handleChange(e, val) }}
            value={value}
            // getOptionSelected={(option) => { console.log(option, ">>>>"); return (option && option[name]) || "" }}
            // renderOption={(option, { selected }) => {
            //     return (
            //         <React.Fragment>
            //             {/* <Checkbox
            //                 icon={icon}
            //                 checkedIcon={checkedIcon}
            //                 style={{ marginRight: 8 }}
            //                 checked={selected}
            //             /> */}
            //             {option[name] || ""}
            //         </React.Fragment>
            //     )
            // }
            // }
            // style={{ width: 500 }}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label={label} placeholder={placeholder} required={required} />
            )}
            {...rest}
        />
    );
}

