import React from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { formatTimestampToWithoutTimeTodayTomorrow } from 'utilities/common';

const DateTask = ({ selected, name, placeholderText, minDate, onChange, showInput }) => {
    const formattedSelectedDate = selected ? formatTimestampToWithoutTimeTodayTomorrow(selected) : "--";
    const getNextDate = (date) => {
        if (!date) return null;
        const nextDate = new Date(date);
        nextDate.setDate(nextDate.getDate() + 1); // Add one day
        return nextDate;
    };

    const nextDate = getNextDate(selected);
    const formattedNextDate = nextDate ? formatTimestampToWithoutTimeTodayTomorrow(nextDate) : "--";
    return (
        <DatePicker
            className='dark-field'
            selected={selected}
            onChange={(date) => onChange(date, name)}
            timeInputLabel="Time:"
            name={name}
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput={showInput ? false : true}
            placeholderText={placeholderText}
            showIcon
            // minDate={minDate}
            customInput={showInput ? <p> {`${formattedSelectedDate} - ${formattedNextDate}`}</p> : null}
            icon={
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" style={{     marginRight: "5px" }}>
                        <g clipPath="url(#clip0_10561_10961)">
                            <path d="M6 0.499512C4.91221 0.499512 3.84884 0.822081 2.94437 1.42643C2.0399 2.03078 1.33495 2.88976 0.918665 3.89475C0.502383 4.89975 0.393465 6.00561 0.605683 7.07251C0.817902 8.1394 1.34173 9.11941 2.11092 9.8886C2.8801 10.6578 3.86011 11.1816 4.92701 11.3938C5.9939 11.6061 7.09977 11.4971 8.10476 11.0808C9.10976 10.6646 9.96874 9.95962 10.5731 9.05515C11.1774 8.15068 11.5 7.08731 11.5 5.99951C11.4983 4.54135 10.9183 3.1434 9.88719 2.11232C8.85611 1.08125 7.45817 0.501232 6 0.499512ZM7.8535 7.85301C7.75974 7.94675 7.63258 7.99941 7.5 7.99941C7.36742 7.99941 7.24027 7.94675 7.1465 7.85301L5.6465 6.35301C5.55273 6.25927 5.50003 6.13211 5.5 5.99951V2.99951C5.5 2.8669 5.55268 2.73973 5.64645 2.64596C5.74022 2.55219 5.86739 2.49951 6 2.49951C6.13261 2.49951 6.25979 2.55219 6.35356 2.64596C6.44732 2.73973 6.5 2.8669 6.5 2.99951V5.79251L7.8535 7.14601C7.94724 7.23978 7.9999 7.36693 7.9999 7.49951C7.9999 7.63209 7.94724 7.75925 7.8535 7.85301Z" fill="#A9A9B7"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_10561_10961">
                            <rect width="12" height="12" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <svg className='dropdown-arrow-order' xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                        <g clipPath="url(#clip0_10561_10965)">
                            <path d="M5.5 8.5L1 4.5H10L5.5 8.5Z" fill="#5B5B65"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_10561_10965">
                            <rect width="11" height="11" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </>
            }
        />
    )
}

export default DateTask
