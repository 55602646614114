
// @mui material components
import Grid from "@mui/material/Grid";
import CommonLayout from "layouts/DashboardLayout";
import { Box } from "@mui/material";
import StatsAnalyzeChart from "components/hyperdrive/analyze/StatsAnalyzeChart";
import CompletedTaskChart from "components/hyperdrive/analyze/CompletedTaskChart";
import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { fetchAnalyzeData, fetchAnalyzeGraphData } from "../../../redux/slices/hyperdrive/analyze";
import { useSelector } from "react-redux";
import { subDays, format } from 'date-fns'; // Useful to manipulate dates


function Analyze() {
  const { analyzeData = {}, analyzeGraphData = {} } = useSelector(state => state.analyze)
  const dispatch = useDispatch()
  const [completedTaskGraphFilter, setCompletedTaskGraphFilter] = useState({
    groupBy: ''
  })

  const changeFilterHandler = (value) => {
    setCompletedTaskGraphFilter({ ...completedTaskGraphFilter, groupBy: value })
  }

  const meomizeTaskStats = useMemo(() => {
    let data = {};
    let completedTasks = {
      failed: analyzeData?.completedTasks?.failed,
      succeeded: analyzeData?.completedTasks?.succeeded,
      total: analyzeData?.completedTasks?.total,
      taskChart: {
        datasets: [
          {
            data: [analyzeData?.completedTasks?.succeeded, analyzeData?.completedTasks?.failed], // The values for each segment
            backgroundColor: [
              // "#FC7D1A",
              // "#C444FF", 
              "#60EDEF",
              "#3A49F9",

            ],
            barPercentage: 0.4,
            categoryPercentage: 0.5,
            borderWidth: 0, // No border between segments
            cutout: "90%", // Adjust the thickness of the doughnut
          },
        ],
      },
      tasksPerHour: analyzeData?.completedTasks?.tasksPerHour
    }
    let delayedTask = {
      onTime: analyzeData?.delayedTask?.onTime,
      delayedLessThan10: analyzeData?.delayedTask?.delayedLessThan10,
      delayed10To60: analyzeData?.delayedTask?.delayed10To60,
      delayedMoreThan60: analyzeData?.delayedTask?.delayedMoreThan60,
      totalDelayedOrders: analyzeData?.delayedTask?.totalDelayedOrders,
      averageDelayTimePerTask: analyzeData?.delayedTask?.averageDelayTimePerTask,
      taskChart: {
        datasets: [
          {
            data: [analyzeData?.delayedTask?.onTime, analyzeData?.delayedTask?.delayedLessThan10, analyzeData?.delayedTask?.delayed10To60, analyzeData?.delayedTask?.delayedMoreThan60], // The values for each segment
            backgroundColor: [
              "#60EDEF",
              "#3A49F9",
              "#FC7D1A",
              "#C444FF",
            ],
            barPercentage: 0.4,
            categoryPercentage: 0.5,
            borderWidth: 0, // No border between segments
            cutout: "90%", // Adjust the thickness of the doughnut
          },
        ],
      },
    }
    let tasksByServiceTime = {
      onTime: analyzeData?.tasksByServiceTime?.onTime,
      delayedLessThan10: analyzeData?.tasksByServiceTime?.delayedLessThan10,
      delayed10To60: analyzeData?.tasksByServiceTime?.delayed10To60,
      delayedMoreThan60: analyzeData?.tasksByServiceTime?.delayedMoreThan60,
      totalDelayedOrders: analyzeData?.tasksByServiceTime?.totalDelayedOrders,
      serviceTimePerTask: analyzeData?.tasksByServiceTime?.serviceTimePerTask,
      taskChart: {
        datasets: [
          {
            data: [analyzeData?.tasksByServiceTime?.onTime, analyzeData?.tasksByServiceTime?.delayedLessThan10, analyzeData?.tasksByServiceTime?.delayed10To60, analyzeData?.tasksByServiceTime?.delayedMoreThan60], // The values for each segment
            backgroundColor: [
              "#60EDEF",
              "#3A49F9",
              "#FC7D1A",
              "#C444FF",
            ],
            barPercentage: 0.4,
            categoryPercentage: 0.5,
            borderWidth: 0, // No border between segments
            cutout: "90%", // Adjust the thickness of the doughnut
          },
        ],
      },
    }

    data = {
      completedTasks,
      delayedTask,
      tasksByServiceTime,
      averageMiles: {
        averageOfMilesPerTask: analyzeData?.averageOfMilesPerTask,
      }
    }

    return data;


  }, [analyzeData])

  // const monthsFullYear = [
  //   'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  //   'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
  // ];

  // const completedOrdersData = useMemo(() => {
  //   if (!analyzeGraphData?.ordersCompleted) return;
  //   const ordersMap = new Map();
  //   analyzeGraphData?.ordersCompleted.forEach(order => {
  //     const month = new Date(order.date).toLocaleString('default', { month: 'short', });
  //     ordersMap.set(month, order.completedOrders);
  //   });
  //   return monthsFullYear.map(month => ordersMap.get(month) || 0);
  // }, [analyzeGraphData?.ordersCompleted, monthsFullYear]);

  // const failedOrdersData = useMemo(() => {
  //   if (!analyzeGraphData?.ordersCompleted) return;
  //   const ordersMap = new Map();
  //   analyzeGraphData?.ordersCompleted.forEach(order => {
  //     const month = new Date(order.date).toLocaleString('default', { month: 'short' });
  //     ordersMap.set(month, order.failedOrders);
  //   });
  //   return monthsFullYear.map(month => ordersMap.get(month) || 0);
  // }, [analyzeGraphData?.ordersCompleted, monthsFullYear]);



  // Generate an array of the past 30 days
const daysLast30Days = Array.from({ length: 30 }, (_, i) =>
  format(subDays(new Date(), i), 'yyyy-MM-dd')
).reverse(); // This ensures the latest day is at the end of the array

const completedOrdersData = useMemo(() => {
  if (!analyzeGraphData?.ordersCompleted) return;
  const ordersMap = new Map();
  
  // Populate ordersMap with completed orders based on the day (formatted as 'yyyy-MM-dd')
  analyzeGraphData?.ordersCompleted.forEach(order => {
    const day = format(new Date(order.date), 'yyyy-MM-dd');
    ordersMap.set(day, order.completedOrders);
  });
  
  // Return data for the last 30 days, filling 0 for days with no data
  return daysLast30Days.map(day => ordersMap.get(day) || 0);
}, [analyzeGraphData?.ordersCompleted]);

const failedOrdersData = useMemo(() => {
  if (!analyzeGraphData?.ordersCompleted) return;
  const ordersMap = new Map();
  
  // Populate ordersMap with failed orders based on the day (formatted as 'yyyy-MM-dd')
  analyzeGraphData?.ordersCompleted.forEach(order => {
    const day = format(new Date(order.date), 'yyyy-MM-dd');
    ordersMap.set(day, order.failedOrders);
  });
  
  // Return data for the last 30 days, filling 0 for days with no data
  return daysLast30Days.map(day => ordersMap.get(day) || 0);
}, [analyzeGraphData?.ordersCompleted]);

const data = useMemo(() => ({
  labels: daysLast30Days,
  datasets: [
    {
      label: 'Completed Orders',
      data: completedOrdersData,
      backgroundColor: '#06D7F6',
      borderColor: '#06D7F6',
      borderWidth: 1,
      barPercentage: 0.4,
      categoryPercentage: 0.5,
    },
    {
      label: 'Failed Orders',
      data: failedOrdersData,
      backgroundColor: '#4807EA',
      borderColor: '#4807EA',
      borderWidth: 1,
      barPercentage: 0.4,
      categoryPercentage: 0.5,
    }
  ]
}), [daysLast30Days, completedOrdersData, failedOrdersData]);

useEffect(()=>{
  console.log(failedOrdersData)
console.log(completedOrdersData)


},[failedOrdersData])

  const refreshStats = (filters = {}) => {
    dispatch(fetchAnalyzeData(filters))
  }

  useEffect(() => {
    dispatch(fetchAnalyzeData())

  }, [])

  useEffect(()=>{
    dispatch(fetchAnalyzeGraphData(completedTaskGraphFilter))

  },[completedTaskGraphFilter])

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }} className="pb-0 h-100">
      <Grid container spacing={3}>
        <Grid item lg={4}>
          <StatsAnalyzeChart taskStats={meomizeTaskStats} refreshStats={refreshStats} />
        </Grid>
        <Grid item lg={8}>
          <CompletedTaskChart chartData={data} completedTaskGraphFilter={completedTaskGraphFilter} changeFilterHandler={changeFilterHandler} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Analyze;
