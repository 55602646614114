import AddMainStrainForm from 'components/strain/addMainStrainForm'
import CommonLayout from 'layouts/DashboardLayout'

export default function AddMainStrain() {
  return (
    <CommonLayout>
      <AddMainStrainForm />
    </CommonLayout>
  )
}
