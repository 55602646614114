import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchApprovalsAPI, submitNotificationAPI } from "../../../apis/hyperwolf/Driver/approvals"

export const fetchDriverApprovals = createAsyncThunk(
    'approvals/fetchApprovals',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchApprovalsAPI(filters)
            return res
        } catch(error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response.data)
        }
    }
)

export const submitNotification = createAsyncThunk(
    'approvals/submitApprovals',
    async (data, { rejectWithValue }) => {
        try {
            const res = await submitNotificationAPI(data)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response.data)
        }
    }
)

const driverApprovalSlice = createSlice({
    name: 'approvals',
    initialState: {
        approvals:[],
        loading: false,
        error: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.
        addCase(fetchDriverApprovals.fulfilled, (state, action) => {
            state.loading = false;
            state.approvals = action.payload;
            state.error = null;
        })
        .addCase(fetchDriverApprovals.rejected, (state,action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(fetchDriverApprovals.pending, (state) => {
            state.loading = true;
        })
    }
}) 


export const { /* Any additional actions */} = driverApprovalSlice.actions; 

export default driverApprovalSlice.reducer;
