import { DeleteOutline, Edit, SearchSharp } from '@mui/icons-material'
import { Box, Chip, Grid, IconButton, InputAdornment, Stack, TextField, Typography, Card, Badge, CircularProgress } from '@mui/material'
import MDButton from 'components/MDButton'
import { AgGridTable } from 'components/tables/AgGridTable'
import Search from 'examples/Search'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchCategories, openSnackbar } from '../../redux/slices/common'
import { sortAndFilter } from 'components/tables/AgGridTable'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice'
import { deleteCategory, deleteWebCategory, fetchCategoriesList, fetchWebCategories, updateCategoriesOrder } from '../../redux/slices/category'
import { useDebounce } from 'hooks/useDebounce'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import StatusText from 'common/component/StatusText'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import MenuIcon from '@mui/icons-material/Menu';


const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  //userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "" : "",
  border: isDragging ? "none" : "",
  display: 'flex',
  width: '100%',

  // styles we need to apply on draggables
  ...draggableStyle
});



export default function ManageCategories() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { isFetching = false, webCategoriesList = {} ,loading=false } = useSelector(state => state.category)
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [categoriesList, setCategoriesList] = useState([])


  useEffect(() => {
    if (webCategoriesList?.values?.length) {
      setCategoriesList(webCategoriesList?.values)

    }
  }, [webCategoriesList?.values])

  const handleSearch = useDebounce((term) => {
    // Perform search operation with the debounced term
    pageRef.current = 0
    searchFn()
    // mainGridApiRef.current.destroy()
    // getRows(gridRefApi.current)
  }, 500);
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Image',
      field: 'image',
      key: "fullName",
      suppressMenu: false,
      cellRenderer: (props) => {
        if (!props?.data?.image) return "-"
        return <img width={100} height={100} style={{ objectFit: 'cover', borderRadius: '10px', display: 'block' }} src={props?.value} />
      },
      minWidth: 140
    },
    {
      headerName: 'Category Name',
      field: 'webCategoryName',
      key: "webCategoryName",
      suppressMenu: false,
      cellRenderer: (props) => {
        return <Box sx={{ backgroundColor: "#F2F3F7", borderRadius: "5px", p: 1.5, whiteSpace: "normal" }}>
          <Typography variant="caption" display="block" fontWeight="medium" color="secondary">{props?.data?.webCategoryName}</Typography>
        </Box>
      },
      minWidth: 220
    },
    {
      headerName: 'Sub-categories',
      field: 'adminCategories',
      key: "adminCategories",
      suppressMenu: false,
      cellRenderer: (props) => {
        return <Box display='flex' flexWrap='wrap' width='100%' gap={1.25} p={1.25} borderRadius={1.5} sx={{ backgroundColor: "#F1F1F1" }}>
          {props?.value?.map((adminCategory => {
            return <Chip className='chip-square' key={adminCategory?.categoryId} label={adminCategory?.categoryName} />
          }))}
        </Box>
      },
      minWidth: 740
    },
    {
      headerName: 'Status',
      field: 'status',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <StatusText status={props?.data?.status} />
      },
      minWidth: 100
    },
    {
      headerName: 'Action',
      cellRenderer: (props) => {
        return <Box display="inline-flex" alignItems="center">
          <IconButton onClick={() => onRowClicked(props)} color='primary' size='small' variant="primary">
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?._id }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
        </Box>
      },
      cellStyle: { textAlign: 'right', minHeight: "102px", display: "inline-flex", justifyContent: "end" },
      headerClass: 'right-aligned-cell-header',
      maxWidth: 100,
      pinned: 'right'
    },
  ]);

  const deleteHandler = (webCategoryId) => {
    dispatch(setModalLoader(true))
    dispatch(deleteWebCategory(webCategoryId)).unwrap().then((res) => {
      showError(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      dispatch(fetchWebCategories({ skip: pageRef.current, limit: 100, userType: 'admin', searchTerm: searchTermRef.current }))
      // pageRef.current = 0
      // getRows(gridRefApi.current)
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  const getRows = async (params) => {
    gridRefApi.current = params;
    dispatch(fetchWebCategories({ skip: pageRef.current, limit: 20, userType: 'admin', searchTerm: searchTermRef.current })).unwrap().then(res => {
      const { values, total } = res
      const webCategoriesArray = values?.map(((item, idx) => {
        return { ...item, sno: idx + 1, id: item._id }
      }))
      const dataAfterSortingAndFiltering = sortAndFilter(
        webCategoriesArray,
        params.sortModel,
        params.filterModel
      );
      const rowsThisPage = dataAfterSortingAndFiltering.slice(
        params.startRow,
        params.endRow
      );
      // let lastRow = -1;
      // if (total <= params.endRow) {
      //   lastRow = dataAfterSortingAndFiltering.length;
      // }
      params.successCallback(dataAfterSortingAndFiltering, total);
      pageRef.current = pageRef.current + 20
    })
  }
  const onRowClicked = (row) => {
    navigate(`/manageCategories/${row?.data._id}`)
  }
  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value
    handleSearch(value);
    // Debounce the search callback
  };
  const searchFn = () => {
    const dataSource = {
      getRows
    }
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      categoriesList,
      result.source.index,
      result.destination.index
    );
    let newOrderedCat = [...items].map((v, ind) => {
      return { ...v, order: ind + 1 };
    });
    setCategoriesList(newOrderedCat)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  useEffect(() => {
    dispatch(fetchWebCategories({ skip: pageRef.current, limit: 100, userType: 'admin', searchTerm: searchTermRef.current }))
  }, [])

  const saveCategoriesOrder = () => {
    dispatch(updateCategoriesOrder(categoriesList)).unwrap().then((res) => {
      showError(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      // pageRef.current = 0
      // getRows(gridRefApi.current)
    }).catch((err) => {
      showError(err?.message, 'error')
    })
  }

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (isFetching) {
        mainGridApiRef.current.showLoadingOverlay()
      } else if (webCategoriesList?.total == 0) {
        mainGridApiRef.current.showNoRowsOverlay()
      }
      else {
        mainGridApiRef.current.hideOverlay()
      }
    }

  }, [webCategoriesList, isFetching])
  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        
          <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Manage Categories</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                {/* <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} /> */}
                <MDButton
                  startIcon={<AddRoundedIcon fontSize='large' />}
                  variant="contained" color="primary"
                  onClick={() => navigate('/manageCategories/add')} >
                  Add Web Category
                </MDButton>
              </Stack>
            </Grid>
          </Grid>

          <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: "none" }}>
            {
              isFetching ? <Box textAlign='center'>
                 <CircularProgress size={30}/>
              </Box> :     <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
              <Grid item xs={12} lg={12} className="manage-cat-sec">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <ul
                        className="category-ul-list"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {categoriesList?.length > 0 &&
                          categoriesList?.map((item, index) => {
                            let imgUrl = item && item.image || '';
                            const isDealsCategory = item.webCategoryName.includes('Deals');

                            return (
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    className="custon-list"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}

                                  >
                                    <MenuIcon className="mr-2" />
                                    <div className="category-img-sec mr-2">
                                      <img
                                        src={item?.image}
                                        className="category-img"
                                        alt=""
                                      />
                                    </div>
                                    <div className="left-section mr-2">
                                      {/* <TextField
                                        name="categoryName"
                                        fullWidth
                                        value={item.webCategoryName || ''}
                                        // onChange={(e) => { handleChangeCategory(e, index) }}
                                        onChange={() => { }}
                                        label="Category Name"
                                      /> */}
                                      <label>Category</label>
                                      <Badge>{item.webCategoryName}</Badge>
                                    </div>
                                    <div className="sub-category-name-sec mr-2">
                                      <label>Sub Categories</label>
                                      {
                                         item.adminCategories.map((category=>{
                                          return <Badge className="mr-2" key={category?.categoryId}>{category?.categoryName}</Badge>
                                         }))
                                      }
                                      {/* <TextField
                                        name="subCategoryName"
                                        id={item._id}
                                        fullWidth
                                        value={item.adminCategories.length ? item.adminCategories.map((blaze => blaze?.categoryName))?.join(',') : ''}
                                        onChange={(e) => handleChangeSubCategory(e, null, index)}
                                        label="Sub Category"
                                        className="w-50"
                                      /> */}
                                    </div>
                                    {/* {isDealsCategory ? (

                                  <CustomInput
                                    name="subCategoryName"
                                    id={item._id}
                                    fullWidth
                                    value={item.blazeCategories.length ? item.blazeCategories.map((blaze => blaze?.name))?.join(',') : ''}
                                    onChange={(e) => handleChangeSubCategory(e, null, index)}
                                    label="Sub Category"
                                    className="w-50"
                                  />
                                ) : (
                                  <CustomMultiSelect
                                    label="Sub Category"
                                    name="name"
                                    fullWidth
                                    options={values || []}
                                    handleChange={(e, val) =>
                                      handleChangeSubCategory(e, val, index)
                                    }
                                    value={item.blazeCategories || []}
                                    className="w-50"
                                  />
                                )} */}
                                  <div>
                                    <IconButton onClick={() => navigate(`/manageCategories/${item._id}`)} color='primary' size='small' variant="primary">
                                      <EditIcon width='16px' height='16px' />
                                    </IconButton>
                                    <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: item?._id }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
                                      <DeleteIcon width='16px' height='16px' />
                                    </IconButton>
                                  </div>
                                </li>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
                  <MDButton
                    sx={{ minWidth: "140px" }}
                    type='button'
                    variant='contained'
                    color='primary'
                    disabled={loading}
                    loading={loading}
                    onClick={saveCategoriesOrder}>
                    Save
                  </MDButton>
                </Stack>

              </Grid>
            </Grid>
            }
        
        </Card>

      </Box>
    </CommonLayout>
  )
}