import React, { useEffect, useState } from 'react'
import { Card, Grid, Icon, Typography, Table, Box, IconButton, Stack, TextField } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { openSnackbar } from '../../../../redux/slices/common';
import { addFailureReason, editFailureReason, getFailureReasonDetails } from '../../../../redux/slices/hyperdrive/setting';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function AddFailureReason() {
    const [searchParams] = useSearchParams();
    const roleTab = searchParams.get('roleTab');
    const editReason = roleTab?.includes("edit")
    // Extract the id from roleTab
    const reasonId = roleTab ? roleTab.split('/').pop().split('=').pop() : null;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { mainFailureReasonDetailsData ,loading: loading = false} = useSelector(state => state?.commonHyperdrive)
    const [errors, setErrors] = useState({})

    const [reason, setReason] = useState({
        reason: ""
    })
    const reasonChangeHandler = (event) => {
        setReason({ [event?.target?.name]: event?.target.value })
    }

    const validateReasons = () => {
        let isError = false
        let errors = {}
        if (!reason?.reason?.trim()) {
            isError = true
            errors = { ...errors, reason: 'Failure reason is required' }
        }
        return { errors, isError }
    }

    const submitHandler = () => {
        const { isError, errors } = validateReasons()
        setErrors(errors)
        if (isError) return;
        setErrors({})
        if (editReason) {
            dispatch(editFailureReason({ data: reason, reasonId }))
                .unwrap()
                .then(res => {
                    navigate(-1)
                    showResponseMessage(res?.message, 'success');
                })
                .catch(err => {
                    showResponseMessage(err?.message, 'error');
                });
        } else {
            dispatch(addFailureReason({ data: reason }))
                .unwrap()
                .then(res => {
                    navigate(-1)
                    showResponseMessage(res?.message, 'success');
                })
                .catch(err => {
                    showResponseMessage(err?.message, 'error');
                });
        }
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }
    useEffect(() => {
        if (editReason) {
            dispatch(getFailureReasonDetails(reasonId))
        }
    }, [editReason])

    useEffect(() => {
        if (mainFailureReasonDetailsData) {
            setReason({ reason: mainFailureReasonDetailsData?.data?.reason ? mainFailureReasonDetailsData?.data?.reason : "" })
        }
    }, [mainFailureReasonDetailsData])

    return (
        <Card sx={{ py: 3, px: 4, mb: 4, borderRadius: 0 }}>

            <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
                <Typography variant='h5' fontWeight="semibold" display="flex" alignItems="center" color="#9A9AAF" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                    <Icon className="mr-1 cur-pointer">chevron_left</Icon>Failure Reason
                </Typography>
            </Stack>

            <Grid container alignItems="center" spacing={{ xs: 3, md: 3 }} mb={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        fullWidth
                        helperText={errors.reason}
                        error={errors?.reason}
                        label={!editReason ? "Add Reason" : "Edit Reason"}
                        name="reason"
                        onChange={(e) => reasonChangeHandler(e)}
                        className="form-input"
                        value={reason?.reason || ''}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mb={2} mt={2}>
                        <MDButton
                            sx={{ minWidth: "140px" }}
                            type='button'
                            variant='contained'
                            color='primary'
                            onClick={() => submitHandler()}
                            disabled={loading}
                            loading={loading}
                        >
                            Save
                        </MDButton>
                        <MDButton
                            sx={{ minWidth: "140px" }}
                            type='button'
                            variant='outlined'
                            color='dark'
                            className="dark-outlined-btn"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </MDButton>
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    )
}