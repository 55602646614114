import AddMemberForm from 'components/members/addMemberForm'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchMembersDetails } from '../../../redux/slices/member'
import { useParams } from 'react-router-dom'

export default function AddMember() {
  const dispatch = useDispatch()
  const { memberId = "" } = useParams()
  useEffect(() => {
    if(memberId){
      dispatch(fetchMembersDetails(memberId))
    }
  }, [memberId])
  return (
    <CommonLayout>
        <AddMemberForm/>
    </CommonLayout>
  )
}
