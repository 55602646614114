import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { useNavigate, useParams } from 'react-router-dom'
import { useDebounce } from 'hooks/useDebounce'
import StatusText from 'common/component/StatusText'
import { fetchProductActivityLogs } from '../../../redux/slices/products'
import Search from 'examples/Search'
import { formatTimestampToDateView } from 'utilities/common'

export default function ProductActivityLogs() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { loading = false, activityLogs = {} } = useSelector(state => state.products)
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const { productId = '' } = useParams()

  const handleSearch = useDebounce((term) => {
    // Perform search operation with the debounced term
    console.log('Searching for:', term);
    pageRef.current = 0
    searchFn()
    // mainGridApiRef.current.destroy()
    // getRows(gridRefApi.current)
  }, 500);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Date/Time',
      field: 'createdDate',
      suppressMenu: false,
      sortable: false,
      cellRenderer: (props) => {
        return props?.value ?  formatTimestampToDateView(props?.value) : ''
      },
    },
    {
      headerName: 'Updated By',
      field: 'updatedBy',
      key: "updatedBy",
      suppressMenu: false,
      sortable: false
    },
    {
      headerName: 'Batch',
      field: 'productBatchId',
      key: "productBatchId",
      suppressMenu: false,
      sortable: false,
      cellRenderer: (props) => {
        if(!props?.data?._id) return;
        return  props?.data?.previousChanges?.productBatchId ?  props?.data?.previousChanges?.productBatchId : '-'
      },
    },
    {
      headerName: 'Source',
      field: 'source',
      suppressMenu: false,
      sortable: false

    },
    {
      headerName: 'Prev Data (Status/Qty)',
      field: '',
      suppressMenu: false,
      sortable: false,
      cellRenderer: (props) => {
        return props?.data?.source !== 'Product Status Update' ? props?.data?.previousChanges?.currentQuantity?.toString() : props?.data?.source === 'Product Status Update' ? <StatusText status={props?.data?.previousChanges?.oldStatus} /> : ''
      },
    },
    {
      headerName: 'New Data (Status/Qty)',
      field: '',
      suppressMenu: false,
      sortable: false,
      cellRenderer: (props) => {
        return props?.data?.source !== 'Product Status Update' ? props?.data?.newChanges?.currentQuantity?.toString() : props?.data?.source === 'Product Status Update' ? <StatusText status={props?.data?.newChanges?.newStatus} /> : ''
      },
    },
   
  ]);

  const getRows = async (params) => {
    gridRefApi.current = params;
    dispatch(fetchProductActivityLogs({ skip: pageRef.current, limit: 20, searchTerm: searchTermRef.current, productId })).unwrap().then(res => {
      console.log('res', res)
      const { activityLogs, totalActivityLogs } = res
      params.successCallback([...activityLogs], totalActivityLogs);
      pageRef.current = pageRef.current + 20
    })
  }

  const onRowClicked = (row) => {
    navigate(`/regions/${row?.data.regionId}`)
  }

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value
    handleSearch(value);

    // Debounce the search callback
  };

  const searchFn = () => {
    const dataSource = {
      getRows
    }
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  }

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay()
      } else if (activityLogs?.total == 0) {
        mainGridApiRef.current.showNoRowsOverlay()
      }
      else {
        mainGridApiRef.current.hideOverlay()
      }
    }

  }, [activityLogs, loading])

  return (
    <Box>
      <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2} mb={2}>
        <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
      </Stack>
      <AgGridTable col={columnDefs} mainGridApiRef={mainGridApiRef} getRows={getRows} pageRef={pageRef} isLoading={loading} style={{ height: "70vh" }} maxLimit={20} />
    </Box>
  )
}
