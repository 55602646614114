import React from 'react'

export default function MembersIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00002 11.9961C11.7614 11.9961 14 9.75754 14 6.99611C14 4.23468 11.7614 1.99609 9.00002 1.99609C6.23858 1.99609 4 4.23468 4 6.99611C4 9.75754 6.23858 11.9961 9.00002 11.9961Z" fill="currentColor"/>
      <path d="M17 18.9961C17 20.646 15.6499 21.9961 14 21.9961H3.99999C2.35008 21.9961 1 20.646 1 18.9961C1 16.2364 3.24 13.9961 6.00002 13.9961H12C14.76 13.9961 17 16.2364 17 18.9961Z" fill="currentColor"/>
      <path d="M20 6.99611C20 9.75586 17.76 11.9961 15 11.9961C14.6602 11.9961 14.3201 11.9659 14 11.8863C15.24 10.626 16 8.89602 16 6.99611C16 5.0962 15.24 3.36624 14 2.10596C14.3201 2.02639 14.6602 1.99609 15 1.99609C17.76 1.99614 20 4.23636 20 6.99611Z" fill="currentColor"/>
      <path d="M23.0004 18.9961C23.0004 20.646 21.6504 21.9961 20.0004 21.9961H18.0005C18.6303 21.166 19.0005 20.126 19.0005 18.9961C19.0005 17.0361 18.1904 15.2661 16.8906 13.9961H18.0005C20.7604 13.9961 23.0004 16.2364 23.0004 18.9961Z" fill="currentColor"/>
    </svg>
  )
}
