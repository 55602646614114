const { isEmptyObject } = require("utilities/common");

export function retailerValidation(fields) {

    console.log(fields, 'fields')
    const errors = {};

    if (!fields.email || !isValidEmail(fields.email)) {
        errors.email = 'Enter a valid email address';
    }

    if (!fields.phone) {
        errors.phone = 'Enter a valid phone number';
    }

    if(fields?.phone && !isPhoneNumberValid(fields?.phone)){
        errors.phone = 'Phone number must be 10 digits';
    }


    if (!fields.firstName || fields.firstName.trim() === '') {
        errors.firstName = 'First name is required';
    }

    if (!fields.lastName || fields.lastName.trim() === '') {
        errors.lastName = 'Last name is required';
    }


    if (!fields.retailerName || fields.retailerName.trim() === '') {
        errors.retailerName = 'Retailer name is required';
    }


    // if (!fields.status || !['active', 'inactive'].includes(fields.status)) {
    //     errors.status = 'Select a valid status (active or inactive)';
    // }


    if (!fields.address) {
        errors.address = 'Address is required';
    }

    if (!fields.subscription || typeof fields.subscription !== 'string') {
        errors.subscription = 'Subscription information is required';
    }
   
    if (!fields.isLifeTime) {
    if (!fields.valiDateFrom || !fields.valiDateTo) {
        console.log(fields.isLifeTime, 'hh')
        if (fields.subscription !== "Unpaid" )
        errors.validUpto = 'Valid upto is required';
    }
}

    // if (!fields.uploadedKML || fields.uploadedKML.trim() === '' || fields.uploadedKML === undefined) {
    //     console.log(errors, 'errors')
    //     console.log(fields, 'fields here')
    //     console.log(!fields.uploadedKML, '12')
    //     errors.uploadedKML = 'KML file is required.';
    // } 
    

    return { errors, isValid: isEmptyObject(errors) };
}

export function isValidZipcode(zipcode) {
    const zipcodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    return zipcodeRegex.test(zipcode);
}

export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function isValidPhone(phone) {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
}



function isPhoneNumberValid(phoneNumber) {
    // Remove all non-digit characters
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  
    // Check if the cleaned phone number has a length of 10
    return cleanedPhoneNumber.length === 10;
  }