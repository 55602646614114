export const categoryValidations = (category) => {
    const errors = {};

    // Validation for categoryName
    if (!category.categoryName.trim()) {
        errors.categoryName = 'Category name is required';
    } else if (category.categoryName.length > 255) {
        errors.categoryName = 'Category name should be at most 255 characters';
    }
    // Validation for categoryImage (assuming it's a URL)
    if (!category.categoryImage) {
        errors.categoryImage = 'Category Image is required';
    }
    else if (category.categoryImage && !isValidURL(category.categoryImage)) {
        errors.categoryImage = 'Invalid category image URL';
    }


    // Validation for status
    if (!['Active', 'Inactive','active','inactive'].includes(category.status)) {
        errors.status = 'Status should be either Active or Inactive';
    }

    // Validation for description
    if (!category.metaTitle.trim()) {
        errors.metaTitle = 'Meta title is required';
    } else if (category.metaTitle.length > 255) {
        errors.metaTitle = 'Meta title should be at most 255 characters';
    }

    // Validation for metaDescription
    if (!category.metaDescription.trim()) {
        errors.metaDescription = 'Meta description is required';
    } else if (category.metaDescription.length > 500) {
        errors.metaDescription = 'Meta description should be at most 500 characters';
    }

    // Validation for canonical (assuming it's a URL)
    // if (category.canonical && !isValidURL(category.canonical)) {
    //     errors.canonical = 'Invalid canonical URL';
    // }

    // Validation for heading
    // if (!category.heading.trim()) {
    //     errors.heading = 'Heading is required';
    // } else if (category.heading.length > 255) {
    //     errors.heading = 'Heading should be at most 255 characters';
    // }

    // Validation for createdBy
    if (!category.createdBy.trim()) {
        errors.createdBy = 'Created by is required';
    } else if (category.createdBy.length > 255) {
        errors.createdBy = 'Created by should be at most 255 characters';
    }

    // Validation for canonical (assuming it's a URL)
    // if (!category.canonical.trim()) {
    //     errors.canonical = 'Canonical is required';
    // } else if (!isValidURL(category.canonical)) {
    //     errors.canonical = 'Invalid canonical URL';
    // }

    // Validation for description
    if (!category.description.trim()) {
        errors.description = 'Description is required';
    }
    // Check if any errors occurred
    const isValid = Object.keys(errors).length === 0;

    return { isValid, errors };
};


export const webCategoriesValidation = (category) => {
    const errors = {};

    // Validation for categoryName
    if (!category.webCategoryName?.trim()) {
        errors.webCategoryName = 'Category name is required';
    } else if (category.webCategoryName.length > 255) {
        errors.webCategoryName = 'Category name should be at most 255 characters';
    }
    // Validation for categoryImage (assuming it's a URL)
    if (!category.image) {
        errors.image = 'Image is required';
    }
    else if (category.image && !isValidURL(category.image)) {
        errors.image = 'Invalid category image URL';
    }


    // Validation for status
    if (!['active', 'inactive','Active', 'Inactive'].includes(category.status)) {
        errors.status = 'Status should be either Active or Inactive';
    }

    // Validation for description
    if (!category.metaTitle?.trim()) {
        errors.metaTitle = 'Meta title is required';
    } else if (category.metaTitle.length > 255) {
        errors.metaTitle = 'Meta title should be at most 255 characters';
    }

    // Validation for metaDescription
    if (!category.metaDescription?.trim()) {
        errors.metaDescription = 'Meta description is required';
    } 
    // else if (category.metaDescription.length > 500) {
    //     errors.metaDescription = 'Meta description should be at most 500 characters';
    // }

    // Validation for heading
    // if (!category.heading?.trim()) {
    //     errors.heading = 'Heading is required';
    // } else if (category.heading.length > 255) {
    //     errors.heading = 'Heading should be at most 255 characters';
    // }

    // Validation for createdBy
    if (!category.createdBy?.trim()) {
        errors.createdBy = 'Created by is required';
    } else if (category.createdBy.length > 255) {
        errors.createdBy = 'Created by should be at most 255 characters';
    }


    // if(category?.adminCategories?.length === 0){
    //     errors.adminCategories = 'Admin Categories should be atleast one';
    // }
    // Check if any errors occurred
    const isValid = Object.keys(errors).length === 0;

    return { isValid, errors };
};


// Helper function to check if a string is a valid URL
const isValidURL = (url) => {
    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
};
