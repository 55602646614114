import { CloseRounded } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from 'common/customIcons/search';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'hooks/useDebounce';
import { AgGridTable } from 'components/tables/AgGridTable';
import { useDispatch } from 'react-redux';
import { getDriverAnalyticsInfo } from '../../../../redux/slices/hyperdrive/driverAnalytics';
import { useSelector } from 'react-redux';
import { formatTimestampToWithoutTime } from 'utilities/common';
import { formatTimestampToTime } from 'utilities/common';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DateRange from 'components/dateRangePicker/DateRange';

export default function CheckInOut({ driverId }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch()
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const { driverDetails, loading: loading = false } = useSelector(state => state?.driverAnalyticsHyperDrive)
  const driverList = driverDetails?.data?.checkList
  const [filterShow, setFilterShow] = useState(false)
  const fromDateRef = useRef('');
  const toDateRef = useRef('');
  const [fromDate, setFromDate] = useState('');
  fromDateRef.current = startDate ? moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : "";
  toDateRef.current = endDate ? moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : "";
  const columnDefs = useMemo(() => [
    {
      headerName: 'Date',
      field: 'date',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: (props) => {
        return props?.value ? formatTimestampToWithoutTime(props?.value) : "-";
      },
      flex: 1,
    },
    {
      headerName: 'Check in',
      field: 'checkIn',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: (props) => {
        return props?.value ? `${formatTimestampToTime(props?.value)}AM` : "-";
      },
      flex: 1,
    },
    {
      headerName: 'Check Out',
      field: 'checkOut',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: (props) => {
        return props?.value ? `${formatTimestampToTime(props?.value)}PM` : "-";
      },
      flex: 1,
    },
    {
      headerName: 'Total Hours',
      field: 'totalHours',
      suppressMenu: false,
      minWidth: 80,
      flex: 1,
    },
    {
      headerName: 'Drawer Starting Cash',
      field: 'drawerStartingCash',
      suppressMenu: false,
      minWidth: 80,
      flex: 0.8,
    },
    {
      headerName: 'Drawer Total Cash',
      field: 'drawerTotalCash',
      suppressMenu: false,
      minWidth: 80,
      cellStyle: { textAlign: "right", justifyContent: "end" },
      headerClass: "right-aligned-cell-header",
      flex: 0.5,
    },
  ], []);

  const getRows = useCallback(async (params, apply) => {
    gridRefApi.current = params;
    try {
      const res = await dispatch(getDriverAnalyticsInfo({
        driverId,
        data: {
          analyticType: "checklist",
          skip: pageRef.current,
          limit: 20,
          search: searchTermRef.current,
          from: (fromDateRef.current && toDateRef.current) && fromDateRef.current ? fromDateRef.current : "",
          to: (fromDateRef.current && toDateRef.current) && toDateRef.current ? toDateRef.current : "",
        }
      })).unwrap();

      const { checkList, totalCheckList } = res?.data;
      const driverListArray = checkList?.map(item => ({
        ...item,
      }));

      params.successCallback(driverListArray, totalCheckList);
      pageRef.current += 20;
    } catch {
      params.successCallback([], 0);
    }
  }, [dispatch]);

  const searchFn = useCallback((apply) => {
    const dataSource = {
      getRows: (params) => getRows(params, apply)
    };
    if (mainGridApiRef.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  }, [getRows]);

  const handleSearch = useDebounce((apply) => {
    pageRef.current = 0;
    searchFn(apply);
  }, 500);

  const searchHandler = useCallback((searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue;
    handleSearch("apply");
  }, [handleSearch]);

  const clearAllHandler = useCallback(() => {
    searchFn();
    pageRef.current = 0;
    toDateRef.current = "";
    fromDateRef.current = "";
    setDateRange([null, null])
  }, [searchFn]);

  const dateHandleFrom = (event) => {
    toDateRef.current = null;
    const selectedDate = event.target.value;
    const formattedDate = moment(selectedDate).toISOString();
    fromDateRef.current = formattedDate;
    setFromDate(formattedDate);
    if (toDateRef.current && moment(formattedDate).isAfter(moment(toDateRef.current))) {
      toDateRef.current = null;
    }
  }

  // const dateHandleTo = (event) => {
  //   const selectedDate = event.target.value;
  //   const formattedDate = moment(selectedDate).toISOString();
  //   toDateRef.current = formattedDate;
  //   setFromDate(formattedDate);
  //   if (fromDateRef.current && moment(formattedDate).isBefore(moment(fromDateRef.current))) {
  //     event.target.value = ''; 
  //     toDateRef.current = null;
  //   } else {
  //     toDateRef.current = formattedDate;
  //   }
  // }

  const dateHandleTo = (event) => {
    const selectedDate = event.target.value;
    const formattedDate = moment(selectedDate).toISOString();
    if (fromDateRef.current) {
      const fromDateMoment = moment(fromDateRef.current);
      const toDateMoment = moment(formattedDate);
      const diffDays = toDateMoment.diff(fromDateMoment, 'days');

      if (diffDays > 5) {
        alert('The "To" date must be within 5 days of the "From" date.');
        event.target.value = '';
        toDateRef.current = null;
        return;
      }
    }

    toDateRef.current = formattedDate;
    setFromDate(formattedDate);

    if (fromDateRef.current && moment(formattedDate).isBefore(moment(fromDateRef.current))) {
      event.target.value = '';
      toDateRef.current = null;
    } else {
      toDateRef.current = formattedDate;
    }
  }

  useEffect(() => {
    if (mainGridApiRef.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (driverList?.data?.totalCheckList === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      } else {
        mainGridApiRef.current.hideOverlay();
      }
    }
  }, [driverList, loading]);

  const applyHandler = () => {
    pageRef.current = 0;
    if (toDateRef.current && fromDateRef.current) {
      searchFn("apply");
    }
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
        <Typography variant='h4' className='text-white-700' fontSize="20px">Check In\ Check Out</Typography>
        <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="center" gap={1.5} className="hd-search-filter">
          <TextField className="custom-search-field dark-field" placeholder="Search" label=""
            value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <IconButton size='small'>
                  <SearchIcon width='18px' height='18px' />
                </IconButton>
              </InputAdornment>,
              endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                <IconButton onClick={() => searchHandler('')} size='small'>
                  <CloseRounded />
                </IconButton>
              </InputAdornment> : null
            }}
          />
          <Box className="filter-btns">
            <Stack direction='row' alignItems="center" gap={1} className='w-100'>
              <Button
                variant="outlined"
                color="primary"
                className="hd-filter-dropdown d-flex align-item-center gap-2"
                onClick={() => setFilterShow(!filterShow)}
              >
                <span>Filter</span>
                <ArrowDropDownIcon />
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
      {
        filterShow &&
        <Box className="cus-filter-sec" mb={1}>
          <Grid className='filter-box' container alignItems="center" spacing={1.5}>
            <Grid item xs={12} sm={12} md={12} xxl={12}>
              <Box className="filter-price-unit">
                <Stack direction='row' justifyContent='flex-end' width='100%' gap={2} >
                  <Grid item xs={12} sm={12} md={12} xxl={12}>
                    <Box display="flex" alignItems="center" justifyContent='flex-end' gap={2} className="w-100">
                      <Typography variant='body2' fontSize="14px" className='text-black-200'>Date Range</Typography>
                      <Box className="filter-pro-status hd-date-field dark-field dark-calendar">
                        <Stack direction='row' alignItems="center" gap={1}>
                          {/* <TextField
                            type='datetime-local'
                            name="from"
                            label="From"
                            InputLabelProps={{ shrink: true }}
                            onChange={dateHandleFrom}
                            value={fromDateRef.current ? moment(fromDateRef.current).format('YYYY-MM-DDTHH:mm') : ''}
                            sx={{ width: 220 }}
                          />
                          <TextField
                            type='datetime-local'
                            name="to"
                            label="To"
                            onChange={dateHandleTo}
                            InputLabelProps={{ shrink: true }}
                            value={toDateRef.current ? moment(toDateRef.current).format('YYYY-MM-DDTHH:mm') : ''}
                            sx={{ width: 220 }}
                            disabled={!fromDateRef.current}
                          /> */}
                          <DateRange setDateRange={setDateRange} dateRange={dateRange} showIcon={true} />
                        </Stack>
                      </Box>
                      <Box className="filter-btns">
                        <Stack direction='row' alignItems="center" gap={1} className='w-100'>
                          <Button className="filter-clear-all" onClick={clearAllHandler} size="small" variant="text" color="secondary" style={{ width: "100px" }}>
                            Clear All
                          </Button>
                          <Button className="filter-apply" onClick={() => applyHandler()} size="small" variant="contained" color="primary" style={{ width: "120px" }}>
                            Apply
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
      <div className="ag-theme-quartz-dark">
        <AgGridTable
          col={columnDefs}
          mainGridApiRef={mainGridApiRef}
          getRows={getRows}
          pageRef={pageRef}
          isLoading={loading}
          style={{ height: "64vh" }}
          gridOptions={{ rowHeight: 64, }}
          maxLimit={20}
        />
      </div>
    </Box>
  )
}
