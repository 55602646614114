import { Archive, CloseRounded } from '@mui/icons-material';
import { Box, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from 'common/customIcons/search';
import MDButton from 'components/MDButton';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import { AgGridTable } from 'components/tables/AgGridTable';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { fetchTaskTable, addTaskArchive, tableUnarchiveExport } from '../../../redux/slices/hyperdrive/drivers';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { formatTimestampToDateView } from 'utilities/common';
import StatusTask from 'common/component/StatusTask';
import ArchiveIcon from 'common/customIcons/archive';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import moment from 'moment';
import { openSnackbar } from '../../../redux/slices/common';
import EditIcon from 'common/customIcons/edit';
import { openCustomDrawer } from '../../../redux/slices/modalSlice';

export const Table = ({ dataPassComponent }) => {
    if (!dataPassComponent || Object.keys(dataPassComponent).length === 0) {
        return <div>Loading...</div>;
    }

    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch()
    const [searchTerm, setSearchTerm] = useState("")
    const [csv, setCsv] = useState([])
    const mainGridApiRef = useRef('')
    const pageRef = useRef(0)
    const gridRefApi = useRef(null)
    const searchTermRef = useRef("")
    const { taskTable = {}, taskloading: taskloading = false } = useSelector(state => state.drivers)
    const today = new Date();
    const nextDate = moment(today).add(1, 'day').toDate()

    const searchHandler = (searchValue) => {
        setSearchTerm(searchValue);
        searchTermRef.current = searchValue
        handleSearch(searchValue);
    };

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Task ID',
            field: 'taskDisplayId',
            minWidth: 180
        },
        {
            headerName: 'Assigned To',
            field: 'fleet',
            cellRenderer: useCallback((props) => (
                props?.data?.fleet?.fleetName
            ), []),
            minWidth: 180
        },
        {
            headerName: 'Destination',
            field: 'address',
            cellRenderer: useCallback((props) => (
                <Box mr={2} className='text-truncate'>{props?.data?.address?.address1}</Box>
            ), []),
            minWidth: 240
        },
        {
            headerName: 'Recipient',
            field: 'recipientDetails',
            cellRenderer: useCallback((props) => (
                <Box mr={2} className='text-truncate'>{props?.data?.recipientDetails?.name}</Box>
            ), []),
            minWidth: 240,
        },
        {
            headerName: 'Status',
            field: 'taskStatus',
            suppressMenu: false,
            minWidth: 120,
            flex: 1.5,
            cellStyle: { whiteSpace: "normal", lineHeight: "1.5" },
            cellRenderer: useCallback((props) => (
                <StatusTask value={props?.data?.taskStatus} icon={"showIcon"} />
            ), []),
        },
        {
            headerName: 'Task Created',
            field: 'createdDate',
            suppressMenu: false,
            cellRenderer: useCallback((props) => (
                props?.value ? formatTimestampToDateView(props?.value) : "-"
            ), []),
            minWidth: 100
        },
        {
            headerName: 'Last Updated',
            field: 'updatedDate',
            suppressMenu: false,
            cellRenderer: useCallback((props) => (
                props?.value ? formatTimestampToDateView(props?.value) : "-"
            ), []),
            minWidth: 100
        },
        {
            headerName: 'Action',
            suppressMenu: true,
            cellRenderer: (props) => {
                const taskId = props?.data?._id
                const data =
                {
                    taskId: taskId,
                    taskArchived: true
                }
                return <Box display="inline-flex" alignItems="center">
                    <IconButton color='primary' size='small' variant="primary" onClick={() => handleTaskDetails(props?.data?._id)}>
                        <EditIcon width='16px' height='16px' />
                    </IconButton>
                    <IconButton color='warning' size='small' variant="primary" sx={{ marginLeft: 1, color: "#FFDB1E" }} onClick={() =>
                        dispatch(addTaskArchive({ data })).unwrap()
                            .then(res => {
                                searchFn(dataPassComponent)
                                showResponseMessage(res?.message, 'success');
                            })
                            .catch(err => {
                                showResponseMessage(err?.message, 'error');
                            })}
                    >
                        <Archive width='16px' height='16px' />
                    </IconButton>
                </Box>
            },
            cellStyle: { textAlign: 'right', justifyContent: 'end' },
            headerClass: 'right-aligned-cell-header',
            minWidth: 120,
            maxWidth: 100,
            //   pinned: 'right'
        },
    ]);

    const handleTaskDetails = (taskDetailFetch) => {
        dispatch(openCustomDrawer({
            drawer: 'TASK_DETAIL',
            drawerData: taskDetailFetch
        }))
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }
    const getRows = useCallback(async (params, filters) => {
        gridRefApi.current = params;
        try {
            const res = await dispatch(fetchTaskTable({
                data: {
                    skip: pageRef.current,
                    limit: 20,
                    searchTerm: searchTermRef.current,
                    startDate: filters?.startDate ? filters?.startDate : today,
                    endDate: filters?.endDate ? filters?.endDate : nextDate,
                    driverStatus: filters?.driverStatus === "offDuty" ? false : filters?.driverStatus === "inTransit" ? true : "",
                    taskStatus: filters?.taskStatus ? filters?.taskStatus : ""
                    // ...(filters?.startDate && { startDate: filters.startDate }),
                    // ...(filters?.endDate && { endDate: filters.endDate }),
                    // ...(filters?.driverStatus && { driverStatus: filters.driverStatus }),
                    // ...(filters?.taskStatus && { taskStatus: filters.taskStatus }),
                },
            })).unwrap();
            const { tasks } = res?.data;
            const { totalTasks } = res
            const driverListArray = tasks?.map((item) => ({
                ...item,
            }));
            params.successCallback([...driverListArray], totalTasks);
            pageRef.current += 20;
        } catch {
            params.successCallback([], 0);
        }
    }, [dispatch]);


    useEffect(() => {
        if (dataPassComponent) {
            searchFn(dataPassComponent)
        }
    }, [dataPassComponent])

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (taskloading) {
                mainGridApiRef.current.showLoadingOverlay();
            } else if (taskTable?.totalTasks === 0) {
                mainGridApiRef.current.showNoRowsOverlay();
            } else {
                mainGridApiRef.current.hideOverlay();
            }
        }
    }, [taskTable, taskloading, dataPassComponent]);

    const searchFn = useCallback((dataPassComponent) => {
        pageRef.current = 0
        const dataSource = {
            getRows: (params) => getRows(params, dataPassComponent)  // Pass status to getRows
        };
        if (mainGridApiRef.current) {
            mainGridApiRef.current.ensureIndexVisible(0, null);
            mainGridApiRef.current.setGridOption('datasource', dataSource);
        }
    }, [getRows]);

    const handleSearch = useDebounce((term) => {
        pageRef.current = 0
        searchFn(dataPassComponent)
    }, 500);

    const parseCsv = (csv) => {
        return new Promise((resolve, reject) => {
            Papa.parse(csv, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    // Resolve with the parsed data
                    resolve(result.data);
                    // console.error('Error parsing CSV work:', result);

                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                    reject(error);
                }
            });
        });
    };

    const handleExport = async () => {
        try {
            const res = await dispatch(tableUnarchiveExport()).unwrap()
            const parseData = await parseCsv(res)
            setCsv(parseData)
        } catch (error) {
            console.error('Error handling CSV export or parsing:', error);
        }
    }

    useEffect(() => {
        handleExport()
    }, [])

    return (
        <Box px={4} py={3}>
            <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={0} mb={3}>
                <Grid item xs={12} sm={2}>
                    <Typography variant='h4' color='#fff'>Table</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={1.5}>
                        <TextField className="custom-search-field dark-field" placeholder="Search" label=""
                            value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <IconButton size='small'>
                                        <SearchIcon width='18px' height='18px' />
                                    </IconButton>
                                </InputAdornment>,
                                endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                                    <IconButton onClick={() => searchHandler('')} size='small'>
                                        <CloseRounded />
                                    </IconButton>
                                </InputAdornment> : null
                            }}
                            style={{ width: "270px" }}
                        />

                        <MDButton onClick={() => { handleExport() }} style={{ height: "100%", textTransform: "none", minWidth: "180px", borderRadius: "30px" }}
                            startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
                            variant="contained" color="primary"
                        >
                            <CSVLink data={csv} style={{ color: "#ffffff", textTransform: "none" }}>
                                Export as .CSV
                            </CSVLink>
                        </MDButton>

                    </Stack>
                </Grid>
            </Grid>

            <div className="ag-theme-quartz-dark">
                <AgGridTable
                    col={columnDefs}
                    mainGridApiRef={mainGridApiRef}
                    getRows={getRows}
                    pageRef={pageRef}
                    isLoading={taskloading}
                    style={{ height: "64vh" }}
                    gridOptions={{ rowHeight: 64 }}
                    maxLimit={20}
                />
            </div>

            <Stack direction='row' alignItems="center" justifyContent="end" mt={3} className='w-100 yellow-btn'>
                <MDButton style={{ height: "100%", textTransform: "none", minWidth: "120px" }}
                    variant="contained" color="primary"
                    onClick={() => setSearchParams({ roleTab: "archive" })}
                    className="d-flex align-item-center gap-2"
                >
                    <ArchiveIcon />
                    <span>Archive</span>
                </MDButton>
            </Stack>
        </Box>
    )
}

export default Table;
