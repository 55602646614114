import { Box, Typography } from '@mui/material';
import ActiveIcon from 'common/customIcons/active';
import InActiveIcon from 'common/customIcons/inactive';
import InProgressIcon from 'common/customIcons/inprogress';
import React from 'react';

const statusComponents = {
    deliveryTask: (showIcon) => (
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Delivery Task</Typography>
    ),
    returnToHeadquarterTask: (showIcon) => (
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Return to Headquarter Task</Typography>
    ),
    startTask: (showIcon) => (
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Start Task</Typography>
    ),
    breakTask: (showIcon) => (
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Break Task</Typography>
    ),
    pickUpTask: (showIcon) => (
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Pickup Task</Typography>
    ),

    not_started: (showIcon) => (
        <Box color="#8A8A98" display="flex" alignItems="center">
            {showIcon && <InActiveIcon width="18px" height="18px" flex="none" />}
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Not Started</Typography>
        </Box>
    ),
    in_progress: (showIcon) => (
        <Box color="#FFDB1E" display="flex" alignItems="center">
            {showIcon && <InProgressIcon width="18px" height="18px" flex="none" />}
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">In Progress</Typography>
        </Box>
    ),
    completed: (showIcon) => (
        <Box color="#24CA49" display="flex" alignItems="center">
            {showIcon && <ActiveIcon width="18px" height="18px" flex="none" />}
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Completed</Typography>
        </Box>
    ),
    failed: (showIcon) => (
        <Box color="#FD4438" display="flex" alignItems="center">
            {showIcon && <InActiveIcon width="18px" height="18px" flex="none" />}
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Failed</Typography>
        </Box>
    ),
    onduty: () => (
        <Box color="#24CA49" display="flex" alignItems="center">
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">On Duty</Typography>
        </Box>
    ),
    offduty: () => (
        <Box color="#FD4438" display="flex" alignItems="center">
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Off Duty</Typography>
        </Box>
    )
};

const StatusTask = ({ value, icon }) => {
    const showIcon = icon === "showIcon";
    const content = statusComponents[value] ? statusComponents[value](showIcon) : <p style={{ color: "gray" }}>--</p>;

    return (
        <div style={{ fontWeight: "500" }}>
            {content}
        </div>
    );
};

export default StatusTask;
