import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addMemberAPI, deleteMemberAPI, fetchMemberAPI, fetchMemberDetailsAPI, fetchMemberOrdersAPI, updateMemberAPI } from '../apis/members'
import axiosClient from "../../axiosClient/index";
import { getQueryString } from "utilities/common";
import { openSnackbar } from "./common";

export const fetchMembers = createAsyncThunk(
  'members/fetchMembers',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await axiosClient.get(`/api/v1/member?${getQueryString(filters)}`);
      return res
    } catch (error) {
      console.log("erro", error.response)
      return rejectWithValue(error?.response?.data)
    }
  }
)


export const fetchMemberOrders = createAsyncThunk(
  'members/fetchMemberOrders',
  async ({ filters, memberId }, { rejectWithValue }) => {
    try {
      const res = await fetchMemberOrdersAPI(filters, memberId)
      return res
    } catch (error) {
      console.log("erro", error.response)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const fetchMembersDetails = createAsyncThunk(
  'members/fetchMembersDetails',
  async (memberId, { rejectWithValue }) => {
    try {
      const res = await fetchMemberDetailsAPI(memberId)
      return res
    } catch (error) {
      console.log("erro", error.response)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const addMember = createAsyncThunk(
  'members/addMember',
  async (data, { rejectWithValue }) => {
    try {
      const res = await addMemberAPI(data)
      return res
    } catch (error) {
      console.log("erro", error.response)
      return rejectWithValue(error?.response?.data)
    }
  }
)


export const updateMember = createAsyncThunk(
  'members/updateMember',
  async ({ data, memberId }, { rejectWithValue }) => {
    try {
      const res = await updateMemberAPI(data, memberId)
      return res
    } catch (error) {
      console.log("erro", error.response)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const deleteMember = createAsyncThunk(
  'members/deleteMember',
  async (memberId, { rejectWithValue }) => {
    try {
      const res = await deleteMemberAPI(memberId)
      return res
    } catch (error) {
      console.log("erro", error.response)
      return rejectWithValue(error?.response?.data)
    }
  }
)

// Create a slice with reducers
const membersSlice = createSlice({
  name: 'members',
  initialState: {
    members: [],
    memberDetails: {},
    loading: false,
    error: null,
  },
  reducers: {
    // Define additional reducers here if needed
  },
  extraReducers: (builder) => {
    // Extra reducer for the fulfilled action of fetchMembers
    builder.addCase(fetchMembers.fulfilled, (state, action) => {
      state.loading = false;
      state.members = action.payload; // Update state with the received data
      state.memberDetails = {}
      state.error = null;
    });

    // Extra reducer for the rejected action of fetchMembers
    builder.addCase(fetchMembers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; // Update state with the error message
    });

    // Extra reducer for the pending action of fetchMembers
    builder.addCase(fetchMembers.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMembersDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.memberDetails = action.payload.memberDetails; // Update state with the received details
      state.error = null;
    });

    // Extra reducer for the rejected action of fetchMembersDetails
    builder.addCase(fetchMembersDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; // Update state with the error message
    });

    // Extra reducer for the pending action of fetchMembersDetails
    builder.addCase(fetchMembersDetails.pending, (state) => {
      state.loading = true;
    });

    builder
      .addCase(fetchMemberOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMemberOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.memberOrders = action.payload.memberOrders; // Assuming the response is the payload
        state.ordersTotal = action?.payload?.total || 0
      })
      .addCase(fetchMemberOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Using the error returned from rejectWithValue
      });
    builder
      .addCase(addMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

    builder.addCase(addMember.fulfilled, (state, action) => {
      state.loading = false;
      state.addedMember = action.payload; // Update state with the added member data
      state.error = null;
    });

    // Extra reducer for the rejected action of addMember
    builder.addCase(addMember.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; // Update state with the error message
    });

    builder
      .addCase(updateMember.pending, (state,action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateMember.fulfilled, (state, action) => {
        state.loading = false;
        // You can update your state with the data received from the API if needed
      })
      .addCase(updateMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // The error message returned from the API
      });
  },
});

export const { /* Any additional actions */ } = membersSlice.actions;

export default membersSlice.reducer;