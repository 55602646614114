import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAnalyzeDataAPI, getAnalyzeGraphDataAPI } from '../../../redux/apis/hyperdrive/analyze';

const initialState = {
  loading: false,
  error: null,
  analyzeData: {},
  analyzeGraphData:{}
};

export const fetchAnalyzeData = createAsyncThunk(
  'common/fetchAnalyzeData',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await getAnalyzeDataAPI(filters);
      return res?.data;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);


export const fetchAnalyzeGraphData = createAsyncThunk(
  'common/fetchAnalyzeGraphData',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await getAnalyzeGraphDataAPI(filters);
      return res?.data;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);
const analyzeSlice = createSlice({
  name: 'driverAnalyze',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalyzeData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnalyzeData.fulfilled, (state, action) => {
        state.loading = false;
        state.analyzeData = action.payload;
      })
      .addCase(fetchAnalyzeData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(fetchAnalyzeGraphData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnalyzeGraphData.fulfilled, (state, action) => {
        state.loading = false;
        state.analyzeGraphData = action.payload;
      })
      .addCase(fetchAnalyzeGraphData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { } = analyzeSlice.actions;

export default analyzeSlice.reducer;