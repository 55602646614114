/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";
import { useDispatch, useSelector } from "react-redux";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, Tooltip } from "@mui/material";
import { useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import {
  setMiniSidenav
} from "../../redux/slices/materialUISlice";

// Material Dashboard 2 React context
// import { useMaterialUIController } from "context";

function SidenavCollapse({ icon, name, active, collapse, index, handleOpenCategory, screen, isOpen, setOpen, ...rest }) {
  const controller = useSelector((state) => state.materialUI);
  const dispatch = useDispatch();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  const isChildNavOpen = useMemo(() => {
    if (active) {
      return collapse?.find((item => item.screen === screen)) ? true : false
    }
    return false
  }, [collapse, active])

  useEffect(() => {
    if (isChildNavOpen) {
      dispatch(setMiniSidenav(true))
      setOpen({
        id: index,
        status: true,
      });
    }
  }, [isChildNavOpen])

  return (
    <ListItem component="li" sx={{ margin: 0 }}>
      <MDBox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
        }
        onClick={() => handleOpenCategory(index)}

      >

        {!miniSidenav ? <Tooltip title={name} arrow placement="right">
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>
        </Tooltip> : <ListItemIcon
          sx={(theme) =>
            collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
          }
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>}

        <ListItemText
          primary={name}
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }

        />
        {
          collapse?.length >= 1 &&
            (isOpen?.id === index && isOpen?.status) ? <ExpandLess onClick={() => handleOpenCategory(index)} />
            : collapse?.length >= 1 ? <ExpandMore onClick={() => handleOpenCategory(index)} />
              : null
        }
      </MDBox>

      <List>
        <Collapse
          in={isOpen?.id === index ? (isOpen?.status) : false}
          timeout="auto"
          unmountOnExit>

          {collapse?.map((subItem, subIndex) => {
            const { name, active } = subItem
            // const { name = "", blazeCategorySlug = "" } =
            //   subItem;
            // let isActive = subItem?.blazeCategorySlug === subtype
            return (

              <NavLink key={subIndex} to={subItem?.route}>
                <ListItem component="li">
                  {!miniSidenav ? <Tooltip title={name} arrow placement="right">
                    <ListItemIcon
                      sx={(theme) =>
                        collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
                      }
                    >
                      {typeof subItem.icon === "string" ? (
                        <Icon sx={(theme) => collapseIcon(theme, { active })}>{subItem.icon}</Icon>
                      ) : (
                        subItem.icon
                      )}
                    </ListItemIcon>
                  </Tooltip> : null}
                  {
                    miniSidenav && <MDBox
                      {...rest}
                      sx={(theme) =>
                        collapseItem(theme, {
                          active: active,
                          transparentSidenav,
                          whiteSidenav,
                          darkMode,
                          sidenavColor,
                        })
                      }
                    >
                      {
                        miniSidenav && <Tooltip title={name} arrow placement="right">
                          <ListItemIcon
                            sx={(theme) =>
                              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
                            }
                          >
                            {typeof subItem.icon === "string" ? (
                              <Icon sx={(theme) => collapseIcon(theme, { active })}>{subItem.icon}</Icon>
                            ) : (
                              subItem.icon
                            )}
                          </ListItemIcon>
                        </Tooltip>
                      }

                      <ListItemText
                        primary={subItem?.name}
                        sx={(theme) =>
                          collapseText(theme, {
                            miniSidenav,
                            transparentSidenav,
                            whiteSidenav,
                            active,
                          })
                        } />

                    </MDBox>
                  }


                </ListItem>
              </NavLink>
            );
          })}

        </Collapse>
      </List>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  collapse: PropTypes.array,
  index: PropTypes.number,
  handleOpenCategory: PropTypes.func,
  isOpen: PropTypes.object
};

export default SidenavCollapse;
