import { FormControl, InputLabel, List, ListItem, MenuItem, Select } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';

export default function InfiniteScrollList({ uiScreen, dataFetcher = () => { }, options = [], optionSelectHandler = () => { }, value = '', label = 'Select' }) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [lists, setLists] = useState([])
    const observer = useRef();

    const loadMoreData = useCallback(async (data) => {
        console.log("uiScreen", uiScreen);

        setLoading(true);
        try {
            const limit = 20;
            const skip = page * limit;
            const res = await dispatch(dataFetcher({
                skip,
                limit,
            })).unwrap();

            const newPosts = uiScreen ? res?.fleetDetails : res?.data;
            console.log("newPosts", res);

            if (newPosts?.length === 0) {
                setHasMore(false);
            }
        } catch (err) {
            console.log("err", err)
        } finally {
            setLoading(false);
        }
    }, [dispatch, page]);

    useEffect(() => {
        if (hasMore) {
            loadMoreData();
        }
    }, [loadMoreData, hasMore]);

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading || !hasMore) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );
    useEffect(() => {
        setLists([...lists, ...options])
    }, [JSON.stringify(options)])

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={(e) => optionSelectHandler(e.target.value)}
                className='form-select'
                MenuProps={{
                    style: {
                        height: 400
                    }
                }}
                label={label}
            >
                {
                    lists?.length > 0 && lists?.map(((option, idx) => {
                        return <MenuItem
                            value={option?.value}
                            key={idx + 1}
                            ref={lists.length === idx + 1 ? lastPostElementRef : null}
                        // className='d-flex align-item-top jc-s-btwn gap-2'
                        >
                            {
                                option?.label
                            }
                        </MenuItem>
                    }))
                }
            </Select>
        </FormControl>

    )
}
