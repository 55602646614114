import { Autocomplete, Box, Button, Card, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, OutlinedInput, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchBrands } from '../../../redux/slices/brand';
import MDButton from 'components/MDButton';
import { closeCustomDrawer } from '../../../redux/slices/modalSlice';
import { isNumber } from 'utilities/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAdmins, fetchTransportations } from '../../../redux/slices/hyperwolf/driver/driver';
import { fetchDriversRegions } from '../../../redux/slices/hyperwolf/driver/driverRegion';
import DateRange from 'components/dateRangePicker/DateRange';
import { formattedDate } from 'utilities/common';

function hasAnyValue(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && (
            (typeof obj[key] === 'object' && hasAnyValue(obj[key])) ||
            (typeof obj[key] !== 'object' && obj[key] !== '')
        )
        ) {
            return true;
        }
    }
    return false;
}



export default function FilterApproval(props = {}) {
    const location = useLocation()
    const { data} = useSelector(state => state)
    console.log(useSelector(state => state.drivers), 'state console')
    const navigate = useNavigate()
    const [ formData, setFormData] = useState({ notificationStatus: ''})
    const { applyApprovalFilter, getQueryValues= {} , setIsLoading = () => {}} = props
    const dispatch = useDispatch()

    useEffect(() => {
        if (formData.notificationStatus) {
            applyFiltersHandler();
        }
    }, [formData.notificationStatus]);

    const applyFiltersHandler = () => {
        const { notificationStatus } = formData || {}
        console.log(formData,  'formDataxxx')
        let queryPayload =  {
            notificationStatus: notificationStatus
        }
        const currentParams = new URLSearchParams(location.search);

        if (queryPayload.notificationStatus === 'all') {
            queryPayload = { ...queryPayload, notificationStatus: '' }
        }

        Object.keys(queryPayload).forEach((key) => {
            if (typeof queryPayload[key] === 'object') {
                currentParams.set(key, JSON.stringify(queryPayload[key]));
            } else {
                currentParams.set(key, queryPayload[key]);
            }

            currentParams.set('driverFilter=approvals', '');
        const urlWithParams = `${location.pathname}?${currentParams.toString()}`;
            navigate(urlWithParams)
            applyApprovalFilter({ queryPayload })
        });
    }

    const handleStatusChange = (e) => {
        const newStatus = e.target.value;
        console.log(newStatus, 'new status')
        setFormData(prevData => ({...prevData, notificationStatus: newStatus}));
        console.log(formData, 'hhhhhhhh')
    }


    return (
        <Box className="cus-filter-sec" mb={1}>
            <Grid className='filter-box' container alignItems="center" justifyContent="flex-end" spacing={1.5}>
            <Grid item xs={12} sm={4} md={4} xxl={2}>
            <Box className="filter-pro-status">
            <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select  size='small' value={formData.notificationStatus} onChange={handleStatusChange} fullWidth className='form-select' name='notificationStatus' label='Status'>
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='pending' >Pending</MenuItem>
                <MenuItem value='approved' >Approved</MenuItem>
                <MenuItem value='declined' >Declined</MenuItem>
            </Select>   
            </FormControl>
            </Box>
            </Grid>
            </Grid>
        </Box>

    )

}