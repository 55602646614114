
// @mui material components
import Grid from "@mui/material/Grid";
import CommonLayout from "layouts/DashboardLayout";
import { Box } from "@mui/material";
import Tracking from "./Tracking";


function Home() {

  return (
    <Tracking />
  );
}

export default Home;
