import { Autocomplete, Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { bulkProductUpdate, openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog } from '../../redux/slices/modalSlice';
import { overrideApproval } from '../../redux/slices/approvals';
import { fetchProducts } from '../../redux/slices/products';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import GridVirtualized from '../component/Virtualized';
import SearchBar from '../component/SearchBar';


export default function OverrideApproval({ closeModal, itemContent = null, loadMore = () => { }}) {
    const { modalData, modal, isLoading = false } = useSelector(state => state.modal)
    const { isDeleting = false } = useSelector(state => state.common)
    const { loading=false,  products: productsData } = useSelector(state => state.products)
    const [allProductsData, setAllProductsData] = useState([])
    const [loadedProductCount, setLoadedProductCount] = useState(0);
    const [total, setTotal] = useState(0)
    const [productName, setProductName] = useState("")
    const [masterProductId, setMasterProductId] = useState("")
    const [errors, setErrors] = useState({})
    const { approvalId, retailerId, productId, resetData = () => { } } = modalData
    console.log(modalData, 'modalData')
    const searchFn = modalData.searchFn
    const approvalRef = modalData.approvalRef
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const search = useSearchParams()
    const [searchTerm, setSearchTerm] = useState('')
    const [labelName, setLabelName] = useState('')
    const [selectedOption, setSelectedOption] = useState('')
    const route = pathname.split("/").slice(1)[0];

    useEffect(() => {
        dispatch(fetchProducts({ filters: { skip: 0, limit: 1000, userType: 'admin'}})).unwrap().then(res => {
     const { filteredProducts, total } = res
       setAllProductsData(filteredProducts)
       setTotal(total)
   });
     }, []);

     console.log(route, 'PATHNAME APPROVALS')

     console.log(loading, 'loading state')

    console.log(masterProductId, 'this is masterProductId')
    typeof(approvalRef), 'type o fapprovalREf'

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
          dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
          dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
      }
      const currentUrl = window.location.href;
      console.log(currentUrl, 'current url')
      console.log(search, 'filter')



    const handleOverrideApproval = () => {
        console.log('hhh')
        if (!selectedOption) {
            setErrors({ productName: 'Product Name should not be empty' })
            return
        }
        setErrors({})
        dispatch(overrideApproval({ approvalId, data: { retailerId: retailerId, productId: productId, masterProductId: selectedOption.productId } })).unwrap().then((res => {
            showResponseMessage(res?.message, 'success')
            dispatch(closeCustomModalDialog({}))
            if (typeof searchFn === 'function') {
                console.log(typeof(searchFn, 'search  fm type'))
                searchFn();
                approvalRef.current()
            } 
            else if (route === "approvals"){
                navigate('/approvals')
            }
            else {
                console.error(typeof(searchFn),typeof(approvalRef), 'searchFn, approvalRef is not a function');
                navigate(-1)
            }
        })).catch((err => {
            showResponseMessage(err?.message, 'error')
            console.log("err", err)
        }))
    }



    const searchHandler = (value) => {
        // const { value } = e.target;
        setSearchTerm(value);
        // searchTermRef.current = value
        // handleSearch(value);

        console.log(searchTerm, 'searchTerm..')
      };

      const optionSelectHandler = (selectedOption) => {
        console.log("Selected option:", selectedOption);
        setMasterProductId(selectedOption.productId)
        // Add your logic here, e.g., updating state or making API calls
    }    


            
   

    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between"  >
                Override Product
                <IconButton onClick={closeModal} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <DialogContent  >

        
            <Box mt={1}>
            <Autocomplete
                      id="tags-outlined"
                      options={allProductsData?.length > 0 ? allProductsData?.filter((productItem => productItem?.status === 'active')) : []}
                      getOptionLabel={(option) => option?.productName}
                      filterSelectedOptions
                    //   onChange={(val, selectedOption, details) => {
                    //     productDataChangeHandler({ target: { name: 'productId', value: selectedOption?.productId } }, orderIdx)
                    //   }}
                    //   value={productName || ''}
                    onChange={(event, selectedOption) => {
                        setSelectedOption(selectedOption); // Update the state with the selected option
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors?.errors?.[orderIdx]?.productId)}
                          helperText={errors?.errors?.[orderIdx]?.productId}
                          label="Select Product"
                        />
                      )}

                    />
            </Box>



                {/* <Box mt={1} sx={{ position: 'relative'}}>
                    <SearchBar searchHandler={searchHandler} label="Search Product" 
                    options={searchTerm && productsData?.filteredProducts?.filter(product=>product?.productName.includes(searchTerm))} 
                    optionSelectHandler={optionSelectHandler}
                    labelName={labelName}
                    /> */}
                    {/* <GridVirtualized 
                    data={productsData.filteredProducts}
                    itemContent={(index,product) => <div key={index}>{product.productName}</div>}
                    loadMore={loadMore}
                    total={total}
                    showMore={loadedProductCount >= total ? false : true}
                    isInfinite
                    viewMode="list"
                    setLoadedItemCount={setLoadedProductCount}
                    /> */}
                {/* </Box> */}
            </DialogContent>

            <DialogActions>
                <MDButton onClick={closeModal} size="small" color="secondary">
                    Cancel
                </MDButton>
                <MDButton onClick={handleOverrideApproval} size="small" color='error'>
                    {
                        isLoading || isDeleting ? <CircularProgress size={16} color='light' /> : " Update"
                    }
                </MDButton>
            </DialogActions>




        </>
    )

}