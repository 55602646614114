import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { isEmptyObject } from 'utilities/common';
import { addUser } from '../../../redux/slices/hyperwolf/users';
import { validateUserData } from 'validations/hyperwolf/user';
import CommonLayout from 'layouts/DashboardLayout';
import { Box, Card, Button, Typography, CircularProgress, Grid } from '@mui/material';
import CustomInput from 'components/common/CustomInput';

const initialUser = {
    name: "",
    email: "",
    password: "",
}

function HyperwolfUserAdd(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const { modalData } = useSelector(state => state.common)
    const { loading = false, singleUser = {} } = useSelector(state => state.hyperwolfUser)

    // const { title = "Add", data: editData = {} } = modalData

    const [user, setUser] = useState({ ...initialUser })
    const [errors, setErrors] = useState({ ...initialUser })

    useEffect(() => {
        if (!isEmptyObject(singleUser)) {
            setUser(singleUser)
        }
    }, [])

    const onChangehandler = (e) => {
        const { name = '', value = '' } = e.target || {}
        setUser({ ...user, [name]: value })
    }

    const addUserHandler = () => {
        const { errors, isValid } = validateUserData(user)
        setErrors(errors);

        if (isValid) {
            dispatch(addUser({data:user, id:(user._id || '')})).unwrap().then(res => {
                navigate('/hyperwolf/users')
            })
        }
    }

    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: "none" }}>
                    <Typography variant="h4" className="d-flex jc-ctr">{user?._id ? 'Update' : 'Add'} User</Typography>
                    <Grid container component="main" className={`add-zipcode-modal-container`}>
                        <Grid item xs={12} className="add-category-input">
                            <Grid item xs={12}>
                                <CustomInput
                                    name="name"
                                    value={user.name || ""}
                                    onChange={onChangehandler}
                                    label="Name"
                                    className="w-100"
                                    margin="normal"
                                    error={errors.name}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <CustomInput
                                    name="email"
                                    value={user.email || ""}
                                    onChange={onChangehandler}
                                    label="Email"
                                    className="w-100"
                                    margin="normal"
                                    error={errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <CustomInput
                                    type="password"
                                    name="password"
                                    value={user.password || ""}
                                    onChange={onChangehandler}
                                    label="Password"
                                    className="w-100 "
                                    margin="normal"
                                    error={errors.password}
                                />
                            </Grid>

                        </Grid>

                    </Grid >
                    <Box textAlign='end' mt={2}>
                        <Button onClick={addUserHandler} variant="contained" color="primary">{loading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Submit'}</Button>

                    </Box>
                </Card>
            </Box>

        </CommonLayout>
    );
}



export default HyperwolfUserAdd