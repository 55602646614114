import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchOrdersAPI(filters) {

    return await axiosClient.get(`/api/v1/order?${getQueryString(filters)}`);
}

export async function fetchWarrantyOrdersAPI(filters) {
    return await axiosClient.get(`/api/v1/order/warranty?${getQueryString(filters)}`);
}


export async function warrantyOrderAPI(data) {
    return await axiosClient.post(`/api/v1/order/warranty/status`,data);
}


export async function fetchMemberOrdersAPI(filters) {

    return await axiosClient.get(`/api/v1/member/orders?${getQueryString(filters)}`);
}

export async function createOrderAPI(data, orderId = "") {
    if (orderId) {
        return await axiosClient.put(`/api/v1/order/update/${orderId}`, data);
    }
    return await axiosClient.post(`/api/v1/order`, data);
}

export async function deleteOrderAPI(orderId) {
    return await axiosClient.delete(`/api/v1/order/${orderId}`);
}


export async function fetchOrderDetailsAPI(orderId) {
    return await axiosClient.get(`/api/v1/order/${orderId}`);
}