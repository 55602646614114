import { useState, useEffect, useMemo } from "react";

// react-router components
import { useLocation, Link, useParams, useSearchParams } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import NotificationItem from "examples/Items/NotificationItem";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';


// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarMobileMenu
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setDarkMode
} from "../../../redux/slices/materialUISlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, Stack, Typography } from "@mui/material";

// Images
import hyperwolfLogo from "assets/images/hyperwolf.svg";
import hyperwolfWhiteLogo from "assets/images/hyperwolf-white.svg";
import { logoutUserData } from "../../../redux/slices/authSlice";
import { orderFilterTabs } from "utilities/common";
import { ExpandMore } from "@mui/icons-material";
import MDButton from "components/MDButton";
import { strainsTabs } from "utilities/common";
import { brandTabs } from "utilities/common";
import { roleAndPermissionTabs } from "utilities/common";
import NotificationCount from "components/notifications/NotificationCount";
import { getData } from "utilities/common";
import { setData } from "utilities/common";
import { driverFilterTabs } from "utilities/common";
import { adminsOptions } from "utilities/common";
import { hyperdriveTaskOptions } from "utilities/common";
import { openCustomDrawer } from "../../../redux/slices/modalSlice";
import { HYPERDRIVE } from "utilities/constants";
import AddIcon from '@mui/icons-material/Add';
import { cannabinoidsTabs } from "utilities/common";

const createTaskDrawerOptions = {
  pick_up_task: 'PICKUP_TASK',
  drop_off_task: 'DROP_OFF_TASK',
  break_task: 'BREAK_TASK',
  start_task: 'START_TASK',
  return_to_hq_task: 'RETURN_HQ_TASK'
}


function DashboardNavbar({ absolute, light, isMini }) {
  const { user = {} } = useSelector(state => state.auth)
  const { adminsMode = '' } = useSelector(state => state.common)

  const [navbarType, setNavbarType] = useState();
  const controller = useSelector((state) => state.materialUI);
  const params = useParams()
  const { pathname, search } = useLocation()
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [adminsMenu, setOpenAdminsMenu] = useState(false);
  const [taskMenu, setOpenTaskMenu] = useState(false);


  const route = pathname.split("/").slice(1);
  const { account } = useSelector((state) => state.auth)
  let productFilter = searchParams.get('orderFilter')
  let brandTab = searchParams.get('brandTab')
  let roleTab = searchParams.get('roleTab')
  const basePath = pathname.split('/').slice(0, 4).join('/');

  let productTab = searchParams.get('filter')
  let retailerTab = searchParams.get('filter')
  let driverFilter = searchParams.get('driverFilter')
  let cannabinoidsFilter = searchParams.get('cannabinoidTab')


  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      dispatch(setTransparentNavbar((fixedNavbar && window.scrollY === 0) || !fixedNavbar));
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => dispatch(setMiniSidenav(!miniSidenav));
  const handleConfiguratorOpen = () => dispatch(setOpenConfigurator(!openConfigurator));
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenProfileMenu = (event) => setOpenProfileMenu(event.currentTarget);
  const handleOpenAdminsMenu = (event) => setOpenAdminsMenu(event.currentTarget);
  const handleOpenTaskMenu = (event) => setOpenTaskMenu(event.currentTarget);


  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseProfileMenu = () => setOpenProfileMenu(false);
  const handleCloseAdminsMenu = () => setOpenAdminsMenu(false);
  const handleCloseTaskMenu = () => setOpenTaskMenu(false);



  const deviceToken = getData('firebaseDeviceToken');
  const cleanDeviceToken = deviceToken?.replace(/^"(.*)"$/, '$1').replace(/\\(.)/g, '$1');


  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem title="Notification Here" />
      {/* <NotificationItem icon={<Icon>email</Icon>} title="My profile" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" /> */}
    </Menu>
  );
  // Render the notifications menu
  const renderProfileMenu = () => (
    <Menu
      anchorEl={openProfileMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openProfileMenu)}
      onClose={handleCloseProfileMenu}
      sx={{ mt: 2 }}
      MenuListProps={{ className: "hd-dropdown-sec" }}
    >
      <NotificationItem className="popover-dropdown" fontSize="14px" style={{ color: "#F5F5FF" }} onClick={() => { dispatch(logoutUserData({ email: user?.email, deviceToken: cleanDeviceToken, isSuperAdmin: true })); }} icon={<Icon><ExitToAppIcon /></Icon>} title="Logout" />
      {/* <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" /> */}
    </Menu>
  );

  const adminsSelectHandler = (value = '') => {
    if (value) {
      setData('adminsMode', value)
      window.location.href = '/'
      // window.location.reload()
    }
  }

  const taskSelectHandler = (value = '') => {
    if (value) {
      dispatch(openCustomDrawer({ drawer: createTaskDrawerOptions[value], drawerData: {} }))
      handleCloseTaskMenu()
    }
  }

  const renderAdminsMenu = () => (
    <>
      <Menu
        anchorEl={adminsMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(adminsMenu)}
        onClose={handleCloseAdminsMenu}
        MenuListProps={{ className: "hd-dropdown-sec" }}
      >
        {
          adminsOptions?.filter((adminItem => adminItem?.value !== adminsMode))?.map((admin => {
            return <MenuItem key={admin.value} onClick={() => adminsSelectHandler(admin.value)}> <Typography fontSize="14px" variant='body1'>{admin?.key}</Typography> </MenuItem>
          }))
        }
      </Menu>

    </>
  );

  const renderTaskMenu = () => (
    <>
      <Menu
        anchorEl={taskMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(taskMenu)}
        onClose={handleCloseTaskMenu}
        MenuListProps={{ className: "hd-dropdown-sec" }}
      >
        {
          hyperdriveTaskOptions?.map((taskOption => {
            return <MenuItem onClick={() => taskSelectHandler(taskOption.value)} key={taskOption.value}>
              <Typography fontSize="14px" variant='body1'>{taskOption?.key}</Typography>
            </MenuItem>
          }))
        }
      </Menu>

    </>
  );


  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const user_detail = useMemo(() => {
    return account?.find((account) => account.name)

  }, [account])
  const content = (
    <div>
      {`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id dignissim justo.
   Nulla ut facilisis ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus.
   Sed malesuada lobortis pretium.`}
    </div>
  );

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleDarkMode = () => dispatch(setDarkMode(!darkMode));

  useEffect(() => {
    if (adminsMode === HYPERDRIVE && !darkMode) {
      handleDarkMode()
    }
  }, [adminsMode, darkMode])

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    palette: { white, dark, background },
    borders: { borderWidth },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? background.sidenav : white.main,
    color: darkMode ? white.main : dark.main,
    border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? background.sidenav : white.main,
      color: darkMode ? white.main : dark.main,
      border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
    },
  });

  // sidenav type active button styles
  const sidenavTypeActiveButtonStyles = ({
    functions: { pxToRem, linearGradient },
    palette: { white, gradients, background },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
    color: darkMode ? background.sidenav : white.main,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
      color: darkMode ? background.sidenav : white.main,
    },
  });

  // const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  //   width: 62,
  //   height: 34,
  //   padding: 8,
  //   '& .MuiSwitch-switchBase': {
  //     margin: 0,
  //     padding: 0,
  //     transform: 'translateX(6px)',
  //     '&.Mui-checked': {
  //       color: '#fff',
  //       transform: 'translateX(22px)',
  //       '& .MuiSwitch-thumb:before': {
  //         backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
  //           '#fff',
  //         )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
  //       },
  //       '& + .MuiSwitch-track': {
  //         opacity: 1,
  //         backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
  //       },
  //     },
  //   },
  //   '& .MuiSwitch-thumb': {
  //     backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
  //     width: 32,
  //     height: 32,
  //     '&::before': {
  //       content: "''",
  //       position: 'absolute',
  //       width: '100%',
  //       height: '100%',
  //       left: 0,
  //       top: 0,
  //       backgroundRepeat: 'no-repeat',
  //       backgroundPosition: 'center',
  //       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
  //         '#fff',
  //       )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
  //     },
  //   },
  //   '& .MuiSwitch-track': {
  //     opacity: 1,
  //     backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
  //     borderRadius: 20 / 2,
  //   },
  // }));

  const tabLink = {
    textDecoration: "none"
  };
  const activeTabLink = {
    textDecoration: "underline",
    textUnderlineOffset: "4px"
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      className="header-sec"
    >
      <Toolbar sx={(theme) => navbarContainer(theme)} className="cus-toolbar">
        {/* <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}> */}
        {/* <Box display='flex' alignItems='center'>
            {
              tabs?.map(((tab, idx) => {
                const { member: { firstName, lastName }, transNo } = tab || {}
                return <>
                  <Box display='flex' alignItems='center' gap={1} fontSize={'12px'}>
                    <span style={{ fontSize: "14px", height: 20, width: 20, border: '1px solid purple', background: 'purple' }}></span>
                    <Box width='100%'>
                      <Typography style={{ fontSize: '12px' }} variant='body1'>{firstName} {lastName}</Typography>
                      <Typography style={{ fontSize: '12px' }} variant='body1'>{transNo}</Typography>
                    </Box>
                  </Box>
                  {
                    tabs?.length !== idx + 1 && <Divider sx={{ height: 48, color: 'red', background: 'grey', fontWeight: 700, padding: 0 }} orientation="vertical" flexItem>
                    </Divider>
                  }
                </>
              }))
            }
          </Box> */}

        {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
        {/* <div className="welcome-name">
            <MDTypography
              // component="p"
              variant="h3"
              color={light ? "white" : "dark"}
              fontWeight="medium"
            >
              Hello, {user_detail?.name}!
            </MDTypography>
            <MDTypography
              component="p"
              // variant="body2"
              color={light ? "white" : "dark"}
              fontWeight="normal"
            >
              Welcome back to the IOAG!
            </MDTypography>
          </div> */}

        {/* <div>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                // border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                bgcolor: 'background.paper',
                color: 'text.secondary',
                '& svg': {
                  m: 1.5,
                },
                '& hr': {
                  mx: 0.5,
                },
              }}
            >
              <Box display='flex' alignItems='center' gap={1} fontSize={'12px'}>
                <span style={{ fontSize: "14px", height: 20, width: 20, border: '1px solid purple', background: 'purple' }}></span>
                <Box>
                  <Typography style={{ fontSize: '12px' }} variant='body1'>Robert Davis</Typography>
                  <Typography style={{ fontSize: '12px' }} variant='body1'>#45663</Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
          </div> */}
        {/* </MDBox> */}

        <MDBox display="flex" gap={1} width={{ xs: "auto" }}>
          {
            adminsMode !== 'hyperdrive' && <IconButton
              size="large"
              disableRipple
              color="inherit"
              style={{ padding: 0 }}
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon sx={iconsStyle}>
                {/* {miniSidenav ? "menu_open" : "menu"} */}
                {!miniSidenav ? "menu" : "menu_open"}
              </Icon>
            </IconButton>
          }

          <img src={darkMode ? hyperwolfWhiteLogo : hyperwolfLogo} alt="HYPERWOLF" />
        </MDBox>

        {
          params?.productId && !params?.retailerId && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            <Link to={`${pathname}?filter=products`}>
              <Button size="medium" variant="text" color={productTab === 'products' ? "primary" : "inherit"} style={productTab === 'products' ? activeTabLink : tabLink}>Product</Button>
            </Link>
            <Link to={`${pathname}?filter=inventory`}>
              <Button size="medium" variant="text" color={productTab === 'inventory' ? "primary" : "inherit"} style={productTab === 'inventory' ? activeTabLink : tabLink}>Inventory</Button>
            </Link>
            <Link to={`${pathname}?filter=batches`}>
              <Button size="medium" variant="text" color={productTab === 'batches' ? "primary" : "inherit"} style={productTab === 'batches' ? activeTabLink : tabLink}>Batches</Button>
            </Link>
            <Link to={`${pathname}?filter=terpenoids`}>
              <Button size="medium" variant="text" color={productTab === 'terpenoids' ? "primary" : "inherit"} style={productTab === 'terpenoids' ? activeTabLink : tabLink}>Terpenes</Button>
            </Link>
            <Link to={`${pathname}?filter=activity_logs`}>
              <Button size="medium" variant="text" color={productTab === 'activity_logs' ? "primary" : "inherit"} style={productTab === 'activity_logs' ? activeTabLink : tabLink}>Logs</Button>
            </Link>
          </Stack>
        }

        {

          params?.retailerId && !params?.productId && searchParams.get('filter') && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            <Link to={`${basePath}?filter=retailerDetails`}>
              <Button size="medium" variant="text" color={retailerTab === 'retailerDetails' ? "primary" : "inherit"} style={retailerTab === 'retailerDetails' ? activeTabLink : tabLink}>Retailer Details</Button>
            </Link>
            <Link to={`${basePath}?filter=retailerProducts`}  >
              <Button size="medium" variant="text" color={retailerTab === 'retailerProducts' ? "primary" : "inherit"} style={retailerTab === 'retailerProducts' ? activeTabLink : tabLink}>Products</Button>
            </Link>
            <Link to={`${basePath}?filter=retailerOrders`}  >
              <Button size="medium" variant="text" color={retailerTab === 'retailerOrders' ? "primary" : "inherit"} style={retailerTab === 'retailerOrders' ? activeTabLink : tabLink}>Orders</Button>
            </Link>
            <Link to={`${basePath}?filter=retailerMembers`}  >
              <Button size="medium" variant="text" color={retailerTab === 'retailerMembers' ? "primary" : "inherit"} style={retailerTab === 'retailerMembers' ? activeTabLink : tabLink}>Members</Button>
            </Link>
            <Link to={`${basePath}?filter=retailerApprovals`}  >
              <Button size="medium" variant="text" color={retailerTab === 'retailerApprovals' ? "primary" : "inherit"} style={retailerTab === 'retailerApprovals' ? activeTabLink : tabLink}>Approvals</Button>
            </Link>
          </Stack>
        }

        {
          pathname === '/manage-cannabinoids' && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              cannabinoidsTabs?.map((cannabinoid => {
                return <Link key={cannabinoid?.value} to={`${pathname}?cannabinoidTab=${cannabinoid.value}`}>
                  <Button size="medium" variant="text" color={cannabinoidsFilter === cannabinoid.value ? "primary" : "inherit"} style={cannabinoidsFilter === cannabinoid.value ? activeTabLink : tabLink}>{cannabinoid?.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }
        {
          (pathname === '/orders' || pathname.includes('/orders/member')) && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              orderFilterTabs?.map((orderTab => {
                return <Link key={orderTab?.value} to={`${pathname}?orderFilter=${orderTab?.value}&sortby=${searchParams?.get('sortby') || 'today'}&searchTerm=${searchParams?.get('searchTerm') || ''}`}>
                  <Button size="medium" variant="text" color={productFilter === orderTab.value ? "primary" : "inherit"} style={productFilter === orderTab.value ? activeTabLink : tabLink}>{orderTab?.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {
          (pathname === '/hyperwolf/driver') && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              driverFilterTabs?.map((driverTab => {
                return <Link key={driverTab?.value} to={`${pathname}?driverFilter=${driverTab.value}&searchTerm=${searchParams?.get('searchTerm') || ''}`}>
                  <Button size="medium" variant="text" color={driverFilter === driverTab.value ? "primary" : "inherit"} style={driverFilter === driverTab.value ? activeTabLink : tabLink}>{driverTab?.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {
          pathname === '/strains' && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              strainsTabs?.map((orderTab => {
                return <Link key={orderTab?.value} to={`${pathname}?orderFilter=${orderTab.value}`}>
                  <Button size="medium" variant="text" color={productFilter === orderTab.value ? "primary" : "inherit"} style={productFilter === orderTab.value ? activeTabLink : tabLink}>{orderTab?.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {
          pathname === '/brands' && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              brandTabs?.map((orderTab => {
                return <Link key={orderTab?.value} to={`${pathname}?brandTab=${orderTab.value}`}>
                  <Button size="medium" variant="text" color={brandTab === orderTab.value ? "primary" : "inherit"} style={brandTab === orderTab.value ? activeTabLink : tabLink}>{orderTab?.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {
          pathname === '/user-roles' && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              roleAndPermissionTabs?.map((roleAndPermission => {
                return <Link key={roleAndPermission?.value} to={`${pathname}?roleTab=${roleAndPermission.value}`}>
                  <Button size="medium" variant="text" color={roleTab === roleAndPermission.value ? "primary" : "inherit"} style={roleTab === roleAndPermission.value ? activeTabLink : tabLink}>{roleAndPermission?.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })} width={{ xs: "auto" }}>
            {/* <MDBox color={light ? "white" : "inherit"}> */}
            {/* <IconButton
              size="large"
              disableRipple
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon sx={iconsStyle}>
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton> */}

            <Stack direction="row" alignItems="center" justifyContent="end" width="100%" gap={3}>
              {/* <FormGroup>
                  <FormControlLabel
                    control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
                    // label="MUI switch"
                  />
                </FormGroup> */}
              {/* <Switch {...label} /> */}

              {/* <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
                <MDTypography variant="h6">Light / Dark</MDTypography>
                <Switch checked={darkMode} onChange={handleDarkMode} />
              </MDBox> */}

              {/* <IconButton
                size="medium"
                disableRipple
                color="secondary"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant=""
                onClick={handleOpenMenu}
              >
                <Badge badgeContent={4} color="error" size="small">
                  <SvgIcon>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.3529 9.18605C18.3529 7.55774 17.6836 5.99613 16.4922 4.84474C15.3008 3.69335 13.6849 3.04651 12 3.04651C10.3151 3.04651 8.6992 3.69335 7.50779 4.84474C6.31639 5.99613 5.64706 7.55774 5.64706 9.18605V17.3721H18.3529V9.18605ZM20.4706 18.0546L20.8941 18.6C20.9531 18.676 20.989 18.7664 20.9979 18.861C21.0067 18.9557 20.9881 19.0508 20.9441 19.1358C20.9001 19.2208 20.8326 19.2922 20.7489 19.3422C20.6653 19.3921 20.5689 19.4186 20.4706 19.4186H3.52941C3.43109 19.4186 3.33472 19.3921 3.25108 19.3422C3.16745 19.2922 3.09986 19.2208 3.05589 19.1358C3.01192 19.0508 2.99331 18.9557 3.00214 18.861C3.01097 18.7664 3.04689 18.676 3.10588 18.6L3.52941 18.0546V9.18605C3.52941 7.01497 4.42185 4.93282 6.01039 3.39764C7.59893 1.86246 9.75346 1 12 1C14.2465 1 16.4011 1.86246 17.9896 3.39764C19.5782 4.93282 20.4706 7.01497 20.4706 9.18605V18.0546ZM9.35294 20.4419H14.6471C14.6471 21.1203 14.3682 21.771 13.8718 22.2507C13.3753 22.7305 12.702 23 12 23C11.298 23 10.6247 22.7305 10.1282 22.2507C9.63183 21.771 9.35294 21.1203 9.35294 20.4419V20.4419Z" fill="currentColor" />
                    </svg>
                  </SvgIcon>
                </Badge>
              </IconButton> */}

              {renderMenu()}
              {/* <IconButton size="small" disableRipple onClick={handleOpenProfileMenu}>
                  <Avatar
                    alt="Admin"
                    src={profileIcon}
                    sx={{ width: 32, height: 32 }}
                  />
                  <MDBox textAlign="left" ml={1.25} mt={-.25}>
                    <Typography variant="h5" fontSize={14} lineHeight={1.3} textTransform="capitalize">{user?.name}</Typography>
                    <Typography variant="h6" fontWeight="regular" fontSize={12} lineHeight={1.3} textTransform="capitalize">Super Admin</Typography>
                  </MDBox>
                </IconButton> */}

              {
                adminsMode !== HYPERDRIVE && <NotificationCount />
              }
              {
                adminsMode === HYPERDRIVE && <>
                  <ZoomOutMapOutlinedIcon style={{ fill: "#9A9AAF", cursor: "pointer" }} />

                  <FileDownloadOutlinedIcon style={{ fill: "#9A9AAF", cursor: "pointer" }} />

                  <Link to={`/hyperdrive/site-setting`} style={{ color: "#9A9AAF", display: "flex" }}>
                    <SettingsIcon />
                  </Link>

                  <MDButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleOpenTaskMenu}
                    endIcon={<ExpandMore sx={{ width: "22px", height: "22px" }} />}
                  >
                    <AddIcon />&nbsp;New Task
                  </MDButton>

                </>
              }

              <MDButton
                variant="contained"
                color="light"
                size="small"
                onClick={handleOpenAdminsMenu}
                endIcon={<ExpandMore sx={{ width: "22px", height: "22px" }} />}
                className="grey-btn"
              >
                {adminsMode}
              </MDButton>

              {renderAdminsMenu()}

              {renderTaskMenu()}

              <MDButton
                variant="contained"
                color="light"
                size="small"
                onClick={handleOpenProfileMenu}
                endIcon={<ExpandMore sx={{ width: "22px", height: "22px" }} />}
                className="grey-btn"
              >
                {user?.name?.trim() || user?.email?.trim()?.split('@')[0]}
              </MDButton>
              {renderProfileMenu()}


            </Stack>
            {/* </MDBox> */}
          </MDBox>
        )}
      </Toolbar>
    </AppBar >
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;