import React from 'react'

export default function TerpenesIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.19147 6.37577C3.92895 8.06788 5.17397 9.74156 6.91616 10.463C7.11503 10.5447 7.34083 10.606 7.57285 10.6571C8.03688 9.97454 8.56928 9.42071 9.19282 8.96706C9.72934 8.57878 10.2908 8.27428 10.8687 8.03516C10.703 7.92072 10.529 7.8165 10.3467 7.7225C9.62162 7.34852 8.83651 7.0195 8.07208 6.66596C8.79092 6.85806 9.6154 6.92958 10.3032 7.17686C10.732 7.33219 11.1277 7.54677 11.4964 7.80222C12.5964 7.43231 13.7461 7.25451 14.8938 7.11966C13.9637 5.59514 11.9791 4.37104 10.4834 3.81723C8.92143 3.23887 7.1523 3.16735 5.41425 2.98958C4.34946 2.88125 3.19145 2.4889 2.34417 2.03114C2.15151 1.92691 1.94643 2.10062 2.01272 2.31111C2.42913 3.62511 2.64043 5.11284 3.19147 6.37577Z" fill="currentColor"/>
      <path d="M21.556 6.1755C19.5963 7.22591 17.3486 7.35465 15.1466 7.60397C14.0714 7.72658 13.0066 7.87779 11.9936 8.18026C11.774 8.24563 11.5544 8.31922 11.339 8.40097C10.6989 8.64211 10.0815 8.95886 9.49942 9.38189C8.95253 9.77832 8.50506 10.2484 8.12804 10.7654C7.52728 11.591 7.10882 12.5412 6.76494 13.5283C6.13725 15.3205 5.8493 17.2251 5.90524 19.1196C5.92802 19.8879 5.94045 20.687 6.13311 21.437C6.17661 21.6025 6.21804 21.813 6.34855 21.9356C6.51842 22.097 6.82294 21.9315 6.75458 21.6965C6.26155 20.0392 6.56814 18.1019 7.24969 16.5181C8.03275 14.6972 9.545 13.6141 11.3928 12.9541C11.8838 12.7783 12.3851 12.6251 12.8947 12.48C12.9051 12.4759 12.9155 12.4759 12.9258 12.4759C13.0273 12.4759 13.0708 12.623 12.9652 12.67C12.3644 12.9377 11.7678 13.2075 11.2002 13.4997C8.42011 14.9302 7.20617 18.2613 8.24817 18.2613C8.33312 18.2613 8.43254 18.2388 8.54648 18.1918C10.154 17.5358 12.064 17.5297 13.8311 17.2068C14.4525 17.0944 15.0574 16.9411 15.6251 16.7082C17.8789 15.7804 19.4906 13.9657 20.4498 11.7831C21.1644 10.1544 21.442 8.23139 21.9827 6.53928C22.0532 6.32266 21.8978 6.13672 21.7072 6.13672C21.6596 6.13463 21.6078 6.14691 21.556 6.1755Z" fill="currentColor"/>
    </svg>
  )
}
